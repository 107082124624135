import { Button, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import swal from "sweetalert";
import { checkPermission } from "utils/checkPermission";
import Confirm from "../confirm";

export default function DeclineRequest({ open, onClose, id }) {
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");

  const [openConfirm, setOpenConfirm] = useState(false);
  const [formData, setFormData] = useState({
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDecline = async () => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/student-clearance/${id}`,
        {
          approved_by_id: userId,
          approval_type:
            role === "admin" ? "administration" : role.replace(" ", "_"),
          status: "declined",
          reason: formData.reason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        onClose();
        swal({
          title: "Request Declined Successfully!",
          icon: "success",
        });
        setFormData({
          reason: "",
        });
      }
    } catch (error) {
      console.error("Failed to approve request", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>Clearance Detail</DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Reason
          </MDTypography>
          <MDInput
            name="reason"
            fullWidth
            required
            value={formData.reason}
            onChange={handleChange}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        {checkPermission("approve-student-clearance") && (
          <Button
            style={{
              margin: "20px",
              padding: "5px 20px",
              backgroundColor: "#00274D",
              color: "#FFFFFF",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "13px",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#0056b3")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#00274D")
            }
            onClick={() => setOpenConfirm(true)}
          >
            Decline
          </Button>
        )}
      </DialogActions>

      <Confirm
        open={openConfirm}
        onConfirm={handleDecline}
        onCancel={() => setOpenConfirm(false)}
        title="Are you sure you want to decline this request?"
        description="Make sure you have a valid reason to decline this request."
      />
    </Dialog>
  );
}
