import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState,useCallback } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddYearDialog from "./addYear";
import EditYearDialog from "./editYear";
import LoadingDialog from 'components/Loading/LoadingDialog';

const Years = () => {
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);
  
    const [openEdit, setOpenEdit] = useState(false);
    const[years,setYears] = useState([])
    const [selectedYears, setSelectedYears] = useState(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(true); // Loading state
  const [deletingStatus, setDeletingStatus] = useState(false); 
  const role = localStorage.getItem("role");

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);
      const fetchYears = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/years`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
         
  
          setYears(response.data);
        } catch (err) {
          console.error(err);
        }  finally {
          setLoading(false)
        }
      },[token]);
      useEffect(() => {
       
        fetchYears();
      },[fetchYears]);

      const handleAddYears = (newYears) => {
        fetchYears();
      };
      
    
      const handleEditYears = (updatedYears) => {
        setYears(prevYears => prevYears.map((year) =>
          year.id === updatedYears.id ? { ...year, ...updatedYears } : year
        ));
      };

      const handleDeleteYears = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this Year!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          setDeletingStatus(true);
          try {
            const response = await axios.delete(`${API_BASE_URL}/years/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
            if(response.status === 204){
              setYears(years.filter((year) => year.id !== id));
              swal({
                title: "Years Deleted Successfully!",
                // text: "Course Created Successfully!",
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              // text: "Course Created Successfully!",
              icon: "warning"
            });
          }finally {
            setDeletingStatus(false); // End loading for deletion
          } 
        }
      };

      const handleOpenEdit = (years) => {
        setSelectedYears(years);
        setOpenEdit(true);
      };
    
      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedYears(null);
      };
      

      const columns = [
        ...(role !== 'academic dean'
          ? [
        {
          accessor: 'action',
          Header: '',  // You can leave this blank if you don't want a header
          width: 40, 
          padding:0,
          Cell: ({ row }) => (
            <PopupState variant="popover"  popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }}  style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteYears(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        }
      ]
      : []),
        { Header: 'Year Name', accessor: 'year_name' },
        {
          Header: 'Is Active',
          accessor: 'is_active',
          Cell: ({ value }) => (
            value === 1 ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
          ),
          showSortIcons: false,
        }
        
        
       
      ];
    
  return (
    <MDBox>
        <MDTypography variant="h5" fontWeight="medium">
           Years
         </MDTypography>
         <MDBox>
     
         <DataTable
            table={{
            columns,
            rows: years,
            }}
            canSearch={true}
            sortable={true}
            rowsPerPage={5}
            showPagination
            widgets = {role !== "academic dean" && (
            <MDBox ms={2}>
            <MDButton 
            variant="gradient"
            ml="auto"
                color="info"
                size="small"
                onClick={() => setOpenAdd(true)}
                sx={{background:"#00274D", my:3,mx:3}}
            >
            <span>Add</span>
            </MDButton>
            </MDBox>)
            }
        />

{!loading && years.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No data available
          </MDTypography>
        )}
         </MDBox>
         <AddYearDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddYears} />
              
        {selectedYears && (
        <EditYearDialog
            open={openEdit}
            onClose={handleCloseDialog}
            years={selectedYears}
            onEdit={handleEditYears}
        />
        )}
         <LoadingDialog open={loading || deletingStatus} />
    </MDBox>
  )
}

export default Years