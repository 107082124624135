import React, { useState ,useEffect,useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import ViewPurchaseRequest from 'layouts/adminDashboard/inventory/purchaseRequest/viewRequest';
import LoadingDialog from 'components/Loading/LoadingDialog';



const PurchaseList = () => {
  const [search] = useState('');
  const token = localStorage.getItem("authToken");


  const [requests, setRequests] = useState([]);

  const [openView, setOpenView] = useState(false);
  const [detailData,setdetailData] = useState([])
  const [loading, setLoading] = useState(false); 

  // Fetch store requests
  const fetchPurchaseRequest = useCallback(async () => {
   setLoading(true);
    try {
          const response = await axios.get(`${API_BASE_URL}/purchases`, {
              headers: { "Authorization": `Bearer ${token}` }
          });
          
          setRequests(response.data);
      } catch (err) {
          console.error('Error fetching store requests:', err);
      } finally {
        setLoading(false);
      }
  },[token]);

  useEffect(() => {
    fetchPurchaseRequest();
  },[fetchPurchaseRequest]);

 

const handleViewDetails = (data) => {
  setdetailData(data);
  setOpenView(true);
  
}


const handleCloseDialog = () => {
    setOpenView(false);
    fetchPurchaseRequest();
};

const filteredData = search
      ? requests && requests.filter(request =>
          request.store.store_name.toLowerCase().includes(search.toLowerCase())
      ) : requests;

      const columns = [
        {
          accessor: 'action',
          Header: '',
          width: 40,
          padding: 0,
          Cell: ({ row }) => (
            <PopupState popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                    handleViewDetails(row.original);
                    popupState.close();
                  }} style={{ color: '#1976d2' }}>
                    <VisibilityIcon style={{ marginRight: 8 }} /> View Details
                  </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
        },
        { Header: 'Supplier Name', accessor: 'supplier_name' },
        { Header: 'Requested Date', accessor: 'date' },
        { Header: 'Requested By', accessor: 'requested_by.full_name' }, 
        { Header: 'Total Amount', accessor: 'total_amount' }, 
        { Header: 'Store', accessor: 'store_location.name' },

            {
                Header: 'Request No',
                accessor: (row) => (
                    <Link to={`/finance/purchase-request`} 
                          style={{ textDecoration: 'none', color: 'blue' }}>
                        {`PR ${row.purchase_request.id}`}
                    </Link>
                )
            },
        { Header: 'Remark', accessor: 'remark' },
      ];

 
      

  return (
    <DashboardLayout>
          <MDBox>
              <Card>
                  <CardContent>
                      <MDTypography variant="h5" fontWeight="medium">
                          Purchase
                      </MDTypography>
                      <MDBox>
                          <DataTable
                              table={{
                                  columns,
                                  rows: filteredData,
                              }}
                              canSearch={true}
                              sortable={true}
                              pagination
                              rowsPerPage={5}
                              showPagination
                          />
                      </MDBox>
                    
                  </CardContent>
                  {
                    detailData && (
                      <ViewPurchaseRequest detailData={detailData} targetView = {"purchase"} openView={openView}  onClose={handleCloseDialog}/>
                    )
                  }

              </Card>
          </MDBox>
          <LoadingDialog open={loading} />
      </DashboardLayout>
  )
}

export default PurchaseList