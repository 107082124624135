import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import EditEducationalBackgroundDialog from './EditEducationalBackgroundDialog';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from '@mui/material';
import { axiosInstance } from 'api2';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import FileViewer from "components/FileViewer";
import MDButton from 'components/MDButton';

const EducationalBackground = ({ educationalBackground }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const {id} = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [open, setOpen] = useState(false);




  const handleEditClick = (background) => {
    setSelectedBackground(background);
    setOpenDialog(true);
  };

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpen(true);
  };

  const handleDelete = async (dataId) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this education background!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
     

      try {
        const response = await axiosInstance.delete(`/employees/${id}/educational-backgrounds/${dataId.id}`);
        if (response.status === 204) {
          // setStatus((prevStatus) => prevStatus.filter((status) => status.id !== id)); // Optimistic update
        swal({
          title: "Education Bckground Deleted Successfully!",
          icon: "success",
        }).then(() => window.location.reload());
        }
      } catch (error) {
        swal({
          title: `${error.response.data.message}`,
          icon: "warning"
        });
      } 
    }
  };


  const columns = [
    { Header: 'Education Level', accessor: 'level' },
    { Header: 'Educational Institution', accessor: 'educational_institution', showSortIcons: false },
    { Header: 'Education Type', accessor: 'education_type', showSortIcons: false },
    { Header: 'Attended Year', accessor: 'attended_year', showSortIcons: false },
    {
      Header: 'Document',
      accessor: 'document_path',
      Cell: ({ value }) => (
        <MDButton
          variant="body2"
          color="primary"
          component="a"
          onClick={() => handleViewFile(value)}
          style={{ cursor: 'pointer' }}
          sx={{ 
            cursor: 'pointer',
            textDecoration: 'underline',
            boxShadow: 1, // Adds a default shadow
            transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
            '&:hover': {
              color: 'secondary.main',
              boxShadow: 4, // Increases shadow intensity on hover
            }
          }}
        >
          View File
        </MDButton>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      showSortIcons:false,
      Cell: ({ row }) => (
        <MDBox display="flex" gap={2}>
          <IconButton onClick={() => handleEditClick(row.original)}>
           <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original)}>
           <DeleteIcon />
          </IconButton>
        </MDBox>
      ),
    },
  ];

  return (
    <MDBox>
      <Card>
        <CardContent>
          <MDTypography variant="h6">Education</MDTypography>
          <MDBox my={2}>
            <DataTable
              table={{
                columns,
                rows: educationalBackground,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
            />
          </MDBox>
          <FileViewer
        open={open}
        onClose={() => setOpen(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
          {openDialog && (
            <EditEducationalBackgroundDialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              background={selectedBackground}
            />
          )}
        </CardContent>
      </Card>
    </MDBox>
  );
};

export default EducationalBackground;
