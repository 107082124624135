import React,{useState,useEffect} from 'react';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useNavigate, useLocation } from "react-router-dom";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ScheduleIcon from '@mui/icons-material/Schedule';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const ExamIntroPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const examData = location.state;
  

    console.log("examData:",examData)

    const [timeLeft, setTimeLeft] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const examId = examData.exam_id;
    

    const examDate = new Date(examData.date);
    useEffect(() => {
        const checkExamStarted = () => {
            const now = new Date();
            if (now >= examDate) {
                setIsStarted(true);
            }
        };

        // Initial check
        checkExamStarted();

        // Re-check every second
        const startCheckInterval = setInterval(checkExamStarted, 1000);
        
        // Cleanup interval
        return () => clearInterval(startCheckInterval);
    }, [examDate]);


    useEffect(() => {
        // Store exam date in localStorage if it doesn't exist
        // const examDate = new Date(examData.date);
        const examDurationInMinutes = examData.duration || 0;
        const examEndDate = new Date(examDate.getTime() + examDurationInMinutes * 60000); // Exam end date

        if (!localStorage.getItem(`examStartTime_${examId}`)) {
            localStorage.setItem(`examStartTime_${examId}`, examDate);
        }

        const timer = setInterval(() => {
            const now = new Date();
            // console.log("examDate:", examDate, "now:", now);
            const difference = examEndDate - now; // Get the difference in milliseconds

            if (difference > 0) {
                // Calculate remaining time if the exam hasn't started yet
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                const formattedTimeLeft = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
                setTimeLeft(formattedTimeLeft);
                
                // Update remaining time in localStorage
                localStorage.setItem(`remainingTime_${examId}`, Math.floor(difference / 1000)); // Store remaining time in seconds
            } else {
                // If the exam has started, indicate that time has passed
                setTimeLeft('Time Passed');
                localStorage.setItem(`remainingTime_${examId}`, 0); // Set remaining time to 0 since the exam has started
                clearInterval(timer); // Stop the timer when the exam has started
            }
        }, 1000);

        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, [examData.date, examId,isStarted]);

    return (
        <DashboardLayout>
            <MDBox sx={{ p: { xs: 0, md: 3 } }}>
                <Card>
                    <MDBox p={3}>
                        <MDBox display="flex" alignItems="center" justifyContent="space-between">
                            <MDTypography variant="h3" fontWeight="semibold" gutterBottom color="#00274D" mb={2}>
                                Welcome to {examData.course_name} Exam
                            </MDTypography>
                            <MDTypography variant="h5" fontWeight="medium" color="error" sx={{ fontSize: '1.5rem', fontFamily: 'monospace' }}>
                            {timeLeft !== 'Time Passed' ? ("Time Left:"):""} {timeLeft}
                        </MDTypography>
                        </MDBox>
                        <MDTypography variant="body2" gutterBottom>
                            Please read the following instructions carefully before starting the exam.
                        </MDTypography>

                        <MDBox>
                            <MDTypography variant="body2" sx={{ mt: 1 }}>
                                <MDBox display="flex" alignItems="center">
                                    <QuestionMarkIcon sx={{ mr: 1, color: '#00274D' }} />
                                    <strong sx={{mr:1}}>Total Questions: </strong> {examData.questions.length}
                                </MDBox>
                            </MDTypography>

                            <MDTypography variant="body2" sx={{ mt: 1 }}>
                                <MDBox display="flex" alignItems="center">
                                    <ScheduleIcon sx={{ mr: 1, color: '#00274D' }} />
                                    <strong sx={{mr:1}}>Duration: </strong> {examData.duration} minutes
                                </MDBox>
                            </MDTypography>

                            <MDBox mt={3} display="flex" alignItems="center">
                                <WarningAmberIcon sx={{ mr: 1, color: 'red' }} />
                                <MDTypography variant="body2" color="error">
                                    <strong sx={{mr:1}}>Warning: </strong> Any form of cheating will result in a score of 0.
                                </MDTypography>
                            </MDBox>

                            <MDBox mt={3}>
                                <MDTypography variant="body2" color="textSecondary">
                                    Make sure to be ready before you start the exam, as timing will begin as soon as you click "Start Exam."
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        {
                            (isStarted && timeLeft !== "Time Passed") && (
                                <MDBox mt={2}>
                                <MDButton 
                                    variant="gradient"
                                    size="small"
                                    color="info" 
                                    onClick={() => navigate(`/exam/${examData.exam_id}`, { state:examData })}
                                    sx={{ background: "#00274D" }}
                                >
                                    Start Exam
                                </MDButton>
                            </MDBox>
                            )
                        }
                        {timeLeft !== "Time Passed" && 
                            <MDBox mt={3} textAlign="center">
                            <MDTypography variant="h4" color="green" fontWeight="medium">
                                Good Luck!
                            </MDTypography>
                        </MDBox>
                        }
                        
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
};

export default ExamIntroPage;
