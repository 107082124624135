import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { axiosInstance } from 'api2';
import LoadingDialog from 'components/Loading/LoadingDialog';
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

const StudentDashboard = () => {
  const [studentInfo, setStudentInfo] = useState(null);
  const targetId = localStorage.getItem('targetId');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/student-dashboard/${targetId}`);
        const data = await response.data;
        
        setStudentInfo({
          name: `${data.student.first_name} ${data.student.last_name}`,
          CGPA: data.cgpa || "N/A", // Set the CGPA calculated
          totalCourses: data.totalCoursesTaken,
          totalExam: data.totalExams,
          totalAssessment: data.totalAssessments,
          EnrolledCourses: data.courses.map(course => ({
            courseName: `${course.course_name}`,
            credit: `${course.credit_hour}`,
            courseNumber: `${course.course_number}`,
            courseDescription: `${course.course_description}`,
          })),
          announcements: data.announcements, // Add announcements if available
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
      }finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };
  
    fetchStudentData();
  }, [targetId]);
 


  const total = [
    {
      color: "primary",
      icon: "school", // or any suitable icon for employees
      label: "CGPA",
      count: studentInfo?.CGPA,
     
    },
    {
      color: "secondary",
      icon: "book", // or any suitable icon for payroll
      label: "Total Courses",
      count: studentInfo?.totalCourses, // Payroll count
    
    },
    {
      color: "success",
      icon: "assignment_turned_in", // or any suitable icon for leave requests
      label: "Total Exams",
      count: studentInfo?.totalExam
     
    },
    {
      color: "error",
      icon: "assessment", // or any suitable icon for leave requests
      label: "Total Assessment",
      count: studentInfo?.totalAssessment
     
    },
  ];


  return (
    <DashboardLayout>
        <>
        
      <MDBox mt={4}>
      <Grid container spacing={3}>
            {total.map((item, index) => {
              return (
                <Grid item xs={true} key={index}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color={item.color}
                      icon={item.icon}
                      title={item.label}
                      count={item.count}
                    />
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
  </MDBox>


      <MDBox sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, mt: 4 }}>
        {/* Enrolled Courses Section */}
        <MDBox sx={{ flex: 2 }}>
          <MDTypography variant="h5" fontWeight="medium">
            Enrolled Courses
          </MDTypography>
          <Grid container spacing={3} mt={2}>
  {studentInfo?.EnrolledCourses.map((item, index) => (
    <Grid item xs={12} sm={6} key={index}>
      <Card>
        <CardContent>
          <MDTypography variant="h6" fontWeight="medium">
            {item.courseName}
          </MDTypography>
          <MDTypography variant="body2" color="text.secondary">
            Course Number: {item.courseNumber}
          </MDTypography>
          <MDTypography variant="body2" color="text.secondary">
            Credits: {item.credit}
          </MDTypography>
          
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

        </MDBox>

        {/* Announcements Section */}
        <MDBox sx={{ flex: 1, p: 2, borderRadius: 2 }}>
          <MDTypography variant="h5" fontWeight="medium" sx={{ mb: 2 }}>
            Announcements
          </MDTypography>
          {studentInfo?.announcements && studentInfo.announcements.map((item) => (
            <MDBox key={item.id} sx={{ mb: 2, p: 2, bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
              <MDTypography variant="h6" fontWeight="medium">
                {item.title}
              </MDTypography>
              <MDTypography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Announced By:</strong> {item?.user?.full_name} | <strong>Date:</strong> {new Date(item.announcement_date).toLocaleDateString()}
              </MDTypography>
              <MDTypography variant="body2" sx={{ color: 'text.primary' }}>
                {item.content}
              </MDTypography>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
        </>

     
     <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default StudentDashboard;
