import React, { useEffect, useState } from "react";
import DataTable from 'examples/Tables/DataTable';
import { Typography, TextField, Autocomplete } from "@mui/material";
import MDBox from 'components/MDBox';
import { axiosInstance } from "api2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, CardContent, useMediaQuery  } from "@mui/material";
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import LoadingDialog from "components/Loading/LoadingDialog";
import MDTypography from "components/MDTypography";

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const targetId = localStorage.getItem("targetId");
  const [loading,setLoading] = useState(true);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [attendanceResponse, coursesResponse] = await Promise.all([
          axiosInstance.get(`/student/${targetId}/attendance`),
          axiosInstance.get(`/student/${targetId}/registration`)
        ]);

        setAttendanceData(attendanceResponse.data[0].attendances);

        const registrations = coursesResponse.data[0].registrations; 

        const courses = registrations.map((registrationDetail) => {
          const courseHistory = registrationDetail.course_history;
          if (courseHistory && courseHistory.instructor) {
            return {
              courseId: courseHistory.course.id,
              courseNumber: courseHistory.course.course_number,
              courseName: courseHistory.course.course_name,
              creditHour: courseHistory.course.credit_hour,
              instructorName: courseHistory.instructor.full_name,
              registrationDate: registrationDetail.registration_date,
              remark: courseHistory.remark
            };
          } else {
            return null; 
          }
        }).filter(course => course !== null); 

        setCourses(courses);
      } catch (error) {
        console.error("Error fetching data:", error);
        
      }finally{
        setLoading(false);
      }
    };

    fetchData();
  }, [targetId]);

  const columns = [
    { Header: "Course Name", accessor: "course.course_name" },
    { Header: "Course Number", accessor: "course.course_number", showSortIcons: false },
    { Header: "Instructor Name", accessor: "instructor.full_name" },
    { Header: "Day", accessor: "day", showSortIcons: false },
    {
      Header: "Status",
      accessor: "status",
      showSortIcons: false,
      Cell: ({ value }) => renderStatus(value),  // Use renderStatus for displaying status
    },
  ];

  const renderStatus = (status) => {
    switch (status) {
      case 'present':
        return <CheckCircleIcon color="success" />;
      case 'absent':
        return <CancelIcon color="error" />;
      case 'late':
        return (
          <Typography
            component="span"
            style={{ display: 'flex', alignItems: 'center', color: '#FF9800',fontSize:"1rem" }}  // Orange color for late
          >
            <AccessTimeIcon style={{ marginRight: 4, }} /> {/* Optional clock icon */}
            Late
          </Typography>
        );
      default:
        return '-';
    }
  };

  const filteredAttendanceData = attendanceData.filter(item => {
    const isMatchingCourse = selectedCourse ? item.course.course_name === selectedCourse.courseName : true;
    const isMatchingDate = selectedDate ? item.day === selectedDate : true; // Filter by date
    return isMatchingCourse && isMatchingDate;
  });



  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <Typography variant="h5" fontWeight="medium" mb={2}>
            Attendances
          </Typography>
 
    <DataTable
      table={{
        columns: columns,
        rows: filteredAttendanceData,
      }}
      canSearch
      showTotalEntries={false}
      isSorted
      noEndBorder
      widgets={
        <MDBox display="flex" flexDirection={isSmallScreen ? 'column' : 'row'}>
          {/* Course Autocomplete */}
          <Autocomplete
            fullWidth
            sx={{ mx: 2, my: 2, width: isSmallScreen ? '150px' : '200px' }}
            options={courses}
            getOptionLabel={(option) => option.courseName || ''}
            onChange={(event, newValue) => setSelectedCourse(newValue)}
            value={selectedCourse}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Course"
                InputProps={{
                  ...params.InputProps,
                  style: { height: '40px' }, // Adjust the height as needed
                }}
                InputLabelProps={{
                  style: { marginTop: '0px' }, // Adjust the label margin if needed
                }}
              />
            )}
          />

          {/* Date Picker */}
          <TextField
            type="date"
            fullWidth
            sx={{ mx: 2, my: 2, width: isSmallScreen ? '150px' : '200px' }}
            onChange={(event) => setSelectedDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MDBox>
      }
    />
     {!loading && filteredAttendanceData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No Data available
          </MDTypography>
        )}




        <LoadingDialog open={loading} />
        </CardContent>
      </Card>
    </DashboardLayout>
  )
}

export default Attendance;
