import React, { useEffect, useState,useCallback } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';

import { Check } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Card, CardContent } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { checkPermission } from "utils/checkPermission";
import CourseHistoryForm from "./create";
import ApproveStudents from "./deanApproval";
import RegisteredStudents from "./registeredStudents";
import LoadingDialog from "components/Loading/LoadingDialog";



const CourseHistroy = () => {
  const [search] = useState('');
  const [loading, setLoading] = useState(true);
  const[registeredStudents,setRegisteredStudents] = useState([]);
  const[courseHistory,setCourseHistory] = useState([])
  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [deletingStatus, setDeletingStatus] = useState(false); 
  const role = localStorage.getItem("role");

  const token =  localStorage.getItem("authToken");

  const fetchCourseHistory = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/course-history`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      
      setCourseHistory(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[token]);

  useEffect(() => {
    fetchCourseHistory();
  },[fetchCourseHistory]);

  const handleAddCourseHistory = () => {
    fetchCourseHistory()
  };



  const handleDeleteCourse = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this course!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setDeletingStatus(true);
      try {
        const response = await axios.delete(`${API_BASE_URL}/course-history/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 200){
          
          swal({
            title: "Course Deleted Successfully!",
            // text: "Course Created Successfully!",
            icon: "success"
          });
          fetchCourseHistory();
        }
      } catch (error) {
        
        console.log("error:",error)
        if(error){
          swal({
            title: `${error.response.data.message}`,
            // text: "Course Created Successfully!",
            icon: "warning"
          });

        }
      }finally {
        setDeletingStatus(false); // End loading for deletion
      } 
    }
  };

  const handleViewDetails = (data) => {
   
    setRegisteredStudents(data.registrations)

    setOpenView(true);
    
  }
  const approvalstudent = (data) => {
   
    setRegisteredStudents(data.registrations)
    setOpenApproval(true);
    
  }

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenView(false);
    setOpenApproval(null);
  };
  


  const filteredData = courseHistory.filter(course => {
    return (
      course.course.course_name.toLowerCase().includes(search.toLowerCase()) ||
      course.course.course_number.toLowerCase().includes(search.toLowerCase()) ||
      course.instructor.full_name.toLowerCase().includes(search.toLowerCase())   

    );
  });

  const columns = [
    {
      accessor: 'action',
      Header: '',
      width: 40,  
      padding:0,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
            <MoreVertIcon />
          </Button>
          <Menu {...bindMenu(popupState)}>
          {role !== "academic dean" && (
               <React.Fragment>
            <MenuItem onClick={() => {
              // handleOpenEdit(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <EditIcon style={{ marginRight: 8 }} /> Edit
            </MenuItem>
            <MenuItem onClick={() => {
              handleDeleteCourse(row.original.id);
              popupState.close();
            }} style={{ color: '#d32f2f' }}>
              <DeleteIcon style={{ marginRight: 8 }} /> Delete
            </MenuItem>
            
              </React.Fragment>
          )}
              { checkPermission('registration-approval') && <MenuItem onClick={() => {
                  approvalstudent(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <Check style={{ marginRight: 8 }} /> Approve
                </MenuItem>
              }
            <MenuItem onClick={() => {
              handleViewDetails(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
     
           </Menu>
        </React.Fragment>
      )}
    </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'Semester', accessor: 'semester', sortable: true ,showSortIcons:false },
    { Header: 'Year', accessor: 'year', sortable: true,showSortIcons:false  },
    { Header: 'Course Number', accessor: 'course.course_number', sortable: true,showSortIcons: false },
    { Header: 'Course Name', accessor: 'course.course_name', sortable: true,showSortIcons:false  },
    { Header: 'Credit Hour', accessor: 'course.credit_hour', sortable: true,showSortIcons: false},
      {
          Header: 'Instructor',
          accessor: row => row.instructor ? row.instructor.full_name : 'TBA',
                    sortable: true,showSortIcons: false,
      },
  { Header: 'Remark', accessor: 'remark', sortable: true,showSortIcons: false },
   
  ];



  

  return (
    <DashboardLayout>
        
      <MDBox>     
      <Card>
        <CardContent>
          <MDTypography variant="h5" fontWeight="medium">
              Course History
            </MDTypography>
            <div>
          
        </div>
              <MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                  widgets = {role !== "academic dean" && (
                    <MDBox ms={2}>
                   <MDButton 
                   variant="gradient"
                   ml="auto"
                       color="info"
                       size="small"
                       onClick={() => setOpenAdd(true)}
                       sx={{background:"#00274D", my:4,mx:1}}
                 >
                   <span>Add </span>
                 </MDButton>
                 </MDBox>
                   )}
                />
                {!loading && filteredData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No Course History available
          </MDTypography>
        )}
              </MDBox>
              <CourseHistoryForm open={openAdd} onClose={handleCloseDialog} onAdd={handleAddCourseHistory} />
              {
                registeredStudents &&(
                  <RegisteredStudents registeredStudents={registeredStudents} openView={openView} onClose={handleCloseDialog}/>
                )
              }
              
              {
                registeredStudents &&(
                  <ApproveStudents registeredStudents={registeredStudents} openView={openApproval} onClose={handleCloseDialog}/>
                )
              }
            </CardContent>
      </Card>

      </MDBox>
  
      <LoadingDialog open={loading || deletingStatus} />
     </DashboardLayout>
  )
}

export default CourseHistroy