import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  first_name: "",
  last_name: "",
  postal_address: "",
  private_address: "",
  profile_picture: null,
  email_1: "",
  email_2: "",
  phone: 0,
  date_of_birth: "",
  place_of_birth: "",
  nationality: "",
  nationality_at_birth: "",
  gender: "",
  emergency_name: "",
  emergency_address: "",
  emergency_phone: "",
  emergency_relationship: "",
  program_id: "",
  status: "",
  application_date: "",
  registration_date: "",
  personal_testimony: "",
  personal_intent: "",
  denomination: "",
  local_congregation: "",
  church_involvement: "",
  denomination_address: "",
  church_phone: "",
  jesus_receive_date: "",
  ministry_experience: "",
  ministry_duration: 0,
  sponsor_ship: "",
  sponsor_name: "",
  sponsor_address: "",
  sponsor_phone: "",
  sponsor_email: "",
  physical_challenge: "",
  dorm_id: 0,
  can_use_cafe: false,
  other_information: "",
};

const studentInfoSlice = createSlice({
  name: "studentInfo",
  initialState,
  reducers: {
    // addStudentInfo: (state, action) => {
    //   state.push(action.payload);
    // },
    updateStudentInfo: (state, action) => {
      return { ...state, ...action.payload };
      // console.log("Updated state:", JSON.parse(JSON.stringify(state)));
    },

    updateFile: (state, action) => {
      const { field, file } = action.payload; // field can be 'profile_picture', 'personal_testimony', etc.
      state[field] = file; // Update the specific file field
    },
  },
});

export const { updateStudentInfo, updateFile } = studentInfoSlice.actions;

export default studentInfoSlice.reducer;
