import React,{useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MDButton from 'components/MDButton';
import {axiosInstance} from 'api2';

import FileViewer from "components/FileViewer";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { STORAGE_BASE_URL } from 'api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Collapse,  IconButton } from '@mui/material'
import LoadingDialog from 'components/Loading/LoadingDialog';
import { Avatar } from '@mui/material';




const columnsPayments = [
  { Header: 'Full Name', accessor: 'full_name' },
  { Header: 'Phone Number', accessor: 'phone_number',showSortIcons: false },
  { Header: 'Payment Amount', accessor: 'payment_amount',showSortIcons: false },
  { Header: 'Payment Method', accessor: 'payment_method',showSortIcons: false },
  { Header: 'Payment Date', accessor: 'payment_date',showSortIcons: false },
  { Header: 'Transaction ID', accessor: 'transaction_id', },
  { Header: 'Reviewed', accessor: 'is_reviewed' }, // 0 or 1, can be displayed as 'No' or 'Yes'
];


const columnsEducationalBackground = [
  { Header: 'Country', accessor: 'country' },
  { Header: 'Institution', accessor: 'educational_institution',showSortIcons: false },
  { Header: 'Program', accessor: 'study_program',showSortIcons: false },
  { Header: 'Major', accessor: 'major',showSortIcons: false },
  { Header: 'Degree', accessor: 'class_of_degree',showSortIcons: false },
  { Header: 'Graduation Year', accessor: 'college_graduation_year',showSortIcons: false },
  { Header: 'Attended Year', accessor: 'attended_year',showSortIcons: false },
  { Header: 'Document', accessor: 'document',showSortIcons: false }
];

const columnsEmploymentRecord = [
  { Header: 'Employer Name', accessor: 'employer_name' },
  { Header: 'Address', accessor: 'employer_address',showSortIcons: false },
  { Header: 'Job Title', accessor: 'job_title',showSortIcons: false },
  { Header: 'Start Date', accessor: 'employment_start_date',showSortIcons: false },
  { Header: 'End Date', accessor: 'employment_end_date',showSortIcons: false },
  { Header: 'Responsibilities', accessor: 'job_responsibilities',showSortIcons: false }
];

const columnsReferences = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Relationship', accessor: 'relationship',showSortIcons: false },
  { Header: 'Phone', accessor: 'phone',showSortIcons: false },
  { Header: 'Email', accessor: 'email',showSortIcons: false }
];


const MyAccount = () => {
  const [jsonData, setJsonData] = useState([]);
  const targetId = localStorage.getItem("targetId");
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandExperience, setExpandExperience] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    };

    const handleExpandExperience = () => {
      setExpandExperience(!expandExperience);
  };


  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await axiosInstance.get(`applicant-student/${targetId}`);
        setJsonData(response.data.student);
        // setData(response.data);
      } catch (error) {
        console.error(error);
      }finally {
        setLoading(false); // Stop loading once data is fetched
      }
    }
    fetchStudent();
  }, [targetId]);

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };

  if(jsonData.length === 0 || loading) {
    return <LoadingDialog open={loading} />;
  }

  const lables = (label) => (  
    <span style={{ color: '#00274D',fontWeight:"normal",fontSize:"1rem" }} variant="h6" fontWeight="medium">{label}</span>
); 

const valueStyle = { 
color: '#6B7280', // Tailwind Gray-500 for subtle contrast
fontWeight: "normal", 
fontSize: "1rem",
marginLeft: '0.5rem' // Adds space between label and value
};


  return (
    <DashboardLayout>
        <MDBox mt={1}>
        <Grid container spacing={2}  >
          {/* First Grid for Personal Data */}
          <Grid item xs={12} sm={6} md={7}>
        <Card>
        <MDBox display="flex" justifyContent="center">
  
    <Avatar
                      sx={{ width: 120, height: 120,marginY:3 }}
                      src={`${STORAGE_BASE_URL}/${jsonData.profile_picture}`}
                    />
</MDBox>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography variant="h6" fontWeight="bold">Applicant Information</MDTypography>
                <MDBox mt={1}>
                  <MDTypography variant="body2">
                    {lables("Private residential (address):")}
                    <span style={valueStyle}>{jsonData.private_address}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Email (1):")} 
                    <span style={valueStyle}>{jsonData.email_1}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Email (2):")} 
                    <span style={valueStyle}>{jsonData.email_2}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Place of Birth:")} 
                    <span style={valueStyle}>{jsonData.place_of_birth}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Nationality/at birth:")} 
                    <span style={valueStyle}>{jsonData.nationality_at_birth}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Gender:")} 
                    <span style={valueStyle}>{jsonData.gender}</span>
                  </MDTypography>
                  
                  {/* Other sections */}
                  
                  <MDTypography variant="h6" fontWeight="bold" mt={3}>Religious Background</MDTypography>
                  <MDBox mt={1}>
                    <MDTypography variant="body2">
                      {lables("Denomination:")} 
                      <span style={valueStyle}>{jsonData.denomination}</span>
                    </MDTypography>
                    <MDTypography variant="body2">
                      {lables("Local Congregation:")}
                      <span style={valueStyle}>{jsonData.local_congregation}</span>
                    </MDTypography>
                    <MDTypography variant="body2">
                      {lables("Address:")} 
                      <span style={valueStyle}>{jsonData.denomination_address}</span>
                    </MDTypography>
                    <MDTypography variant="body2">
                      {lables("Tel No:")} 
                      <span style={valueStyle}>{jsonData.church_phone}</span>
                    </MDTypography>
                    <MDTypography variant="body2">
                      {lables("Jesus Christ received date:")} 
                      <span style={valueStyle}>{jsonData.jesus_receive_date}</span>
                    </MDTypography>

                    <MDBox display="flex" alignItems="center" justifyContent="space-between">
                      <MDTypography variant="body2">
                        {lables("Ministry Experience")}
                      </MDTypography>
                      <IconButton onClick={handleExpandClick}>
                        <ExpandMoreIcon />
                      </IconButton>
                    </MDBox>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <MDTypography variant="body2">
                          {jsonData.ministry_experience}
                        </MDTypography>
                      </CardContent>
                    </Collapse>

                    <MDTypography variant="body2">
                      {lables("Ministry Duration:")} 
                      <span style={valueStyle}>{jsonData.ministry_duration}</span>
                    </MDTypography>

                  </MDBox>
                </MDBox>
              </Grid>
              
              {/* Right side for Sponsor and Employment Records */}
              
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography variant="h6" fontWeight="medium">Sponsor</MDTypography>
                <MDBox mt={1}>
                  <MDTypography variant="body2">
                    {lables("Sponsor:")} 
                    <span style={valueStyle}>{jsonData.sponsor_ship}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Name:")} 
                    <span style={valueStyle}>{jsonData.sponsor_name}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Telephone:")} 
                    <span style={valueStyle}>{jsonData.sponsor_phone}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Address:")} 
                    <span style={valueStyle}>{jsonData.sponsor_address}</span>
                  </MDTypography>
                  <MDTypography variant="body2">
                    {lables("Email:")} 
                    <span style={valueStyle}>{jsonData.sponsor_email}</span>
                  </MDTypography>
                </MDBox>
                
                {/* Employment Records */}
                <MDTypography variant="h6" fontWeight="bold" mt={5}>Employment Records</MDTypography>
                {jsonData.employment_records.length > 0 && jsonData.employment_records.map((employment, index) => (
                  <MDBox mt={1} key={index}>
                    <MDTypography variant="body2">
                      {lables("Employer:")} 
                      <span style={valueStyle}>{employment.employer_name}</span>
                    </MDTypography>
                    <MDTypography variant="body2">
                      {lables("Address:")} 
                      <span style={valueStyle}>{employment.employer_address}</span>
                    </MDTypography>
                    <MDTypography variant="body2">
                      {lables("Job Title:")} 
                      <span style={valueStyle}>{employment.job_title}</span>
                    </MDTypography>
                    <MDTypography variant="body2">
                    {lables("Start Date:")} 
                    <span style={valueStyle}>{employment.employment_start_date}</span>
                  </MDTypography>

                  <MDTypography variant="body2">
                    {lables("End Date:")} 
                    <span style={valueStyle}>{employment.employment_end_date}</span>
                  </MDTypography>

                  <MDBox display="flex" alignItems="center" mt={3} justifyContent="space-between">
                    <MDTypography variant="body2">
                      {lables("Description of your work")}
                    </MDTypography>
                    <IconButton onClick={handleExpandExperience}>
                      <ExpandMoreIcon />
                    </IconButton>
                  </MDBox>

                  <Collapse in={expandExperience} timeout="auto" unmountOnExit>
                    <CardContent>
                      <MDTypography variant="body2">
                        {employment.job_responsibilities}
                      </MDTypography>
                    </CardContent>
                  </Collapse>
                  </MDBox>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <MDBox display = "flex" flexDirection = "column" gap={2}>
          <Accordion sx={{py:2}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
                <MDTypography variant="h6" fontWeight="medium">Application Fee</MDTypography>
                </AccordionSummary>
                              <AccordionDetails>
                                
                                   <DataTable
                        table={{ columns: columnsPayments, rows: jsonData.payments ? jsonData.payments : [] }} />
                    
        </AccordionDetails>
      </Accordion>

         
            <Card mt={2}>
              <CardContent>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" py={2}>
                <MDTypography variant="h6" fontWeight="medium">Testimony of spiritual Life</MDTypography>
                <MDButton
                        variant="body2"
                        color="primary"
                        component="a"
                        onClick={() => handleViewFile(jsonData.personal_testimony)}
                        style={{ cursor: 'pointer' }}
                        sx={{ 
                          cursor: 'pointer',
                          color: '#00274D',
                          textDecoration: 'underline',
                          boxShadow: 1, // Adds a default shadow
                          transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
                          '&:hover': {
                            color: 'secondary.main',
                            boxShadow: 4, // Increases shadow intensity on hover
                          }
                        }}
                      >
                        View File
                      </MDButton>

                </MDBox>
                
              </CardContent>
              
            </Card>
            <Card mt={2}>
              <CardContent>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" py={2}>
                <MDTypography variant="h6" fontWeight="medium">Personal Intent</MDTypography>
                <MDButton
                        variant="body2"
                        color="primary"
                        component="a"
                        onClick={() => handleViewFile(jsonData.personal_intent)}
                        style={{ cursor: 'pointer' }}
                        sx={{ 
                          cursor: 'pointer',
                          color: '#00274D',
                          textDecoration: 'underline',
                          boxShadow: 1, // Adds a default shadow
                          transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
                          '&:hover': {
                            color: 'secondary.main',
                            boxShadow: 4, // Increases shadow intensity on hover
                          }
                        }}
                      >
                        View File
                      </MDButton>

                </MDBox>
                
              </CardContent>
              
            </Card>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
                <MDTypography variant="h6" fontWeight="medium">References</MDTypography>
                </AccordionSummary>
        <AccordionDetails>
        <DataTable
                        table={{ columns: columnsReferences, rows: jsonData.references ? jsonData.references : [] }} />
          </AccordionDetails>
          </Accordion>
          <Accordion>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1-content"
    id="panel1-header"
  >
    <MDTypography variant="h6" fontWeight="medium">Educational Background</MDTypography>
  </AccordionSummary>
  <AccordionDetails>
    <DataTable
      table={{
        columns: columnsEducationalBackground,
        rows: jsonData.educational_backgrounds ? jsonData.educational_backgrounds : []
      }} 
    />
  </AccordionDetails>
</Accordion>
<Accordion>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel3-content"
    id="panel3-header"
  >
    <MDTypography variant="h6" fontWeight="medium">Employment Record</MDTypography>
  </AccordionSummary>
  <AccordionDetails>
    <DataTable
      table={{
        columns: columnsEmploymentRecord,
        rows: jsonData.employment_records ? jsonData.employment_records : []
      }} 
    />
  </AccordionDetails>
</Accordion>


          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
                <MDTypography variant="h6" fontWeight="medium">Languages</MDTypography>
                </AccordionSummary>
        <AccordionDetails>
        <MDBox p={2}  borderColor="divider">
        <MDTypography variant="body2">
    {lables("Mother Tongue:")} <span style={valueStyle}>{jsonData.languages.find(lang => lang.education_level === "Mother Tongue")?.language}</span><br />
    {lables("Language Proficiency Mother tongue Language(s) used as medium of instruction in secondary school:")} <span style={valueStyle}>{jsonData.languages.find(lang => lang.education_level === "Secondary School")?.language}</span><br />
    {lables("Language(s) used as medium of instruction in higher education:")} <span style={valueStyle}>{jsonData.languages.find(lang => lang.education_level === "High School")?.language}</span><br />
    {lables("Other language(s) of which you have a working knowledge:")}  <span style={valueStyle}>{jsonData.languages.find(lang => lang.education_level === "Other Languages")?.language}</span><br />
    {lables("Do you use English in your work?")} <span style={valueStyle}>{jsonData.languages.find(lang => lang.education_level === "Do you use English in your work")?.language}</span>
  </MDTypography>
</MDBox>

          </AccordionDetails>
          </Accordion>
          </MDBox>
          </Grid>
        </Grid>
        <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />


        </MDBox>
        <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default MyAccount;
