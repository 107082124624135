import React, { useState } from 'react';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';

const ExpandableDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 50; // Adjust this value to change the truncation length

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <MDBox>
      <MDTypography 
        variant="body2" 
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          overflow: isExpanded ? 'visible' : 'hidden',
          textOverflow: isExpanded ? 'clip' : 'ellipsis',
          maxWidth: '300px', // Adjust this value as needed
        }}
      >
        {description}
      </MDTypography>
      {description.length > maxLength && (
        <MDButton onClick={toggleExpand} variant="text" color="info" size="small">
          {isExpanded ? 'Less' : 'More'}
        </MDButton>
      )}
    </MDBox>
  );
};

export default ExpandableDescription;