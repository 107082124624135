
const getCGPAStatus = (cgpa) => {
  if (cgpa < 1.3) {
    return { status: 'Advised to withdraw', color: 'red' };
  } else if (cgpa >= 1.5 && cgpa < 1.75) {
    return { status: 'Probation', color: 'orange' };
  } else if (cgpa >= 1.75 && cgpa < 3.5) {
    return { status: 'Warning', color: 'yellow' };
  } else if (cgpa >= 3.5 && cgpa < 3.7) {
    return { status: 'Distinction', color: 'green' };
  } else if (cgpa >= 3.7 && cgpa < 3.9) {
    return { status: 'Great Distinction', color: 'blue' };
  } else if (cgpa >= 3.9) {
    return { status: 'Very Great Distinction', color: 'purple' };
  } else {
    return { status: 'Invalid CGPA', color: 'black' };
  }
};
export default getCGPAStatus;