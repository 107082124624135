import React, { useState ,useEffect,useCallback} from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";

const Bincard = () => {
    const token =  localStorage.getItem("authToken");
    
    const[bincard,setBincard] = useState([])

    
      const fetchAsset = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/bincard`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
                 
          setBincard(response.data);
        } catch (err) {
          console.error(err);
        } 
       
      },[token]);
      useEffect(() => {

        fetchAsset();
      },[fetchAsset]);

      const columns = [
        { Header: 'Asset Code', accessor: 'asset.code',showSortIcons: false, },
        { Header: 'Asset Name', accessor: 'asset.name', },
        { Header: 'In Quantity', accessor: 'in_quantity',showSortIcons: false, },
        { Header: 'OUt Quantity', accessor: 'out_quantity',showSortIcons: false, },
        { Header: 'Balance', accessor: 'balance',showSortIcons: false, },
        { Header: 'Date', accessor: 'date',showSortIcons: false, },
        { Header: 'Location', accessor: 'store_location.name',showSortIcons: false, },
      ];
  return (

    <>

        <MDBox >
                <MDTypography variant="h5" fontWeight="medium">
                   Bincard Balance
                 </MDTypography>
                 <MDBox>
                
                <MDBox >
                    <DataTable
                      table={{
                        columns,
                        rows: bincard
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
                    />
                </MDBox>
                
                </MDBox>
                
        </MDBox>

        </>
  )
}

export default Bincard