import React,{useState,useEffect} from "react";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example app
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import LoadingDialog from "components/Loading/LoadingDialog";

// Data
import {
  
  announcements,
} from "../dashboard/data/data";

// Dashboard components
import MDTypography from "components/MDTypography";
import { axiosInstance } from "api2";

function formatNumber(num) {
  // Ensure the input is a number
  const numberValue = parseFloat(num);
  if (isNaN(numberValue)) {
      return 'Invalid number';
  }
  // Convert the number to a string and use toLocaleString for formatting
  return numberValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  });
}


function HrDashboard() {
  const [data, setData] = useState({ employee: [], leaveRequests: 0, payroll: 0,announcements,payrolls:[] });
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/hr-dashboard`);
        const data = await response.data;
        

        setData({
          employee: data.employees,
          leaveRequests:data.leave_count,
          payroll:data.total_net_pay,
          announcements:data.announcements,
          payrolls:data.payrolls
        });
     
      } catch (error) {
        console.error('Error fetching student data:', error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };
  
    fetchStudentData();
  }, []);

  const total = [
    {
      color: "primary",
      icon: "group", // or any suitable icon for employees
      label: "Total Employees",
      count: data.employee?.length.toLocaleString('en-US'),
     
    },
    {
      color: "secondary",
      icon: "attach_money", // or any suitable icon for payroll
      label: "Payroll Records",
      count: formatNumber(data.payroll), // Payroll count
    
    },
    {
      color: "error",
      icon: "event_available", // or any suitable icon for leave requests
      label: "Leave Requests",
      count: data.leaveRequests
     
    },
  ];

  let totalBasicSalary = 0;
let totalGrossPay = 0;
let totalNetPay = 0;

// Iterate through payroll data to calculate totals
data.payrolls.forEach(payroll => {
    totalBasicSalary += parseFloat(payroll.basic_salary);
    totalGrossPay += parseFloat(payroll.gross_pay);
    totalNetPay += parseFloat(payroll.net_pay);
});


const payrollDashboard = {
  labels: ["Total Basic Salary", "Total Gross Pay", "Total Net Pay"],
  datasets: [
      {
          label: "Payroll Summary",
          color: "info",
          data: [totalBasicSalary, totalGrossPay, totalNetPay],
      },
  ],
};


  const columns = [
    {
        Header: "First Name",
        accessor: "first_name", 
        Cell: ({ row }) => `${row.original.first_name} ${row.original.last_name}`,
        showSortIcons: true
    },
 
    {
        Header: "Email",
        accessor: "email", // Maps to employee's email
        showSortIcons: false
    },
    {
        Header: "Phone",
        accessor: "phone",
        showSortIcons: false
    },
    {
        Header: "Position Title",
        accessor: "position.position_title", // Maps to employee's position title
        showSortIcons: false
    },
    
    {
        Header: "Salary Range Min",
        accessor: "position.salary_range_min", // Maps to position's minimum salary
        showSortIcons: false,
        Cell: ({ value }) => formatNumber(value),
    },
    {
        Header: "Salary Range Max",
        accessor: "position.salary_range_max", // Maps to position's maximum salary
        showSortIcons: false,
        Cell: ({ value }) => formatNumber(value),
    },
   
];


  return (
    <DashboardLayout>
     

      {/* Dashboard */}
      <Grid container spacing={3} pt={6}>
        {/* Charts */}
        <Grid item xs={12} >
          <Grid container spacing={3}>
            {total.map((item, index) => {
              return (
                <Grid item xs={true} key={index}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color={item.color}
                      icon={item.icon}
                      title={item.label}
                      count={item.count}
                    />
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={3} pt={6}>
            <Grid item xs={12} lg={8}>
                <VerticalBarChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Payroll Summary"
                description="Summary of Payroll Information"
                chart={payrollDashboard}
            />
      
            </Grid>
            <Grid item xs={12} lg={4}>
            
          <Card>
              {
              data.announcements.map((item) => (
                <MDBox key={item.id} sx={{ mb: 2, p: 2, bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
                  <MDTypography variant="h6" fontWeight="medium">{item.title}</MDTypography>
                  <MDTypography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                    <strong>Announced By:</strong> {item?.user?.full_name} | <strong>Date:</strong> {new Date(item.announcement_date).toLocaleDateString()}
                  </MDTypography>
                  <MDTypography variant="body2" sx={{ color: 'text.primary' }}>{item.content}</MDTypography>
                </MDBox>
              ))
              }
            </Card>
            <Grid item xs={12}>
          </Grid>
       
     
            
            </Grid>
          </Grid>
          <MDBox py={6}>
        <Card>
          <CardContent>
            <MDTypography variant="h6" gutterBottom>
              Employees
            </MDTypography>
            {data.employee.length > 0 ? ( // Check if there are employees before rendering DataTable
            <DataTable
              table={{
                columns,
                rows: data.employee,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
            />
          ) : (
            <MDTypography>No employee data available.</MDTypography> // Fallback message
          )}

          </CardContent>
        </Card>
      </MDBox>
        </Grid>

       
      </Grid>

     <LoadingDialog open={loading}/>

      <Footer />
    </DashboardLayout>
  );
}

export default HrDashboard;
