import React,{useEffect,useState} from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import LoadingDialog from 'components/Loading/LoadingDialog';
 
const RegisteredStudents = ({ openView, onClose, registeredStudents }) => {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(registeredStudents){
            setLoading(false);
        }
    },[registeredStudents])


    const columns = [
        { Header: 'Full Name', accessor: (row) => `${row.student.first_name} ${row.student.last_name || ''}`, id: 'fullName', },
        { Header: 'Registered Date', accessor: 'registration_date',showSortIcons: false, },
        { Header: 'Status', accessor: 'status',showSortIcons: false, },
        { Header: 'Payment Status', accessor: (row) => row.is_paid ? 'Paid' : 'Not Paid', id: 'paymentStatus',showSortIcons: false, },
        { Header: 'Email', accessor: 'student.email_1' },
        { Header: 'Phone', accessor: 'student.phone',showSortIcons: false, },
        {Header:'Class',accessor:'student.class',showSortIcons: false,},
    ];

    const rows = registeredStudents.map(registration => ({
        ...registration,
        student: registration.student
    }));

    return (
        <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle textAlign="center">
                Registered Students
            </DialogTitle>
            <DialogContent>
                <DataTable
                    table={{
                        columns,
                        rows
                    }}
                    canSearch={true}
                    sortable={true}
                    pagination
                    rowsPerPage={5}
                    showPagination
                />
            </DialogContent>
            <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Close
      </MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />
        </Dialog>
    )
}

export default RegisteredStudents;