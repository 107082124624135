import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { Button, Card, Menu, MenuItem } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useCallback, useEffect, useState } from "react";
import ViewClearanceRequestDetail from "./viewDetails";

export default function ClearanceApproval() {
  const token = localStorage.getItem("authToken");

  const [requests, setRequests] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const fetchRequests = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/student-clearance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setRequests(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch requests:", error);
    }
  }, [token]);

  const handleOpenDetail = (row) => {
    setOpenDetails(true);
    setCurrentRow(row);
  };

  const handleCloseDialog = () => {
    setOpenDetails(false);
    setCurrentRow(null);
    fetchRequests();
  };

  const columns = [
    {
      Header: "",
      accessor: "action",
      width: "40px",
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button
                color="secondary"
                {...bindTrigger(popupState)}
                style={{ padding: 0, minWidth: 0 }}
              >
                <GridMoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    handleOpenDetail(row.original);
                    popupState.close();
                  }}
                  style={{ color: "#1976d2" }}
                >
                  <EyeIcon style={{ marginRight: 8 }} /> View Details
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    {
      Header: "Student Name",
      Cell: ({ row }) => (
        <div>
          {row.original.student.first_name} {row.original.student.last_name}
        </div>
      )
    },
    { Header: "Date of Requested", accessor: "date_requested" },
    { Header: "Year", accessor: "year.year_name" },
    { Header: "Reason", accessor: "reason" },
  ];

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  return (
    <DashboardLayout>
      <Card>
        <MDBox p={3}>
          <MDTypography>Student Clearance Requests</MDTypography>
          {requests && requests.length > 0 && (
            <DataTable
              table={{
                columns,
                rows: requests,
              }}
              sortable
              pagination
              entriesPerPage={{ defaultValue: 5 }}
              showPagination
            />
          )}
        </MDBox>
      </Card>
      {currentRow && (
        <ViewClearanceRequestDetail
          data={currentRow}
          open={openDetails}
          onClose={handleCloseDialog}
        />
      )}
    </DashboardLayout>
  );
}
