import AddIcon from "@mui/icons-material/Add";
import { Autocomplete, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";
import { useCallback } from "react";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const AddGradeRequests = ({ open, onClose, onAdd }) => {
  const targetId = localStorage.getItem("targetId");
  const [modalData, setModalData] = useState({
    student_id: Number(targetId),
    instructor_id: null,
    course_id: null,
    reason: "",
    status: "pending",
  });

  const [instructors, setInstructors] = useState([]);
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchRegistrations = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`student/${targetId}/registration`);
      const registrations = response.data[0];

  
      // Map courses and instructors from the response
      const coursesData = registrations.registrations.map(reg => ({
        id: reg.course_history.course.id,
        courseName: `${reg.course_history.course.course_name} (${reg.course_history.course.course_number})`,
        deadline:reg.course_history.mark_deadline
      }));

     

      const today = new Date();
        const filteredCourses = coursesData.filter(course => {
          const deadlineDate = new Date(course.deadline);
          return deadlineDate > today;
        })

      const instructorsData = registrations.registrations
        .map(reg => ({
          id: reg.course_history.instructor?.id,
          name: reg.course_history.instructor?.full_name
        }))
        .filter((value, index, self) => 
          index === self.findIndex((t) => t.id === value.id)
        ); // Remove duplicate instructors
      
  
      setCourses(filteredCourses || []);
      setInstructors(instructorsData);
    } catch (err) {
      console.error("Failed to fetch courses and instructors:", err);
    }
  },[targetId]);

  
  // Call fetchRegistrations when component mounts or targetId changes
  useEffect(() => {

      fetchRegistrations();
    
  }, [fetchRegistrations]);

  

  // Handle form input changes
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  // Handle form submission
  const handleModalSubmit = async () => {
    if (!modalData.instructor_id || !modalData.course_id || modalData.reason === "") {
      alert("Please add all required fields to continue");
    } else {
      try {
    
        setLoading(true);
        const response = await axiosInstance.post("/grade-change-requests", modalData);
        
        if (response.status === 201) {
          onAdd();
          onClose();
          swal({
            title: "Grade Request Created Successfully!",
            icon: "success",
          });

          // Reset form data
          setModalData({
            student_id: targetId,
            instructor_id: null,
            course_id: null,
            reason: "",
            status: "pending",
          });
          setError({});
        }
      } catch (err) {
        if (err.response && err.response.status === 400) {
          const errors = err.response.data.error;
          setError(errors);
        } else {
          console.error("Error submitting form:", err);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}>
        <AddIcon /> Add Grade Request
      </DialogTitle>
      <DialogContent>
      <MDBox mb={2}>
  <Autocomplete
  sx={{ mt:2 }}
    options={courses}
    getOptionLabel={(option) => option.courseName}
    onChange={(event, newValue) => {
      setModalData({
        ...modalData,
        course_id: newValue ? newValue.id : null,
      });
    }}
    renderInput={(params) => <TextField {...params} label="Course" />}
  />
</MDBox>

<MDBox mb={2}>
  <Autocomplete
    options={instructors}
    getOptionLabel={(option) => option.name}
    onChange={(event, newValue) => {
      setModalData({
        ...modalData,
        instructor_id: newValue ? newValue.id : null,
      });
    }}
    renderInput={(params) => <TextField {...params} label="Instructor" />}
  />
</MDBox>

        {/* Reason */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Reason
          </MDTypography>
          <MDInput
            type="text"
            name="reason"
            fullWidth
            required
            value={modalData.reason}
            onChange={handleModalChange}
            error={Boolean(error.reason)}
            helperText={error.reason}
          />
        </MDBox>
      </DialogContent>

      {/* Actions */}
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddGradeRequests;
