import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { useMemo } from 'react';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { useState,useEffect,useCallback } from 'react';
import AddCourseHistory from './addCourseHistory';
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';
import { clearCourseHistory } from '../../../../../redux/features/courseHistorySlice';
import { Button } from '@mui/material';
import swal from 'sweetalert';
import { axiosInstance } from 'api2';

const ViewRegisterations = ({ applicantDetail,onTriggerUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [student,setStudent] = useState(null)
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();



  useEffect(() => {
    if (applicantDetail && applicantDetail.registrations) {
      setLoading(false); // Stop loading once data is available
      console.log("applicantDetail.student",applicantDetail.student)
    
      setStudent(applicantDetail.student)
    }
  }, [applicantDetail]);

  const handleAddCourseHistory = () => {

   onTriggerUpdate()
  dispatch(clearCourseHistory());
  };

  const handleOpenCourseHistory = () => {
    setOpenAdd(true);
  }


  const handleCloseDialog = () => {
    setOpenAdd(false);
  };

  const handleDeleteRow = useCallback(async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this registered course!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
    try {
    const response = await axiosInstance.delete(`/drop-course/${id}`);
    if(response.status === 200){
      swal({
        title: "Course Deleted Successfully!",
        icon: "success",
      });
       onTriggerUpdate()
      dispatch(clearCourseHistory());
 
      
    }

   
    } catch (error) {
    console.log("error:",error)
    }
    }

    

  },[onTriggerUpdate,dispatch]);

 
  const columns = useMemo(() => [

    {
      Header: 'Semester',
      accessor: row => row.course_history?.semester || 'N/A',
      sortable: true,
      showSortIcons: false
    },
    {
      Header: 'Year',
      accessor: row => row.course_history?.year || applicantDetail.class || 'N/A',
      sortable: true,
      showSortIcons: false
    },
    {
      Header: 'Course Number',
      accessor: row => row.course_history?.course?.course_number || 'N/A',
      sortable: true,
      showSortIcons: false
    },
    {
      Header: 'Course Name',
      accessor: row => row.course_history?.course?.course_name || 'N/A',
      sortable: true,
    },
    {
        Header: 'Instructor',
        accessor: row => row.course_history?.instructor?.full_name || 'N/A',
        sortable: true,
      },
    {
      Header: 'Is Paid',
      accessor: row => row.is_paid ? 'Yes' : 'No',
      showSortIcons: false
    },
    {
      Header: 'Registration Date',
      accessor: 'registration_date',
      sortable: true,
      showSortIcons: false
    },
    {
      Header: 'Transfer Credits',
      accessor: 'transfer_credits',
      sortable: true,
      showSortIcons: false
    },
    {
      Header: 'Remark',
      accessor: row => row.course_history?.remark || 'N/A',
      showSortIcons: false,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => handleDeleteRow(row.original.id)} // Assuming each row has a unique `id`
          sx={{
            backgroundColor: 'error.main', // Use the default error color
            color: '#fff', // Set text color to white
            '&:hover': {
              backgroundColor: 'error.dark', // Darker shade on hover
            },
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'hidden', // Prevent overflow
            textOverflow: 'ellipsis', // Ellipsis for long text
          }}
        >
          Delete
        </Button>
      ),
    },
  ], [applicantDetail,handleDeleteRow]);

  const data = useMemo(() => {
    if (!applicantDetail || !applicantDetail.registrations) return [];
    return applicantDetail.registrations;
  }, [applicantDetail]);

  return (
    <Card>
      <CardContent>
        <MDBox mb={3}>
          <MDTypography variant="h5" fontWeight="medium">
            Registrations for {applicantDetail.first_name} {applicantDetail.last_name}
          </MDTypography>
        </MDBox>
        <MDBox>
          <DataTable
            table={{ columns, rows: data }}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
            widgets={role !== "academic dean" && (
              <MDBox ms={2}>
              <MDButton 
              variant="gradient"
              ml="auto"
                  color="info"
                  size="small"
                  onClick={handleOpenCourseHistory}
                  sx={{background:"#00274D", my:4,mx:3}}
            >
              <span>Add</span>
            </MDButton>
            
            </MDBox>
            )
                                                                                                           
            }
          />
        </MDBox>
      </CardContent>
      <AddCourseHistory open={openAdd} onClose={handleCloseDialog} onAdd={handleAddCourseHistory} student={student}/>
      <LoadingDialog open={loading } />
    </Card>
  );
};

export default ViewRegisterations;