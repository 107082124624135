import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";

const CourseTaken = ({ openView, onClose, coursesTaken }) => {
    const columns = [
        { Header: 'Course Number', accessor: 'course_number', showSortIcons: false },
        { Header: 'Course Name', accessor: 'course_name', showSortIcons: false },
        { Header: 'Instructor Name', accessor: 'instructor_name', showSortIcons: false },
        { Header: 'Credit Hour', accessor: 'credit_hour', showSortIcons: false }
    ];

    const rows = coursesTaken.map(course => ({
        ...course,
    }));

    return (
        <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle textAlign="center">
                Courses History
            </DialogTitle>
            <DialogContent>
                <DataTable
                    table={{
                        columns,
                        rows
                    }}
                    canSearch={true}
                    sortable={true}
                    pagination
                    rowsPerPage={5}
                    showPagination
                />
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{ background: "grey" }}
                    onClick={onClose}
                >
                    Close
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

export default CourseTaken;
