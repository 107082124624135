import { createSlice } from "@reduxjs/toolkit";

const initialState =  [
 { education_level: "Mother Tongue", language: "" },
{ education_level: "Secondary School", language: "" },
{ education_level: "High School", language: "" },
{ education_level: "Other Languages", language: "" },
{ education_level: "Do you use English in your work", language: "" },
]

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    addLanguage: (state, action) => {
      state.push(action.payload);
    },
    updateLanguage: (state, action) => {
      const { education_level, language } = action.payload;
      const existingEntry = state.find(entry => entry.education_level === education_level);
      if (existingEntry) {
        existingEntry.language = language;
      }
    },
  },
});


export const { addLanguage,updateLanguage } = languagesSlice.actions;
export default languagesSlice.reducer;
