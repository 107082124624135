import React, { useEffect, useState,useCallback } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Card, CardContent  } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewResponses from "./viewResponse";
import AddMarks from "./addMark";
import AddIcon from '@mui/icons-material/Add';

const InstructorGradeRequest = () => {
  const [search] = useState('');
  const [loading, setLoading] = useState(true);
  const [gradeRequests, setGradeRequests] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const targetId = localStorage.getItem("targetId");
  const [openAddMark,setOpenAddMark] = useState(false);


  const [newResponse, setNewResponse] = useState([]); // State for the new response
  const [requests,setRequests] = useState([]);




  const fetchGradeRequests = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`instructor/${targetId}/grade-change-requests`);
      console.log("gradeREquest:",response.data)
      setGradeRequests(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[targetId]);

  useEffect(() => {
  

    fetchGradeRequests();
  }, [fetchGradeRequests]);



const handleAddMarks = () => {
  fetchGradeRequests()

};




  const filteredData = gradeRequests.filter(request => request.reason.toLowerCase().includes(search.toLowerCase()));


  const handleViewDetails = (value) => {
    setOpenDetail(true);
    setNewResponse(value);
  };

  const handleViewMarks = (value) => {
    console.log("value:",value)
    setOpenAddMark(true);
    setRequests(value)
  };





  const handleCloseDialog = () => {
    setOpenDetail(false)
    setOpenAddMark(false)
  };

  const ExpandableReason = ({ reason }) => {
    const [expanded, setExpanded] = useState(false);
    const isLong = reason.length >30; // Change this number based on your requirement
  
    return (
      <div>
        <span>{expanded || !isLong ? reason : `${reason.slice(0, 30)}...`}</span>
        {isLong && (
          <Button onClick={() => setExpanded(!expanded)} style={{ marginLeft: 8, fontSize: '0.8rem' }}>
            {expanded ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      accessor: 'action',
      Header: '',
      width: 40,
      padding: 0,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => {
                handleViewMarks(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <AddIcon style={{ marginRight: 8 }}/>Add Mark
            </MenuItem>
              <MenuItem onClick={() => {
              handleViewDetails(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
           
                </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    {
      Header: 'Student Name',
      accessor: 'student',
      Cell: ({ value }) => `${value.first_name} ${value.last_name}`,
      showSortIcons: false,
    },
    { Header: 'Instructor Name', accessor: 'instructor.full_name', showSortIcons: false },
    { Header: 'Course Name', accessor: 'course.course_name', showSortIcons: false },
    { Header: 'Request Date', accessor: 'request_date', showSortIcons: false },
    {
      Header: 'Reason',
      accessor: 'reason',
      Cell: ({ value }) => <ExpandableReason reason={value} />,
      showSortIcons: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      showSortIcons: false,
      Cell: ({ value }) => (
        <span
          style={{
            color: value === 'approved' ? 'green' : value === 'pending' ? '#D4AF37' : 'black',
            fontWeight: 'bold',
          }}
        >
          {value.charAt(0).toUpperCase() + value.slice(1)} {/* Capitalize first letter */}
        </span>
      ),
    },
    
   
  ];

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Grade Requests
            </MDTypography>
            <MDBox display="flex" gap={3}>
              <MDBox flex="1">
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                />
                {!loading && filteredData.length === 0 && (
                  <MDTypography variant="h5" display="block" align="center" my={2} sx={{
                    fontStyle: "italic",
                    fontWeight: "bold",
                    color: "#9e9e9e",
                    opacity: 0.7,
                  }}>
                    No data available
                  </MDTypography>
                )}
              </MDBox>

            
            </MDBox>
            
                  {
                    requests && (
                      <AddMarks open={openAddMark}
                  onAdd={handleAddMarks}
                  onClose={handleCloseDialog} requests={requests}/>
                    )
                  }
            <LoadingDialog open={loading} />
          </MDBox>
        </CardContent>
      </Card>
        {/* Conditional Modal for Mobile View */}
     
       
        {
                openDetail && (
                  <ViewResponses responses={newResponse.responses} id={newResponse.id} openView={openDetail} onClose={handleCloseDialog}/>
                )
              }



    </DashboardLayout>
  );
};

export default InstructorGradeRequest;
