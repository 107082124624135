import React,{useState} from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import PurchaseRequestApprovalModal from './purchaseRequestApproval';
import { checkPermission } from 'utils/checkPermission';
// import StoreRequestApprovalModal from '../approval';

const ViewPurchaseRequest = ({openView, onClose,detailData, targetView}) => {
   
      
    const columns = [
        { Header: 'Asset Name', accessor: 'asset.name' }, 
        { Header: 'Unit of Measure', accessor: 'unit_of_measure',showSortIcons: false, },        
        { Header: 'Quantity', accessor: 'quantity',showSortIcons: false, },    
        {
            Header: 'Unit Price',
            accessor: 'unit_price',
            Cell: ({ row }) => {
              const formattedUnitPrice = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(row.original.unit_price);
              
              return <span>{formattedUnitPrice}</span>;
            },showSortIcons: false,
          },
                  { 
          Header: 'Amount', 
          accessor: 'amount',                          
          Cell: ({ row }) => {
            const amount = row.original.quantity * row.original.unit_price;
            const formattedAmount = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }).format(amount);
            
            return <span>{formattedAmount}</span>;
          },showSortIcons: false,
        },
        { Header: 'Remark', accessor: 'remark',showSortIcons: false, },   
               
      ];

      const rows = detailData.lines ? detailData.lines.map(line => ({
        ...line,
        asset: line.asset
    })) : [];

    const [openApprove, setOpenApprove] = useState(false);
    const [status, setSatus] = useState(false);

     const handleApprove = (status) => {
        setOpenApprove(true);
        setSatus(status);
     }
     const handleApproveClose = () => {
        setOpenApprove(false);
        onClose();
     }
     console.log(detailData);
     
  return (
    <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle textAlign="center">
        View Details
    </DialogTitle>
    <DialogContent>
        <DataTable
            table={{
                columns,
                rows
            }}
            canSearch={true}
            sortable={true}
            pagination
            rowsPerPage={5}
            showPagination
        />
            </DialogContent>
            <DialogActions>

            { checkPermission("department_head_approval") && !detailData.department_head && targetView === "request"  ? (
    <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={() => handleApprove("department_head")} // Pass as a function
    >
        Review
    </MDButton>
) : null }

{ checkPermission("approve_purchase") && !detailData.approved_by  && targetView === "request" ? (
    <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={() => handleApprove("approved")} // Pass as a function
    >
        Approve
    </MDButton>
) : null }

{ checkPermission("check_purchase") && !detailData.checked_by && targetView === "request" ? (
    <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={() => handleApprove("checked")} // Pass as a function
    >
        Check
    </MDButton>
) : null }
        <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
        >
        Close
        </MDButton>
        </DialogActions>
        <PurchaseRequestApprovalModal open={openApprove} detailData={detailData} status={status}  onClose={handleApproveClose} />

        </Dialog>
        )

        }

export default ViewPurchaseRequest