import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Button, Card, CardContent, Grid, Menu, MenuItem } from '@mui/material';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState,useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import PieChart from "examples/Charts/PieChart";

const PrincipalDashboard = () => {
  const navigate = useNavigate();
  const [search] = useState('');
  const[applicants,setApplicants] = useState([])

  const token =  localStorage.getItem("authToken");
  const [announcements, setAnnouncements] = useState([]);
   const [enrollmentData,setEnrollmentData] = useState([]);
   const [programData,setProgramData] = useState([]);
   const [counts,setCountsData] = useState([]);


  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/applicant-student`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });
        setApplicants(response.data);
      } catch (err) {
      } finally {
      }
    };
    fetchApplicants();
  }, [token]);
  
  const filteredData = applicants.filter(registrar => {
    return (
      registrar.first_name.toLowerCase().includes(search.toLowerCase()) ||
      registrar.email_1.toLowerCase().includes(search.toLowerCase()) 
     
    );

  });
  const handleViewDetail = (person) => {
    navigate('/viewApplicantDetail', { state: { person } });

  }

  const handleTransaction = async (payment) => {    

    if (payment != null && payment.transaction_id !== null ){
      try {
         if (payment.payment_method === "Chapa") {

        await axios.get(`${API_BASE_URL}/callback/${payment.transaction_id}`);    

   
         }
         else{
    


         }

      } catch (err) {
        console.error(err);
      }
    }
  };

  // eslint-disable-next-line no-sparse-arrays
  const columns = [ 
    {
      accessor: 'action',
      Header: '', 
      width:40,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={popupState.close}>Enter Entrance Result</MenuItem>
                <MenuItem onClick={popupState.close}>Enter Interview Result</MenuItem>
                <MenuItem onClick={popupState.close}>Approve</MenuItem>
                <MenuItem onClick={() => {
                  handleViewDetail(row.original);
                  popupState.close();
                }}>View Detail</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
    },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
    },
    {
      Header: 'Name',
      accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
      id: 'full_name', // Optional: Use an id for the column
      sorted: "none",
    },
    { Header: 'Telephone', accessor: 'phone', showSortIcons: false, },
    { Header: 'Gender', accessor: 'gender' },
    { Header: 'Email', accessor: 'email_1' },
    { Header: 'Application Date', accessor: 'application_date' },

    {
      Header: 'Application Program',
      id:'application_program',
      accessor: row => (
        <Link to={`/programs/${row.program.id}`}>
          {row.program ? row.program.program_name : null}
        </Link>
      ),
      sortable: true,
    },  
    
    { Header: 'Postal Address', accessor: 'postal_address' },
    {
      Header: 'Application Fee',
      id: 'application_fee',
      accessor: row => (
        <MDButton onClick={() => handleTransaction(row.payments.length ? row.payments[0] : null)}>
          {row.payments.length ? row.payments[0].transaction_id : 'No Transaction'}
        </MDButton>
      ),
    }

  ];
  


const fetchData = useCallback(async () => {
  const response = await axios.get(`${API_BASE_URL}/principal-dashboard`,{
    headers:{
      "Authorization":`Bearer ${token}`
    }
  });
  
  setEnrollmentData(response.data.enrollmentData);
  setCountsData(response.data.counts);
  setProgramData(response.data.programData);
  setAnnouncements(response.data.announcements)

},[token])
useEffect(() => {
  fetchData();
},[fetchData])


const totals = [
  {
    label: "Total Students",
    count: counts.students || 0, // Dynamically set the total students
    icon: "person",
    color: "info",
  },
  {
    label: "Employees",
    count: counts.employees || 0, // Dynamically set the total courses
    icon: "work",
    color: "primary",
  },
  {
    label: "Instructors",
    count:counts.instructors || 0, // Dynamically set the total instructors
    icon: "school",
    color: "success",
  },
  {
    label: "Total Income",
    count:counts.payments || 0, // Dynamically set the total instructors
    icon: "attach_money", // Icon representing income
    color: "warning",
  },
]

 // Dynamically generate the labels and datasets
 const countReactDashboard2 = {
  labels: enrollmentData?.map((entry) => entry.year), // Extract years for the labels
  datasets: [
    {
      label: "Male",
      color: "info",
      data: enrollmentData?.map((entry) => entry.male), // Extract male enrollment counts
    },
    {
      label: "Female",
      color: "success",
      data: enrollmentData?.map((entry) => entry.female), // Extract female enrollment counts
    },
  ],
};


const programDatasets = {
  labels: programData?.map((method) => method.name), // Extract labels
  datasets: 
    {
      label: "Programs",
      backgroundColors: ["info", "primary", "success", "warning"],
      data: programData?.map((method) => method.value), // Extract values
    },
  
};


  return (
    <DashboardLayout>
     
    <Grid container spacing={3} justifyContent="center">
        {/* Dashboard Header */}
            {/* Row 1: Four Stats Cards */}
            <Grid item xs={12} p={2}>

            <Grid container item spacing={3}>
            {totals?.map((item, index) => {
              return (
                <Grid item xs={true} key={index}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color={item.color}
                      icon={item.icon}
                      title={item.label}
                      count={item.count}
                     
                    />
                  </MDBox>
                </Grid>
              );
            })}
                
            </Grid>

            {/* Other content like charts and applicant list can follow here */}

            <>
        {/* Row 1: Two Charts */}
        <Grid container item spacing={3} mt={1}>
            {/* First Chart: Enrollment Trends */}
            <Grid item xs={12} sm={6} md={4}>
             <VerticalBarChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Total Students"
                description="Student Count by Each Year"
                chart={countReactDashboard2}
              />
        </Grid>
            {/* Second Chart: Program Distribution */}
            <Grid item xs={12} sm={6} md={4}>
                 <PieChart
                icon={{ color: "info", component: "group" }}
                title="Programs"
                description="Total Programs in Each Branch"
                chart={programDatasets}
              />
            </Grid>
            <Grid item  sm={6} md={4} >
     
      

            <Grid item xs={12} md={12}>
                <MDTypography variant="h4" align="center" gutterBottom>
                    Announcements
                </MDTypography>
            </Grid>

            {/* Announcements List */}
            {announcements.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                     <MDBox key={item.id} sx={{ mb: 2, p: 2, bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
              <MDTypography variant="h6" fontWeight="medium">
                {item.title}
              </MDTypography>
              <MDTypography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Announced By:</strong> {item?.user?.full_name} | <strong>Date:</strong> {new Date(item.announcement_date).toLocaleDateString()}
              </MDTypography>
              <MDTypography variant="body2" sx={{ color: 'text.primary' }}>
                {item.content}
              </MDTypography>
            </MDBox>
                </Grid>
            ))}

        </Grid>
        </Grid>
        </>
     
     
        </Grid>

        {/* Row 2: List of Latest Applicants */}
        <Grid item xs={12}>
            <Card>
                <CardContent>
                <MDTypography variant="h5" fontWeight="medium">
              Applicants
            </MDTypography>
              <MDBox mt={1}>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                />
              </MDBox>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </DashboardLayout>
  )
}

export default PrincipalDashboard