
import React,{ useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import MDButton from "components/MDButton";
import { Checkbox, FormControl, FormGroup, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions ,Grid,Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  widgets,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 5;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25","50","100"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [selectedColumns, setSelectedColumns] = useState(columns.map(col => col.accessor));
  const [openDialog, setOpenDialog] = useState(false);
  const [openPdfDialog, setOpenPdfDialog] = useState(false); 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredDatas, setFilteredDatas] = useState(data);

  const dateColumns = columns.filter(
    column => typeof column.accessor === 'string' && column.accessor.includes("date")
  );
 

  useEffect(() => {
    if (startDate && endDate) {
      setFilteredDatas(
        data.filter((row) => 
          dateColumns.some((column) => {
            const rowDate = new Date(row[column.accessor]);
            return rowDate >= new Date(startDate) && rowDate <= new Date(endDate);
          })
        )
      );
    } else {
      setFilteredDatas(data);
    }
  }, [startDate, endDate, data, dateColumns]);



  useEffect(() => {
    const defaultSelectedColumns = columns
      .filter(column => column.Header && column.Header !== 'Profile' && column.Header !== 'receipt_path') // Exclude empty headers and Profile column
      .map(column => (typeof column.accessor === 'function' ? column.id : column.accessor)); // Use column.id for function accessors
    setSelectedColumns(defaultSelectedColumns);
  }, [columns]);


  const handleColumnSelection = (column) => {
    setSelectedColumns((prev) =>
      prev.includes(column)
        ? prev.filter((col) => col !== column)
        : [...prev, column]
    );
  };


  const tableInstance = useTable(
    { columns, data:filteredDatas, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;


  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 5), [defaultValue, setPageSize]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);
 
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  // Function to export table data to Excel
  const exportToExcel = () => {
    // Helper function to get value from any type of accessor
    const getValue = (row, column) => {
      const accessor = column.accessor;
  
      // Skip 'action' columns
      if (accessor === 'action' || accessor === 'receipt_path' || accessor === 'id' || accessor === 'attachment') return '';
  
      // If accessor is a function
      if (typeof accessor === 'function') {
        try {
          const result = accessor(row);
  
          // Check if the result is a React element and extract its text content
          if (React.isValidElement(result)) {
            const { props } = result;
            if (typeof props.children === 'string') return props.children;
            if (Array.isArray(props.children)) {
              return props.children
                .filter(child => typeof child === 'string' || typeof child === 'number')
                .join(' ');
            }
            return ''; // In case it's another type of children (e.g., nested components)
          }
  
          return result; // Return as-is if not a React component
        } catch (error) {
          console.error('Error accessing value:', error);
          return '';
        }
      }
  
      // For direct string accessors
      if (typeof accessor === 'string') {
        // Split the accessor by '.' to handle nested properties
        const keys = accessor.split('.');
        return keys.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : '', row);
      }

  
      return '';
    };
  
    // Filter columns based on selection, excluding 'action' column
    const selectedColumnsData = columns.filter(column => {
      const isSelected = selectedColumns.includes(column.accessor) || selectedColumns.includes(column.id);
  
      // Exclude action column regardless of selection
      if (column.accessor === 'action') return false;
  
      // Include id and attachment regardless of selection
      const isAlwaysIncluded = ['id', 'attachment'].includes(column.accessor);
  
      return isSelected || isAlwaysIncluded; // Include if selected or always included
    });
  
    // Create filtered data array with selected columns
    const filteredData = filteredDatas?.map(row => {
      return selectedColumnsData.reduce((acc, column) => {
        const value = getValue(row, column);
        acc[column.Header] = value === null || value === undefined ? '' : value;
        return acc;
      }, {});
    });

  
    // Generate and export the Excel file
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "data.xlsx");
  };
  


  const exportToPDF = () => {
    // Enhanced helper function to extract text content from accessor functions
    const extractTextFromAccessor = (accessor, row) => {
      try {
        const result = accessor(row);
        if (typeof result === 'string' || typeof result === 'number') {
          return result;
        } else if (React.isValidElement(result)) {
          const props = result.props;
          if (props.children) {
            if (typeof props.children === 'string') return props.children;
            if (Array.isArray(props.children)) {
              return props.children
                .filter(child => typeof child === 'string' || typeof child === 'number')
                .join(' ');
            }
          }
        }
        return result || '';
      } catch (error) {
        return '';
      }
    };
  
    // Helper function to get value from any type of accessor
    const getValue = (row, column) => {
      try {
        const accessor = column.accessor;
  
        // Skip action columns
        if (accessor === 'action' || accessor === 'receipt_path' || accessor === 'id' || accessor === 'attachment') return '';
  
        // If accessor is a function
        if (typeof accessor === 'function') {
          return extractTextFromAccessor(accessor, row);
        }
  
        // For direct string accessors
        if (typeof accessor === 'string') {
          const keys = accessor.split('.');
          return keys.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : '', row);
        }
  
        return '';
      } catch (error) {
        console.error('Error getting value:', error);
        return '';
      }
    };
  
    // Filter columns based on selection
    const selectedColumnsData = columns.filter(column => {
      const isSelected = selectedColumns.includes(column.accessor) || selectedColumns.includes(column.id);
      const isNotAction = !['action', 'receipt_path', 'id', 'attachment'].includes(column.accessor);
      return isSelected && isNotAction;
    });
  
    // Extract headers
    const pdfHeaders = selectedColumnsData.map(column => column.Header || '');
  
    // Map data for selected columns
    const pdfData = filteredDatas?.map(row => {
      return selectedColumnsData.map(column => {
        const value = getValue(row, column);
        if (value === null || value === undefined) return '';
        if (typeof value === 'boolean') return value ? 'Yes' : 'No';
        if (typeof value === 'object' && !Array.isArray(value)) {
          return JSON.stringify(value);
        }
        return String(value);
      });
    });
  
    // Calculate column widths based on content and headers
    const calculateColumnWidths = (headers, data) => {
      return headers.map((header, index) => {
        const maxContentLength = Math.max(
          header.length,
          ...data.map(row => String(row[index] || '').length)
        );
        // Set a minimum width to avoid very narrow columns
        return Math.max(maxContentLength * 1.2, 30); // Adjust the multiplier as needed
      });
    };
  
    // Calculate column widths
    const columnWidths = calculateColumnWidths(pdfHeaders, pdfData);
    const tableWidth = columnWidths.reduce((sum, width) => sum + width, 0);
  
    // Set PDF dimensions based on table width
    let doc;
    if (tableWidth > 210) { // A4 portrait width
      doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
      });
    } else {
      doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });
    }
  
    // Title styling (optional)
    doc.setFontSize(20);
    // doc.text(title, 20, 20);
  
    // Table styling
    doc.autoTable({
      head: [pdfHeaders],
      body: pdfData,
      startY: 30,
      styles: {
        cellPadding: 2, // Reduced padding to minimize space
        fontSize: 12,
        lineWidth: 0.5, // Reduced line width
        overflow: 'linebreak',
        halign: 'center',
        valign: 'middle',
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [255, 255, 255], // No background color for headers
        textColor: [0, 0, 0],
        fontStyle: 'bold',
        halign: 'center',
        valign: 'middle',
      },
      columnStyles: columnWidths.reduce((acc, width, index) => {
        acc[index] = {
          columnWidth: width, // Use calculated widths
        };
        return acc;
      }, {}),
      margin: { top: 10, left: 20, right: 20, bottom: 10 },
    });
  
    doc.save("report.pdf");
  };
  
  
  
  
  





  const handleOpenPdfDialog = () => {
    setOpenPdfDialog(true);
  };

  const handleClosePdfDialog = () => {
    setOpenPdfDialog(false);
  };



const handleOpenDialog = () => {
  setOpenDialog(true);
};

// Close dialog
const handleCloseDialog = () => {
  setOpenDialog(false);
};







const excludedHeaders = ['Profile', 'receipt_path', 'id', 'Attachment', 'Actions'];
  return (
    <TableContainer sx={{ boxShadow: "none" }}>


      {/* Dialog for column selection */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
  <DialogTitle>Select Columns</DialogTitle>
  <DialogContent>
    <FormControl component="fieldset">
    <FormGroup>
            <Grid container spacing={2}>
              {columns
                .filter(column => column.Header && !excludedHeaders.includes(column.Header)) // Updated filtering logic
                .map((column) => {
                  // Determine the accessor for the checkbox
                  const accessorValue = typeof column.accessor === 'function' ? column.id : column.accessor;
                  return (
                    <Grid item xs={6} sm={4} md={3} key={accessorValue}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={accessorValue}
                            checked={selectedColumns.includes(accessorValue)}
                            onChange={() => handleColumnSelection(accessorValue)}
                          />
                        }
                        label={column.Header}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </FormGroup>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <MDButton onClick={handleCloseDialog} color="secondary">Close</MDButton>
    <MDButton onClick={() => { exportToExcel(); handleCloseDialog(); }} color="info">Export</MDButton>
  </DialogActions>
</Dialog>

 {/* PDF Export Dialog */}
 <Dialog open={openPdfDialog} onClose={handleClosePdfDialog} maxWidth="lg">
        <DialogTitle>Select Columns for PDF Export</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
          <FormGroup>
            <Grid container spacing={2}>
              {columns
                .filter(column => column.Header && !excludedHeaders.includes(column.Header))
                .map((column) => {
                  // Determine the accessor for the checkbox
                  const accessorValue = typeof column.accessor === 'function' ? column.id : column.accessor;
                  return (
                    <Grid item xs={6} sm={4} md={3} key={accessorValue}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={accessorValue}
                            checked={selectedColumns.includes(accessorValue)}
                            onChange={() => handleColumnSelection(accessorValue)}
                          />
                        }
                        label={column.Header}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </FormGroup>

          </FormControl>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClosePdfDialog} color="secondary">Close</MDButton>
          <MDButton onClick={exportToPDF} color="info">Export to PDF</MDButton>
        </DialogActions>
      </Dialog>





      {entriesPerPage || canSearch ? (
        <MDBox 
        display="flex" 
        mt={1}
        // alignItems="center" 
        alignItems={{ xs: "flex-start", md: "center" }}
        flexDirection={{ xs: "column", md: "row" }} // Column on small screens, row on medium and larger
      >
        {canSearch && (
          <MDBox width={{ xs: "100%", md: "30rem" }}>
            <MDInput
              placeholder="Search..."
              value={search}
              size="sm"
              fullWidth
              onChange={({ currentTarget }) => {
                setSearch(search);
                onSearchChange(currentTarget.value);
              }}
            />
          </MDBox>
        )}
      
        { widgets }
        
        <MDBox display="flex" gap={1} sx={{ mt: { xs: 2, md: 0 }, width: '100%', justifyContent: { xs: 'space-between', md: 'flex-start' } }} flexDirection={{ xs: "column", md: "row" }}>
        <MDButton
          variant="gradient"
          ml="auto"
          color="info"
          size="small"
          // onClick={exportToExcel}
          onClick={handleOpenDialog}
          sx={{ 
            background: "#00274D", 
            my:{xs:1,md:4},
            mx: 1, 
            width: { xs: "20%", md: "auto" } // Responsive width
          }} >
          <span>excel</span>
          </MDButton>
          <MDButton
          variant="gradient"
          ml="auto"
          color="info"
          size="small"
          onClick={handleOpenPdfDialog} sx={{ 
            background: "#00274D", 
            my:{xs:0,md:4},
            mx: 1, 
            width: { xs: "20%", md: "auto" } // Responsive width
          }}>
          <span>pdf</span>


          </MDButton>

          {dateColumns.length > 0 && (
  <MDBox display="flex" alignItems="center" gap={4}  sx={{ mb: { xs: 2, sm: 0 } }}>
    {/* Start Date Input */}
    <MDBox display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="caption" sx={{ fontSize: '12px', marginBottom: '4px' }}>
        Start Date
      </Typography>
      <MDInput
        type="date"
        value={startDate}
        sx={{
          width: '180px',
          padding: '4px 8px',
          // Hide default icon
          '& input::-webkit-calendar-picker-indicator': {
            display: startDate ? 'none' : 'block', // Hide icon when date is selected
          },
        }}
        onChange={(e) => setStartDate(e.target.value)}
        InputProps={{
          endAdornment: startDate && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setStartDate("")}
                size="small"
                sx={{ padding: 0 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MDBox>

    {/* End Date Input */}
    <MDBox display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="caption" sx={{ fontSize: '12px', marginBottom: '4px' }}>
        End Date
      </Typography>
      <MDInput
        type="date"
        value={endDate}
        sx={{
          width: '180px',
          padding: '4px 8px',
          // Hide default icon
          '& input::-webkit-calendar-picker-indicator': {
            display: endDate ? 'none' : 'block', // Hide icon when date is selected
          },
        }}
        onChange={(e) => setEndDate(e.target.value)}
        InputProps={{
          endAdornment: endDate && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setEndDate("")}
                size="small"
                sx={{ padding: 0 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MDBox>
  </MDBox>
)}
</MDBox>

      </MDBox>
      
      ) : null}
      <Table {...getTableProps()} id="data-table">
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  key={idx}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                  showSortIcons={column.showSortIcons !== false} 
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (
                  <DataTableBodyCell
                    key={idx}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="end"
        alignItems={{ xs: "flex-start", sm: "end" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }} sx={{mx:2}}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
                  {entriesPerPage && (
            <MDBox display="flex" alignItems="center" sx={{mx:2}}>
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
       {pageOptions.length > 1 && (
  <MDPagination
    variant={pagination.variant || "gradient"}
    color={pagination.color || "info"}
    sx={{ mx: 2 }}
  >
    {canPreviousPage && (
      <MDPagination item onClick={() => previousPage()}>
        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
      </MDPagination>
    )}

    {/* Render limited Page Numbers */}
    {(() => {
      const maxPagesToShow = 5;
      const totalPages = pageOptions.length;
      const startPage = Math.max(0, pageIndex - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(startPage + maxPagesToShow, totalPages);

      // Adjust startPage if there are fewer than 5 pages available
      const adjustedStartPage = Math.max(0, endPage - maxPagesToShow);

      return pageOptions.slice(adjustedStartPage, adjustedStartPage + maxPagesToShow).map((option) => (
        <MDPagination
          item
          key={option}
          onClick={() => gotoPage(option)}
          active={pageIndex === option}
          sx={{
            fontWeight: pageIndex === option ? "bold" : "normal",
            bgcolor: pageIndex === option ? "primary.main" : "transparent",
            color: pageIndex === option ? "white" : "inherit",
          }}
        >
          {option + 1}
        </MDPagination>
      ));
    })()}

    {canNextPage && (
      <MDPagination item onClick={() => nextPage()}>
        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
      </MDPagination>
    )}
  </MDPagination>
)}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 5, entries: [5, 10, 15, 20, 25,50,100] },
  canSearch: true,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;