import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {Box} from "@mui/material";
import LoadingDialog from 'components/Loading/LoadingDialog';
import FileViewer from "components/FileViewer";
import MDButton from "components/MDButton";


const LeaveRequests = () => {
  const token = localStorage.getItem("authToken");
  const role = localStorage.getItem("role"); // Get the role from localStorage
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [position, setPosition] = useState(null);
  const [loading,setLoading] = useState(true)
  const targetId = localStorage.getItem("targetId");

  console.log("targetId:",targetId)

  useEffect(() => {
    if (role === "human resource") {
      setPosition("hr");
    } else {
      setPosition("head");
    }
  }, [role]);

  const [leaveRequests, setLeaveRequests] = useState([]);

  const fetchLeaveRequests = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/leaves`, {
        headers: { Authorization: `Bearer ${token}` },
      });
     
      setLeaveRequests(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false)
    }
  }, [token]);

  useEffect(() => {
    fetchLeaveRequests();
  }, [fetchLeaveRequests]);

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };




  const columns = [
    {
      Header: 'Employee Name',
      accessor: (row) => `${row.employee.first_name} ${row.employee.last_name}`,
      showSortIcons: true,
    },
    { Header: 'Leave Type Name', accessor: 'leave_type.leave_type_name', showSortIcons: true },
    { Header: 'Start Date', accessor: 'start_date', showSortIcons: false },
    { Header: 'End Date', accessor: 'end_date', showSortIcons: false },
    { Header: 'Reason', accessor: 'reason', showSortIcons: false },
    { Header: 'Status', accessor: 'status', showSortIcons: false },
    {
      Header: 'Attachment',
      accessor: 'attachment',
      showSortIcons: false,
      Cell: ({ value }) => (
        <>
          {value && value.startsWith('LeaveRequests-files')  ? (
            <MDButton
              variant="body2"
              color="primary"
              component="a"
              onClick={() => handleViewFile(value)}
              style={{ cursor: 'pointer' }}
              sx={{ 
                cursor: 'pointer',
                textDecoration: 'underline',
                boxShadow: 1, // Adds a default shadow
                transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
                '&:hover': {
                  color: 'secondary.main',
                  boxShadow: 4, // Increases shadow intensity on hover
                }
              }}
            >
              View File
            </MDButton>
          ) : null}
        </>
      ),
    },
    

    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ row }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [approvalStatus, setApprovalStatus] = useState(null);
  
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
  
        const handleClose = () => {
          setAnchorEl(null);
        };
  
        const handleApproval = async (leaveId, status) => {
          console.log("status:",status)
          const confirm = await swal({
            title: `Are you sure you want to ${status === 'Approved' ? 'approve' : 'reject'} this leave request?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
  
          if (confirm) {
            console.log("position:",position)
            try {
              // Call your API or handle backend logic here for approval/rejection
              const response = await axios.put(`${API_BASE_URL}/leaves/${leaveId}`, {
                status,position
              }, {
                headers: { "Authorization": `Bearer ${token}` },
              });
  
              if (response.status === 200) {
                setApprovalStatus(status); // Update the local UI status
                fetchLeaveRequests();
                swal({
                  title: `Leave request ${status} successfully!`,
                  icon: "success",
                });
              }
            } catch (error) {
              swal({
                title: `${error.response?.data?.message || 'An error occurred'}`,
                icon: "error",
              });
            }
          }
        };
  
        return (
          <Box display="flex" justifyContent="space-between" gap={2}>
            {approvalStatus === null ? (
              <>
                <IconButton onClick={handleClick}>
                  <MenuIcon /> {/* Changed icon from MoreVertIcon to MenuIcon */}
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <MenuItem
                    onClick={() => handleApproval(row.original.id, 'Approved')}
                    sx={{ color: '#4caf50' }} // Green text for approval
                  >
                    <CheckIcon /> Approve
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleApproval(row.original.id, 'Rejected')}
                    sx={{ color: '#f44336' }} // Red text for rejection
                  >
                    <CloseIcon /> Reject
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Box
                component="button"
                style={{
                  backgroundColor: approvalStatus === 'Approved' ? '#4caf50' : '#f44336',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
              >
                {approvalStatus}
              </Box>
            )}
          </Box>
        );
      },
      showSortIcons: false,
    },
  ];
  

  return (
      <DashboardLayout>
          <Card>
              <CardContent>
                  <MDBox>
                      <MDTypography variant="h5" fontWeight="medium" my={2}>
                          Leave Requests
                      </MDTypography>
                      <MDBox>
                  
                          <DataTable
                              table={{
                                  columns,
                                  rows: leaveRequests,
                              }}
                              canSearch={true}
                              sortable={true}
                              rowsPerPage={5}
                              showPagination
                          />
                            {!loading && leaveRequests.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            Leave Requests not available
          </MDTypography>
        )}
                      </MDBox>
                  </MDBox>
                  <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
              </CardContent>
          </Card>
      <LoadingDialog open={loading} />

      </DashboardLayout>
  );
};

export default LeaveRequests;
