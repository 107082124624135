import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, TextField } from '@mui/material';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { axiosInstance } from 'api2';
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const CircularIconButton = styled(IconButton)({
    backgroundColor: '#1976d2',
    color: '#fff',
    padding: 8,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
});

const ViewResponses = ({ openView, onClose, responses,id }) => {
    const targetId = localStorage.getItem("targetId");
    const [res, setRes] = useState([]);
    const [showInput, setShowInput] = useState(false);
    const [newResponse, setNewResponse] = useState("");
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        if (responses) {
            setRes(responses);
        }
    }, [responses]);

    const handleAddResponse = async () => {
        const responsePayload = {
            sender_id: targetId,
            response: newResponse
        };
        setLoading(true);
        
        try {
            // Replace `api/grade-responses` with the actual endpoint
            const response = await axiosInstance.post(`/grade-change-requests/${id}/responses`, responsePayload);
            if (response.status === 201) {
            setRes([...res, response.data]); // Update responses with the new response
            swal({
                title: "Response Send Successfully!",
                // text: "Course Created Successfully!",
                icon: "success"
              });
            setNewResponse(""); // Reset input field
            setShowInput(false); // Close input box
            }
        } catch (error) {
            console.error("Error sending response:", error);
        } finally {
            setLoading(false)
        }
    };

    return (
        <Dialog open={openView} onClose={onClose} fullWidth maxWidth="sm">
            <DialogContent>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h6" gutterBottom>Responses</MDTypography>
                    <CircularIconButton aria-label="add response" onClick={() => setShowInput(!showInput)}>
                        <AddIcon />
                    </CircularIconButton>
                </MDBox>

                {showInput && (
                    <MDBox my={2}>
                        <TextField
                            fullWidth
                            label="Write your response"
                            value={newResponse}
                            onChange={(e) => setNewResponse(e.target.value)}
                            variant="outlined"
                            multiline
                        />
                        <MDButton
                            variant="gradient"
                            color="primary"
                            size="sm"
                            sx={{ mt: 2,background:"#00274D" }}
                            onClick={handleAddResponse}
                        >
                            Send
                        </MDButton>
                    </MDBox>
                )}

                {res && res.map((res, index) => (
                    <MDBox key={res.id} my={1} sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 12px",
                        backgroundColor: index % 2 === 0 ? "#ffffff" : "#e0e7ff",
                        borderRadius: "12px",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                    }}>
                        <MDBox sx={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            backgroundColor: Number(res.sender_id) === Number(targetId) ? "#3f51b5" : "green",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                            fontSize: "16px",
                            mr: 2,
                            flexShrink: 0,
                        }}>
                            R
                        </MDBox>
                        <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                            <MDTypography variant="body2" fontWeight="medium">{res.response}</MDTypography>
                            <MDTypography variant="caption" color="textSecondary">
                                {new Date(res.created_at).toLocaleString()}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                ))}
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{ background: "grey" }}
                    onClick={onClose}
                >
                    Close
                </MDButton>
            </DialogActions>
            <LoadingDialog open={loading}/>
        </Dialog>
    );
};

export default ViewResponses;
