import React, { useState, useEffect,useCallback } from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import LoadingDialog from "components/Loading/LoadingDialog";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`, 
    'Content-Type': 'application/json',
  },
});

const ViewCourse = () => {
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState(null);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const targetId = localStorage.getItem("targetId");

  const token = localStorage.getItem("authToken");
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [coursesData, yearsData,semestersData] = await Promise.all([
          axiosInstance.get(`/instructor/${targetId}/courses`),
          axiosInstance.get('/years'),
          axiosInstance.get('/semesters'),
        ]);
        const coursesArray = coursesData.data[0].course_history.map(course => ({
          id: course.course_id,
          name: course.course?.course_name || 'Unknown Course'
        }));
        setCourses(coursesArray);
        setYears(yearsData.data);
        setSemesters(semestersData.data);
      } catch (error) {
        console.error("Error fetching courses and years:", error);
      }
    };

    fetchData();
  },[targetId,selectedCourse,selectedYear,selectedSemester]);

  const fetchStudents = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/instructor/${targetId}/students`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      

      const allStudents = response.data[0].course_history.flatMap(student => 
        student.registrations.map(registration => ({
        //   studentId: registration.student_id,
          studentName: `${registration.student.first_name} ${registration.student.last_name}`, // Replace with actual student name if available
          email: registration.student.email_1,
          courseName: student.course?.course_name || 'Unknown Course',
          courseCode: student.course?.course_number || 'Unknown Course',
          courseId: student.course_id,
          year: student.year,
          class: registration.student.class,
          semester:student.semester
        }))
      );
     
      setStudents(allStudents);
      setFilteredStudents(allStudents);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[token,targetId]);

  useEffect(() => {
    fetchStudents();
  },[fetchStudents]);

  // Filter data based on selected course and year
  useEffect(() => {
    let updatedStudents = [...students];

    if (selectedCourse) {
      updatedStudents = updatedStudents.filter(student => student.courseId === selectedCourse.id);
    }

    if (selectedYear) {
      updatedStudents = updatedStudents.filter(student => student.year === parseInt(selectedYear.year_name));
    }


    if (selectedSemester) {
        updatedStudents = updatedStudents.filter(student => student.semester === selectedSemester.semester_code);
      }

    setFilteredStudents(updatedStudents);
  }, [selectedCourse, selectedYear, students,selectedSemester]);

 

  const columns = [
    
    { Header: 'Student Name', accessor: 'studentName' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Course Code', accessor: 'courseCode' },
    { Header: 'Course Name', accessor: 'courseName' },
    { Header: 'Class', accessor: 'class' },
  ];

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Students
            </MDTypography>

           

            <MDBox>
           
                <DataTable
                  table={{
                    columns,
                    rows: filteredStudents,
                  }}
                  canSearch={true}
                  sortable={true}
                  pagination
                  rowsPerPage={5}
                  showPagination
                  widgets={
                    <MDBox display="flex">
                    {/* Course Autocomplete */}
                    <Autocomplete
                      fullWidth
                      sx={{ mx: 2, my: 2, width: '200px' }}
                      options={courses}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, newValue) => setSelectedCourse(newValue)}
                      value={selectedCourse}
                      renderInput={(params) => <TextField {...params} label="Course" />}
                    />
      
                    {/* Year Autocomplete */}
                    <Autocomplete
                      fullWidth
                      sx={{ mx: 2, my: 2, width: '200px' }}
                      options={years}
                      getOptionLabel={(option) => option.year_name || ''}
                      onChange={(event, newValue) => setSelectedYear(newValue)}
                      value={selectedYear}
                      renderInput={(params) => <TextField {...params} label="Year" />}
                    />

<Autocomplete
                fullWidth
                sx={{ mx: 2, my: 2, width: '200px' }}
                options={semesters}
                getOptionLabel={(option) => option.semester_code || ''}
                onChange={(event, newValue) => setSelectedSemester(newValue)}
                value={selectedSemester}
                renderInput={(params) => <TextField {...params} label="Semester" />}
              />
                  </MDBox>
                  }
                />
                 {!loading && filteredStudents.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No Students available
          </MDTypography>
        )}

            </MDBox>
          </MDBox>
        </CardContent>
      </Card>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default ViewCourse;
