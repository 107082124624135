import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { useState } from 'react';
// import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, Collapse, Grid, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { STORAGE_BASE_URL } from 'api';
const columnsReferences = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Relationship', accessor: 'relationship' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Email', accessor: 'email' }
  ];
  
 

const OthersStudentDetail = ({applicantDetail}) => {
  
    const [expanded, setExpanded] = useState(false);
    const [expandExperience, setExpandExperience] = useState(false);

    const handleExpandClick = () => {
    setExpanded(!expanded);
    };

    const handleExpandExperience = () => {
        setExpandExperience(!expandExperience);
    };
    const lables = (label) => (  
            <span style={{ color: '#00274D',fontWeight:"normal" }}>{label}</span>
    ); 
    const jsonData = applicantDetail;
  return (
    // <DashboardLayout>
        <MDBox mt={1}>
        <Grid container spacing={2}  >
          {/* First Grid for Personal Data */}
          <Grid item xs={12} sm={6} md={7}>
            <Card>
           
           <MDBox display="flex" width={"100%"} justifyContent = "center" >
            <img 
            src={`${STORAGE_BASE_URL}/${jsonData.student.profile_picture}`}
            alt="Profile"
            textAlign="center"
            style={{ borderRadius: '50%', width: '200px', height: '200px', objectFit: 'cover' }}
          />
          </MDBox>
              <CardContent>
              <Grid container spacing={2}  >

<Grid item xs={12} sm={6} md={6}>
             
                <MDTypography variant="h6" fontWeight="medium">Applicant Information</MDTypography>
                <MDBox mt={1}>
                  <MDTypography variant="body2">{lables("Private residential(address):")} {jsonData.student.private_address}</MDTypography>
                  <MDTypography variant="body2">{lables("Email(1) :")} {jsonData.student.email_1}</MDTypography>
                  <MDTypography variant="body2">{lables("Email(2) :")} {jsonData.student.email_2}</MDTypography>
                  <MDTypography variant="body2">{lables("Place of Birth :")} {jsonData.student.place_of_birth}</MDTypography>
                  <MDTypography variant="body2">{lables("Nationality/at birth :")} {jsonData.student.nationality_at_birth}</MDTypography>
                  <MDTypography variant="body2">{lables("Gender :")} {jsonData.student.gender}</MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" mt={3}>Religious Background</MDTypography>
                <MDBox mt={1}>
                  <MDTypography variant="body2">Denomination: {jsonData.student.denomination}</MDTypography>
                  <MDTypography variant="body2">Local Congretion: {jsonData.student.local_congregation}</MDTypography>
                  <MDTypography variant="body2">Address: {jsonData.student.denomination_addressh}</MDTypography>
                  <MDTypography variant="body2">Tel No: {jsonData.student.church_phone}</MDTypography>
                  <MDTypography variant="body2">Jesus Christ recieved date: {jsonData.student.jesus_receive_date}</MDTypography>
                  <MDBox display="flex" alignItems="center"  justifycontent="flex-between">
                    <MDTypography variant="body2">Ministry Experience</MDTypography>
                    <IconButton onClick={handleExpandClick}>
                        <ExpandMoreIcon />
                    </IconButton>
                    

                  </MDBox>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>                  
                    <CardContent>
                    <MDTypography variant="body2">
                      {jsonData.student.ministry_experience}
                      </MDTypography>
                    </CardContent>
                </Collapse>
              <MDTypography variant="body2">Ministry Duration: {jsonData.ministry_duration}</MDTypography>

                  
                </MDBox>
                </MDBox>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>

                <MDTypography variant="h6" fontWeight="medium">Sponsor</MDTypography>
                <MDBox mt={1}>
                  <MDTypography variant="body2">{lables("Sponsor :")} {jsonData.student.sponsor_ship}</MDTypography>
                  <MDTypography variant="body2">{lables("Name :")} {jsonData.student.sponsor_name}</MDTypography>
                  <MDTypography variant="body2">{lables("Telephone :")} {jsonData.student.sponsor_phone}</MDTypography>
                  <MDTypography variant="body2">{lables("Address :")} {jsonData.student.sponsor_address}</MDTypography>
                  <MDTypography variant="body2">{lables("Email :")} {jsonData.student.sponsor_email}</MDTypography>
                  
                  <MDTypography variant="h6" fontWeight="medium" mt={5}>Employment Records</MDTypography>
                {
                    jsonData.employmentRecords.length > 0 && jsonData.employmentRecords.map((employment, index) => (
                    <MDBox mt={1} key={index}>
                        <MDTypography variant="body2">Employer: {employment.employer_name}</MDTypography>
                        
                        <MDTypography variant="body2">Address: {employment.employer_address}</MDTypography>
                        <MDTypography variant="body2">Job Title: {employment.job_title}</MDTypography>
                        <MDTypography variant="body2">Start Date: {employment.employment_start_date}</MDTypography>
                        <MDTypography variant="body2">End Date: {employment.employment_end_date}</MDTypography>
                        <MDBox display="flex" alignItems="center" mt={3} justifycontent="space-between">
                        <MDTypography variant="body2">Description of your work</MDTypography>
                        <IconButton onClick={handleExpandExperience}>
                            <ExpandMoreIcon />
                        </IconButton>
                        </MDBox>
                        <Collapse in={expandExperience} timeout="auto" unmountOnExit>
                        <CardContent>
                            <MDTypography variant="body2">
                           {employment.job_responsibilities}
                            </MDTypography>
                        </CardContent>
                        </Collapse>
                    </MDBox>
                    ))
                }
              
                </MDBox>
                </Grid>
                </Grid>
              </CardContent>
              
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <MDBox display = "flex" flexDirection = "column" gap={2}>
          <Accordion sx={{py:2}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
                <MDTypography variant="h6" fontWeight="medium">Application Fee</MDTypography>
                </AccordionSummary>
                              <AccordionDetails>
                                {
                                jsonData.student.payments.length > 0 && jsonData.student.payments.map((payment, index) => (  
                                
                     <><MDTypography variant="body2">Name: {payment.full_name}</MDTypography>
                     <MDTypography variant="body2">Phone Number: {payment.phone_number}</MDTypography>
                     <MDTypography variant="body2">Payment Amount:  {payment.payment_amount}</MDTypography>
                     <MDTypography variant="body2">Payment Date: {payment.payment_date}</MDTypography>
                     <MDTypography variant="body2">Payment Method: {payment.payment_method}</MDTypography>
                     <MDTypography variant="body2">Transaction Id: {payment.transaction_id}</MDTypography>
                     <MDTypography variant="body2">Receipt File: {payment.receipt_path}</MDTypography></>
                                  ))}
        </AccordionDetails>
      </Accordion>

         
            <Card mt={2}>
              <CardContent>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" py={2}>
                <MDTypography variant="h6" fontWeight="medium">Testimony of spiritual Life</MDTypography>
                <MDButton variant="info">View</MDButton>

                </MDBox>
                
              </CardContent>
              
            </Card>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
                <MDTypography variant="h6" fontWeight="medium">References</MDTypography>
                </AccordionSummary>
        <AccordionDetails>
        <DataTable
                        table={{ columns: columnsReferences, rows: jsonData.references ? jsonData.references : [] }} />
          </AccordionDetails>
          </Accordion>
          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
                <MDTypography variant="h6" fontWeight="medium">languages</MDTypography>
                </AccordionSummary>
        <AccordionDetails>
        <DataTable
                        table={{ columns: columnsReferences, rows: jsonData.references }} />
          </AccordionDetails>
          </Accordion>
          </MDBox>
          </Grid>
        </Grid>


        </MDBox>
    // </DashboardLayout>
  )
}

export default OthersStudentDetail