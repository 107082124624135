import React, { useState ,useEffect} from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";


const Categories = () => {
  const [search] = useState('');
  const token =  localStorage.getItem("authToken");
 
  const [showAlert, setShowAlert] = useState(false);
  const[category,setCategory] = useState([])

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/categories`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        console.log("categories:",response.data)

        setCategory(response.data);
      } catch (err) {
        console.error(err);
      } 
      
    };
    fetchCategory();
  }, [token]);


  const filteredData = search
  ? category && category.filter(c => 
      c.name &&
      c.name.toLowerCase().includes(search.toLowerCase())
    )
  : category;

  const columns = [

    { Header: 'Category Code', accessor: 'code',showSortIcons: false, },
    { Header: 'name', accessor: 'name', },
    { Header: 'Description', accessor: 'description',showSortIcons: false, },
    { Header: 'Remark', accessor: 'remark',showSortIcons: false, },
   
  ];

  return (
    <>
  
          <MDBox>

          <MDTypography variant="h5" fontWeight="medium">
            Category
          </MDTypography>
          <MDBox>
                  <DataTable
                    table={{
                      columns,
                      rows: filteredData,
                    }}
                    canSearch={true}
                    sortable={true}
                    pagination
                    rowsPerPage={5}
                    showPagination
                    
                  />
          </MDBox>
          
          
          </MDBox>

    </>
  )
}

export default Categories