import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';


const FamilyBackground = ({
  motherName,
  spouseFullName,
  isMarried,
  emergencyFullName,
  emergencyAddress,
  phone,
  emergencyPhone,kids
}) => {
    const columns = [
     
      { Header: 'Name', accessor: 'full_name' },
      { Header: 'Birth Date', accessor: 'birth_date',showSortIcons: false, },
      { Header: 'Gender', accessor: 'gender',showSortIcons: false, },
     
    ];
  return (
    <MDBox>
      <Card>
        <CardContent>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} sm={4}>
          
              <MDTypography variant="body2">
                <strong>Mother Name:</strong> {motherName}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Spouse Full Name:</strong> {spouseFullName}
              </MDTypography>
          
          
          
          
                </Grid>
                <Grid item xs={12} sm={4}>
          
                    <MDTypography variant="body2">
                <strong>Emergency Full Name:</strong> {emergencyFullName}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Emergency Address:</strong> {emergencyAddress}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Emergency Phone:</strong> {emergencyPhone}
              </MDTypography>
          
          
          
                </Grid>
                <Grid item xs={12} sm={4}>
          
          
                    <MDTypography variant="body2">
                <strong>Is Married:</strong> {isMarried ? "Married" : "Single"}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Telephone:</strong> {phone}
              </MDTypography>
          
          
          
                </Grid>
                <Grid item xs={12}>
                <MDTypography variant="body2">
                <strong>Person to be notified in case of emergency:</strong> {emergencyFullName},{emergencyAddress},{emergencyPhone}
              </MDTypography>
                </Grid>
          </Grid>
          <MDBox my={5}>
                    <DataTable
                      table={{
                        columns,
                        rows: kids,
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
          
                    />
          </MDBox>
        </CardContent>
      </Card>
    </MDBox>
  )
}

export default FamilyBackground