import React, { useState, useEffect,useRef,useCallback} from "react";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from "examples/Tables/DataTable";
import API_BASE_URL from "api";
import axios from "axios";
import { Autocomplete, TextField, Select, MenuItem } from '@mui/material';
import swal from "sweetalert";
import { useMediaQuery } from '@mui/material'; // Ensure this is correct
import LoadingDialog from "components/Loading/LoadingDialog";


const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json',
  },
});

const AttendanceForm = () => {
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(true);
   const targetId = localStorage.getItem("targetId");
   const [loading2,setLoading2] = useState(false)



   const fetchData = useCallback(async () => {
     try {
       const [coursesData, studentData] = await Promise.all([
         axiosInstance.get(`/instructor/${targetId}/courses`),
         axiosInstance.get(`/instructor/${targetId}/students`),
       ]);

       const coursesArray = coursesData.data[0].course_history.map(course => ({
         id: course.course_id,
         name: course.course?.course_name || 'Unknown Course',
         deadline:course.mark_deadline
       }));

       const today = new Date();
       const filteredCourses = coursesArray.filter(course => {
         const deadlineDate = new Date(course.deadline);
         return deadlineDate > today;
       })

       setCourses(filteredCourses || []);

       const allStudents = studentData.data[0].course_history.flatMap(student =>
         student.registrations.map(registration => ({
           studentId: registration.student_id,
           studentName: `${registration.student.first_name} ${registration.student.last_name}`,
           email: registration.student.email_1,
           courseName: student.course?.course_name || 'Unknown Course',
           courseCode: student.course?.course_number || 'Unknown Course',
           courseId: student.course_id,
           year: student.year,
           class: registration.student.class,
           semester: student.semester,
           attendanceStatus: 'present', // Default to present
           remark: '', // Default empty remark
           showRemarkInput: false, // Track remark input visibility
         }))
       );
       setStudents(allStudents);
       setFilteredStudents(allStudents);
       setLoading(false);
     } catch (error) {
       console.error("Error fetching data:", error);
     } finally {
       setLoading(false)
     }
   },[targetId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedCourse) {
      const filtered = students.filter(student => student.courseId === selectedCourse.id);
      setFilteredStudents(filtered);
    } else {
      setFilteredStudents(students);
    }
  }, [selectedCourse, students]);

  const handleAttendanceChange = (studentIndex, field, value) => {
    const updatedStudents = [...filteredStudents];
    updatedStudents[studentIndex][field] = value;
    setFilteredStudents(updatedStudents);
  };


  const submitAttendance = async () => {
    const attendanceData = {
      instructor_id: 1, // Assuming the instructor ID is known
      course_id: selectedCourse ? selectedCourse.id : null, // Use selected course ID or default
      students: filteredStudents.map(student => ({
        id: student.studentId,
        status: student.attendanceStatus,
        remark: student.attendanceStatus !== 'present' ? student.remark : null, // Include remark only if status is not "present"
      })),
    };

    setLoading2(true)

    try {
      const response = await axiosInstance.post('/attendances', attendanceData);

      if(response.status === 201){

        swal({
          title: "Attendance Submitted Successfully!",
          icon: "success"
        });
  
        fetchData()
        resetStudentData()
        setSelectedCourse(null)
      }
     
    } catch (error) {
      console.error("Error submitting attendance:", error);
    } finally {
      setLoading2(false)
    }
  };
  const resetStudentData = () => {
    const updatedStudents = filteredStudents.map(student => ({
      ...student,
      attendanceStatus: 'present',
      remark: '',
      showRemarkInput: false,
    }));
    setFilteredStudents(updatedStudents);
  };

  const columns = [
    { Header: 'Student Name', accessor: 'studentName' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Course Code', accessor: 'courseCode', showSortIcons: false },
    { Header: 'Course Name', accessor: 'courseName', showSortIcons: false },
    { Header: 'Class', accessor: 'class', showSortIcons: false },
    {
      Header: 'Action',
      accessor: 'attendanceStatus',
      showSortIcons: false,
      Cell: ({ row }) => {
        const studentIndex = row.index;
        return (
          <MDBox>
            <Select
              value={filteredStudents[studentIndex].attendanceStatus}
              onChange={(event) => handleAttendanceChange(studentIndex, 'attendanceStatus', event.target.value)}
              defaultValue="present"
              disabled={!selectedCourse}
              sx={{
                backgroundColor: "#f5f5f5",
                padding: "10px",
                borderRadius: 1,
              }}
            >
              <MenuItem value="present">Present</MenuItem>
              <MenuItem value="absent">Absent</MenuItem>
              <MenuItem value="late">Late</MenuItem>
            </Select>
          </MDBox>
        );
      }
    },
    {
      Header: 'Remark',
      accessor: 'remark',
      showSortIcons: false,
      Cell: ({ row }) => {
        const studentIndex = row.index;

        const student = filteredStudents[studentIndex];

        const isAbsentOrLate = ['absent', 'late'].includes(student.attendanceStatus);
        const inputRef = useRef(null);
        
        useEffect(() => {
          if (isAbsentOrLate && inputRef.current) {
            inputRef.current.focus();
          }
        }, [isAbsentOrLate, studentIndex]);
  
        return isAbsentOrLate ? (
          <TextField
            key={`remark-${student.studentId}`}  // Assuming each student has a unique id
            inputRef={inputRef}
            value={filteredStudents[studentIndex].remark || ''} 
            onChange={(event) => handleAttendanceChange(studentIndex, 'remark', event.target.value)}
            placeholder="Enter remark"
            fullWidth
            variant="outlined"
            size="small"
          />
        ) : null;
      }
    }
  ];

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));


  return (
    <>
     
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Attendance
            </MDTypography>

            <MDBox>
             
                <>
                  <DataTable
                table={{
                  columns,
                  rows: filteredStudents,
                }}
                canSearch={true}
                sortable={true}
                pagination
                rowsPerPage={5}
                showPagination
                widgets={
                  <MDBox sx={{ my: 2, display: 'flex', }}>
                    <Autocomplete
                      fullWidth
                      sx={{
                        width: isSmallScreen ? '100px' : '200px', // Use the variable instead
                        mx: isSmallScreen ? 0 : 2, // Use the variable instead
                      }}
                      options={courses}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, newValue) => setSelectedCourse(newValue)}
                      value={selectedCourse}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Course"
                          InputProps={{
                            ...params.InputProps,
                            style: { height: '40px' }, // Adjust the height as needed
                          }}
                          InputLabelProps={{
                            style: { marginTop: '0px' }, // Adjust the label margin if needed
                          }}
                        />
                      )}
                    />
                  </MDBox>
                }
              />
                {
                selectedCourse &&
                  <MDButton
                    mt={2}
                    variant="gradient"
                    color="info"
                    size="medium"
                    onClick={submitAttendance}
                    sx={{ background: "#00274D", my: 4, mx: 1 }}
                  >
                    Submit 
                  </MDButton>
                }
                </>
              <LoadingDialog open={loading2 || loading}/>
            </MDBox>
          </MDBox>
       
    </>
  );
};

export default AttendanceForm;
