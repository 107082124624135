// EditCoursesDialog.js
import { Alert, Autocomplete, TextField } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from '@mui/material/Switch';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';


const EditProgramsDialog = ({ open, onClose, course, onEdit }) => {
  const [branches,setBranches] = useState([])
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/branches`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        setBranches(response.data);
      } catch (err) {
        console.error(err);
      } 
    };
    fetchBranches();
  },[token]);
  
  const [modalData, setModalData] = React.useState(course);
  const [error, setError] = React.useState(null);
  

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true)
    try {
      const response = await axios.put(`${API_BASE_URL}/programs/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Program Updated Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      console.log(error.reponse)
      // setError("An error occurred");
    }finally {
      setLoading(false); // Stop loading
    }
  };


  const selectedBranch = branches.find(branch => branch.id === modalData.branch_id) || null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>+ EDit Programs</DialogTitle>
    <DialogContent>
      {error && (
        <MDBox mx={2} mt={2} mb={2}>
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        </MDBox>
      )}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Program Name
        </MDTypography>
        <MDInput
          type="text"
          name="program_name"
          fullWidth
          required
          value={modalData.program_name}
          onChange={handleModalChange}
        />
      </MDBox>
      
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Degree Award
        </MDTypography>
        <MDInput
          type="text"
          name="degree_award"
          fullWidth
          required
          value={modalData.degree_award}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Program Code
        </MDTypography>
        <MDInput
          type="text"
          name="program_code"
          fullWidth
          value={modalData.program_code}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
      <Autocomplete
          options={branches || []}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setModalData({ ...modalData, branch_id: newValue ? newValue.id : '' });
          }}
          value={selectedBranch}
          renderInput={(params) => (
            <TextField {...params} label="Select Branch" fullWidth required />
          )}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Is Online
        </MDTypography>
        <Switch
          checked={modalData.is_oline === 1} 
          onChange={(e) => setModalData({ ...modalData, is_oline: e.target.checked ? 1 : 0 })}
        />
      </MDBox>
      
    </DialogContent>
    <DialogActions>
      <MDButton   variant="gradient"
                     size="small"
                     color="info"
                     sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit</MDButton>
      <MDButton   variant="gradient"
                     size="small"
                     color="black"
                     sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />
  </Dialog>
  );
};

export default EditProgramsDialog;
