import React, { useState, useEffect,useCallback } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from 'api';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';

const TakeExam = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [answers, setAnswers] = useState({});
  const targetId = localStorage.getItem('targetId');
  const { id } = useParams();
  const location = useLocation();
  const data = location.state;
  const [questions, setQuestions] = useState(data.questions || []);
  const [remainingTime, setRemainingTime] = useState(
    parseInt(localStorage.getItem(`remainingTime_${id}`)) || data.duration * 60
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps

  

  
  

  
  const showWarningNotSelected = () => {
    swal({
      title: "Warning!",
      text: "You must select at least one question before leaving the exam.",
      icon: "warning",
      button: "OK",
    });
  };
  
  

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;

    setSelectedOption(value);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questions[currentIndex].id]: value,
    }));
  };
 

  const handleNextClick = () => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questions[currentIndex].id]: selectedOption,
    }));

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
      const nextQuestionId = questions[currentIndex + 1]?.id;
      setSelectedOption(answers[nextQuestionId] || '');
    }
  };

  const handleBackClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      const prevQuestionId = questions[currentIndex - 1]?.id;
      setSelectedOption(answers[prevQuestionId] || '');
    }
  };

  const handleSubmit = useCallback(async () => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questions[currentIndex].id]: selectedOption,
    }));
  
    const submissionData = questions.map(question => {
      return {
        exam_id: id,
        question_id: question.id,
        student_id: targetId,
        choice: answers[question.id] || selectedOption,
      };
    });
  
    const results = [];
    try {
      for (const item of submissionData) {
        results.push(item);
      }
  
      const response = await axiosInstance.post('/exam-results', results);
  
      if (response.status === 201) {
        swal({
          title: "Exam Submitted Successfully!",
          icon: "success",
        });
        setIsSubmitted(true);
        localStorage.removeItem(`remainingTime_${id}`);
        navigate("/exam");
      }
    } catch (error) {
      console.error('Error submitting the exam:', error);
      // Optionally handle the error
    }
  }, [
    answers,
    questions, // Add questions as a dependency
    currentIndex, // Add currentIndex as a dependency
    selectedOption, // Add selectedOption as a dependency
    id, // Add id as a dependency
    targetId, // Add targetId as a dependency
    axiosInstance, // Add axiosInstance if it's defined outside the component
    navigate, // Add navigate from useNavigate
  ]);

  useEffect(() => {
    if (data) {
      setQuestions(data.questions);
    }
  
    if (!isSubmitted) {
      const timer = setInterval(() => {
        setRemainingTime(prevTime => {
          if (prevTime > 0) {
            localStorage.setItem(`remainingTime_${id}`, prevTime - 1);
            return prevTime - 1;
          } else {
            clearInterval(timer); // Stop the timer when time is up
  
            // Ensure handleSubmit and warning are called once
            if (!isSubmitted) {
              const hasSelectedAnswer = Object.keys(answers).length > 0; // Check if any answer is selected
              if (!hasSelectedAnswer) {
                // Show warning only once
                showWarningNotSelected();
              } else {
                // Submit exam if at least one answer is selected
                handleSubmit();
              }
            }
  
            return 0;
          }
        });
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [data, id, isSubmitted, answers,handleSubmit]); // Add answers dependency

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && !isSubmitted) {
        setRemainingTime(0); // Set remaining time to 0 when the tab is hidden
  
        const isAnyQuestionSelected = Object.keys(answers).length > 0; // Check if any answer is selected
  
        if (!isAnyQuestionSelected) {
          showWarningNotSelected(); // Show warning if no question is selected
        } else {
          handleSubmit(); // Automatically submit the exam if an answer has been selected
        }
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isSubmitted, answers, handleSubmit]); // Add 'answers' and 'handleSubmit' to the dependency array
  // Add 'answers' to dependency array
  
    
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && !isSubmitted) {
        const isAnyQuestionSelected = Object.keys(answers).length > 0; // Check if any answer is selected
  
        if (!isAnyQuestionSelected) {
          showWarningNotSelected(); // Show warning if no question is selected
        } else {
          handleSubmit(); // Automatically submit the exam if an answer has been selected
        }
      }
    };
  
    const handleBeforeUnload = (event) => {
      if (!isSubmitted) {
        const isAnyQuestionSelected = Object.keys(answers).length > 0; // Check if any answer is selected
        
        if (!isAnyQuestionSelected) {
          event.preventDefault(); // Prevents the default action (like refreshing)
          event.returnValue = ''; // Chrome requires returnValue to be set
          showWarningNotSelected(); // Show alert that at least one question must be selected
        } else {
          handleSubmit(); // Automatically submit the exam if the user confirms they want to leave
        }
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSubmitted, answers,handleSubmit]); // Add relevant dependencies
  

  const currentQuestion = questions[currentIndex];

  return (
    <DashboardLayout>
  <Card>
    <CardContent>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        p={4}
        sx={{
          backgroundColor: '#00274D',
          color: 'white',
          p: 2,
          flex: 1,
          borderRadius: 5,
          opacity: 0.9,
        }}
      >
        <MDBox>
          <MDTypography
            variant="h5"
            fontWeight="medium"
            color="white"
            sx={{
              fontSize: { xs: '1.5rem', sm: '1.75rem' } // Adjusts for small screens
            }}
          >
            {data.course_name} {data.course.course_number}
          </MDTypography>
          <MDTypography
            variant="body1"
            mt={1}
            color="white"
            sx={{
              fontSize: { xs: '1rem', sm: '1.125rem' } // Adjusts for small screens
            }}
          >
            {data.course.course_name} {data.exam_type} exam
          </MDTypography>
          <MDTypography
            variant="body2"
            mt={1}
            color="white"
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' } // Adjusts for small screens
            }}
          >
            Credit Hours: {data.course.credit_hour}
          </MDTypography>
        </MDBox>

        <MDBox>
          <MDTypography
            variant="h6"
            color="white"
            sx={{
              fontSize: { xs: '1.25rem', sm: '1.5rem' } // Adjusts for small screens
            }}
          >
            Timer: {formatTime(remainingTime)}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox sx={{ mb: 3 }}>
        <>
          <MDTypography
            variant="h5"
            fontWeight="medium"
            sx={{
              fontSize: { xs: '1.5rem', sm: '1.75rem' } // Adjusts for small screens
            }}
          >
            Question {currentIndex + 1} / {questions.length}
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            mt={2}
            sx={{
              fontSize: { xs: '1.25rem', sm: '1.5rem' } // Adjusts for small screens
            }}
          >
            {currentIndex + 1}. {currentQuestion.question}
          </MDTypography>
          <MDBox mt={2}>
            <FormControl component="fieldset">
              <RadioGroup
                name={`question-${currentQuestion.id}`}
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <FormControlLabel value="a" control={<Radio />} label={currentQuestion.option_a} />
                <FormControlLabel value="b" control={<Radio />} label={currentQuestion.option_b} />
                {currentQuestion.option_c && (
                  <FormControlLabel value="c" control={<Radio />} label={currentQuestion.option_c} />
                )}
                {currentQuestion.option_d && (
                  <FormControlLabel value="d" control={<Radio />} label={currentQuestion.option_d} />
                )}
                {currentQuestion.option_e && (
                  <FormControlLabel value="e" control={<Radio />} label={currentQuestion.option_e} />
                )}
                {currentQuestion.option_f && (
                  <FormControlLabel value="f" control={<Radio />} label={currentQuestion.option_f} />
                )}
              </RadioGroup>
            </FormControl>
          </MDBox>
        </>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end" mt={4} gap={2}>
        <MDButton
          variant="contained"
          sx={{ backgroundColor: '#00274D', color: '#fff' }}
          onClick={handleBackClick}
          disabled={currentIndex === 0}
        >
          Previous
        </MDButton>
        {currentIndex === questions.length - 1 ? (
          <MDButton
            variant="contained"
            sx={{ backgroundColor: '#0041B3', color: '#fff' }}
            onClick={handleSubmit}
            disabled={selectedOption === ''}
          >
            Submit
          </MDButton>
        ) : (
          <MDButton
            variant="contained"
            onClick={handleNextClick}
            sx={{
              backgroundColor: '#0041B3',
              color: '#fff'
            }}
            disabled={selectedOption === ''}
          >
            Next
          </MDButton>
        )}
      </MDBox>
    </CardContent>
  </Card>
</DashboardLayout>

  );
};

export default TakeExam;
