import React, { useState,useEffect,useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import { axiosInstance } from "api2";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormGroup from "@mui/material/FormGroup";
import { Alert,  Autocomplete,
  TextField, } from '@mui/material';
  

const PostAnnouncement = ({ open, onClose, onAdd }) => {
  const targetId = localStorage.getItem("targetId");
  const [positions,setPositions] = useState([]);
  const [byPosition, setByPosition] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [recipients, setRecipients] = useState([]); 
  const [updatedRecipients, setUpdatedRecipients] = useState([]); 
  const [errors, setErrors] = useState({});

 

  const [modalData, setModalData] = useState({
    announced_by: Number(targetId),
    announcement_date: "",
    title: "",
    content: "",
    // recipients: []  // Added recipients array to hold selected options
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/positions');
       
        setPositions(response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPosition) {
      const fetchEmployees = async () => {
        try {
          const response = await axiosInstance.get(`/employees`);
      const employees = response?.data?.filter((employee) => {
        return employee.position_id === selectedPosition.id;
      })

      setRecipients(employees.map((employee) => employee.id));
        
      
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      }   
      fetchEmployees()  
    }
  },[selectedPosition]);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setErrors({});
  };

  const fetchUsers = useCallback(async (role) => {
    try {
      var url = "employees";
      if(role === "instructor" ) {
        url = "instructors";
      }else if (role === "student") {
        url = "applicant-student";
      }
      else{

      }
      const response = await axiosInstance.get(`/${url}`);

  
      setRecipients(response.data?.map((user) => user.id))
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  },[]);

  const fetchAllUsers = useCallback(async () => {
    try {
      
      const response = await axiosInstance.get(`/users`);
      setRecipients(response.data?.map((employee) => employee.id));
      
   
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  },[]);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setErrors({});


    if (value === "positions") {
      setByPosition((prev) => !prev); // Toggle the position selection box
      setUpdatedRecipients((prev) => (prev.includes(value) ? [] : [value])); // Toggle the recipients
  } else {
      setByPosition(false); // Hide the position selection box if any other option is selected
      setUpdatedRecipients([value]); // Set the updated recipients to the selected value
  }
  
    // Set the selected recipient type
    setUpdatedRecipients(value);
  
    // Execute actions based on the selected recipient
    switch (value) {
      case "positions":
        setByPosition(true);
        break;
      case "students":
        fetchUsers("student");
        break;
      case "employees":
        fetchUsers("employees");
        break;
      case "all":
        fetchAllUsers();
        break;
      default:
        break;
    }
  };
  

  const handleModalSubmit = async () => {
    try {
      const response = await axiosInstance.post(`/announcements`, { 
        ...modalData,
        recipients: recipients, // Attach recipients upon submission
        audience:updatedRecipients,
        position_title:selectedPosition?.position_title 

      });
      console.log("slectedPosition:",selectedPosition?.position_title)
      
      if (response.status === 201) {
        onAdd();
        onClose();
        swal({
          title: "Announcement Created Successfully!",
          icon: "success"
        });
        setModalData({
          announcement_date: "",
          title: "",
          content: "",
        
        });
      setRecipients([])
      }
    } catch (error) {
      console.log("error:", error);
      // if (error.response && error.response.data.errors) {
      //   // Set the errors in the state
      //   setErrors(error.response.data.errors);
      // }
    }
  };



  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}><AddIcon /> Post Announcement</DialogTitle>
      <DialogContent>
      {Object.entries(errors).map(([field, messages]) => (
            <Alert severity="error" key={field}>
              {`${field.charAt(0).toUpperCase() + field.slice(1)}: ${messages.join(", ")}`}
            </Alert>
          ))}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Announcement Date
          </MDTypography>
          <MDInput
            type="date"
            name="announcement_date"
            fullWidth
            required
            value={modalData.announcement_date}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Title
          </MDTypography>
          <MDInput
            type="text"
            name="title"
            fullWidth
            required
            value={modalData.title}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Content
          </MDTypography>
          <MDInput
            type="text"
            name="content"
            fullWidth
            required
            multiline
            rows={3}
            value={modalData.content}
            onChange={handleModalChange}
          />
        </MDBox>
        {
          byPosition && (
            <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Position
            </MDTypography>
            <Autocomplete
              fullWidth
              options={positions}
              getOptionLabel={(option) => option.position_title || ''}
              onChange={(event, newValue) => setSelectedPosition(newValue)}
              value={selectedPosition}
              renderInput={(params) => <TextField {...params} label="Position" />}
            />
          </MDBox>
            
          )
        }
      
      <MDBox mb={2}>
  <MDTypography variant="caption" color="text" fontWeight="medium">
    Select Recipients
  </MDTypography>
  <FormGroup>
    <FormControlLabel
      control={
        <Radio
          checked={updatedRecipients === "positions"}
          onChange={handleRadioChange}
          value="positions"
          name="recipients"
        />
      }
      label="By Positions"
    />
    <FormControlLabel
      control={
        <Radio
          checked={updatedRecipients === "students"}
          onChange={handleRadioChange}
          value="students"
          name="recipients"
        />
      }
      label="To Students"
    />
    <FormControlLabel
      control={
        <Radio
          checked={updatedRecipients === "employees"}
          onChange={handleRadioChange}
          value="employees"
          name="recipients"
        />
      }
      label="To Employees"
    />
    <FormControlLabel
      control={
        <Radio
          checked={updatedRecipients === "all"}
          onChange={handleRadioChange}
          value="all"
          name="recipients"
        />
      }
      label="To All Students & Employees"
    />
  </FormGroup>
</MDBox>

      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default PostAnnouncement;
