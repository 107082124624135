import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for store requests
const initialState = {
  store_requests: [],
};

// Create a slice for store requests
const storeRequestSlice = createSlice({
  name: 'store_requests',
  initialState,
  reducers: {
    // Action to add a new store request
    addStoreRequest: (state, action) => {
      state.store_requests.push(action.payload);
    },
    // Action to delete a store request by its ID
    deleteStoreRequest: (state, action) => {
      const { id } = action.payload;
      state.store_requests = state.store_requests.filter((request) => request.id !== id);
    },
    // Action to clear all store requests
    clearStoreRequests: (state) => {
      state.store_requests = [];
    },
  },
});

// Export actions for use in components
export const { addStoreRequest, deleteStoreRequest, clearStoreRequests } = storeRequestSlice.actions;

// Export the reducer to be used in the store
export default storeRequestSlice.reducer;
