import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { axiosInstance } from 'api2';


const LoanRepayment = ({ open, onClose, loan, onAdd }) => {
    const [loanData, setLoanData] = useState({
        loan_id: "",
        repayment_amount: "",
        repayment_date: "",
      });

      useEffect(() => {
        if (loan) {
          setLoanData((prevData) => ({
            ...prevData,
            loan_id: loan.id,
          }));
        }
      }, [loan, loanData]); // Add loanData as a dependency

      const handleLoanDataChange = (e) => {
        const { name, value } = e.target;
        setLoanData({
          ...loanData,
          [name]: value,
        });
      };

      const handleLoanSubmit = async () => {
       
    
        try {
            console.log("loanData:",loanData)
            const updatedLoanData = {
                ...loanData,
                repayment_amount: Number(loanData.repayment_amount),
              };
            
          await axiosInstance.post(`/loan-repayments`, updatedLoanData);
          swal("Success", "Loan updated successfully", "success");
          onAdd();
          onClose();
          setLoanData({
            loan_id: "",
            repayment_amount: "",
            repayment_date: "",
          })
        } catch (error) {
          swal("Error", "Failed to update the loan", "error");
          console.error("Error updating loan:", error);
        }
      };
    


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign="center">
      <AddIcon /> Loan Payment
    </DialogTitle>
    <DialogContent>
     

      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Repayment Amount
        </MDTypography>
        <MDInput
          type="number"
          name="repayment_amount"
          fullWidth
          required
          value={loanData.repayment_amount || ''}
          onChange={handleLoanDataChange}
        />
      </MDBox>

     

      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Loan Repayment Date
        </MDTypography>
        <MDInput
          type="date"
          name="repayment_date"
          fullWidth
          required
          value={loanData.repayment_date || ''}
          onChange={handleLoanDataChange}
        />
      </MDBox>
    </DialogContent>
    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleLoanSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Close
      </MDButton>
    </DialogActions>
  </Dialog>
  )
}

export default LoanRepayment