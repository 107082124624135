import { createSlice } from "@reduxjs/toolkit";

const initialState = [
];

const educationalBackgroundsSlice = createSlice({
  name: "educationalBackgrounds",
  initialState,
  reducers: {
    addEducationalBackground: (state, action) => {      
      state.push(action.payload);
    },
    updateEducationalBackground: (state, action) => {
      state = state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteEducationalBackground: (state, action) => {
      return state.filter(item => 
        JSON.stringify(item) !== JSON.stringify(action.payload)
      );
    }
  },
});
export const { addEducationalBackground, updateEducationalBackground,deleteEducationalBackground } =
  educationalBackgroundsSlice.actions;

export default educationalBackgroundsSlice.reducer;
