import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addKid, deleteKid
} from "../../../../../redux/features/employeeSlice";

const AddChildren = () => {
    const [currentChildren, setCurrentChildren] = useState({
        full_name: "",
        birth_date: "",
        gender: "",
        birth_certificate: null,
      });
      const [fileName, setFileName] = useState('');
      const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const childrens = useSelector((state) => state.employee.kids);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentChildren((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name); 
    setCurrentChildren(prev => ({
      ...prev,
      birth_certificate: file
    }));
  };

  const handleAddChildren = () => {
    
    if (currentChildren.birth_date === "" || currentChildren.gender === "" ||  currentChildren.full_name === 0
     || currentChildren.birth_certificate === null) {
      alert("Please add all required fields to continue");
    }
    else{      
    dispatch(addKid(currentChildren));
    console.log("currentChildren", currentChildren);
    setCurrentChildren({
        full_name: "",
        birth_date: "",
        gender: "",
        birth_certificate: null,
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  }
  };
  const handleDeleteChildren= (row) => {  
    console.log("rows:",row.values)   
    dispatch(deleteKid(row.values));
  };

useEffect(() => {
    if (childrens.length > 0) {
    }
  }, [childrens]);
  return (
            <MDBox my={3}>
            <MDTypography variant="h5" fontWeight="bold" gutterBottom>
                Children
            </MDTypography>
            <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Full Name<span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="text"
                        label="Full Name"
                        name="full_name"
                        value={currentChildren.full_name}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Bith Date <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="date"
                        // label="Bith Date"
                        name="birth_date"
                        value={currentChildren.birth_date}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  
                 
                  <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                      Gender <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="gender-label">Select Gender</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender-select"
                      label="Gender"
                      name="gender"
                      required
                      value={currentChildren.gender}
                      onChange={handleInputChange}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: 1,
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "32px",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          sx={{
                            position: "absolute",
                            right: 8,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
                  
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Upload Birth Certificate <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <input
                        type="file"
                        name="birth_certificate"
                        accept=".jpg,.jpeg,.png,.pdf"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: "block", marginTop: 8 }}
                      />
                    </MDBox>
                    {
                        fileName && (
                          <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                           { fileName && <p>Uploaded File: {fileName}</p>}
                        </MDTypography>
                        )
                      }
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDButton variant="gradient" color="info" size="small" onClick={handleAddChildren}>
                      Add
                    </MDButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    table={{
                      columns: [
                        { Header: "Full Name", accessor: "full_name", width: "25%" },
                        { Header: "Birth Date", accessor: "birth_date", width: "30%" },
                        { Header: "Gender", accessor: "gender" },
                        
                        {
                          Header: "Birt Certficate",
                          accessor: "birth_certificate",
                          Cell: ({ row }) => {
                            const birth_certificate = row.original.birth_certificate; // Access the original birth_certificate object
                            return <span>{birth_certificate ? birth_certificate.name : "No Document"}</span>; // Display the document name or a placeholder
                          },
                        },                    {
                          Header: "Actions",
                          accessor: "actions",
                          Cell: ({ row }) => (
                            <MDButton
                              variant="outlined"
                              color="error"
                              onClick={() => handleDeleteChildren(row)}
                            >
                              Delete
                            </MDButton>
                          ),
                        },
                      ],
                      rows: childrens,
                    }}
                  />
                </Grid>
            </MDBox>
  )
}

export default AddChildren