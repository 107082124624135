import React, { useEffect, useState } from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from "api2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import {Card,CardContent  } from "@mui/material";

const IndividualMarklist = () => {
  const [loading, setLoading] = useState(true);
  const [coursesResults, setCoursesResults] = useState([]);
  const targetId = localStorage.getItem("targetId");

  useEffect(() => {
    const fetchCoursesResults = async () => {
      setLoading(true); // Start loading

      try {
        const response = await axiosInstance.get(`/student/${targetId}/mark-list`); // Update with the correct endpoint
        setCoursesResults(response.data.courses_results); // Assuming the response contains the courses_results array
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // End loading after fetching data
      }
    };

    fetchCoursesResults();
  }, [targetId]);
  const generateScoreColumns = (scoreFields, prefix) => {
    return Object.keys(scoreFields).map((key) => ({
      Header: `${prefix} ${key.replace('_', ' ')}`,
      accessor: `${prefix === 'Assignment' ? 'assignment_scores' : 'scores'}.${key}`,
      showSortIcons: false,
    }));
  };
  const sampleMark = coursesResults.length > 0 ? coursesResults[0] : {
    scores: {
      quiz: 0,
      mid: 0,
      final: 0,
    },
    assignment_scores: {
      individual: 0,
      group: 0,
    },
  };

  const baseColumns = [
    {
      Header: 'Course Name',
      accessor: 'course_name',
    },
    {
        Header: 'Course No',
        accessor: 'course_number',
        showSortIcons: false,
      },
    {
      Header: 'Total',
      accessor: 'total',
      showSortIcons: false,
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      showSortIcons: false,
    },
  ];

  const columns = [
    ...baseColumns,
    ...generateScoreColumns(sampleMark.scores, 'Score'),
    ...generateScoreColumns(sampleMark.assignment_scores, 'Assignment'),
  ];

  return (
    <DashboardLayout>
        <Card>
            <CardContent>
                <MDBox my={2}>
                  <MDTypography variant="h5" fontWeight="medium" my={2}>                  
                    Marklist
                  </MDTypography>
                  <MDBox>
                    <DataTable
                      table={{
                        columns,
                        rows: coursesResults,
                      }}
                      canSearch={true}
                      sortable={true}
                      rowsPerPage={5}
                      showPagination
                    />
                    {!loading && coursesResults.length === 0 && (
                      <MDTypography variant="h5" display="block" align="center" my={2} sx={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "#9e9e9e",
                        opacity: 0.7,
                      }}>
                        No data available
                      </MDTypography>
                    )}
                  </MDBox>
                  <LoadingDialog open={loading} />
                </MDBox>
            </CardContent>
        </Card>
    </DashboardLayout>
  );
};

export default IndividualMarklist;
