import { Card, useMediaQuery, Menu, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Branch from './branches';
import Courses from './courses';
import GradeInfo from './gradeInfo';
import Instructor from './Instructor';
import Programs from './programs';
import Semesters from './semesters';
import Status from './status';
import Years from './years';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CourseAcademics = () => {
  const location = useLocation();
  const active = location.state?.active || 0;
  const [value, setValue] = useState(active);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const isExtraSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index) => {
    setValue(index);
    handleMenuClose();
  };

  const renderTab = (label, index) => (
    <Tab
      label={
        value === index ? (
          <span style={{ color: 'white' }}>{label}</span>
        ) : (
          label
        )
      }
      {...a11yProps(index)}
    />
  );

  const tabsToRender = isExtraSmallScreen ? 2 : (isSmallScreen ? 4 : 8);

  const tabsData = [
    { label: "Courses", component: <Courses /> },
    { label: "Programs", component: <Programs /> },
    { label: "Status", component: <Status /> },
    { label: "Semesters", component: <Semesters /> },
    { label: "Grade Info", component: <GradeInfo /> },
    { label: "Branch", component: <Branch /> },
    { label: "Years", component: <Years /> },
    { label: "Instructor", component: <Instructor /> },
  ];
  
  return (
    <DashboardLayout>
      <Card>
        <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={value} 
            onChange={handleChange} 
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#00274D",
              }
            }}
            sx={{
              "& .MuiTab-root": {
                marginRight: 2,
              },
              "& .MuiTab-root:last-child": {
                marginRight: 0,
              }
            }}
          >
            {tabsData.slice(0, tabsToRender).map((tab, index) => renderTab(tab.label, index))}
            {tabsToRender < tabsData.length && (
              <Tab 
                icon={<MoreHorizIcon />} 
                aria-label="more"
                onClick={handleMenuOpen}
              />
            )}
          </Tabs>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {tabsData.slice(tabsToRender).map((tab, index) => (
              <MenuItem key={index + tabsToRender} onClick={() => handleMenuItemClick(index + tabsToRender)}>
                {tab.label}
              </MenuItem>
            ))}
          </Menu>
        </MDBox>
        {tabsData.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Card>
    </DashboardLayout>
  )
}

export default CourseAcademics