import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useState } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from 'react-router-dom';
import EditKid from './EditKid';
import swal from 'sweetalert';
import { axiosInstance } from 'api2';
import { IconButton } from '@mui/material';
import FileViewer from "components/FileViewer";
import MDButton from 'components/MDButton';

const FamilyBackground = ({
  motherName,
  spouseFullName,
  isMarried,
  emergencyFullName,
  emergencyAddress,
  phone,
  emergencyPhone,kids
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const {id} = useParams();
  const [selectedKid, setSelectedKid] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [open, setOpen] = useState(false);


  const handleEditClick = (kid) => {
    console.log("kids:",kid)
    setSelectedKid(kid);
    setOpenDialog(true);
  };

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpen(true);
  };



  
    const handleDelete = async (dataId) => {
      const confirm = await swal({
        title: "Are you sure?",
        text: "You will not be able to recover this education kids!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
  
      if (confirm) {
        
  
        try {
          const response = await axiosInstance.delete(`/employees/${id}/kids/${dataId.id}`);
          if (response.status === 204) {
           
          swal({
            title: "Kids Deleted Successfully!",
            icon: "success",
          }).then(() => window.location.reload());
          }
        } catch (error) {
          swal({
            title: `${error.response.data.message}`,
            icon: "warning"
          });
        } 
      }
    };

    const columns = [
     
      { Header: 'Name', accessor: 'full_name' },
      { Header: 'Birth Date', accessor: 'birth_date',showSortIcons: false, },
      { Header: 'Gender', accessor: 'gender',showSortIcons: false, },
      {
        Header: 'File Path',
        accessor: 'birth_certificate',
        Cell: ({ value }) => (
          <MDButton
            variant="body2"
            color="primary"
            component="a"
            onClick={() => handleViewFile(value)}
            style={{ cursor: 'pointer' }}
            sx={{ 
              cursor: 'pointer',
              textDecoration: 'underline',
              boxShadow: 1, // Adds a default shadow
              transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
              '&:hover': {
                color: 'secondary.main',
                boxShadow: 4, // Increases shadow intensity on hover
              }
            }}
          >
            View File
          </MDButton>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        showSortIcons:false,
        Cell: ({ row }) => (
          <MDBox display="flex" gap={2}>
            <IconButton onClick={() => handleEditClick(row.original)}>
             <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row.original)}>
             <DeleteIcon />
            </IconButton>
          </MDBox>
        ),
      },
     
    ];

  
  return (
    <MDBox>
      <Card>
        <CardContent>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} sm={4}>
          
              <MDTypography variant="body2">
                <strong>Mother Name:</strong> {motherName}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Spouse Full Name:</strong> {spouseFullName}
              </MDTypography>
          
          
          
          
                </Grid>
                <Grid item xs={12} sm={4}>
          
                    <MDTypography variant="body2">
                <strong>Emergency Full Name:</strong> {emergencyFullName}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Emergency Address:</strong> {emergencyAddress}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Emergency Phone:</strong> {emergencyPhone}
              </MDTypography>
          
          
          
                </Grid>
                <Grid item xs={12} sm={4}>
          
          
                    <MDTypography variant="body2">
                <strong>Is Married:</strong> {isMarried ? "Married" : "Single"}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Telephone:</strong> {phone}
              </MDTypography>
          
          
          
                </Grid>
                <Grid item xs={12}>
                
                </Grid>
          </Grid>
          <MDBox my={5}>
          <MDTypography variant="h5" fontWeight="medium" mb={1}>
        Childrens
      </MDTypography>
                    <DataTable
                      table={{
                        columns,
                        rows: kids,
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
          
                    />
          </MDBox>
          <FileViewer
        open={open}
        onClose={() => setOpen(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
          {openDialog && (
            <EditKid
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              kid={selectedKid}
            />
          )}
        </CardContent>
      </Card>
    </MDBox>
  )
}

export default FamilyBackground