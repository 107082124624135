import { configureStore } from "@reduxjs/toolkit";
import studentInfoReducer from "./features/studentInfoSlice";
import educationalBackgroundsReducer from "./features/educationalBackgroundsSlice";
import studentEmployeementRecordsReducer from "./features/studentEmployeementRecordsSlice";
import referencesReducer from "./features/referencesSlice";
import languagesReducer from "./features/languageSlice";
import paymentReducer from "./features/paymentSlice";
import employeeReducer from "./features/employeeSlice";
import courseHistoryReducer from "./features/courseHistorySlice";


// Create the Redux store with combined reducers
const store = configureStore({
  reducer: {
    studentInfo: studentInfoReducer,
    educationalBackgrounds: educationalBackgroundsReducer,
    studentEmployeementRecords: studentEmployeementRecordsReducer,
    references: referencesReducer,
    languages: languagesReducer,
    payment: paymentReducer,
    employee:employeeReducer,
    course_history:courseHistoryReducer
  },
});

export default store;
