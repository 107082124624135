import React, { useState, useEffect, useCallback } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import swal from 'sweetalert';
import { axiosInstance } from "api2";
import AddMaterial from "./addMaterial";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import FileViewer from "components/FileViewer";
import EditMaterial from "./editMaterial";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LoadingDialog from "components/Loading/LoadingDialog";

const Material = () => {
   
    const [openDialog, setOpenDialog] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
    const [materials, setMaterials] = useState([]);
    const targetId = localStorage.getItem("targetId");
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [loading,setLoading] = useState(true);
    const [deletingStatus, setDeletingStatus] = useState(false); 

    const fetchMaterials = useCallback(async () => {
        try {
           
            // Fetch materials from the API using the targetId
            const response = await axiosInstance.get(`instructor/${targetId}/materials`);

            // Process the response to format assigned_users and shared_by
            const formattedMaterials = response.data.map(material => ({
                id: material.id,
                materialName: material.material_name,
                materialType: material.material_type,
                dueDate: material.due_date,
                remark: material.remark,
                filePath: material.file_path,
                   course:material.course,
        instructor:material.instructor,
                assignedUsers: material.students.map(student => `${student.first_name} ${student.last_name}`).join(', '),
                sharedBy: material.instructor ? material.instructor.full_name : 'N/A',
            }));

          

            // Set the formatted materials to the state
            setMaterials(formattedMaterials);
        } catch (err) {
            console.error(err);
        } finally{
          setLoading(false)
        }
    }, [targetId]);


    const handleViewFile = (filePath) => {
      
      const extension = filePath.split('.').pop().toLowerCase();
      setSelectedFile(filePath);
      setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
      setOpenDialog(true);
    };
  

    useEffect(() => {
        fetchMaterials();
    }, [fetchMaterials]);

    const handleOpenEdit = (material) => {
      setSelectedMaterial(material);
      setOpenEdit(true);
    };

    const handleAddMaterial = () => {
        fetchMaterials();
    };

    const handleEditMaterial = () => {
      fetchMaterials();
    };

    const handleDeleteMaterial = async (id) => {
        const confirm = await swal({
            title: "Are you sure?",
            text: "You will not be able to recover this material!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (confirm) {
          setDeletingStatus(true);
            try {
                const response = await axiosInstance.delete(`/materials/${id}`);
                if (response.status === 204) {
                    setMaterials(materials.filter((m) => m.id !== id));
                    swal({
                        title: "Material Deleted Successfully!",
                        icon: "success"
                    });
                }
            } catch (error) {
                swal({
                    title: `${error.response.data.message}`,
                    icon: "warning"
                });
            } finally {
              setDeletingStatus(false); // End loading for deletion
            } 
        }
    };

    const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
    };

 

    const columns = [
      {
        accessor: 'action',
        Header: '',  // You can leave this blank if you don't want a header
        width: 40,  // Explicit width in pixels
        padding:0,
        Cell: ({ row }) => (
          <PopupState variant="popover"   popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                  <MoreVertIcon />
                </Button>
                <Menu {...bindMenu(popupState)}>
                  
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteMaterial(row.original.id);
                  popupState.close();
                }} style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        ),
        showSortIcons: false,
        
        
      },
        { Header: 'Material Name', accessor: 'materialName' },
           { Header: 'Course Number', accessor: 'course.course_number',showSortIcons: false },
       { Header: 'Course Name', accessor: 'course.course_name' },
        { Header: 'Material Type', accessor: 'materialType', showSortIcons: false },
        { Header: 'Remark', accessor: 'remark', showSortIcons: false },
        {
          Header: 'File Path',
          accessor: 'filePath',
          Cell: ({ value }) => (
            <MDButton
              variant="body2"
              color="primary"
              component="a"
              onClick={() => handleViewFile(value)}
              style={{ cursor: 'pointer' }}
              sx={{ 
                cursor: 'pointer',
                textDecoration: 'underline',
                boxShadow: 1, // Adds a default shadow
                transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
                '&:hover': {
                  color: 'secondary.main',
                  boxShadow: 4, // Increases shadow intensity on hover
                }
              }}
            >
              View File
            </MDButton>
          ),
        },
     
        
    ];

    return (
        <DashboardLayout>
            <Card>
                <CardContent>
                    <MDBox>
                        <MDTypography variant="h5" fontWeight="medium">
                            Materials
                        </MDTypography>
                        <MDBox>
                        <DataTable
                                table={{
                                    columns,
                                    rows: materials,
                                }}
                                canSearch={true}
                                sortable={true}
                                rowsPerPage={5}
                                showPagination
                                widgets={
                                    <MDBox mx={2}>
                                        <MDButton
                                            variant="gradient"
                                            color="info"
                                            size="small"
                                            onClick={() => setOpenAdd(true)}
                                            sx={{ background: "#00274D", my: 4, mx: 3 }}
                                        >
                                            <span>Add</span>
                                        </MDButton>
                                    </MDBox>
                                }
                            />
                        </MDBox>
                        <AddMaterial open={openAdd} onClose={handleCloseDialog} onAdd={handleAddMaterial} />
                    </MDBox>
                    <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
       <EditMaterial open={openEdit} onClose={handleCloseDialog} material={selectedMaterial} onEdit={handleEditMaterial} />
                </CardContent>
            </Card>
            <LoadingDialog open={loading || deletingStatus} />
        </DashboardLayout>
    )
}

export default Material;
