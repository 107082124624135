import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';


import {
  Autocomplete,
  TextField
} from "@mui/material";


// Axios instance for API requests
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});


const EditInstructorDialog = ({ open, onClose, instructor, onEdit }) => {
  const [modalData, setModalData] = useState(instructor);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/employees");
        setEmployees(response.data); // Set the positions data
      } catch (err) {
        console.error("Failed to fetch employees:", err);
      }
    };

    fetchData();
  }, [token]);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const handleModalSubmit = async () => {
    setLoading(true)
    try {
      const response = await axios.put(`${API_BASE_URL}/instructors/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Instructor Updated Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      console.log(error.reponse)
    }finally {
      setLoading(false); // Stop loading
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>
      <AddIcon /> Edit Instructor
    </DialogTitle>
    <DialogContent>
      {/* Position Title */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Full Name
        </MDTypography>
        <MDInput
          type="text"
          name="full_name"
          fullWidth
          required
          value={modalData.full_name}
          onChange={handleModalChange}
          error={Boolean(error.full_name)}
          helperText={error.full_name}
        />
      </MDBox>

      {/* Job Description */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Email
        </MDTypography>
        <MDInput
          type="text"
          name="email"
          fullWidth
          required
          value={modalData.email}
          onChange={handleModalChange}
          error={Boolean(error.email)}
          helperText={error.email}
        />
      </MDBox>

      {/* Minimum Salary */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Phone Number
        </MDTypography>
        <MDInput
          type="number"
          name="phone_number"
          fullWidth
          required
          value={modalData.phone_number}
          onChange={handleModalChange}
          error={Boolean(error.phone_number)}
          helperText={error.phone_number}
        />
      </MDBox>
      <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Qualifications
          </MDTypography>
          <MDInput
            type="text"
            name="qualification"
            fullWidth
            required
            value={modalData.qualification}
            onChange={handleModalChange}
            error={Boolean(error.qualification)}
            helperText={error.qualification}
          />
        </MDBox>


      {/* Maximum Salary */}
      

      {/* Reports To (Autocomplete for selecting a manager) */}
      <MDBox mb={2}>
      
      <Autocomplete
    options={employees}
    value={employees.find(employee => employee.id === modalData.employee_id) || null} 
    getOptionLabel={(option) => option.first_name + " " + option.last_name } 
    onChange={(event, newValue) => {
        setModalData({
            ...modalData,
            employee_id: newValue ? newValue.id : null, 
        });
    }}
    renderInput={(params) => <TextField {...params} label="Employee Id" />}
/>
</MDBox>

    </DialogContent>

    {/* Actions */}
    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleModalSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Cancel
      </MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />
  </Dialog>
  )
}

export default EditInstructorDialog