import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { axiosInstance } from 'api2';
import { CircularProgress } from '@mui/material';
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";


function formatNumber(num) {
  // Ensure the input is a number
  const numberValue = parseFloat(num);
  if (isNaN(numberValue)) {
      return 'Invalid number';
  }
  // Convert the number to a string and use toLocaleString for formatting
  return numberValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  });
}


const EmployeeDashboard = () => {
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const targetId = localStorage.getItem('targetId');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axiosInstance.get(`/employee-dashboard/${targetId}`);
        const data = await response.data;
     
        
        setEmployeeInfo({
          name: `${data.employee.first_name} ${data.employee.last_name}`,
          assets: 0, // Set the CGPA calculated
          leaveBalances:data.leave_balances,
          leaveRequests: data.employee.leave_requests,
          loanRequests: data.employee.loans,
          announcements: data.announcements, // Add announcements if available
          salary:formatNumber(data.employee.salary)
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
      }finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };
  
    fetchEmployeeData();
  }, [targetId]);
 
  if (!employeeInfo && loading) {
    return <DashboardLayout><MDBox display="flex" justifyContent="center" alignItems="center" height="200px">
    <CircularProgress />
  </MDBox></DashboardLayout>;
  }

  const cardData = [
    { label: 'Salary', value: employeeInfo.salary || "N/A", icon: "attach_money", color: "success" },
    { label: 'Leave Days', value: employeeInfo.leaveBalances, icon: "event_available" , color: "info" },
    { label: 'My Assets', value: employeeInfo.assets, icon: "business_center", color: "primary" },
  ];
  

  const col1 = [
    {
        Header: "Leave ID",
        accessor: "id", // Leave request ID
        showSortIcons: false
    },
  
    {
        Header: "Leave Type ID",
        accessor: "leave_type_id", // Leave type ID
        showSortIcons: false
    },
    {
        Header: "Start Date",
        accessor: "start_date", // Start date of the leave request
        showSortIcons: false,
        
    },
    {
        Header: "End Date",
        accessor: "end_date", // End date of the leave request
        showSortIcons: false,
       
    },
    {
        Header: "Total Days Requested",
        accessor: "total_days_requested", // Total days requested for the leave
        showSortIcons: false,
    },
    {
        Header: "Status",
        accessor: "status", // Current status of the leave request
        showSortIcons: false,
    },
    {
        Header: "Reason",
        accessor: "reason", // Reason for the leave request
        showSortIcons: false
    },
    {
        Header: "Approval Date",
        accessor: "approval_date", // Date the leave was approved
        showSortIcons: false,
        
    },
    {
        Header: "HR Approved Date",
        accessor: "hr_approved_date", // Date HR approved the leave (if available)
        showSortIcons: false,
       
    },
    {
        Header: "Attachment",
        accessor: "attachment", // URL for leave attachment
        Cell: ({ value }) => value ? <a href={value} target="_blank" rel="noopener noreferrer">View Attachment</a> : "No Attachment",
    },
    {
        Header: "Approved By",
        accessor: "approved_by", // ID of the person who approved the leave
        showSortIcons: false,
    },
    {
        Header: "HR Approver",
        accessor: "hr_approver", // HR approver (if available)
        showSortIcons: false,
        Cell: ({ value }) => (value ? value : "Pending"),
    },
   
];

const col2 = [
  {
      Header: "Loan ID",
      accessor: "id", // Unique identifier for the loan
      showSortIcons: true,
  },
  {
      Header: "Loan Amount",
      accessor: "loan_amount", // Amount of the loan
      showSortIcons: false,
      
  },
  {
      Header: "Interest Rate (%)",
      accessor: "loan_interest_rate", // Interest rate of the loan
      showSortIcons: false,
      Cell: ({ value }) => `${value}%`, // Format as percentage
  },
  {
      Header: "Start Date",
      accessor: "loan_start_date", // Start date of the loan
      showSortIcons: false,
     
  },
  {
      Header: "End Date",
      accessor: "loan_end_date", // End date of the loan
      showSortIcons: false,
    
  },
  {
      Header: "Status",
      accessor: "loan_status", // Status of the loan (e.g., paid off, pending)
      showSortIcons: false,
  },

  
];





  return (
    <DashboardLayout>
  <>
    

    <MDBox mt={4}>
    <Grid container spacing={3}>
    {cardData.map((item, index) => (
      <Grid item xs={true} key={index}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color={item.color} // Make sure to define color for each item or use a default color
            icon={item.icon}
            title={item.label}
            count={item.value}
          />
        </MDBox>
      </Grid>
    ))}
  </Grid>
    </MDBox>

    <MDBox py={6}>
      <Grid container spacing={3}>
        {/* Left side: Leave and Loan Requests */}
        <Grid item xs={12} md={8}>
  <Card>
    <CardContent>
      {/* Leave Requests Section */}
      <MDBox>
        <MDTypography variant="h6" gutterBottom>
          Leave Requests
        </MDTypography>
        {employeeInfo.leaveRequests.length > 0 ? (
          <DataTable
            table={{ columns: col1, rows: employeeInfo.leaveRequests }}
            canSearch={true}
            sortable={true}
            pagination
            rowsPerPage={5}
            showPagination
          />
        ) : (
          <MDTypography>No leave request data available.</MDTypography>
        )}
      </MDBox>

      {/* Loan Requests Section */}
      <MDBox mt={6}>
        <MDTypography variant="h6" gutterBottom>
          Loan Requests
        </MDTypography>
        {employeeInfo.loanRequests.length > 0 ? (
          <DataTable
            table={{ columns: col2, rows: employeeInfo.loanRequests }}
            canSearch={true}
            sortable={true}
            pagination
            rowsPerPage={5}
            showPagination
          />
        ) : (
          <MDTypography>No loan request data available.</MDTypography>
        )}
      </MDBox>
    </CardContent>
  </Card>
</Grid>


        {/* Right side: Announcements */}
        <Grid item xs={12} md={4}>
          <MDBox sx={{ flex: 1, p: 2, bgcolor: '#f9f9f9', borderRadius: 2, boxShadow: 1 }}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ mb: 2 }}>
              Announcements
            </MDTypography>
            {employeeInfo.announcements && employeeInfo.announcements.map((item) => (
              <MDBox key={item.id} sx={{ mb: 2, p: 2, bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
                <MDTypography variant="h6" fontWeight="medium">
                  {item.title}
                </MDTypography>
                <MDTypography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                  <strong>Announced By:</strong> {item.announced_by} | <strong>Date:</strong> {new Date(item.announcement_date).toLocaleDateString()}
                </MDTypography>
                <MDTypography variant="body2" sx={{ color: 'text.primary' }}>
                  {item.content}
                </MDTypography>
              </MDBox>
            ))}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  </>
</DashboardLayout>

  )
}

export default EmployeeDashboard