/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
 import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
 import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddAssessment from "./addAssessment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import StudentAssignmentGiven from "./studentsAssignment";
import DeleteIcon from '@mui/icons-material/Delete';
import FileViewer from "components/FileViewer";
import LoadingDialog from "components/Loading/LoadingDialog";
import EditAssessment from "./editAssessment";
import EditIcon from '@mui/icons-material/Edit';
import { axiosInstance } from "api2";



const InstructorAssessment = () => {
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const targetId = localStorage.getItem("targetId");
  const [loading, setLoading] = useState(true); 
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [deletingStatus, setDeletingStatus] = useState(false); 
  const token =  localStorage.getItem("authToken");
  
  const [showAlert, setShowAlert] = useState(false);

    
  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [students,setStudents] = useState([])

  const [assessments, setAssessments] = useState([]);
  const [assessment_id,setAssessmentId] = useState("")

    const fetchAssessment = useCallback(async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/instructor/${targetId}/assessment`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        
        setAssessments(response.data[0].assessments);
      } catch (err) {
        console.error("Failed to fetch employees:", err);
      } finally{
        setLoading(false)
      }
    },[token,targetId]);
 
    useEffect(() => {    
      fetchAssessment();
    },[fetchAssessment]);

    useEffect(() => {
      let timer;
      if (showAlert) {
        timer = setTimeout(() => {
          setShowAlert(false);
        }, 5000); // 5000 milliseconds = 5 seconds
      }
      return () => clearTimeout(timer); // Clean up the timer
    }, [showAlert]);

    const handleViewFile = (filePath) => {
      const extension = filePath.split('.').pop().toLowerCase();
      setSelectedFile(filePath);
      setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
      setOpenDialog(true);
    };

    const handleOpenEdit = (assessment) => {
      setSelectedAssessment(assessment);
      setOpenEdit(true);
    };
  
    const handleAddAssessment = () => {
        
      fetchAssessment()
    };


    const handleEditAssessment = () => {
      fetchAssessment();
    };


    const handleCloseDialog = () => {
      setOpenAdd(false);
      setOpenDialog(false);
      setOpenEdit(false);
      setSelectedFile(null);
      setFileType(""); 
      setOpenView(false);
    };
 

    const handleViewDetails = (data) => {
      setAssessmentId(data.id)
      setStudents(data.students)
      setOpenView(true);
      
      
    }

    
  const handleDeleteAssessment = async (id) => {
    const confirm = await swal({
        title: "Are you sure?",
        text: "You will not be able to recover this assessment!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    });

    if (confirm) {
      setDeletingStatus(true);
        try {
            const response = await axiosInstance.delete(`/assessments/${id}`);
            if (response.status === 204) {
                setAssessments(assessments.filter((m) => m.id !== id));
                swal({
                    title: "Assessment Deleted Successfully!",
                    icon: "success"
                });
            }
        } catch (error) {
            swal({
                title: `${error.response.data.message}`,
                icon: "warning"
            });
        } finally {
          setDeletingStatus(false); // End loading for deletion
        } 
    }
};


    const columns = [
      {
        accessor: 'action',
        Header: '',  // You can leave this blank if you don't want a header
        width: 40,  // Explicit width in pixels
        padding:0,
        Cell: ({ row }) => (
          <PopupState variant="popover"   popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                  <MoreVertIcon />
                </Button>
                <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                  
                  <MenuItem onClick={() => {
                    handleViewDetails(row.original);
                    popupState.close();
                  }} style={{ color: '#1976d2' }}>
                    <VisibilityIcon style={{ marginRight: 8 }} /> View Students
                  </MenuItem>

                  <MenuItem onClick={() => {
                  handleDeleteAssessment(row.original.id);
                  popupState.close();
                }} style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>



                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        ),
        showSortIcons: false,
        
        
      },
      { Header: 'Assessment Name', accessor: 'assessment_name' },
      { Header: 'Assessment Type', accessor: 'assessment_type',showSortIcons:false },
      { Header: 'Total Mark', accessor: 'mark',showSortIcons:false },
      { Header: 'Course Name', accessor: 'course.course_name',showSortIcons: false, },
      { Header: 'Due Date', accessor: 'due_date' },
      {
        Header: 'File Path',
        accessor: 'file_path',
        Cell: ({ value }) => (
          <MDButton
            variant="body2"
            color="primary"
            component="a"
            onClick={() => handleViewFile(value)}
            style={{ cursor: 'pointer' }}
            sx={{ 
              cursor: 'pointer',
              textDecoration: 'underline',
              boxShadow: 1, // Adds a default shadow
              transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
              '&:hover': {
                color: 'secondary.main',
                boxShadow: 4, // Increases shadow intensity on hover
              }
            }}
          >
            View File
          </MDButton>
        ),
      },
    ];
    


  return (
    <DashboardLayout>
        <Card>
          <CardContent>
            <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
               Assessments
             </MDTypography>
             <MDBox>
          
                    <DataTable
                      table={{
                        columns,
                        rows: assessments,
                      }}
                      canSearch={true}
                      sortable={true}
                      rowsPerPage={5}
                      showPagination
                      widgets = {
                        <MDBox mx={2}>
                       <MDButton
                       variant="gradient"
                       ml="auto"
                           color="info"
                           size="small"
                           onClick={() => setOpenAdd(true)}
                           sx={{background:"#00274D", my:4,mx:3}}
                     >
                       <span>Add</span>
                     </MDButton>
                     </MDBox>
                      }
                    />
                       {!loading && assessments.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No assessment available
          </MDTypography>
        )}
             </MDBox>
             <AddAssessment open={openAdd} onClose={handleCloseDialog} onAdd={handleAddAssessment} />
             {
              students && (
                <StudentAssignmentGiven students={students} openView={openView} onClose={handleCloseDialog}  id={assessment_id}/>
              )
             }

            
            
            
            
            </MDBox>
             {/* FileViewer Component */}
      <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
       <EditAssessment open={openEdit} onClose={handleCloseDialog} assessment={selectedAssessment} onEdit={handleEditAssessment}
        />
          </CardContent>
        </Card>
        <LoadingDialog open={loading || deletingStatus} />
    </DashboardLayout>
  )
}

export default InstructorAssessment