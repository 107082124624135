import React, { useState, useEffect } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import {Delete, Edit } from '@mui/icons-material';
import DataTable from 'examples/Tables/DataTable';
import { 
  Button,
  TextField,
  Grid,
  Autocomplete,
  IconButton,
} from "@mui/material";
import LoadingDialog from 'components/Loading/LoadingDialog';


const EditIssuingVoucher = ({ open, onClose, issue, onEdit }) => {
  

  const updatedIssue = {
    ...issue,
    issued_by: issue.issued_by.id, // Only use the ID of issued_by
  };
  const [modalData] = useState(updatedIssue);


  const [error, setError] = useState(null);
  const [supplier, setSupplier] = useState("");
  const [date, setDate] = useState("");
  const [remark, setRemark] = useState("");
  const [issued_to, setIssuedTo] = useState("");
  
  const [quantity, setQuantity] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [unit_of_measure, setUnitOfMeasurement] = useState("");
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [displayGoods, setDisplayGoods] = useState([]);
  const [assets, setAssets] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
     
  const [selectedAsset, setSelectedAsset] = useState(null);
  
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false); 
  useEffect(() => {
    if (issue) {
      setSupplier(modalData.supplier_name);
      setIssuedTo(modalData.issued_to);
      setDate(modalData.date);
      setSelectedStore(modalData.store_location);
      

      const transformedGoods = modalData.lines.map(line => ({
        store_id: modalData.store_location?.id,
        store_name: modalData.store_location?.name,
        asset_id: line.asset.id,
        asset_name: line.asset.name,
        asset_code: line.asset.code,
        supplier: modalData.supplier_name,
        quantity: line.quantity,
        unit_price: line.unit_price,
        unit_of_measure: line.unit_of_measure,
        remark: line.remark,
        issued_to: modalData.issued_to,
        request_date: modalData.date,
      }));

      setDisplayGoods(transformedGoods);
    }
  }, [issue, modalData]);

  useEffect(() => {
    // Fetch stores and assets
    const fetchData = async () => {
      setLoading(true);
      try {
        const storesResponse = await axios.get(`${API_BASE_URL}/stores`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        setStores(storesResponse.data);

        const assetsResponse = await axios.get(`${API_BASE_URL}/assets`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        setAssets(assetsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally{
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleAddRequest = () => {
    if (!supplier || !selectedAsset || quantity <= 0 || unitPrice <= 0 || !remark || !issued_to || !date || !unit_of_measure ) {
      setError("Please fill in all fields correctly.");
      return;
    }
  
  
    const newRequest = {
        store_id: selectedStore.id,       
        store_name: selectedStore.name, 
      asset_id: selectedAsset.id,        
      asset_name: selectedAsset.name,    
      asset_code: selectedAsset.code,    
      supplier,                         
      quantity,                          
      unit_price: unitPrice, 
      unit_of_measure: unit_of_measure,            
      remark,                            
      issued_to,                            
      request_date: date,                
    };

  
    setDisplayGoods((prev) => [...prev, newRequest]);
    setUnitOfMeasurement("");
    setQuantity("");
    setUnitPrice("");
    setRemark("");
    setDate("");
    setError(null); 
  };





  const handleDeleteGoods = (index) => {
    setDisplayGoods(prev => prev.filter((_, i) => i !== index));
  };

  const handleEditGoods = (item, index) => {

    setEditingItem({...item, index})
    setSelectedAsset(assets.find(asset => asset.id === item.asset_id)); // Find the asset by its id
    setEditDialogOpen(true);
  };

  const handleSaveEdit = () => {
   
    const updatedItem = {
      ...editingItem,
      asset_id: selectedAsset?.id || editingItem.asset_id, // Update with selected asset ID if changed
      asset_name: selectedAsset?.name || editingItem.asset_name, 
    };

    const updatedGoods = displayGoods.map((good, idx) =>
      idx === editingItem.index ? updatedItem : good
    );
  
    setDisplayGoods(updatedGoods);
    setEditDialogOpen(false);
  };

  const handleInputChange = (field, value) => {
    setEditingItem(prev => ({ ...prev, [field]: value }));
  };

  const handleModalSubmit = async () => {
    try {
      setLoading(true);
     
      const updatedIssue = {
        
        store_id:modalData?.store_location?.id,
        supplier_name: supplier,
        issued_by: modalData.issued_by,
        issued_to: issued_to,
        date: modalData.date,
        total_amount: displayGoods.reduce((total, request) => total + request.quantity * request.unit_price, 0),
        remark: remark,
        lines: displayGoods.map(good => ({
          asset_id: good.asset_id,
          quantity: good.quantity,
          unit_price: good.unit_price,
          unit_of_measure: good.unit_of_measure,
          amount: good.quantity * good.unit_price, 
          remark: good.remark,
        })),
      };


      const response = await axios.put(`${API_BASE_URL}/goods-issuing-vouchers/${modalData.id}`, updatedIssue, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Good Issuing Updated Successfully!",
          icon: "success"
        });
        setDisplayGoods([]);
      }
    } catch (error) {
      setError(error.response?.data?.errors);
    } finally{
      setLoading(false);
    }
  };

  const columns = [
    { Header: 'Store', accessor: 'store_name' },
    { Header: 'Asset Name', accessor: 'asset_name' },
    { Header: 'Quantity', accessor: 'quantity', showSortIcons: false },
    { Header: 'IssuedTo', accessor: 'issued_to', showSortIcons: false },
    {
      Header: 'Unit Price',
      accessor: 'unit_price',
      Cell: ({ value }) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
      showSortIcons: false,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ row }) => {
        const amount = row.original.quantity * row.original.unit_price;
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(amount);
      },
      showSortIcons: false,
    },
    { Header: 'Remark', accessor: 'remark', 

    
      showSortIcons: false },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <>
          <IconButton onClick={() => handleEditGoods(row.original, row.index)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDeleteGoods(row.index)}>
            <Delete />
          </IconButton>
        </>
      ),
      showSortIcons: false,
    },
  ];

  return (
    <DashboardLayout>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle textAlign="center">Edit Issued Good</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Supplier"
                value={supplier}
                onChange={(e) => {
                  setSupplier(e.target.value);
                  setError(null);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Issued To"
                value={issued_to}
                onChange={(e) => {
                  setIssuedTo(e.target.value);
                  setError(null);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                type='date'
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                  setError(null);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={stores}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedStore(newValue);
                setError(null)
              }}
              value={selectedStore}
              renderInput={(params) => (
                <TextField {...params} label="Store" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={assets}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedAsset(newValue);
                setError(null)
              }}
              value={selectedAsset}
              renderInput={(params) => (
                <TextField {...params} label="Asset" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
                setError(null);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Unit Price"
              type="number"
              name="unit_price"
              value={unitPrice}
              onChange={(e) => {
                setUnitPrice(e.target.value);
                setError(null);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Unit of Measure"
              value={unit_of_measure}
              onChange={(e) => {
                setUnitOfMeasurement(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>

            
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Remark"
                value={remark}
                onChange={(e) => {
                  setRemark(e.target.value);
                  setError(null);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          {error && (
            <MDTypography color="error" variant="body2" mt={5} mx={2}>
              {error}
            </MDTypography>
          )}
          {/* {
            if(modalData.assets.id === selectedAsset.id){
              // disable
            }
          } */}
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12}>
              <MDButton
                variant="gradient"
                size="small"
                color="info"
                type="button"
                sx={{ background: "#00274D" }}
                disabled={modalData?.lines?.some(line => line.asset_id === selectedAsset?.id)} // Disable if asset_id is already in lines
                onClick={handleAddRequest}
              >
                Add
              </MDButton>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                table={{
                  columns,
                  rows: displayGoods,
                }}
                canSearch={true}
                sortable={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleModalSubmit}
          >
            Submit
          </MDButton>
          <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            
          <Grid item xs={12} md={6} lg={4} my={3}>
            <Autocomplete
              options={assets}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedAsset(newValue);
                setError(null)
              }}
              value={selectedAsset}
              renderInput={(params) => (
                <TextField {...params} label="Asset" />
              )}
            />
          </Grid>
            <Grid item xs={12}>
              <TextField
                label="Quantity"
                type="number"
                value={editingItem?.quantity || ''}
                onChange={(e) => handleInputChange('quantity', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Unit Price"
                type="number"
                value={editingItem?.unit_price || ''}
                onChange={(e) => handleInputChange('unit_price', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Remark"
                value={editingItem?.remark || ''}
                onChange={(e) => handleInputChange('remark', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEdit}>Save</Button>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
}

export default EditIssuingVoucher;