import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import MDTypography from 'components/MDTypography';


const Address = ({residentCity,woreda,kebele,houseNumber,housePhone}) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} sm={4}>
                
            <MDTypography variant="body2">
              <strong>Town:</strong> {residentCity}
            </MDTypography>
            <MDTypography variant="body2">
              <strong>House Number:</strong> {houseNumber}
            </MDTypography>
            
         
              </Grid>
          <Grid item xs={12} sm={4}>
          <MDTypography variant="body2">
              <strong>Woreda:</strong> {woreda}
            </MDTypography>
            <MDTypography variant="body2">
              <strong>Telephone:</strong> {housePhone}
            </MDTypography>
           
           
            
         
              </Grid>
          <Grid item xs={12} sm={4}>
          <MDTypography variant="body2">
              <strong>Kebele:</strong> {kebele}
            </MDTypography>
            
          
            
         
              </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Address