import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import { Alert } from "@mui/material";
import LoadingDialog from 'components/Loading/LoadingDialog';

const AddPermissionDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    name: ""
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // State to track loading

  const token = localStorage.getItem("authToken");

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true); // Start loading
    
    try {

      const response = await axios.post(`${API_BASE_URL}/permissions`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Permission Created Successfully!",
          icon: "success"
        });
        setModalData({ name: "" });
      }
    } catch (error) {
      setError(error.response?.data?.errors?.name[0]);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}><AddIcon /> Add Permission</DialogTitle>
      <DialogContent>
        {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}


          <>
            <MDBox mb={2}>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Permission Name
              </MDTypography>
              <MDInput
                type="text"
                name="name"
                fullWidth
                required
                value={modalData.name}
                onChange={handleModalChange}
              />
            </MDBox>
          </>
        
      </DialogContent>
     
        <DialogActions>
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleModalSubmit}
            disabled={loading}
          >
            Submit
          </MDButton>
          <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </MDButton>
        </DialogActions>
         <LoadingDialog open={loading} />
      
    </Dialog>
  );
};

export default AddPermissionDialog;
