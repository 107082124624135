import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import MDTypography from 'components/MDTypography';

const PersonalBackground = ({
  firstName,
  lastName,
  motherName,
  nationality,
  birthDate,
  grandFatherName,
  birthPlace
}) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} sm={4}>
                
            <MDTypography variant="body2">
              <strong>First Name:</strong> {firstName}
            </MDTypography>
            <MDTypography variant="body2">
              <strong>Mother Name:</strong> {motherName}
            </MDTypography>
            <MDTypography variant="body2">
              <strong>Nationality:</strong> {nationality}
            </MDTypography>
          
            
           
          </Grid>
              <Grid item xs={12} sm={4}>
                
                  <MDTypography variant="body2">
              <strong>Last Name:</strong> {lastName}
            </MDTypography>
            <MDTypography variant="body2">
              <strong>Birth Date:</strong> {birthDate}
            </MDTypography>
            
          
          
           
              </Grid>
              <Grid item xs={12} sm={4}>
                
            
        
            <MDTypography variant="body2">
              <strong>Grand Father Name:</strong> {grandFatherName}
            </MDTypography>
        
            <MDTypography variant="body2">
              <strong>Birth Place:</strong> {birthPlace}
            </MDTypography>
            
              </Grid>
        </Grid>
      </CardContent>
    </Card>
    
  );
};

export default PersonalBackground;
