import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  course_history: [],
};

const courseHistorySlice = createSlice({
  name: 'course_history',
  initialState,
  reducers: {
    addCourseHistory: (state, action) => {
      state.course_history.push(action.payload);
    },
    deleteCourseHistory: (state, action) => {
      console.log(action.payload);
      
      state.course_history = state.course_history.filter((entry) => entry.course_id !== action.payload);
    },
    clearCourseHistory: (state) => {
      state.course_history = [];
    },
  },
});

export const { addCourseHistory, clearCourseHistory,deleteCourseHistory } = courseHistorySlice.actions;
export default courseHistorySlice.reducer;