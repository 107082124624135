export const total = [
  {
    label: "Total Students",
    count: 300,
    icon: "person",
    color: "info",
    percentage: {
      color: "success",
      amount: "+55%",
      label: "than last week",
    },
  },
  {
    label: "Total Courses",
    count: 100,
    icon: "library_books",
    color: "info",
    percentage: {
      color: "error",
      amount: "-25%",
      label: "than last week",
    },
  },
  {
    label: "Total Instructors",
    count: 50,
    icon: "school",
    color: "info",
    percentage: {
      color: "warning",
      amount: "+35%",
      label: "than last week",
    },
  },
];

// Total male and female count by year
export const countReactDashboard = {
  labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
  datasets: [
    {
      label: "Male",
      color: "info",
      data: [300, 230, 670, 340, 888, 890, 567],
    },
    {
      label: "Female",
      color: "success",
      data: [150, 180, 450, 280, 670, 780, 450],
    },
  ],
};

// By campus
export const campus = {
  labels: ["Main", "Tecno", "Adama", "Bishoft"],
  datasets: {
    label: "Projects",
    backgroundColors: ["info", "primary", "success", "warning"],
    data: [1000, 2345, 432, 1234],
  },
};

// Announcements
export const announcements = [
  {
    announced_by: "Jane",
    title: "Meeting Reminder",
    content: "Check out our meeting on Friday at 10 AM.",
    announcement_date: "15th January, 2023",
  },
  {
    announced_by: "Bob",
    title: "New Course Announcement",
    content: "New course 'Advanced JavaScript' is now available.",
    announcement_date: "18th January, 2023",
  },
  {
    announced_by: "Alice",
    title: "Exam Schedule Update",
    content: "The exam schedule has been updated for the upcoming semester.",
    announcement_date: "20th January, 2023",
  },
];

// New admissions table
export const newAdmissions = [
  {
    name: "John Doe",
    phone: "0123456789",
    gender: "Male",
    program_id: "P001",
    status: "Active",
    registration_date: "2024-07-23",
    denomination: "First Class",
    entrance_exam: 30,
    interview: 60,
  },
  {
    name: "Jane Doe",
    phone: "9876543210",
    gender: "Female",
    program_id: "P002",
    status: "Active",
    registration_date: "2024-07-25",
    denomination: "Second Class",
    entrance_exam: 40,
    interview: 50,
  },
  {
    name: "Bob Smith",
    phone: "1234567890",
    gender: "Male",
    program_id: "P003",
    status: "Inactive",
    registration_date: "2024-07-27",
    denomination: "Third Class",
    entrance_exam: 50,
    interview: 40,
  },
  {
    name: "Alice Johnson",
    phone: "0987654321",
    gender: "Female",
    program_id: "P004",
    status: "Active",
    registration_date: "2024-07-29",
    denomination: "Fourth Class",
    entrance_exam: 60,
    interview: 30,
  },
];
