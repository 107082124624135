import { createSlice } from "@reduxjs/toolkit";

const initialState = [
];


const referencesSlice = createSlice({
  name: "references",
  initialState,
  reducers: {
    addReference: (state, action) => {
      state.push(action.payload);
    },
    updateReference: (state, action) => {
      state = state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteReference: (state, action) => {
      return state.filter(item => item.email !== action.payload.email);
    },
    
    
  },
});

export const { addReference, updateReference,deleteReference } = referencesSlice.actions;
export default referencesSlice.reducer;