import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const AddExamResult = ({ open, onClose,applicant }) => {
    
  const [modalData, setModalData] = useState({
    entrance_exam: 0,
    interview: 0,
  });
  const [loading, setLoading] = useState(false); 


  const token = localStorage.getItem("authToken");

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (applicant) {
      setModalData({
        entrance_exam: applicant.entrance_exam || '',
        interview: applicant.interview || '',
      });
    }
  }, [applicant]);

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/student/update-entrance-exam-result/${applicant.id}`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        // onAdd(response.data);
        onClose();
        swal({
          title: response.data.message,
          icon: "success",
        });

        setModalData({
            entrance_exam: applicant.entrance_exam,
    interview: applicant.interview,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        onClose();
        swal({
            title: error.response.data.message,
            icon: "danger",
          });      
        }
    }finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}>
        <AddIcon /> Add exam reslut
      </DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Entrance Written Exam Result
          </MDTypography>
          <MDInput
            type="text"
            name="entrance_exam"
            fullWidth
            required
            value={modalData.entrance_exam}
            onChange={handleModalChange}
          />
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Interview Result
          </MDTypography>
          <MDInput
            type="number"
            name="interview"
            fullWidth
            required
            value={modalData.interview}
            onChange={handleModalChange}
          />
        
        </MDBox>

       
      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddExamResult;
