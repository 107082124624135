import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import swal from "sweetalert";

export default function AddClearanceRequest({ open, onClose, onAdd }) {
  const token = localStorage.getItem("authToken");
  const targetId = localStorage.getItem("targetId");

  const [activeYears, setActiveYears] = useState();

  const [formData, setFormData] = useState({
    reason: "",
    year_id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddRequest = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/student-clearance`,
        {
          student_id: targetId,
          reason: formData.reason,
          date_requested: new Date().toISOString().split("T")[0],
          year_id: formData.year_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        onAdd();
        onClose();
        swal({
          title: "Clearance Request Add Successfully!",
          icon: "success",
        });
        setFormData({
          reason: "",
          year_id: "",
        });
      }
    } catch (err) {
      console.error("Failed to submit clearance request:", err);
    }
  };

  const fetchActiveYears = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/years/active`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setActiveYears(response.data);
      }
      return response.data;
    } catch (err) {
      console.error("Failed to fetch active years:", err);
    }
  }, [token]);

  useEffect(() => {
    fetchActiveYears();
  }, [fetchActiveYears]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Request Clearance</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Year
          </MDTypography>
          <Select
            name="year_id"
            value={formData.year_id}
            onChange={handleChange}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            {activeYears &&
              activeYears.map((year, i) => {
                return (
                  <MenuItem key={i} value={year.id}>
                    {year.year_name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Reason
          </MDTypography>
          <MDInput
            name="reason"
            fullWidth
            required
            value={formData.reason}
            onChange={handleChange}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleAddRequest}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
