import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payment: [
    {
      full_name: "",
      phone_number: "",
      fee_id: 0,
      payment_amount: 0,
      payment_date: "",
      payment_method: "",
      transaction_id: "",
      receipt_path: "",
    },
  ],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updatePayment: (state, action) => {
      const { payment } = action.payload;
      // If payment is an array, update the state with the new array
      if (Array.isArray(payment)) {
        state.payment = payment;
      } else {
        // If it's a single payment object, update the first item or add it to the array
        state.payment[0] = { ...state.payment[0], ...payment };
      }
      console.log("Updated state:", JSON.parse(JSON.stringify(state)));
    },
  },
});

export const { updatePayment } = paymentSlice.actions;

export default paymentSlice.reducer;
