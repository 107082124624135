import React,{useState} from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import GoodsRecievingApprovalModal from '../approval';

const ViewRecievedGoods = ({openView, onClose,detailData}) => {
    
        
    const columns = [

        { Header: 'Store', accessor: 'store_location.name' },    
        { Header: 'Asset Name', accessor: 'asset.name' },     
       
        { Header: 'Quantity', accessor: 'quantity' },         
        { Header: 'Unit Price', accessor: 'unit_price' },
        { 
          Header: 'Amount', 
          accessor: 'amount',                          
          Cell: ({ row }) => {
            const amount = row.original.quantity * row.original.unit_price;
            const formattedAmount = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }).format(amount);
            
            return <span>{formattedAmount}</span>;
          },
        },
        { Header: 'Remark', accessor: 'remark' },           
      ];

      const rows = detailData.lines ? detailData.lines.map(line => ({
        ...line,
        asset: line.asset
    })) : [];

    const [openApprove, setOpenApprove] = useState(false);

     const handleApprove = () => {
        setOpenApprove(true);
     }
     const handleApproveClose = () => {
        setOpenApprove(false);
        onClose();
     }
  return (
    <DashboardLayout>
        <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle textAlign="center">
            View Details
        </DialogTitle>
        <DialogContent>
            <DataTable
                table={{
                    columns,
                    rows
                }}
                canSearch={true}
                sortable={true}
                pagination
                rowsPerPage={5}
                showPagination
            />
          </DialogContent>
                <DialogActions>
                { detailData.approved_by ? null :  
          <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleApprove}
        >
          Approve
        </MDButton>}
            <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
            >
            Close
            </MDButton>
            </DialogActions>
            </Dialog>

            <GoodsRecievingApprovalModal open={openApprove} detailData={detailData}  onClose={handleApproveClose} />
    </DashboardLayout>
        )
        }

export default ViewRecievedGoods