import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import LoadingDialog from 'components/Loading/LoadingDialog';

const EmployeeLeaveBalances = () => {
    const targetId = localStorage.getItem("targetId");
    const token = localStorage.getItem("authToken");
    const [loading,setLoading] = useState(true);
   

    const [leaveBalances, setLeaveBalances] = useState([]);

    const fetchLeaveBalances = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/employee/${targetId}/leave-balance`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        
          
          setLeaveBalances(response.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false)
        }
      }, [token,targetId]);
    
      useEffect(() => {
        fetchLeaveBalances();
      }, [fetchLeaveBalances]);


      const columns = [

        { Header: 'Leave Type Name', accessor: 'leave_type.leave_type_name',showSortIcons: true },
        
        { Header: 'Current Balance', accessor: 'current_balance', showSortIcons: false },
        { Header: 'Last Update Date', accessor: 'last_updated_date', showSortIcons: false },
        
      ];
  return (
    <DashboardLayout>
    <Card>
        <CardContent>
            <MDBox>
                <MDTypography variant="h5" fontWeight="medium">
                    Leave Balances
                </MDTypography>
                <MDBox>
                
                      <DataTable
                      table={{
                          columns,
                          rows: leaveBalances,
                      }}
                      canSearch={true}
                      sortable={true}
                      rowsPerPage={5}
                      showPagination
                  />
                     {!loading &&  leaveBalances.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            Leave Balances not available
          </MDTypography>
        )}
                    
                </MDBox>
            </MDBox>
            <LoadingDialog open={loading} />
        </CardContent>
    </Card>
</DashboardLayout>
  )
}

export default EmployeeLeaveBalances