// EditCoursesDialog.js
import AddIcon from '@mui/icons-material/Add';
import { Alert } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from '@mui/material/Switch';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React,{useState} from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const EditBranchDialog = ({ open, onClose, branches, onEdit }) => {
    const [modalData, setModalData] = React.useState(branches);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = useState(false); 

  const token = localStorage.getItem("authToken");

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${API_BASE_URL}/branches/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Branch Updated Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      console.log(error.reponse)
      // setError("An error occurred");
    }finally {
      setLoading(false); // Stop loading
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}><AddIcon /> Add Branch</DialogTitle>
      <DialogContent>
        {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Branch Name
          </MDTypography>
          <MDInput
            type="text"
            name="name"
            fullWidth
            required
            value={modalData.name}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Address
          </MDTypography>
          <MDInput
            type="text"
            name="address"
            fullWidth
            required
            value={modalData.address}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Branch Category
          </MDTypography>
          <MDInput
            type="text"
            name="branch_category"
            fullWidth
            required
            value={modalData.branch_category}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
            Is Extension
        </MDTypography>
        <Switch
            checked={modalData.is_extension === 1} // Check if the value is 1 to set the switch state
            onChange={(e) => setModalData({ ...modalData, is_extension: e.target.checked ? 1 : 0 })}
        />
        </MDBox>
        
      </DialogContent>
      <DialogActions>
        <MDButton   variant="gradient"
            size="small"
            color="info"
            sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit</MDButton>
        <MDButton   variant="gradient"
                       size="small"
                       color="black"
                       sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  )
}

export default EditBranchDialog