import React, { useState ,useEffect} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";

import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddLocationDialog from "./addLocation";
import EditLocationDialog from "./editLocation";
import LoadingDialog from "components/Loading/LoadingDialog";

const Location = () => {
  const [search] = useState('');
  const token =  localStorage.getItem("authToken");
 
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false); 

  const [openEdit, setOpenEdit] = useState(false);
  const[location,setLocation] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/locations`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        

        setLocation(response.data);
      } catch (err) {
        console.error(err);
      } finally{
        setLoading(false);
      }
      
    };
    fetchLocation();
  }, [token]);

  const handleAddLocation = (newLocation) => {
    setLocation((prevLocation) => [...prevLocation, newLocation]);
  };
  

  const handleEditLocation = (updatedLocation) => {
    setLocation(location.map((l) =>
      l.id === updatedLocation.id ? updatedLocation : l
    ));
  };

  const handleDeleteLocation = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this Location!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setLoading(true);

      try {
        const response = await axios.delete(`${API_BASE_URL}/locations/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 204){
          setLocation(location.filter((c) => c.id !== id));
          swal({
            title: "Location Deleted Successfully!",
            // text: "Course Created Successfully!",
            icon: "success"
          });
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
          // text: "Course Created Successfully!",
          icon: "warning"
        });
      }finally{
        setLoading(false);
      }
    }
  };

  const handleOpenEdit = (location) => {
    setSelectedLocation(location);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedLocation(null);
  };

  const filteredData = search
  ? location && location.filter(l => 
      l.name &&
      l.name.toLowerCase().includes(search.toLowerCase())
    )
  : location;

  const columns = [
    {
      accessor: 'action',
      Header: '',  // You can leave this blank if you don't want a header
      width: 40,  // Explicit width in pixels
      padding:0,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteLocation(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'Code', accessor: 'code',showSortIcons: false, },
    { Header: 'name', accessor: 'name', },
    { Header: 'Description', accessor: 'description',showSortIcons: false, },
    { Header: 'Remark', accessor: 'remark',showSortIcons: false, },
    
   
   
  ];
  return (
    <DashboardLayout>
    <MDBox>
    <Card>
    <CardContent>
    <MDTypography variant="h5" fontWeight="medium">
       Location
     </MDTypography>
     <MDBox>
            <DataTable
              table={{
                columns,
                rows: filteredData,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
              widgets = {
                <MDBox mx={2}>
               <MDButton
               variant="gradient"
               ml="auto"
                   color="info"
                   size="small"
                   onClick={() => setOpenAdd(true)}
                   sx={{background:"#00274D", my:4,mx:3}}
             >
               <span>Add</span>
             </MDButton>
             </MDBox>
              }
            />
     </MDBox>
     <AddLocationDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddLocation} />
    
    {selectedLocation && (
    <EditLocationDialog
        open={openEdit}
        onClose={handleCloseDialog}
        location={selectedLocation}
        onEdit={handleEditLocation}
    />
    )}
    
    </CardContent>
    </Card>
    </MDBox>
    <LoadingDialog open={loading} />
</DashboardLayout>
  )
}

export default Location