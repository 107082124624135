import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import LoaingDialog from "components/Loading/LoadingDialog";

import {
  Autocomplete,
  TextField
} from "@mui/material";

// Axios instance for API requests
const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
  });
  

const EditPositionDialog = ({ open, onClose, position, onEdit }) => {
    const [positions, setPositions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axiosInstance.get("/positions");
            setPositions(response.data); // Set the positions data
          } catch (err) {
            console.error("Failed to fetch positions:", err);
          }
        };
    
        fetchData();
      }, []);
    const [modalData, setModalData] = React.useState(position);
 

  const token = localStorage.getItem("authToken");

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  };

  const handleModalSubmit = async () => {
    setLoading(true)
    try {
        
       
      const response = await axios.put(`${API_BASE_URL}/positions/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Position Updated Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      console.log(error.reponse)
      // setError("An error occurred");
    } finally{
      setLoading(false)
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>
      <AddIcon /> Add Position
    </DialogTitle>
    <DialogContent>
      {/* Position Title */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Position Name
        </MDTypography>
        <MDInput
          type="text"
          name="position_title"
          fullWidth
          required
          value={modalData.position_title}
          onChange={handleModalChange}
          
        />
      </MDBox>

      {/* Job Description */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Job Description
        </MDTypography>
        <MDInput
          type="text"
          name="job_description"
          fullWidth
          required
          value={modalData.job_description}
          onChange={handleModalChange}
         
        />
      </MDBox>

      {/* Minimum Salary */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Min Salary
        </MDTypography>
        <MDInput
          type="number"
          name="salary_range_min"
          fullWidth
          required
          value={modalData.salary_range_min}
          onChange={handleModalChange}
          
        />
      </MDBox>

      {/* Maximum Salary */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Maximum Salary
        </MDTypography>
        <MDInput
          type="number"
          name="salary_range_max"
          fullWidth
          required
          value={modalData.salary_range_max}
          onChange={handleModalChange}
          
        />
      </MDBox>

      {/* Reports To (Autocomplete for selecting a manager) */}
      <MDBox mb={2}>
      
<Autocomplete
  options={positions}
  getOptionLabel={(option) => option.position_title} // Display the position title in the dropdown
  onChange={(event, newValue) => {
    setModalData({
      ...modalData,
      reports_to: newValue ? newValue.id : null, // Set reports_to to the selected position's id
    });
  }}
  renderInput={(params) => <TextField {...params} label="Reports To" />}
/>
</MDBox>

    </DialogContent>

    {/* Actions */}
    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleModalSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Cancel
      </MDButton>
    </DialogActions>
    <LoaingDialog open={loading} />
  </Dialog>
  )
}

export default EditPositionDialog