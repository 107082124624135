import React, { useState } from 'react';
import { Alert, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { axiosInstance } from 'api2';
import swal from 'sweetalert';
import MDButton from 'components/MDButton';


const AddEvaluation = ({ open, onClose, onAdd }) => {
  const [evaluationData, setEvaluationData] = useState({
    description: '',
    poor_description: '',
    excellent_description: '',
    type: '',
  });
  
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvaluationData({
      ...evaluationData,
      [name]: value,
    });
    setError(null);
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log("evaluationData:",evaluationData)
    try {
      const response = await axiosInstance.post(`/evaluations`, {
        ...evaluationData
      });

      if (response.status === 201) {
        swal({
          title: "Evaluation Added Successfully!",
          icon: "success"
        });
        onAdd()
        onClose(); // Close the dialog after successful submission
        setEvaluationData({ description: '', poor_description: '', excellent_description: '', type: '' }); // Reset form
      }
    } catch (error) {
        console.log("error:",error.response.data)
        if(error){
            setError(error.response?.data?.errors?.description?.[0] || "An error occurred");

        }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Add Evaluation</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        <TextField
          label="Description"
          name="description"
          fullWidth
          required
          value={evaluationData.description}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        
        <TextField
          label="Poor Description"
          name="poor_description"
          fullWidth
          required
          value={evaluationData.poor_description}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        
        <TextField
          label="Excellent Description"
          name="excellent_description"
          fullWidth
          required
          value={evaluationData.excellent_description}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        
        <TextField
          label="Type"
          name="type"
          fullWidth
          required
          value={evaluationData.type}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleSubmit}  variant="gradient"
                       size="small"
                       color="info"
                       sx={{background:"#00274D"}} disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </MDButton>
        <MDButton  variant="gradient"
                       size="small"
                       color="info" onClick={onClose} 
                       sx={{background:"grey"}} >Cancel</MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddEvaluation;