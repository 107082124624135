// AddCourseDialog.js
import AddIcon from '@mui/icons-material/Add';
import { Alert, Autocomplete, TextField } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const AddSemesterDialog = ({ open, onClose, onAdd }) => {
    const [modalData, setModalData] = useState({
        semester_code: "",
        semester_name:"",
        "start_month":null,
        "end_month":null
      });
      const [error, setError] = useState(null);
      const [loading, setLoading] = useState(false); 
    
      const token = localStorage.getItem("authToken");
      const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalData({
          ...modalData,
          [name]: value,
        });
        setError(null);
      };

      const handleModalSubmit = async () => {
        setLoading(true);
        try {
          const response = await axios.post(`${API_BASE_URL}/semesters`, modalData, {
            headers: { "Authorization": `Bearer ${token}` }
          });

          if (response.status === 201) {
            onAdd(response.data);
            onClose();
            swal({
              title: "Semesters Created Successfully!",
              // text: "Course Created Successfully!",
              icon: "success"
            });
            setModalData({
                semester_code: "",
                semester_name:"",
                "start_date":null,
                "end_date":null
            })
          }
        } catch (error) {
          setError(error.response?.data?.errors?.semester_code[0]);
          
        }finally {
          setLoading(false); // Stop loading
        }
      };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}><AddIcon /> Add  Semesters</DialogTitle>
    <DialogContent>
      {error && (
        <MDBox mx={2} mt={2} mb={2}>
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        </MDBox>
      )}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Semester Name
        </MDTypography>
        <MDInput
          type="text"
          name="semester_name"
          fullWidth
          required
          value={modalData.semester_name}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Semester Code
        </MDTypography>
        <MDInput
          type="text"
          name="semester_code"
          fullWidth
          required
          value={modalData.semester_code}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
  <MDTypography variant="caption" color="text" fontWeight="medium">
    Start Month
  </MDTypography>
  <Autocomplete
    options={[
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ]}
    getOptionLabel={(option) => option}
    onChange={(event, newValue) => handleModalChange({ target: { name: 'start_month', value: newValue }})}
    value={modalData.start_month}
    renderInput={(params) => <TextField {...params} label="Select Start Month" fullWidth />}
  />
</MDBox>

<MDBox mb={2}>
  <MDTypography variant="caption" color="text" fontWeight="medium">
    End Month
  </MDTypography>
  <Autocomplete
    options={[
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ]}
    getOptionLabel={(option) => option}
    onChange={(event, newValue) => handleModalChange({ target: { name: 'end_month', value: newValue }})}
    value={modalData.end_month}
    renderInput={(params) => <TextField {...params} label="Select End Month" fullWidth  />}
  />
</MDBox>
      
    </DialogContent>
    <DialogActions>
      <MDButton   variant="gradient"
                     size="small"
                     color="info"
                     sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit</MDButton>
      <MDButton   variant="gradient"
                     size="small"
                     color="black"
                     sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />
  </Dialog>
  )
}

export default AddSemesterDialog