import React, { useState, useEffect,useCallback} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Autocomplete,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDButton from 'components/MDButton';
import swal from 'sweetalert';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';
import { Alert } from '@mui/material';

const AddMarks = ({ open, onClose, onAdd,requests }) => {
  const [errors, setErrors] = useState({});
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [additionalMark, setAdditionalMark] = useState("");
  const [loading, setLoading] = useState(false);
  const targetId = localStorage.getItem("targetId");
  const [gradeRequests,setGradeRequests] = useState(requests);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    if (requests) {
      console.log("requests:",requests)
        setGradeRequests(requests);
        setSelectedCourse(requests.course);
    }
}, [requests]);

  const fetchData = useCallback(async () => {
    try {
      const [semestersData,yearsData,coursesData] = await Promise.all([
        axiosInstance.get(`/semesters`),
        axiosInstance.get(`/years/active`),
        axiosInstance.get(`/instructor/${targetId}/courses`),
      ]);


      setYears(yearsData.data);
      setSemesters(semestersData.data);


      const coursesArray = coursesData.data[0].course_history.map(course => ({
        id: course.course_id,
        name: course.course?.course_name || 'Unknown Course',
        semester:course.semester,
        year:course.year,
        deadline:course.mark_deadline

      }));

      console.log("coursesArray:",coursesArray)

      
const today = new Date();

const filteredCourses = coursesArray.filter(course => {
  // Parse the deadline as a Date object
  const deadlineDate = new Date(course.deadline);
  // && deadlineDate >= today
  console.log("deadlineDate >= today:",deadlineDate >= today)
  console.log("slectedCourse:",selectedCourse)
  console.log("course.id === selectedCourse?.id:",course.id === selectedCourse?.id)

  return course.id === selectedCourse?.id  && deadlineDate >= today;
});
   

      setCourses(filteredCourses);

  

    
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false)
    }
  },[targetId,selectedCourse]);

 useEffect(() => {
   fetchData();
 }, [fetchData]);


 useEffect(() => {
  console.log("selectedSemester:",selectedSemester)
  console.log("selectedYear:",selectedYear)
  console.log("courses4:",courses)
    if (selectedYear && selectedSemester && courses.length > 0) {
      console.log("selectedSemester:",selectedSemester)
        const filtered = courses.filter(course =>
            Number(course.year) === Number(selectedYear.year_name) &&
            String(course.semester) === String(selectedSemester.semester_code)
        );
        console.log("filtered:",filtered)
        setFilteredCourses(filtered);
    } else {
        setFilteredCourses([]); // Reset if no year/semester selected
    }
}, [selectedYear, selectedSemester, courses]);
  

 
  
  



  const handleSubmit = async (e) => {
    e.preventDefault();

    if(additionalMark === "") {
      setErrors({
        ...errors,
        mark: ["Mark is required"]
      });
    }
    else{
    try {
      const payload = {
        student_id: gradeRequests.student_id, // Assuming `studentId` is part of the selected student object
        mark: Number(additionalMark), // Convert to number
        course_id: filteredCourses[0]?.id, // Assuming `id` is the course ID
        semester: selectedSemester?.semester_code, // Assuming `semester_code` is the code for the semester
        year: selectedYear?.year_name, // Assuming `year_name` is the name for the year
        instructor_id: gradeRequests?.instructor_id, // Use the target ID for the instructor
        reason: "he needs additional mark for the assignment", // Static reason as per your request
    };


    const response = await axiosInstance.post("/additional-marks", payload);
    if (response.status === 201) {
      
    
    onAdd(); // Callback to add the new mark to your UI
    setAdditionalMark(""); // Clear the additional mark input
    setSelectedCourse(null); // Reset selected course
    setSelectedSemester(null); // Reset selected semester
    setSelectedYear(null); // Reset selected year
    setErrors(null); // Clear any previous errors
    onClose(); // Close the dialog
    swal({ title: "Additional mark added successfully!", icon: "success" });
    }
    } catch (error) {
      console.log("error:", error);
      const errors = error.response.data.errors;
      console.log("additionalMark:",additionalMark)

 

  // Check if `course_id` is in the errors
  if (errors.course_id) {
    // Customize the error message for `course_id`
    setErrors({
      ...errors,
      course_id: ["courses not available"]
    });
  } else {
    // Set the errors in the state as received
    setErrors(errors);
  }

    }
  }
  };

  return (
    <DashboardLayout>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle textAlign="center">
          <Add /> Add Marks
        </DialogTitle>
        {Object.entries(errors || {}).map(([field, messages]) => (
  <Alert severity="error" key={field}>
    {`${field.charAt(0).toUpperCase() + field.slice(1)}: ${messages.join(", ")}`}
  </Alert>
))}

        <DialogContent>
          <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={6}>
              <Autocomplete
                options={years}
                getOptionLabel={(option) => option.year_name}
                onChange={(e, newValue) => {
                  setSelectedYear(newValue);
                  setErrors({});
                }}
                value={selectedYear}
                renderInput={(params) => <TextField {...params} label="Year" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={semesters}
                getOptionLabel={(option) => option.semester_name}
                onChange={(e, newValue) => {
                  setSelectedSemester(newValue);
                  setErrors({});
                }}
                value={selectedSemester}
                renderInput={(params) => <TextField {...params} label="Semester" />}
              />
            </Grid>
           
          

          
            <Grid item xs={12} md={6}>
              <TextField
                label="Additional Mark"
                type="number"
                value={additionalMark}
                onChange={(e) => {
                  setAdditionalMark(e.target.value);
                  setErrors({});
                }}
                fullWidth
              />
            </Grid>
           
          </Grid>
      
        </DialogContent>
        <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default AddMarks;
