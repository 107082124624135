// AddCourseDialog.js
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Alert } from '@mui/material';
 
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import { axiosInstance } from "api2";
import LoadingDialog from 'components/Loading/LoadingDialog';

const AddCourseDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    course_name: "",
    credit_hour: null,
    course_description: "",
    course_number: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); 

 
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`courses`, modalData);
      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Course Created Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
        setModalData({
          course_name: "",
          credit_hour: 0,
          course_description: "",
          course_number: "",
        })
      }
    } catch (error) {
      setError(error.response?.data?.errors?.course_number?.[0]);
      // setError(error.response.data.errors.course_number[0])
    }finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}><AddIcon /> Add Courses</DialogTitle>
      <DialogContent>
        {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Course Name
          </MDTypography>
          <MDInput
            type="text"
            name="course_name"
            fullWidth
            required
            value={modalData.course_name}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Course Description
          </MDTypography>
          <MDInput
            type="text"
            name="course_description"
            fullWidth
            required
            value={modalData.course_description}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Credit Hours
          </MDTypography>
          <MDInput
            type="number"
            name="credit_hour"
            fullWidth
            required
            value={modalData.credit_hour}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Course Number
          </MDTypography>
          <MDInput
            type="text"
            name="course_number"
            fullWidth
            value={modalData.course_number}
            onChange={handleModalChange}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton   variant="gradient"
                       size="small"
                       color="info"
                       sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit</MDButton>
        <MDButton   variant="gradient"
                       size="small"
                       color="black"
                       sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddCourseDialog;
