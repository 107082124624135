import { Button, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function Confirm({
  open,
  onConfirm,
  onCancel,
  title,
  description,
}) {
  return (
    <Dialog open={open} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="body2" color="text" fontWeight="medium">
            {description}
          </MDTypography>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            margin: "20px",
            padding: "5px 20px",
            color: "#00274D",
            border: "1px solid #00274D",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "13px",
            transition: "background-color 0.3s",
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          style={{
            margin: "20px",
            padding: "5px 20px",
            backgroundColor: "#00274D",
            color: "#FFFFFF",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "13px",
            transition: "background-color 0.3s",
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#00274D")
          }
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
