import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { axiosInstance } from 'api2';
import { CircularProgress } from '@mui/material';
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';
import PieChart from "examples/Charts/PieChart";


const FinanceDashboard = () => {
    const [financeInfo, setFinanceInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFinanceData = async () => {
          try {
            // Fetch finance dashboard data
            const response = await axiosInstance.get('/finance-dashboard');
            const data = await response.data;
      
            setFinanceInfo({
              students: data.students,
              employees: data.employees,
              assets: data.assets,
              paymentMethods: data.payment_methods.map(method => ({
                label: method.payment_method,
                value: method.total
              })),
              payrolls: data.payrolls,
              loans: data.loans
            });
          } catch (error) {
            console.error('Error fetching finance data:', error);
          } finally {
            setLoading(false); // Stop loading once data is fetched
          }
        };
      
        fetchFinanceData();
      }, []);
      
      if (!financeInfo && loading) {
        return (
          <DashboardLayout>
            <MDBox display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </MDBox>
          </DashboardLayout>
        );
      }
      
      const cardData = [
        { label: 'Total Students', value: financeInfo.students || "N/A", icon: "school", color: "info" },
        { label: 'Total Employees', value: financeInfo.employees || "N/A", icon: "group", color: "primary" },
        { label: 'Total Assets', value: financeInfo.assets || "N/A", icon: "business_center", color: "success" },
       
      ];

  

      const columns = [
        {
          Header: "Employee Name",
          accessor: "employee", // Employee object
          showSortIcons: false,
          Cell: ({ value }) => `${value.first_name} ${value.last_name}`, // Display full name
        },
        {
          Header: "Basic Salary",
          accessor: "basic_salary", // Employee's basic salary
          showSortIcons: false,
          Cell: ({ value }) => `ETB ${parseFloat(value).toLocaleString()}`, // Format currency
        },
        {
          Header: "Gross Pay",
          accessor: "gross_pay", // Gross pay
          showSortIcons: false,
          Cell: ({ value }) => `ETB ${parseFloat(value).toLocaleString()}`, // Format currency
        },
        {
          Header: "Total Deductions",
          accessor: "total_deduction", // Total deductions
          showSortIcons: false,
          Cell: ({ value }) => `ETB ${parseFloat(value).toLocaleString()}`, // Format currency
        },
        {
          Header: "Net Pay",
          accessor: "net_pay", // Net pay
          showSortIcons: false,
          Cell: ({ value }) => `ETB ${parseFloat(value).toLocaleString()}`, // Format currency
        },
        {
          Header: "Generated Date",
          accessor: "created_at", // Date payroll was generated
          showSortIcons: false,
          Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "N/A"),
        },
      ];
      

      

      const chartData = {
        labels: financeInfo.loans.map((loan) => loan.loan_start_date), // Loan start dates on x-axis
        datasets: 
          {
            label: "Loan Amount",
            data: financeInfo.loans.map((loan) => Number(loan.loan_amount)), // Ensure loan_amount is numeric
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            fill: true,
          },
        
      };

      const paymentMethodsData = {
        labels: financeInfo.paymentMethods.map((method) => method.label), // Extract labels
        datasets: 
          {
            label: "Payment Methods",
            backgroundColors: ["info", "primary", "success", "warning"],
            data: financeInfo.paymentMethods.map((method) => method.value), // Extract values
          },
        
      };

    
      
      
      
    
  return (
    <DashboardLayout>
        <MDBox mt={4}>
    <Grid container spacing={3}>
    {cardData.map((item, index) => (
      <Grid item xs={true} key={index}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color={item.color} // Make sure to define color for each item or use a default color
            icon={item.icon}
            title={item.label}
            count={item.value}
          />
        </MDBox>
      </Grid>
    ))}
  </Grid>
    </MDBox>
    <MDBox>
    <Grid container spacing={3} pt={6}>
        <Grid item xs={12} lg={8}>
        <ReportsLineChart
      color="primary"
      title="Loan Data"
      description="This chart shows the loan amounts over time."
      date={new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
      chart={chartData}
    />
        </Grid>
        <Grid item xs={12} lg={4}>
        <PieChart
                icon={{ color: "info", component: "group" }}
                title="Payment methods"
                description="Diffrenet payment methods use by students"
                chart={paymentMethodsData}
              />
        </Grid>
    </Grid>
    </MDBox>
    <MDBox>
    <MDTypography variant="h6" gutterBottom mt={3}>
              Payroll
            </MDTypography>
    {financeInfo.payrolls.length > 0 ? (
          <DataTable
            table={{ columns, rows: financeInfo.payrolls }}
            canSearch={true}
            sortable={true}
            rowsPerPage={5}
            showPagination
          />
        ) : (
          <MDTypography>No payroll data available.</MDTypography>
        )}
    </MDBox>
    </DashboardLayout>
  )
}

export default FinanceDashboard