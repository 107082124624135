import React, { useState, useEffect, useCallback } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import { axiosInstance } from "api2";

import FileViewer from "components/FileViewer";




const Materials = () => {

    const [openDialog, setOpenDialog] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
    const [materials, setMaterials] = useState([]);


    const fetchMaterials = useCallback(async () => {
        try {
           
            // Fetch materials from the API using the targetId
            const response = await axiosInstance.get(`/materials`);

            // Process the response to format assigned_users and shared_by
            const formattedMaterials = response.data.map(material => ({
                id: material.id,
                materialName: material.material_name,
                materialType: material.material_type,
                dueDate: material.due_date,
                remark: material.remark,
                filePath: material.file_path,
                   course:material.course,
        instructor:material.instructor,
                assignedUsers: material.students.map(student => `${student.first_name} ${student.last_name}`).join(', '),
                sharedBy: material.instructor ? material.instructor.full_name : 'N/A',
            }));

          

            // Set the formatted materials to the state
            setMaterials(formattedMaterials);
        } catch (err) {
            console.error(err);
        } 
    }, []);


    const handleViewFile = (filePath) => {
        console.log("filePath:",filePath)
        const extension = filePath.split('.').pop().toLowerCase();
        setSelectedFile(filePath);
        setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
        setOpenDialog(true);
      };


      useEffect(() => {
        fetchMaterials();
    }, [fetchMaterials]);
    console.log("mterials:",materials)

    const columns = [
      
        { Header: 'Instructor Name', accessor: 'instructor.full_name' },
          { Header: 'Material Name', accessor: 'materialName' },
             { Header: 'Course Number', accessor: 'course.course_number',showSortIcons: false },
         { Header: 'Course Name', accessor: 'course.course_name' },
          { Header: 'Material Type', accessor: 'materialType', showSortIcons: false },
          { Header: 'Due Date', accessor: 'dueDate', showSortIcons: false },
          { Header: 'Remark', accessor: 'remark', showSortIcons: false },
          {
            Header: 'File Path',
            accessor: 'filePath',
            Cell: ({ value }) => (
              <MDButton
                variant="body2"
                color="primary"
                component="a"
                onClick={() => handleViewFile(value)}
                style={{ cursor: 'pointer' }}
                sx={{ 
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  boxShadow: 1, // Adds a default shadow
                  transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
                  '&:hover': {
                    color: 'secondary.main',
                    boxShadow: 4, // Increases shadow intensity on hover
                  }
                }}
              >
                View File
              </MDButton>
            ),
          },
       
          
      ];

    


  return (
    <DashboardLayout>
    <Card>
        <CardContent>
            <MDBox>
                <MDTypography variant="h5" fontWeight="medium">
                    Materials
                </MDTypography>
                <MDBox>
                    <DataTable
                        table={{
                            columns,
                            rows: materials,
                        }}
                        canSearch={true}
                        sortable={true}
                        pagination
                        rowsPerPage={5}
                        showPagination
                       
                    />
                </MDBox>
             
            </MDBox>
            <FileViewer
open={openDialog}
onClose={() => setOpenDialog(false)}
filePath={selectedFile}
fileType={fileType}
/>

        </CardContent>
    </Card>
</DashboardLayout>
  )
}

export default Materials