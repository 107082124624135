import {
    Autocomplete,
    TextField
  } from "@mui/material";
  import Dialog from "@mui/material/Dialog";
  import DialogActions from "@mui/material/DialogActions";
  import DialogContent from "@mui/material/DialogContent";
  import DialogTitle from "@mui/material/DialogTitle";
  import { axiosInstance } from "api2";
  import MDBox from "components/MDBox";
  import MDButton from "components/MDButton";
  import MDInput from "components/MDInput";
  import MDTypography from "components/MDTypography";
  import React, { useEffect, useState,useCallback } from "react";
  import swal from "sweetalert";
  import API_BASE_URL from "api";
  import axios from "axios";
  import LoadingDialog from "components/Loading/LoadingDialog";

const EditMaterial = ({ open, onClose, material, onEdit }) => {

  
    const [modalData, setModalData] = useState({
        id: "",
        assigned_students: [],
        material_name: "",
        material_type: "",
       
        remark: "",
        file: null,
        course_id:null
      });
      const [loading,setLoading] = useState(false)
      const [courses, setCourses] = useState([]);
      const [selectedCourse, setSelectedCourse] = useState(null);
    
      
      useEffect(() => {
        if (material) {
          setModalData({
            id: material.id,
            assigned_students: material.assignedUsers.split(", "), // Splitting by comma and space
            material_name: material.materialName || "",
            material_type: material.materialType || "",
       
            remark: material.remark || "",
            file: material.filePath || null,
            course_id:material.course.id
          });
          const foundCourse = courses.find(course => course.id === material.course.id);
          setSelectedCourse(foundCourse || null);
        }
      }, [material,courses]);
      
      const token = localStorage.getItem("authToken");
    
   
  const targetId = localStorage.getItem("targetId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [coursesData] = await Promise.all([
          axiosInstance.get(`/instructor/${targetId}/courses`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
       
        const coursesArray = coursesData.data[0].course_history.map(course => ({
          id: course.course_id,
          name: course.course?.course_name || 'Unknown Course',
        }));
       
        
        setCourses(coursesArray);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchData();
  }, [targetId, token]);

  const fetchStudents = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/instructor/${targetId}/students`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
  
      const courseHistory = response.data[0].course_history;


  
      // Filter students based on selected course ID
      const studentsForSelectedCourse = courseHistory
        .filter(course => course.course_id === courseId)
        .flatMap(course => course.registrations.map(registration => registration.student.id));
    

  
        setModalData(modalData => ({ ...modalData, assigned_students: studentsForSelectedCourse }));

    } catch (err) {
      console.error(err);
    } finally {
    }
  },[token,targetId]);

  useEffect(() => {
    if (selectedCourse) {
      fetchStudents(selectedCourse.id); // Fetch students for the selected course
    }
  },[fetchStudents,selectedCourse]);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  };

  const handleCourseChange = (event, newValue) => {
    setSelectedCourse(newValue);
    setModalData((prevModalData) => ({
      ...prevModalData,
      course_id: newValue ? newValue.id : null, // Update course_id based on the selected course
    }));
  };

  

  const handleModalSubmit = async () => {
    setLoading(true)
   
    const formData = new FormData();
    formData.append("course_id", Number(modalData.course_id )|| "");
    formData.append("instructor_id", Number(targetId) || "");
    modalData.assigned_students.forEach((studentId) => {
      formData.append("assigned_students[]", Number(studentId));
    });
    formData.append("material_name", modalData.material_name);
    formData.append("material_type", modalData.material_type);
    formData.append("remark", modalData.remark);
   
    if (modalData.file) {
      formData.append("file_path", modalData.file);
    }


    try {
    

    
    
      const response = await axiosInstance.post(`/materials/${material.id}/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
     
      if (response.status === 200) {
        onEdit();
        onClose();
        swal({
          title: "Material Updated Successfully!",
          icon: "success",
        });
      }
    } catch (error) {
      console.error("Error submitting material:", error.response);
      swal({
        title: "Error",
        text: "Failed to add material. Please try again.",
        icon: "error",
      });
    } finally {
      setLoading(false)
    }
  };

  const handleFileChange = (e) => {
    setModalData({
      ...modalData,
      file: e.target.files[0],
    });
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>
      Edit Material
    </DialogTitle>
    <DialogContent>
    <MDBox mb={2}>
    <Autocomplete
    fullWidth
    sx={{ my: 2 }}
    options={courses}
    getOptionLabel={(option) => option.name || ''}
    onChange={handleCourseChange} // Use the new handler
    value={selectedCourse} // This should be the full course object
    renderInput={(params) => <TextField {...params} label="Course" />}
  />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Material Name
        </MDTypography>
        <MDInput
          type="text"
          name="material_name"
          fullWidth
          required
          value={modalData.material_name}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Material Type
        </MDTypography>
        <MDInput
          type="text"
          name="material_type"
          fullWidth
          required
          value={modalData.material_type}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Remark
        </MDTypography>
        <MDInput
          type="text"
          name="remark"
          fullWidth
          required
          multiline
          rows={3}
          value={modalData.remark}
          onChange={handleModalChange}
        />
      </MDBox>
      
      
     
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Upload File
        </MDTypography>
        <MDInput
          type="file"
          name="file"
          fullWidth
          onChange={handleFileChange}
        />
      </MDBox>
    </DialogContent>
    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleModalSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Cancel
      </MDButton>
    </DialogActions>
    <LoadingDialog loading={loading} />
  </Dialog>
  )
}

export default EditMaterial