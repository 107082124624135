/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import axios from "axios";
import LoadingDialog from "components/Loading/LoadingDialog";

function ServerDataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  widgets,
  fetchDataUrl,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [metadata, setMetadata] = useState();
  const [data, setData] = useState([]);

  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25", "50", "100"];

  const token = localStorage.getItem("authToken");

  const columns = useMemo(() => table.columns, [table]);
  const fetchData = useCallback(
    async (
      page = 1,
      pageSize = 10,
      searchTerm,
      sort_order = "asc",
      sort_by
    ) => {
      setLoading(true);
      let searchQuery = searchTerm ? `&search=${searchTerm}` : "";
      let sortByQuery = sort_by ? `&sort_by=${sort_by}` : "";
      let sortOrderQuery = sort_order ? `&sort_order=${sort_order}` : "";
      try {
        try {
          const response = await axios.get(
            `${fetchDataUrl}?page=${page}&pageSize=${pageSize}${searchQuery}${sortByQuery}${sortOrderQuery}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setMetadata(response.data);
          setData(response.data.data);
          setLoading(false);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      } catch (error) {}
    },
    [fetchDataUrl, token]
  );

  useEffect(() => {
    fetchData(currentPage, perPage, searchTerm, sortOrder, sortBy);
  }, [fetchData, currentPage, perPage, searchTerm, sortOrder, sortBy]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
    state: { pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue, setPageSize]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value);
    setPerPage(value);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= metadata.last_page) {
      setCurrentPage(newPage);
    }
  };

  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setSearchTerm(value || undefined);
  }, 500);

  const handleSortChange = (column) => {
    const newSortOrder =
      sortBy === column.id && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column.id);
    setSortOrder(newSortOrder);
  };

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <>
      <TableContainer sx={{ boxShadow: "none" }}>
        {entriesPerPage || canSearch ? (
          <MDBox
            display="flex"
            mt={1}
            // alignItems="center"
            alignItems={{ xs: "flex-start", md: "center" }}
            flexDirection={{ xs: "column", md: "row" }} // Column on small screens, row on medium and larger
          >
            {canSearch && (
              <MDBox width={{ xs: "100%", md: "30rem" }}>
                <MDInput
                  placeholder="Search..."
                  value={search}
                  size="large"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}

            {widgets}
          </MDBox>
        ) : null}
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <DataTableHeadCell
                    key={idx}
                    {...column.getHeaderProps(
                      isSorted && column.getSortByToggleProps()
                    )}
                    {...column.getHeaderProps(
                      isSorted &&
                        column.getSortByToggleProps({
                          onClick: () => handleSortChange(column), // Update sorting on click
                        })
                    )}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                    showSortIcons={column.showSortIcons !== false}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell, idx) => (
                    <DataTableBodyCell
                      key={idx}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="end"
          alignItems={{ xs: "flex-start", sm: "end" }}
          p={!showTotalEntries && metadata && metadata.last_page === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }} sx={{ mx: 2 }}>
              <MDTypography
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                {metadata &&
                  `Showing ${metadata.from} to ${metadata.to} of ${metadata.total}
              entries`}
              </MDTypography>
            </MDBox>
          )}
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center" sx={{ mx: 2 }}>
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          <MDPagination
            variant={pagination.variant || "gradient"}
            color={pagination.color || "info"}
            sx={{ mx: 2 }}
          >
            {metadata && metadata.prev_page_url && (
              <MDPagination
                item
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}

            {/* Render Pagination */}
            {metadata &&
              metadata.links &&
              metadata.links
                .filter((link) => {
                  return (
                    !link.label.includes("Next") &&
                    !link.label.includes("Previous")
                  );
                }) // Filter out Next and Previous
                .map((link, index) => {
                  if (link.label === "...") {
                    // If the label is '...', display the ellipsis without a link
                    return (
                      <MDPagination
                        key={index}
                        sx={{
                          fontWeight: "bold",
                          color: "inherit",
                        }}
                      >
                        ...
                      </MDPagination>
                    );
                  } else {
                    // Render normal pagination links
                    return (
                      <MDPagination
                        item
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        active={currentPage === parseInt(link.label)}
                        sx={{
                          width: "fit-content",
                          fontWeight:
                            currentPage === parseInt(link.label)
                              ? "bold"
                              : "normal",
                          bgcolor:
                            currentPage === parseInt(link.label)
                              ? "primary.main"
                              : "transparent",
                          color:
                            currentPage === parseInt(link.label)
                              ? "white"
                              : "inherit",
                        }}
                      >
                        {link.label}
                      </MDPagination>
                    );
                  }
                })}

            {metadata && metadata.next_page_url && (
              <MDPagination
                item
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        </MDBox>
      </TableContainer>
      <LoadingDialog open={loading} />
    </>
  );
}

// Setting default values for the props of DataTable
ServerDataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25, 50, 100] },
  canSearch: true,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the ServerDataTable
ServerDataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default ServerDataTable;
