import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState } from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddGradeDialog from "./addGradeDialog";
import EditGradeDialog from "./editGradeDialog";
import LoadingDialog from 'components/Loading/LoadingDialog';

const GradeInfo = () => {
    const [search] = useState('');
    const token =  localStorage.getItem("authToken");

    const [showAlert, setShowAlert] = useState(false);

  
    const [openEdit, setOpenEdit] = useState(false);
    const[grades,setGrade] = useState([])
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [deletingStatus, setDeletingStatus] = useState(false); 
    const role = localStorage.getItem("role");

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); 
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);

      useEffect(() => {
        const fetchStatus = async () => {
          try {
            const response = await axios.get(`${API_BASE_URL}/grades-info`, {
              headers: { "Authorization": `Bearer ${token}` }
            });
    
            setGrade(response.data);
          } catch (err) {
            console.error(err);
          } finally {
            setLoading(false)
          }

        };
        fetchStatus();
      }, [token]);

    

  const handleAddGrade= (newGrade) => {
    
    setGrade((prevGrade) => [...prevGrade, newGrade]);
  };
  

  const handleEditGrade = (updatedGrade) => {
    setGrade(grades.map((grade) =>
      grade.id === updatedGrade.id ? updatedGrade : grade
    ));
  };

  const handleDeleteGrade = async (id) => {
   
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this grade!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setDeletingStatus(true);
      try {
        const response = await axios.delete(`${API_BASE_URL}/grades-info/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 204){
          setGrade(grades.filter((grade) => grade.id !== id));
          swal({
            title: "Grade Info Deleted Successfully!",
            // text: "Course Created Successfully!",
            icon: "success"
          });
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
          // text: "Course Created Successfully!",
          icon: "warning"
        });
      }finally {
        setDeletingStatus(false); 
    }
    }
  };

  const handleOpenEdit = (grade) => {
    setSelectedGrade(grade);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedGrade(null);
  };

  const filteredData = grades.filter(course => {
    return (
      course.grade.toLowerCase().includes(search.toLowerCase()) 
       
    );
  });

  const columns = [
    ...(role !== 'academic dean'
      ? [
    {
      accessor: 'action',
      Header: '',  
      width: 40,  
      padding:0,
      Cell: ({ row }) => (
        <PopupState variant="popover"   popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteGrade(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    } ]
    : []),
    { Header: 'Grade', accessor: 'grade' },
    { Header: 'Point', accessor: 'point',showSortIcons: false, },
    { Header: 'Min Score', accessor: 'min_score',showSortIcons: false, },
    { Header: 'Max Score', accessor: 'max_score',showSortIcons: false, },
    { Header: 'Credit', accessor: 'credit',showSortIcons: false, },
    
   
  ];

  return (
    <MDBox>
         <MDTypography variant="h5" fontWeight="medium">
            Grade Info
          </MDTypography>
          <MDBox>
        
  <DataTable
    table={{
      columns,
      rows: filteredData,
    }}
    canSearch={true}
    sortable={true}
    rowsPerPage={5}
    showPagination
    widgets={role !== "academic dean" && (
      <MDBox ms={2}>
        <MDButton
          variant="gradient"
          ml="auto"
          color="info"
          size="small"
          onClick={() => setOpenAdd(true)}
          sx={{ background: "#00274D", my: 4, mx: 3 }}
        >
          <span>Add</span>
        </MDButton>
      </MDBox>)
    }
  />
   {!loading && filteredData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No Grade Info available
          </MDTypography>
        )}


          </MDBox>
          <AddGradeDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddGrade} />
              
              {selectedGrade && (
                <EditGradeDialog
                  open={openEdit}
                  onClose={handleCloseDialog}
                  grade={selectedGrade}
                  onEdit={handleEditGrade}
                />
              )}
    <LoadingDialog open={loading || deletingStatus} />
    </MDBox>
  )
}

export default GradeInfo