import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MDButton from 'components/MDButton';
import { axiosInstance } from 'api2';
import swal from "sweetalert";
import { useParams } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MDBox from 'components/MDBox';

const EditKid = ({ open, onClose, kid }) => {
  const [formData, setFormData] = useState({
    full_name: '',
    gender: '',
    birth_date: '',
    birth_certificate: '',
  });
  const { id } = useParams();

  useEffect(() => {
    if (kid) {
      setFormData({
        full_name: kid.full_name,
        gender: kid.gender,
        birth_date: kid.birth_date,
        birth_certificate: kid.birth_certificate,
      });
    }
  }, [kid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      birth_certificate: e.target.files[0], // For file uploads, store the file directly
    }));
  };

  const handleSubmit = async () => {
    try {

      const response = await axiosInstance.post(`/employees/${id}/kids/${kid.id}`, formData,{
        headers: {
            'Content-Type': 'multipart/form-data',
          },
      });
      if (response.status === 200) {
        onClose();
        swal({
          title: "Kid Information Updated Successfully!",
          icon: "success"
        }).then(() => window.location.reload());
      }
      onClose(); // Close the dialog after success
    } catch (error) {
      console.error(error.response.data);
      // Handle errors (e.g., show validation errors)
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Kid Information</DialogTitle>
      <DialogContent>
        <TextField
          name="full_name"
          label="Full Name"
          value={formData.full_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <MDBox mt={1}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="gender-label" sx={{ 
                backgroundColor: '#f5f5f5', 
                px: 1,
                my: 0.5,
                transform: 'translate(14px, -9px) scale(0.75)',
                '&.Mui-focused': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                },
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                }
              }}>Select Gender</InputLabel>
              <Select
                labelId="gender-label"
                id="gender-select"
                name="gender"
                required
                value={formData.gender}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f5f5f5",
                  padding: "10px",
                  borderRadius: 1,
                  "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            paddingRight: "32px",
                  },
                }}
                IconComponent={() => (
                  <ArrowDropDownIcon
            sx={{
              position: "absolute",
              right: 8,
              pointerEvents: "none",
            }}
                  />
                )}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
        </MDBox>

        <TextField
          type='date'
          name="birth_date"
          label="Birth Date"
          value={formData.birth_date}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <input
          type="file"
          name="birth_certificate"
          onChange={handleFileChange}
        />
      </DialogContent>
      <DialogActions>
        <MDButton 
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }} 
          onClick={handleSubmit}
        >
          Save
        </MDButton>
        <MDButton 
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }} 
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditKid;
