import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, TextField } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { axiosInstance } from 'api2';
import Switch from '@mui/material/Switch';
import LoadingDialog from 'components/Loading/LoadingDialog';

const AddRemuneration = ({ open, onClose, onAdd }) => {
  const [loanData, setLoanData] = useState({
    employee_id: "",
    title: "", // Default title
    amount: "", // This will map to loan_amount
    is_percentage: false, // Default value
    is_addition: false, // Default value for is_addition
  });

  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch employees data
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get('/employees');
        
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    fetchEmployees();
  }, []);

  const handleLoanDataChange = (e) => {
    const { name, value } = e.target;
    setLoanData({
      ...loanData,
      [name]: value,
    });
  };

  const handleLoanSubmit = async () => {
    if (!selectedEmployee) {
      swal({
        title: "Please select an employee!",
        icon: "warning",
      });
      return;
    }

    const dataToSubmit = {
      employee_id: selectedEmployee.id,
      title: loanData.title,
      amount: parseFloat(loanData.amount), // Ensure amount is a number
      is_percentage: loanData.is_percentage,
      is_addition: loanData.is_addition,
    };

    setLoading(true);

    try {
      const response = await axiosInstance.post('/remunerations', dataToSubmit);
      if (response.status === 201) {
        onAdd();
        onClose();
        swal({
          title: "Remuneration Added Successfully!",
          icon: "success",
        });
        setLoanData({
          employee_id: "",
          title: "Personal Loan", // Reset to default
          amount: "",
          is_percentage: false,
          is_addition: true,
        });
        setSelectedEmployee(null);
      }
    } catch (error) {
      console.error("Error submitting loan:", error.response);
    } finally{
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign="center">
        <AddIcon /> Add Remuneration
      </DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Employee
          </MDTypography>
          <Autocomplete
            fullWidth
            options={employees}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}` || ''}
            onChange={(event, newValue) => setSelectedEmployee(newValue)}
            value={selectedEmployee}
            renderInput={(params) => <TextField {...params} label="Employee" />}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Title
          </MDTypography>
          <MDInput
            type="text"
            name="title" // Updated to match JSON structure
            fullWidth
            required
            value={loanData.title}
            onChange={handleLoanDataChange}
          />
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
           Amount
          </MDTypography>
          <MDInput
            type="number"
            name="amount" // Updated to match JSON structure
            fullWidth
            required
            value={loanData.amount}
            onChange={handleLoanDataChange}
          />
        </MDBox>


        {/* Switch for is_percentage */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Is Percentage
          </MDTypography>
          <Switch
            checked={loanData.is_percentage}
            onChange={(e) =>
              setLoanData({
                ...loanData,
                is_percentage: e.target.checked,
              })
            }
          />
        </MDBox>

        {/* Switch for is_addition */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Is Addition
          </MDTypography>
          <Switch
            checked={loanData.is_addition}
            onChange={(e) =>
              setLoanData({
                ...loanData,
                is_addition: e.target.checked,
              })
            }
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleLoanSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddRemuneration;