import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const AddInstructorDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    "full_name": "",
    "email": "",
    "phone_number": "",
    "qualification": "",
    "employee_id": null
  });
  const [loading, setLoading] = useState(false); 

  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState({});

  const token = localStorage.getItem("authToken");

  // Fetch positions for Autocomplete dropdown
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/employees");

        setEmployees(response.data); // Set the positions data
      } catch (err) {
        console.error("Failed to fetch employees:", err);
      }
    };

    fetchData();
  }, [token]);


  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", // Clear the error for this field when it's being modified
    });
  };

  const handleModalSubmit = async () => {
    if (modalData.full_name=== "" ||
      modalData.email=== "" ||
      modalData.phone_number=== 0 ||
      modalData.qualification=== 0) {
       alert("Please add all required fields to continue");
     }
     else{
    try {
      setLoading(true);
      
      const response = await axios.post(`${API_BASE_URL}/instructors`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Instructor Created Successfully!",
          icon: "success",
        });

        // Reset form data
        setModalData({
          "full_name": "",
          "email": "",
          "phone_number": "",
          "qualification": "",
          "employee_id": null
        });
        setError({});
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = err.response.data.error;
        setError(errors); // Set the error object
      } else {
        console.error("Error submitting form:", err);
      }
    }finally {
      setLoading(false); // Stop loading
    }
  }
  };
  const handleEmployeeSelect = (value) => {
    setModalData({
      ...modalData,
      employee_id: value ? value.id : null, 
      phone_number: value ? value.phone : null, 
      full_name: value ? value.first_name + ' ' + value.last_name  : null, 
      email: value ? value.email : null, 

    });
  }


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>
      <AddIcon /> Add Instructor
    </DialogTitle>
    <DialogContent>
      {/* Position Title */}
      <MDBox mb={2}>
      
      <Autocomplete
        options={employees}
        getOptionLabel={(option) => option.first_name + ' ' + option.last_name } 
        onChange={(event, newValue) => {
          handleEmployeeSelect(newValue)
        }}
        renderInput={(params) => <TextField {...params} label="Employee" />}
      />
</MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Full Name
        </MDTypography>
        <MDInput
          type="text"
          name="full_name"
          fullWidth
          required
          value={modalData.full_name}
          onChange={handleModalChange}
          error={Boolean(error.full_name)}
          helperText={error.full_name}
        />
      </MDBox>

      {/* Job Description */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Email
        </MDTypography>
        <MDInput
          type="text"
          name="email"
          fullWidth
          required
          value={modalData.email}
          onChange={handleModalChange}
          error={Boolean(error.email)}
          helperText={error.email}
        />
      </MDBox>

      {/* Minimum Salary */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Phone Number
        </MDTypography>
        <MDInput
          type="number"
          name="phone_number"
          fullWidth
          required
          value={modalData.phone_number}
          onChange={handleModalChange}
          error={Boolean(error.phone_number)}
          helperText={error.phone_number}
        />
      </MDBox>
      <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Qualifications
          </MDTypography>
          <MDInput
            type="text"
            name="qualification"
            fullWidth
            required
            value={modalData.qualification}
            onChange={handleModalChange}
            error={Boolean(error.qualification)}
            helperText={error.qualification}
          />
        </MDBox>
    </DialogContent>

    {/* Actions */}
    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleModalSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Cancel
      </MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />
  </Dialog>
  )
}

export default AddInstructorDialog