import React, { useState,useEffect,useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import { Autocomplete, TextField,useMediaQuery } from '@mui/material';
import LoadingDialog from 'components/Loading/LoadingDialog';


const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`, 
    'Content-Type': 'application/json',
  },
});

const EnrolledCourses = () => {
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [semester, setSemester] = useState(null);
  const [year, setYear] = useState(null);
  const targetId = localStorage.getItem("targetId");
  const token = localStorage.getItem("authToken");
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [semestersData, yearsData] = await Promise.all([
          axiosInstance.get('/semesters'),
          axiosInstance.get('/years'),
        ]);
        setSemesters(semestersData.data);
        setYears(yearsData.data);
      } catch (error) {
        console.error("Error fetching semesters and years:", error);
      }
    };

    fetchData();
  }, []);

  
    const fetchCourses = useCallback(async () => {
      try{
      const response = await axios.get(`${API_BASE_URL}/student/${targetId}/registration`,{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      });
      const courses = response.data[0].registrations;
     
      setAssignedCourses(courses);
      setFilteredCourses(courses);
    }
    catch (err) {
      console.error(err);
    } finally {
      setLoading(false)
    }
  },[token,targetId]);
 


  useEffect(() => {
    fetchCourses();
  },[fetchCourses]);

  

  useEffect(() => {
    let updatedCourses = [...assignedCourses];

    if (semester) {
      updatedCourses = updatedCourses.filter(course => course.course_history.semester === semester.semester_code);
    }

    if (year) {
      updatedCourses = updatedCourses.filter(course => course.course_history.year === parseInt(year.year_name));
    }

    setFilteredCourses(updatedCourses);
  }, [semester, year, assignedCourses]);

  const columns = [
    { Header: 'Course No', accessor: 'course_history.course.course_number',showSortIcons: false, },
    { Header: 'Course Name', accessor: 'course_history.course.course_name' },
    { 
      Header: 'Instructor Name', 
      accessor: 'course_history.instructor.full_name',
      Cell: ({ value }) => value ? value : 'TBA', // Display 'TBA' if no instructor name
      showSortIcons: false
    },
    { Header: 'Credit Hour', accessor: 'course_history.course.credit_hour',showSortIcons: false },
  ];

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));



  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Card>
          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Courses
            </MDTypography>
            
            <MDTypography variant="caption" fontWeight="small">
             
            </MDTypography>
          
            <MDBox mt={2}>

    <DataTable
      table={{
        columns,
        rows: filteredCourses,
      }}
      canSearch
      sortable
      rowsPerPage={5}
      showPagination
      widgets={
        <MDBox display="flex" flexDirection={isSmallScreen ? 'column' : 'row'}>
          {/* Semester Autocomplete */}
          <Autocomplete
            fullWidth
            sx={{ mx: 2, my: 2, width: isSmallScreen ? '150px' : '200px' }}
            options={semesters}
            getOptionLabel={(option) => option.semester_code || ''}
            onChange={(event, newValue) => setSemester(newValue)}
            value={semester}
            renderInput={(params) => <TextField {...params} label="Semester" />}
          />

          {/* Year Autocomplete */}
          <Autocomplete
            fullWidth
            sx={{ mx: 2, my: 2, width: isSmallScreen ? '150px' : '200px' }}
            options={years}
            getOptionLabel={(option) => option.year_name || ''}
            onChange={(event, newValue) => setYear(newValue)}
            value={year}
            renderInput={(params) => <TextField {...params} label="Year" />}
          />
        </MDBox>
      }
    />
 





            </MDBox>
          </CardContent>
        </Card>
      </MDBox>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default EnrolledCourses;
