import { Home, Login, School } from '@mui/icons-material'
import { Card, CardContent, Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "assets/images/logo-ct.png";
function AnAuthenticatedDashboard() {
    const navigate = useNavigate();
  return (
    <DashboardLayout>
       
            {/* Large Description Card */}
            <Grid container item spacing={3} justifyContent="center">
                {/* First Card: New Student Application */}
                <Grid item xs={12}>
                <Card>
                    <CardContent style={{ textAlign: 'center' }}>
                        <img src={logo} alt="MK's Seminary Logo" style={{ width: '150px', marginBottom: '16px' }} />
                        <MDTypography variant="h4" gutterBottom>
                            Welcome to MK's Seminary
                        </MDTypography>
                        <MDTypography variant="h6">
                        The MK Seminary exists to equip men and women for the holistic transformation of the church and society!
                                                </MDTypography>
                    </CardContent>
                </Card>
            </Grid>


            {/* Three Cards Under the Description */}
            <Grid container item spacing={3} justifyContent="center">
                {/* First Card: New Student Application */}
                <Grid item xs={12} sm={4}>
                <Card onClick={() => navigate('/application-intro')} style={{ cursor: 'pointer' }}>
                <CardContent>
                            <Home style={{ fontSize: 40, color: '#3f51b5' }} />
                            <MDTypography variant="h5" component="h2" gutterBottom>
                                New Students
                            </MDTypography>
                            <MDTypography variant="body2" component="p">
                                If you are a new student, apply now!
                            </MDTypography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Second Card: Available Programs */}
                <Grid item xs={12} sm={4}>
                    <Card onClick={() => navigate('/program')} style={{ cursor: 'pointer' }}>
                        <CardContent>
                            <School style={{ fontSize: 40, color: '#3f51b5' }} />
                            <MDTypography variant="h5" component="h2" gutterBottom>
                                Available Programs
                            </MDTypography>
                            <MDTypography variant="body2" component="p">
                                Explore our programs and find your path.
                            </MDTypography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Third Card: Sign In */}
                <Grid item xs={12} sm={4}>
                    <Card onClick={() => navigate('/authentication/sign-in')} style={{ cursor: 'pointer' }}>
                        <CardContent>
                            <Login style={{ fontSize: 40, color: '#3f51b5' }} />
                            <MDTypography variant="h5" component="h2" gutterBottom>
                                Already have an account?
                            </MDTypography>
                            <MDTypography variant="body2" component="p">
                                Click here to sign in.
                            </MDTypography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>

    </DashboardLayout>

  )
}

export default AnAuthenticatedDashboard