import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState, useCallback } from "react";


import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import {Card,CardContent} from '@mui/material';
// import ViewEvaluation from './viewEvaluation';
import MDButton from 'components/MDButton';
import AddEvaluation from '../addEvaluation';

const ViewEvaluationList = () => {
    const [evaluation, setEvaluation] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAdd, setOpenAdd] = useState(false);

    const fetchEvaluation = useCallback(async () => {
        
      try {
        const response = await axiosInstance.get(`/evaluations`
        );
        setEvaluation(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // End loading after fetching data
      } 
    },[])

    useEffect(() => {
     

    fetchEvaluation();
}, [fetchEvaluation]);

const handleAddEvaluation = () => {
  fetchEvaluation();
};








const columnConfig = [
    
    { Header: 'Description', accessor: 'description', showSortIcons: false },
    { Header: 'Excellent Description', accessor: 'excellent_description', showSortIcons: false },
    { Header: 'Poor Description', accessor: 'poor_description', showSortIcons: false },
    { Header: 'Type', accessor: 'type', showSortIcons: false },
  ];
  

  

const handleCloseDialog = () => {
  setOpenAdd(false);

};

// Add columns for actions and other required fields
const columns = [
 
  ...columnConfig,  // Spread the configurations
];
      
  return (
    <DashboardLayout>
    <Card>
      <CardContent>
        <MDBox>
        <MDTypography variant="h5" fontWeight="medium" my={2}>
          Evaluation
        </MDTypography>
        <MDBox>
          <DataTable
            table={{
              columns,
              rows: evaluation,
            }}
            canSearch={true}
            sortable={true}
            rowsPerPage={5}
            showPagination
            widgets={
              <MDButton 
              variant="gradient"
              ml="auto"
                  color="info"
                  size="small"
                  onClick={() => setOpenAdd(true)}
                  sx={{background:"#00274D", my:4,mx:3}}
            >
              <span>Add</span>
            </MDButton>
            }
        
          />
          {!loading && evaluation.length === 0 && (
            <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
              fontStyle: "italic",
              fontWeight: "bold",
              color: "#9e9e9e", // Light grey color
              opacity: 0.7,
            }}>
              No Evaluation available
            </MDTypography>
          )}
        </MDBox>
        
        <AddEvaluation open={openAdd} onClose={handleCloseDialog} onAdd={handleAddEvaluation} />
        <LoadingDialog open={loading } />
          </MDBox>
      </CardContent>
    </Card>
  </DashboardLayout>
  )
}

export default ViewEvaluationList