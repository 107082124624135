import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { axiosInstance } from "api2";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, TextField, Alert } from '@mui/material';

const AddLeaveRequest = ({ open, onClose, onAdd }) => {
  const targetId = localStorage.getItem('targetId');
  
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [modalData, setModalData] = useState({
    employee_id: targetId,
    leave_type_id: 0,
    start_date: '',
    end_date: '',
    reason: '',
    attachment: '',
  });

  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [typesData] = await Promise.all([axiosInstance.get('/leave-types')]);
        setLeaveTypes(typesData.data);
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };

    fetchData();
  }, []);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    if (isSubmitted) {
      setError(null); // Clear error when changing input
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setModalData({
      ...modalData,
      [name]: files[0],
    });
    if (isSubmitted) {
      setError(null); // Clear error when changing input
    }
  };

  const handleModalSubmit = async () => {
    setIsSubmitted(true); // Indicate that the form has been submitted
    try {
      const formData = new FormData();
      for (const key in modalData) {
        formData.append(key, modalData[key]);
      }

      const response = await axiosInstance.post(`/leaves`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Leave Request Created Successfully!",
          icon: "success",
        });

        // Reset form fields
        setModalData({
          leave_type_id: 0,
          start_date: '',
          end_date: '',
          reason: '',
          attachment: '',
        });
        setError(null); // Reset error on success
        setIsSubmitted(false); // Reset submitted state
      }
    } catch (error) {
      if (error.response?.status === 400) {
        const message = error.response.data.message || 'An unknown error occurred.';
        setError(message); // Set error state to a simple message
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign="center">
        <AddIcon /> Add Leave Request
      </DialogTitle>
      {isSubmitted && error && (
        <MDBox mx={2} mt={2} mb={2}>
          <Alert severity="error" variant="filled">
            {error} {/* Directly display the error message */}
          </Alert>
        </MDBox>
      )}

      <DialogContent>
        {/* Leave Type ID */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Leave Type ID
          </MDTypography>
          <Autocomplete
            fullWidth
            options={leaveTypes}
            getOptionLabel={(option) => option.leave_type_name || ''}
            onChange={(event, newValue) => {
              setModalData((prevData) => ({
                ...prevData,
                leave_type_id: newValue ? newValue.id : null,
              }));
            }}
            value={leaveTypes.find(type => type.id === modalData.leave_type_id) || null}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Leave Types" 
                error={!!error} 
                helperText={isSubmitted && error ? error : ''}
              />
            )}
          />
        </MDBox>

        {/* Start Date */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Start Date
          </MDTypography>
          <MDInput
            type="date"
            name="start_date"
            fullWidth
            required
            value={modalData.start_date || ''}
            onChange={handleModalChange}
          />
        </MDBox>

        {/* End Date */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            End Date
          </MDTypography>
          <MDInput
            type="date"
            name="end_date"
            fullWidth
            required
            value={modalData.end_date || ''}
            onChange={handleModalChange}
          />
        </MDBox>

        {/* Reason */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Reason
          </MDTypography>
          <MDInput
            type="text"
            name="reason"
            fullWidth
            required
            value={modalData.reason}
            onChange={handleModalChange}
          />
        </MDBox>

        {/* Attachment (File Upload) */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Attachment
          </MDTypography>
          <MDInput
            type="file"
            name="attachment"
            fullWidth
            required
            onChange={handleFileChange} 
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
      <MDButton variant="gradient" size="small"  color="black"
                       sx={{background:"grey"}} onClick={onClose}>
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
   
      </DialogActions>
    </Dialog>
  );
};

export default AddLeaveRequest;
