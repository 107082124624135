import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// @mui material componentsag
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import API_BASE_URL from "api";
import axios from "axios";
import { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEmployee } from "../../../../redux/features/employeeSlice";
import LoadingDialog from "components/Loading/LoadingDialog";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`, 
    'Content-Type': 'application/json',
  },
});


function Applications() {
  const [staffs, setStaffs] = useState([]);
  const [positions, setPositions] = useState(null);
  const employee = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [loading,setLoading] = useState(true);
  const formRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
        
    dispatch(setEmployee({[name]: value }));
  };
  const editStatus = employee.editStatus || null;


  const [formData, setFormData] = useState({
    selectedPosition: employee.position_id || "",
    selectedStaff: employee.staff_id || "",
  });

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Determine the key to dispatch
  const key = name === "selectedPosition" ? "position_id" : "staff_id";

  // Dispatch the action with the dynamic key
  dispatch(setEmployee({ [key]: value }));


    
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [positionData,staffData] = await Promise.all([
          axiosInstance.get(`/positions`),
          axiosInstance.get('/staff'),
          
        ]);
      
       
        setPositions(positionData.data);
        setStaffs(staffData.data);
      } catch (error) {
        console.error("Error fetching courses and years:", error);
      }finally{
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const handleNext = async () =>  {    
    const form = formRef.current; 

    const fileInput = form.querySelector('input[name="profile_picture"]');

    if (employee.profile_picture) {
      fileInput.removeAttribute('required');
    }

    const documentInput = form.querySelector('input[name="marriage_certificate"]');

    if (employee.marriage_certificate) {
      documentInput.removeAttribute('required');
    }

    if (form.checkValidity()) {
      navigate("/addEducationBackground");
    } else {
      form.reportValidity(); 
      alert('Please fill out all required fields.');
    }

    if (!employee.profile_picture) {
      fileInput.setAttribute('required', true);
    }

    if (!employee.marriage_certificate) {
      documentInput.setAttribute('required', true);
    }
  };
  const handleSwitchChange = (event) => {
    const isMarried = event.target.checked;
    if (isMarried) {
      dispatch(setEmployee({ is_married: 1 }));

    }else{
      dispatch(setEmployee({ is_married: 0 }));

    }
};

  


const handleFileChange = (e) => {
  const { name, files } = e.target;
  const file = files[0];

  if (file) {
    dispatch(setEmployee({ [name]: file })); // Dynamically dispatch based on the input name
  }
};


  return (
    <DashboardLayout>
      <Card>
        <MDBox
          borderRadius="lg"
          p={2}
          textAlign="center"
          position="relative"
        >
          <MDTypography variant="h4" fontWeight="medium">
            {editStatus ? 'Employee Edit Form':'Employee Create Form'}
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form"  id="employeeForm" ref={formRef} role="form">
            <MDTypography variant="h5" fontWeight="bold" gutterBottom>
              Personal Data
            </MDTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    First Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="first name"
                    name="first_name"
                    fullWidth
                    value={employee.first_name}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="last name"
                    name="last_name"
                    fullWidth
                    value={employee.last_name}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Grand Father Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Grand Father Name"
                    name="grand_father_name"
                    fullWidth
                    value={employee.grand_father_name}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                   Email <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="private address"
                    name="email"
                    fullWidth
                    value={employee.email}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Telephone <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="number"
                    label="phone"
                    name="phone"
                    fullWidth
                    value={employee.phone}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Mother Name <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Mother Name"
                            name="mother_name"
                            fullWidth
                            required
                            value={employee.mother_name}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Birth Place */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Birth Place <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Birth Place"
                            name="birth_place"
                            fullWidth
                            required
                            value={employee.birth_place}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Birth Date */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Birth Date <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="date"
                            name="birth_date"
                            fullWidth
                            required
                            value={employee.birth_date}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Gender */}
                <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                      Gender <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="Gender-label" sx={{ 
        backgroundColor: '#f5f5f5', 
        px: 1,
        my: 0.5,
        transform: 'translate(14px, -9px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        }
      }}>Select Gender</InputLabel>
                    <Select
                      labelId="Gender-label"
                      id="Gender-select"
                      fullWidth
                      label="Gender"
                      name="gender"
                      required
                      value={employee.gender}
                      onChange={handleInputChange}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: 1,
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "32px",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          sx={{
                            position: "absolute",
                            right: 8,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                </Grid>
               
                {/* Staff ID */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Resident City <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Resident City"
                            name="resident_city"
                            fullWidth
                            required
                            value={employee.resident_city}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Woreda */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Woreda <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Woreda"
                            name="woreda"
                            fullWidth
                            required
                            value={employee.woreda}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Kebele */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Kebele <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Kebele"
                            name="kebele"
                            fullWidth
                            required
                            value={employee.kebele}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* House Number */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            House Number <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="House Number"
                            name="house_number"
                            fullWidth
                            required
                            value={employee.house_number}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* House Phone */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            House Phone <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="tel"
                            label="House Phone"
                            name="house_phone"
                            fullWidth
                            required
                            value={employee.house_phone}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Is Married */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mt={4.3}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Is Married
                        </MDTypography>
                        <Switch
            checked={employee.is_married === 1} // Check if the value is 1 to set the switch state
            onChange={handleSwitchChange}
          />
                    </MDBox>
                </Grid>

                {/* Spouse Full Name */}
                {
                  employee.is_married === 1 ? (
                    <>
                    <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Spouse Full Name
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Spouse Full Name"
                            name="spouse_full_name"
                            fullWidth
                            value={employee.spouse_full_name}
                            onChange={handleInputChange}
                        />
                    </MDBox>                   
                </Grid>
                </>
                  ):(
                    ""
                  )
                }
              

                {/* Education Level */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Education Level <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Education Level"
                            name="education_level"
                            fullWidth
                            required
                            value={employee.education_level}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Emergency Full Name */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Emergency Full Name <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Emergency Full Name"
                            name="emergency_full_name"
                            fullWidth
                            required
                            value={employee.emergency_full_name}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Emergency Address */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Emergency Address <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="text"
                            label="Emergency Address"
                            name="emergency_address"
                            fullWidth
                            required
                            value={employee.emergency_address}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Emergency Phone */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Emergency Phone <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="tel"
                            label="Emergency Phone"
                            name="emergency_phone"
                            fullWidth
                            required
                            value={employee.emergency_phone}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Employment Date */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Employment Date <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="date"
                            // label="Employment Date"
                            name="employment_date"
                            fullWidth
                            required
                            value={employee.employment_date}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>

                {/* Employment Type */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Employment Type <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="Employment__Type">Employment Type</InputLabel>
                        <Select
                            label="Employment_Type"
                            name="employment_type"
                            fullWidth
                            required
                            value={employee.employment_type}
                            onChange={handleInputChange}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              padding: "10px",
                              borderRadius: 1,
                              "& .MuiSelect-select": {
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "32px",
                              },
                            }}
                            IconComponent={() => (
                              <ArrowDropDownIcon
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                        >
                           <MenuItem value="Full time">Full time</MenuItem>
                           <MenuItem value="Part time">Part time</MenuItem>
                           <MenuItem value="Contract">Contract</MenuItem>
                                          
                        </Select>
                      </FormControl>
                    </MDBox>
                </Grid>

                {/* Marriage Certificate*/}
                {
                  employee.is_married === 1 ? (<>
                     <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Marriage Certificate <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    style={{ display: "block", marginTop: 8 }}
                    name="marriage_certificate"
                    required
                    onChange={handleFileChange}
                  />
                  {
                    employee.marriage_certificate && (
                      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                       {  employee.marriage_certificate 
                       && <p>Uploaded marriage Certificate { employee.marriage_certificate.name}</p>
                       }
                    </MDTypography>
                    )
                  }
                  
                </MDBox>
              </Grid>
                  </>):(
                    ""
                  )
                }
             
                  
              {/* profile picture */}
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Profile Picture <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    style={{ display: "block", marginTop: 8 }}
                    name="profile_picture"
                    required
                    onChange={handleFileChange}
                  />
                  {
                    employee.profile_picture && (
                      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                       {  employee.profile_picture && <p>Uploaded  Profile Picture { employee.profile_picture.name}</p>}
                    </MDTypography>
                    )
                  }
                  
                </MDBox>
              </Grid>

                {/* Hours Per Week */}
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Hours Per Week <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="number"
                            label="Hours Per Week"
                            name="hours_per_week"
                            fullWidth
                            required
                            value={employee.hours_per_week}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={2}>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Salary <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                            type="number"
                            label="Salary"
                            name="salary"
                            fullWidth
                            required
                            value={employee.salary}
                            onChange={handleInputChange}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Select Position <span style={{ color: "red" }}>*</span>
          </MDTypography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="position-select-label">Select Position</InputLabel>
            <Select
              labelId="position-select-label"
              id="position-select"
              label="Select Position"
              fullWidth
              name="selectedPosition"
              required
              value={formData.selectedPosition}
              onChange={handleSelectChange}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                padding: "10px",
                borderRadius: 1,
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "32px",
                },
              }}
              IconComponent={() => (
                <ArrowDropDownIcon
                  sx={{
                    position: "absolute",
                    right: 8,
                    pointerEvents: "none",
                  }}
                />
              )}
            >
              {positions && positions.map((position) => (
                <MenuItem key={position.id} value={position.id}>
                  {position.position_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      </Grid>

      {/* Staff Select */}
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Select Staff <span style={{ color: "red" }}>*</span>
          </MDTypography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="staff-select-label">Select Staff</InputLabel>
            <Select
              labelId="staff-select-label"
              id="staff-select"
              label="Select Staff"
              fullWidth
              name="selectedStaff"
              required
              value={formData.selectedStaff}
              onChange={handleSelectChange}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                padding: "10px",
                borderRadius: 1,
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "32px",
                },
              }}
              IconComponent={() => (
                <ArrowDropDownIcon
                  sx={{
                    position: "absolute",
                    right: 8,
                    pointerEvents: "none",
                  }}
                />
              )}
            >
              {staffs && staffs.map((staff) => (
                <MenuItem key={staff.id} value={staff.id}>
                  {staff.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      </Grid>




                {/* Salary */}
  


                {/* Submit Button */}
            </Grid>
            {/* Buttons */}
            <MDBox
              mt={4}
              mb={1}
              display="flex"
              justifyContent="center"
              position="absolute"
              bottom={{ xs: -6, md: -4 }}
              right={76}
            >
              <MDButton type="submit" variant="gradient" color="info" onClick={handleNext} size="small" sx={{paddingY: -5 }}>
                Next
              </MDButton>
            </MDBox>
            </MDBox>
            </MDBox>

      </Card>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
}

export default Applications;
