import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import Switch from '@mui/material/Switch';
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const EditStaffDialog = ({ open, onClose, staff, onEdit }) => {
  const [modalData, setModalData] = React.useState(staff|| {});

  const token = localStorage.getItem("authToken");
  const [employees, setEmployees] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/employees");
        setEmployees(response.data); // Set the Employees data
      } catch (err) {
        console.error("Failed to fetch employees:", err);
      }
    };

    fetchData();
  }, []);


  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  };

  const handleModalSubmit = async () => {
    setLoading(true)
    try {
      const response = await axios.put(`${API_BASE_URL}/staff/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Staff Updated Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      console.log(error.reponse)
      // setError("An error occurred");
    } finally {
      setLoading(false); // Stop loading
    }
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>
      <AddIcon /> Add Staff
    </DialogTitle>
    <DialogContent>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Staff Name
        </MDTypography>
        <MDInput
          type="text"
          name="name"
          fullWidth
          required
          value={modalData.name}
          onChange={handleModalChange}
        // MUI error handling
        />
       
      </MDBox>

      

      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Description
        </MDTypography>
        <MDInput
          type="text"
          name="description"
          fullWidth
          required
          multiline
          rows={5}
          value={modalData.description}
          onChange={handleModalChange}
        
        />
      
      </MDBox>
      <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Is Taxable Free
          </MDTypography>
          <Switch
            checked={modalData.is_taxable_free === 1}
            onChange={(e) =>
              setModalData({
                ...modalData,
                is_taxable_free: e.target.checked ? 1 : 0,
              })
            }
          />
        </MDBox>
      <MDBox mb={2}>
      <Autocomplete
        options={employees}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        renderInput={(params) => <TextField {...params} label="Staff Head" />}
        onChange={(event, newValue) => {
          setModalData({
            ...modalData,
            staff_head: newValue ? newValue.id : null, // Set reports_to to the selected position's id
          });
        }}
        // value={getEmployeeById(value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </MDBox>

    

      
    </DialogContent>

    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleModalSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Cancel
      </MDButton>

    </DialogActions>
    <LoadingDialog open={loading} />
  </Dialog>
  )
}

export default EditStaffDialog