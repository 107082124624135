// Material Dashboard 2 React Components
import React, { useState,useRef } from "react";
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// @mui material components
import Grid from "@mui/material/Grid";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { updateStudentInfo } from "../../../redux/features/studentInfoSlice";
import { updateStudentEmployeementRecord } from "../../../redux/features/studentEmployeementRecordsSlice";
import { updateLanguage } from "../../../redux/features/languageSlice";
import { useDispatch, useSelector } from "react-redux";

function ApplicationsPage3() {
  const navigate = useNavigate();
  const student = useSelector((state) => state.studentInfo);
  const employementRecords = useSelector((state) => state.studentEmployeementRecords);
  const formRef = useRef(null);


  const dispatch = useDispatch();
  const sponsorType = [
    { id: "sponsor1", name: "Other Church or Org." },
    { id: "sponsor2", name: "Self-Sponsor" },
    { id: "sponsor3", name: "MesereteKristos College" },
  ];
  

  const [currentEmploymeentRecord, setCurrentEmploymeentRecord] = useState(employementRecords[0]);

  const languageData = useSelector((state) => state.languages); // Get language data from Redux store

  const handleLanguageChange = (event, index) => {
    const updatedLanguage = event.target.value;    
    const educationLevel = languageData[index].education_level;

    dispatch(updateLanguage({ education_level: educationLevel, 
      language: updatedLanguage }));
    
    }

  const handleBack = () => {
    navigate("/applicationPage2");
  };

  const handleEmployeeChange = (e) => {
    const { name, value } = e.target;
    setCurrentEmploymeentRecord({
      ...currentEmploymeentRecord,
      [name]: value,
    });
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateStudentInfo({ [name]: value }));
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    dispatch(updateStudentInfo({ sponsor_ship: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = e.target.files[0];
    if (files && files.length > 0) {
      if (name === "personal_testimony") {
        dispatch(updateStudentInfo({ personal_testimony: file }));
      } else if (name === "personal_intent") {
        dispatch(updateStudentInfo({ personal_intent: file }));
      }
    }
  };

  const handleNext = () => { 
    const form = formRef.current;  
    
    const fileInput = form.querySelector('input[name="personal_testimony"]');

    if (student.personal_testimony) {
      fileInput.removeAttribute('required');
    }

    
      const documentInput = form.querySelector('input[name="personal_intent"]');
    if (student.personal_intent) {
      documentInput.removeAttribute('required');
    }
   
    if (form.checkValidity()) {
      dispatch(updateStudentEmployeementRecord(currentEmploymeentRecord));
      navigate("/applicationPage4");

    } else {
      form.reportValidity(); 
      alert("fill all required filled")
    }

    if (!student.personal_testimony) {
      fileInput.setAttribute('required', true);
    }

    if (!student.personal_intent) {
      documentInput.setAttribute('required', true);
    }
  };
  
  return (
    <DashboardLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" id="studentForm3" role="form" ref={formRef}>
            <MDTypography variant="h6" fontWeight="semibold" gutterBottom>
              Sponsor
            </MDTypography>

            <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  Sponsor Type
                </MDTypography>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="sponsor-label" sx={{ 
        backgroundColor: '#f5f5f5', 
        px: 1,
        my: 0.5,
        transform: 'translate(14px, -9px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        }
      }}>Select Sponsor</InputLabel>
                  <Select
                    labelId="sponsor-label"
                    id="sponsor-select"
                    required
                    label="Sponsor"
                    defaultValue=""
                    value={student.sponsor_ship}
                    onChange={handleSelectChange}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: 1,
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "32px",
                      },
                    }}
                    IconComponent={() => (
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: 8,
                          pointerEvents: "none",
                          fontSize: "40px",
                        }}
                      />
                    )}
                  >
                    {sponsorType.map((sponsor) => (
                      <MenuItem key={sponsor.id} value={sponsor.name}>
                        {sponsor.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    required
                    label="sponser name"
                    name="sponsor_name"
                    fullWidth
                    value={student.sponsor_name}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Email <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="email"
                    label="sponser email"
                    name="sponsor_email"
                    required
                    fullWidth
                    value={student.sponsor_email}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Address <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="sponser address"
                    required
                    name="sponsor_address"
                    fullWidth
                    value={student.sponsor_address}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Telephone <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="number"
                    label="sponser telephone"
                    required
                    name="sponsor_phone"
                    fullWidth
                    value={student.sponsor_phone}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDTypography variant="h5" fontWeight="bold" gutterBottom>
                Testimony
              </MDTypography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      Personal Testimony of your spiritual life
                      <span style={{ color: "red" }}>*</span>
                    </MDTypography>
                    <input
                      type="file"
                      required
                      name="personal_testimony"
                      accept=".jpg,.jpeg,.png,.pdf"
                      style={{ display: "block", marginTop: 8 }}
                      onChange={handleFileChange}
                    />
                    {student.personal_testimony.name && (
                      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                        Selected File: {student.personal_testimony.name}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      Personal Intent (Why you want to study in this institution)
                      <span style={{ color: "red" }}>*</span>
                    </MDTypography>
                    <input
                      type="file"
                      required
                      name="personal_intent"
                      accept=".jpg,.jpeg,.png,.pdf"
                      style={{ display: "block", marginTop: 8 }}
                      onChange={handleFileChange}
                    />
                    {student.personal_intent.name && (
                      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                        Selected File: {student.personal_intent.name}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h5" fontWeight="bold" gutterBottom>
                Employment History
              </MDTypography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      Employer Name <span style={{ color: "red" }}>*</span>
                    </MDTypography>
                    <MDInput
                      type="text"    
                      label="employer name"
                      name="employer_name"
                      fullWidth
                      value={currentEmploymeentRecord.employer_name}
                      onChange={handleEmployeeChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Employer Address <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="employer address"
                    name="employer_address"
                    fullWidth
                    value={currentEmploymeentRecord.employer_address}
                    onChange={handleEmployeeChange}
                  />
                  </MDBox>

                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Job Title <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="job title"
                    name="job_title"
                    fullWidth
                    value={currentEmploymeentRecord.job_title}
                    onChange={handleEmployeeChange}
                  />
                </MDBox>

                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      Employment Start Date <span style={{ color: "red" }}>*</span>
                    </MDTypography>
                    <MDInput
                      type="date"
                      lable="start date"
                      name="employment_start_date"
                      fullWidth
                      value={currentEmploymeentRecord.employment_start_date}
                      onChange={handleEmployeeChange}
                    />
                  </MDBox>

                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      Employment End Date <span style={{ color: "red" }}>*</span>
                    </MDTypography>
                    <MDInput
                      type="date"
                      lable="end date"
                      name="employment_end_date"
                      fullWidth
                      value={currentEmploymeentRecord.employment_end_date}
                      onChange={handleEmployeeChange}
                    />
                  </MDBox>

                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      Job Responsibilities <span style={{ color: "red" }}>*</span>
                    </MDTypography>
                    <MDInput
                      type="text"
                      lable="job responsibilities"
                      name="job_responsibilities"
                      fullWidth
                      value={currentEmploymeentRecord.job_responsibilities}
                      onChange={handleEmployeeChange}
                    />
                  </MDBox>

                </Grid>

                </Grid>
              </Grid>

            <MDBox mb={3}>
              <MDTypography variant="h5" fontWeight="bold" gutterBottom>
                Language Proficiency
              </MDTypography>
              <Grid container spacing={3}>
                {languageData.map((language, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        {language.education_level} <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      {language.education_level === "Do you use English in your work" ? (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel id={`english-label-${index}`} >Yes/No</InputLabel>
                          <Select
                            labelId={`english-label-${index}`}
                            id={`english-select-${index}`}
                            name="English"
                            required
                            label="English Language"
                            value={language.language}
                            onChange={(e) => handleLanguageChange(e, index)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              padding: "10px",
                              borderRadius: 1,
                              "& .MuiSelect-select": {
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "32px",
                              },
                            }}
                            IconComponent={() => (
                              <ArrowDropDownIcon
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  pointerEvents: "none",
                                  fontSize: "40px",
                                }}
                              />
                            )}
                          >
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        <MDInput
                          type="text"
                          required
                          name={language.education_level}
                          label={language.education_level}
                          fullWidth
                          value={language.language}
                          onChange={(e) => handleLanguageChange(e, index)}
                        />
                      )}
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>


            <MDBox mt={4} mb={3} textAlign="right">
              <MDButton variant="outlined" color="info" onClick={handleBack}>
                Back
              </MDButton>
              <MDButton variant="contained" color="info" onClick={handleNext} style={{ marginLeft: "16px" }}>
                Next
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default ApplicationsPage3;
