import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import { axiosInstance } from "api2";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState,useCallback } from "react";
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { Card,CardContent } from '@mui/material';
import MDButton from 'components/MDButton';
import RequestWithdraw from './Request';
import ViewRequest from './ViewRequest';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingDialog from 'components/Loading/LoadingDialog';


const WithdrawRequest = () => {
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [selectedRequest, setSelectedRequest] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  // const [openEdit,setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
 const role = localStorage.getItem("role");
  const [requests,setRequests] = useState([]);


  const fetchWithdrawRequests = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/withdrawal-request`);

      console.log("response:",response.data)
      
      setWithdrawRequests(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[]);
  useEffect(() => {
   
    fetchWithdrawRequests();
  }, [fetchWithdrawRequests]);

  

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenView(false);
    
  };

  const handleAddRequest = () => {
    fetchWithdrawRequests();
  };

  const handleViewDetails = (data) => {
    console.log("data",data)
   
    setRequests(data)
    setOpenView(true);

    
  }

  const handleViewRequests = () => {
    fetchWithdrawRequests();
  }

  const columns = [
    {
      accessor: 'action',
      Header: '',
      width: 40,
      padding: 0,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="request-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => {
              handleViewDetails(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    { Header: 'Request Date', accessor: 'request_date', showSortIcons: false },
    { Header: 'Semester', accessor: 'semester',showSortIcons: false },
    { Header: 'Year', accessor: 'year',showSortIcons: false },
    {
      Header: 'Registrar Name',
      accessor: row => row.registrar ? `${row.registrar.first_name} ${row.registrar.last_name}` : 'Not Approved',
      id: 'registrarName',
      showSortIcons: false,
  },

  // Advisor Details
  {
      Header: 'Academic Dean',
      accessor: row => row.advisor ? `${row.advisor.first_name} ${row.advisor.last_name}` : 'Not Approved',
      id: 'advisorName',
      showSortIcons: false,
  },
    { Header: 'Full Semester',showSortIcons: false, accessor: 'is_full_semester', Cell: ({ value }) => (value ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />) },
    
  ];



  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography my={2} variant="h5" fontWeight="medium">
              Withdrawal Requests
            </MDTypography>
            <MDBox>

    <DataTable
      table={{
        columns,
        rows: withdrawRequests,
      }}
      canSearch
      sortable
      rowsPerPage={5}
      showPagination
      widgets={role === "student" && (
        <MDBox mx={2}>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={() => setOpenAdd(true)}
            sx={{ background: "#00274D", width: "100%" }}
          >
            <span>Add</span>
          </MDButton>
        </MDBox>
      )}
    />
   
              {!loading && withdrawRequests.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No Request available
          </MDTypography>
        )}
 

 

            </MDBox>
          </MDBox>
          <RequestWithdraw open={openAdd} onClose={handleCloseDialog} onAdd={handleAddRequest} />
          {
            requests &&  (
              <ViewRequest openView={openView} onClose={handleCloseDialog} data={requests} onView={handleViewRequests}/>
            )
          }
          <LoadingDialog open={loading} />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default WithdrawRequest;
