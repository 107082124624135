import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MDBox from 'components/MDBox';
import { Card, CardContent } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AttendanceList from './attendanceList';
import AttendanceForm from './attendanceForm';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InstructorAttendance = () => {
  const location = useLocation();
  const active = location.state?.active || 0;
  const [value, setValue] = useState(active);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTab = (label, index) => (
    <Tab
      label={
        value === index ? (
          <span style={{ color: 'white' }}>{label}</span>
        ) : (
          label
        )
      }
      {...a11yProps(index)}
    />
  );

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#D4AF37",
                }
              }}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, 
                "& .MuiTab-root": {
                  flex: 1, 
                  margin: { xs: '4px 0', sm: '0 8px' }, 
                },
                "& .MuiTab-root:last-child": {
                  marginRight: 0, 
                },
              }}
            >
              {renderTab("Attendance Form", 0)}
              {renderTab("Attendance List", 1)}
            </Tabs>
          </MDBox>
          <CustomTabPanel value={value} index={0}>
            <AttendanceForm />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AttendanceList />
          </CustomTabPanel>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default InstructorAttendance;
