import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import swal from "sweetalert";
import { axiosInstance } from "api2";
import Switch from '@mui/material/Switch';
import LoadingDialog from 'components/Loading/LoadingDialog';

const EditRemuneration = ({ open, onClose, remuneration, onEdit }) => {
  const [remunerationData, setRemunerationData] = useState({
    title: "",
    amount: "",
    is_percentage: false,
    is_addition: false,
  });
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    if (remuneration) {
      setRemunerationData(remuneration);
    }
  }, [remuneration]);

  useEffect(() => {
    // Fetch employees data
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get('/employees');
        
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    fetchEmployees();
  }, []);



  const handleLoanDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRemunerationData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleLoanSubmit = async () => {
    try {
      await axiosInstance.put(`/remunerations/${remuneration.id}`, remunerationData);
      swal("Success", "Remuneration updated successfully", "success");
      onEdit();
      onClose();
    } catch (error) {
      swal("Error", "Failed to update the remuneration", "error");
      console.error("Error updating remuneration:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign="center">
      Edit Remuneration
    </DialogTitle>
    <DialogContent>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Employee
        </MDTypography>
        <Autocomplete
          fullWidth
          options={employees}
          getOptionLabel={(option) => `${option.first_name} ${option.last_name}` || ''}
          onChange={(event, newValue) => setSelectedEmployee(newValue)}
          value={selectedEmployee}
          renderInput={(params) => <TextField {...params} label="Employee" />}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Title
        </MDTypography>
        <MDInput
          type="text"
          name="title" // Updated to match JSON structure
          fullWidth
          required
          value={remunerationData.title}
          onChange={handleLoanDataChange}
        />
      </MDBox>

      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
         Amount
        </MDTypography>
        <MDInput
          type="number"
          name="amount" // Updated to match JSON structure
          fullWidth
          required
          value={remunerationData.amount}
          onChange={handleLoanDataChange}
        />
      </MDBox>


      {/* Switch for is_percentage */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Is Percentage
        </MDTypography>
        <Switch
          checked={remunerationData.is_percentage}
          onChange={(e) =>
            setRemunerationData({
              ...remunerationData,
              is_percentage: e.target.checked,
            })
          }
        />
      </MDBox>

      {/* Switch for is_addition */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Is Addition
        </MDTypography>
        <Switch
          checked={remunerationData.is_addition}
          onChange={(e) =>
            setRemunerationData({
              ...remunerationData,
              is_percentage: e.target.checked,
            })
          }
        />
      </MDBox>
    </DialogContent>
    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleLoanSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Close
      </MDButton>

    </DialogActions>
    <LoadingDialog loading={loading} />
  </Dialog>
  );
};

export default EditRemuneration;
