import { Box, Card, CardContent, Divider, Tab, Tabs, useMediaQuery, Menu, MenuItem } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useEffect, useState } from 'react';
import Address from './Address';
import EducationalBackground from './EducationalBackground';
import FamilyBackground from './familyBackground';
import PersonalBackground from './personalBackground';
import AdditionDeduction from './Addition-Deduction';
import Payroll from './payroll';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EmployeementDetail = () => {
  const [value, setValue] = useState(0);
  const [employees, setEmployees] = useState({});
  const { id } = useParams();
  const token = localStorage.getItem("authToken");

  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const isExtraSmallScreen = useMediaQuery('(max-width:600px)');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/employees/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployees(response.data);
      } catch (err) {
        // Handle error
      }
    };
    fetchEmployees();
  }, [id, token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index) => {
    setValue(index);
    handleMenuClose();
  };

  const renderTab = (label, index) => (
    <Tab
      label={value === index ? <span style={{ color: 'white' }}>{label}</span> : label}
      {...a11yProps(index)}
    />
  );


  const tabsToRender = isExtraSmallScreen ? 2 : isSmallScreen ? 4 : 6;

  const tabsData = [
    { label: "Personal Background", component:  <PersonalBackground
      firstName={employees.first_name}
      lastName={employees.last_name}
      motherName={employees.mother_name}
      nationality={employees.nationality}
      birthDate={employees.birth_date}
      grandFatherName={employees.grand_father_name}
      birthPlace={employees.birth_place}
    />},
    { label: "Family Background", component:  <FamilyBackground 
      marriageStatus={employees.is_married}
      motherName={employees.mother_name}
      spouseFullName={employees.spouse_full_name}
      isMarried={employees.is_married}
      emergencyFullName={employees.emergency_full_name}
      emergencyAddress={employees.emergency_address}
      emergencyPhone={employees.emergency_phone}
      phone={employees.phone}
      kids={employees.kids}
  /> },
    { label: "Address", component:  <Address 
      residentCity={employees.resident_city}
      woreda={employees.woreda}
      kebele={employees.kebele}
      houseNumber={employees.house_number}
      housePhone={employees.house_phone}
    />  },
    { label: "Educational Background", component: <EducationalBackground
      educationalBackground={employees.educational_backgrounds} 
    />},
    { label: "Payroll", component: <Payroll id={id}/> },
    { label: "Addition/Deduction", component: <AdditionDeduction id={id}/> },
  ];

  return (
    <DashboardLayout>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <MDBox display="flex" alignItems="center" gap={4} flexDirection={{ xs: 'column', sm: 'row' }}>
            <img
              src={`${STORAGE_BASE_URL}/${employees.profile_picture}`}
              alt="Profile"
              style={{
                width: '170px',
                height: '170px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
            <MDBox>
              <MDTypography variant="body2">
                <strong>Name:</strong> {employees?.first_name} {employees?.last_name}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Employee Id:</strong> {employees.id}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Position:</strong> {employees.position?.position_title}
              </MDTypography>
              <MDTypography variant="body2">
                <strong>Telephone:</strong> {employees?.phone}
              </MDTypography>
            </MDBox>
          </MDBox>
        </CardContent>
      </Card>

      <Divider sx={{ mb: 2 }} />

      <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="responsive tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: { backgroundColor: '#00274D' },
          }}
        >
          {tabsData.slice(0, tabsToRender).map((tab, index) => renderTab(tab.label, index))}
          {tabsToRender < tabsData.length && (
            <Tab icon={<MoreHorizIcon />} aria-label="more" onClick={handleMenuOpen} />
          )}
        </Tabs>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {tabsData.slice(tabsToRender).map((tab, index) => (
            <MenuItem key={index + tabsToRender} onClick={() => handleMenuItemClick(index + tabsToRender)}>
              {tab.label}
            </MenuItem>
          ))}
        </Menu>
      </MDBox>

      {tabsData.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </DashboardLayout>
  );
};

export default EmployeementDetail;
