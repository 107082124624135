import React, { useEffect, useState,useCallback } from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from "api2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';

const Marklists = () => {
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState(null);
  const [courses, setCourses] = useState([]);
  const [markList, setMarkList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const [coursesData, yearsData,semestersData] = await Promise.all([
          axiosInstance.get(`/courses`),
          axiosInstance.get('/years'),
          axiosInstance.get('/semesters'),
        ]);

        setCourses(coursesData.data);
        setYears(yearsData.data);
        setSemesters(semestersData.data);
      } catch (error) {
        console.error("Error fetching courses and years:", error);
      }
    };
    setLoading(false);

    fetchData();
  }, []);

  const fetchMarkList = useCallback(async (modalData) => {
    try {
      const response = await axiosInstance.get(`/student/marklist`, {
        params: modalData
      });
      setMarkList(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[]);
  

  // Auto-submit when all fields are selected
  useEffect(() => {
    if (selectedCourse && selectedYear && selectedSemester) {
      const modalData = {
        course_id: selectedCourse.id,
        year: selectedYear.year_name,
        semester: selectedSemester.semester_code,
      };
      fetchMarkList(modalData);
      
    }
  },[fetchMarkList,selectedCourse,selectedYear,selectedSemester]);

  const generateScoreColumns = (scoreFields, prefix) => {
    return Object.keys(scoreFields).map((key) => ({
      Header: `${prefix} ${key.replace('_', ' ')}`,
      accessor: `${prefix === 'Assignment' ? 'assignment_scores' : 'scores'}.${key}`,
      showSortIcons: false,
    }));
  };
  const sampleMark = markList.length > 0 ? markList[0] : {
    scores: {

    },
    assignment_scores: {

    },
  };



  const baseColumns = [
    {
      Header: 'Student Name',
      accessor: (row) => `${row.student_name.first_name} ${row.student_name.last_name}`,
    },

    {
      Header: 'Total',
      accessor: 'total',
      showSortIcons: false,
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      showSortIcons: false,
    },
  ];

  const columns = [
    ...baseColumns,
    ...generateScoreColumns(sampleMark.scores, 'Score'),
    ...generateScoreColumns(sampleMark.assignment_scores, 'Assignment'),
  ];

  return (
    <DashboardLayout>
        <Card>
            <CardContent>
                <MDBox my={2}>
                  <MDTypography variant="h5" fontWeight="medium" my={2}>                  
                    Marklist
                  </MDTypography>
                  <MDBox>
                    <DataTable
                      table={{
                        columns,
                        rows: markList,
                      }}
                      canSearch={true}
                      sortable={true}
                      rowsPerPage={5}
                      showPagination
                      widgets={
                        <MDBox display="flex">
                          {/* Course Autocomplete */}
                          <Autocomplete
                            fullWidth
                            sx={{ mx: 2, my: 2, width: '200px' }}
                            options={courses}
                            getOptionLabel={(option) => option.course_name || ''}
                            onChange={(event, newValue) => setSelectedCourse(newValue)}
                            value={selectedCourse}
                            renderInput={(params) => <TextField {...params} label="Course" />}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}> {/* Use a unique identifier as the key */}
                                  {option.course_name}
                              </li>
                          )}
                          />
                          {/* Year Autocomplete */}
                          <Autocomplete
                            fullWidth
                            sx={{ mx: 2, my: 2, width: '200px' }}
                            options={years}
                            getOptionLabel={(option) => option.year_name || ''}
                            onChange={(event, newValue) => setSelectedYear(newValue)}
                            value={selectedYear}
                            renderInput={(params) => <TextField {...params} label="Year" />}
                          />
                          {/* Semester Autocomplete */}
                          <Autocomplete
                            fullWidth
                            sx={{ mx: 2, my: 2, width: '200px' }}
                            options={semesters}
                            getOptionLabel={(option) => option.semester_code || ''}
                            onChange={(event, newValue) => setSelectedSemester(newValue)}
                            value={selectedSemester}
                            renderInput={(params) => <TextField {...params} label="Semester" />}
                          />
                        </MDBox>
                      }
                    />
                    {!loading && markList?.length === 0 && (
                      <MDTypography variant="h5" display="block" align="center" my={2} sx={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "#9e9e9e",
                        opacity: 0.7,
                      }}>
                        No data available
                      </MDTypography>
                    )}
                  </MDBox>
                  <LoadingDialog open={loading} />
                </MDBox>
            </CardContent>
        </Card>
    </DashboardLayout>
  );
};

export default Marklists;
