import React,{useState,useEffect,useCallback} from "react";
import { Grid, Card, CardContent, Button, Icon} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import axios from "axios";
import API_BASE_URL from "api";
import PostAnnouncement from "./postAnnouncement";
import EditAnnouncement from "./editAnnouncement";
import { checkPermission,fetchAndStorePermissions } from "utils/checkPermission";
import LoadingDialog from "components/Loading/LoadingDialog";


const Announcement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);

  const token =  localStorage.getItem("authToken");
  const targetId = localStorage.getItem("targetId");
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  useEffect(() => {
    const initializePermissions = async () => {
      await fetchAndStorePermissions();
    };
    initializePermissions();
  }, []);

  const fetchAnnouncements = useCallback(async () => {
    try{
    const response = await axios.get(`${API_BASE_URL}/announcements`,{
      headers:{
        "Authorization":`Bearer ${token}`
      }
    });
    setAnnouncements(response.data)
  }catch(err){
    console.log("err:",err)
  }finally{
    setLoading(false)
  }
  },[token]);



  useEffect(() => {
    fetchAnnouncements()
  },[fetchAnnouncements])

  const formattedDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const handleOpenEdit = (announcement) => {
    setSelectedAnnouncement(announcement);
    setOpenEdit(true);

  };


  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
  };

  const handleAddAnnouncement = () => {
    fetchAnnouncements()
  };

  const handleEditAnnouncement = () => {
    fetchAnnouncements()
  };


  return (
    <DashboardLayout>
     
      <MDBox p={3} sx={{height:'100vh',backgroundColor:'#fff'}}>
     
            <MDBox 
      display="flex" 
      justifyContent={{ xs: "center", sm: "space-between" }} 
      alignItems="center" 
      flexDirection={{ xs: "column", sm: "row" }} 
      my={2}
    >
      <MDTypography 
        variant="h3" 
        fontWeight="medium" 
        textAlign={{ xs: "center", sm: "left" }} 
        mb={{ xs: 2, sm: 0 }}
      >
        Announcements
      </MDTypography>
    
      {checkPermission('announcement-create') &&

      <Button
        variant="contained"
        size="small"
        color="white"
        sx={{
          borderRadius: 5,
          px: 3,
          backgroundColor: '#00274D',
          '&:hover': {
            backgroundColor: '#001F3F'
          }
        }}
        onClick={() => setOpenAdd(true)}
      >
        <Icon fontSize="small" sx={{ marginRight: 1, color: "#fff" }}>add</Icon>
        <span style={{ color: "#fff" }}>Post Announcement</span>
      </Button>
}
    </MDBox>

    <Grid container spacing={3} mt={1}>
  {announcements && announcements.map((announcement, index) => (
    <Grid item xs={12} sm={6} key={index}>
      <Card
         sx={{
          borderRadius: '16px 16px 16px 4px', // Rounded corners for message shape
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
          position: 'relative',
          transition: 'transform 0.2s ease-in-out',
          backgroundColor: '#f3f4f6', // Light background color for message box look
          padding: 2,
          '&:hover': {
            transform: 'scale(1.02)',
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 16, // Position the tail
            bottom: -30, // Position lower to increase length
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '40px 16px 0 16px', // Make the tail longer by increasing this value
            borderColor: '#f3f4f6 transparent transparent transparent',
            transform: 'rotate(11deg)', // Angle of the tail
          }
        }}
      >
        <CardContent>
          <MDTypography variant="h4" textAlign="center" fontWeight="semibold">
            {announcement.title}
          </MDTypography>
          <MDTypography variant="body2" fontWeight="medium">
            {announcement.content}
          </MDTypography>
          <MDTypography variant="body2" fontWeight="medium" my={1}>
            {formattedDate(announcement.announcement_date)}
          </MDTypography>
          <MDTypography variant="body2" fontWeight="medium">
            {announcement.user.full_name}
          </MDTypography>
        </CardContent>
        
        {/* <Button
          variant="contained"
          size="small"
          color="white"
          sx={{
            position: 'absolute',
            bottom: 42,
            right: 16,
            borderRadius: 5,
            fontSize: '0.5rem',
            px: 1.5,
            py: 0.5,
            backgroundColor: '#00274D',
            '&:hover': {
              backgroundColor: '#001F3F',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Adds shadow on hover
            }
          }}
        >
          <span style={{ color: "#fff", fontSize: '0.75rem' }}>View Details</span>
        </Button> */}

        {announcement.announced_by === Number(targetId) && (
          <Button
            variant="contained"
            size="small"
            color="white"
            sx={{
              position: 'absolute',
              bottom: 9,
              right: 16,
              borderRadius: 5,
              fontSize: '0.75rem',
              px: 1.5,
              py: 0.4,
              backgroundColor: '#00274D',
              '&:hover': {
                backgroundColor: '#001F3F',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
              }
            }}
            onClick={() => handleOpenEdit(announcement)}
          >
            <span style={{ color: "#fff" }}>Edit</span>
          </Button>
        )}
      </Card>
    </Grid>
  ))}
</Grid>



    <PostAnnouncement open={openAdd} onClose={handleCloseDialog} onAdd={handleAddAnnouncement} />
    {selectedAnnouncement && (
                  <EditAnnouncement
                    open={openEdit}
                    onClose={handleCloseDialog}
                    announcement={selectedAnnouncement}
                    onEdit={handleEditAnnouncement}
                  />
                )}
        <LoadingDialog open={loading}/>

      </MDBox>
    </DashboardLayout>
  );
}

export default Announcement;
