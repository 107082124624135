import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SidenavCollapse from './SidenavCollapse';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  padding: theme.spacing(1.5, 4),
  color: "#fff",
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '2rem',
  fontSize: '1.25rem',
  display: 'flex',
  alignItems: 'center',
  color: "#fff",
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  span: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
 
  '&.active': {
    '& .MuiListItem-root': {
      backgroundColor: "#3e98ef",
    },
  },
}));

const SubRoutesList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(4),

}));

function SidenavNestedItem({ name, icon, route, subroutes }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledListItem button onClick={handleClick}>
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <StyledListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SubRoutesList component="div" disablePadding>
          {subroutes.map((subroute) => (
            <StyledNavLink key={subroute.key} to={`${route}${subroute.route}`}>
              <SidenavCollapse
                name={subroute.name}
                icon={subroute.icon}
                active={false}
                noCollapse={true}
              />
            </StyledNavLink>
          ))}
        </SubRoutesList>
      </Collapse>
    </>
  );
}

export default SidenavNestedItem;