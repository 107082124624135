import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const AddRoleDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    name: "",
    permission: []
  });
  const [fetchedPermissions, setFetchedPermissions] = useState([]);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axiosInstance.get("/permissions");
        setFetchedPermissions(response.data);
      } catch (err) {
        console.error("Failed to fetch permissions:", err);
      }
    };

    fetchPermissions();
  }, []);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  };
  const handlePermissionChange = (permissionName) => {
    setModalData((prevData) => {
      const permissionExists = prevData.permission.includes(permissionName);
      const updatedPermissions = permissionExists
        ? prevData.permission.filter((name) => name !== permissionName) 
        : [...prevData.permission, permissionName]; 
  
      return { ...prevData, permission: updatedPermissions };
    });
  };
  
  
  const handleModalSubmit = async () => {
    setLoading(true);
   
    
    try {
      const response = await axios.post(`${API_BASE_URL}/roles`, modalData, {
        headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` }
      });
      
     
        
     
      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Role Created Successfully!",
          icon: "success"
        });
        setModalData({
          name: "",
          permission: []
        });
      }
    } catch (error) {
      console.log("errors:", error.response?.data?.errors);
    }finally {
      setLoading(false); // Stop loading
    }


  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
    <DialogTitle textAlign={"center"}><AddIcon /> Add Role</DialogTitle>
    <MDBox position="relative">
      <MDBox
        sx={{
          filter: loading ? 'blur(1px)' : 'none',
          transition: 'filter 0.3s ease',
        }}
      >
        <DialogContent>
          <MDBox mb={2}>
            <MDTypography variant="body2" color="text" fontWeight="medium">
              Role Name
            </MDTypography>
            <MDInput
              type="text"
              name="name"
              required
              value={modalData.name}
              onChange={handleModalChange}
              sx={{width:"60%"}}
            />
          </MDBox>
  
          <MDBox my={3}>
            <MDTypography variant="h6" color="text" fontWeight="medium">
              Add Permissions
            </MDTypography>
            <Grid container spacing={2}>
              {fetchedPermissions.map((permission) => (
                <Grid item xs={12} sm={6} md={3} key={permission.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modalData.permission ? modalData.permission.includes(permission.name) : false}
                        onChange={() => handlePermissionChange(permission.name)}
                      />
                    }
                    label={permission.name}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleModalSubmit}
          >
            Submit
          </MDButton>
          <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </MDBox>
      <LoadingDialog open={loading} />
     
    </MDBox>
  </Dialog>
  );
};

export default AddRoleDialog;