import React, { useState, useEffect,useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Autocomplete, TextField } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import API_BASE_URL from "api";
import swal from "sweetalert";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { axiosInstance } from "api2";
import LoadingDialog from "components/Loading/LoadingDialog";

const EditAssessment = ({ open, onClose, assessment, onEdit }) => {
    const [loading,setLoading] = useState(false)
    const targetId = localStorage.getItem("targetId");
    const token = localStorage.getItem("authToken");
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [modalData, setModalData] = useState({
   
        assigned_students: [],
        assessment_name: "",
        due_date: "",
        assessment_type: "",
        file: null, // Add file field here
        mark:0,
        remark:""
      });


      useEffect(() => {
        if (assessment) {
          setModalData({
            assigned_students: assessment.assigned_students || [], // Assuming assigned_students is part of the assessment data
            assessment_name: assessment.assessment_name || "",
            due_date: assessment.due_date || "",
            assessment_type: assessment.assessment_type || "",
            file: assessment.filePath || null, // If you want to handle file differently, you can modify this
            mark: assessment.mark || "",
            remark: assessment.remark || ""
          });
    
          // Pre-select the course if the assessment has course data
          const foundCourse = courses.find(course => course.id === assessment.course.id);
          setSelectedCourse(foundCourse || null);
        }
      }, [assessment,courses]); 



      useEffect(() => {
        const fetchData = async () => {
          try {
            const [coursesData] = await Promise.all([
              axiosInstance.get(`/instructor/${targetId}/courses`, {
                headers: { Authorization: `Bearer ${token}` },
              }),
            ]);
            const coursesArray = coursesData.data[0].course_history.map(course => ({
              id: course.course_id,
              name: course.course?.course_name || 'Unknown Course',
            }));
            
            setCourses(coursesArray);
          } catch (error) {
            console.error("Error fetching courses:", error);
          }
        };
    
        fetchData();
      }, [targetId, token]);

      const fetchStudents = useCallback(async (courseId) => {
        try {
          const response = await axios.get(`${API_BASE_URL}/instructor/${targetId}/students`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
      
          const courseHistory = response.data[0].course_history;
    
    
      
          // Filter students based on selected course ID
          const studentsForSelectedCourse = courseHistory
            .filter(course => course.course_id === courseId)
            .flatMap(course => course.registrations.map(registration => registration.student.id));
    
      
            setModalData(modalData => ({ ...modalData, assigned_students: studentsForSelectedCourse }));
    
        } catch (err) {
          console.error(err);
        } finally {
        }
      },[token,targetId]);
      
    
      useEffect(() => {
        if (selectedCourse) {
          fetchStudents(selectedCourse.id); // Fetch students for the selected course
        }
      },[fetchStudents,selectedCourse]);

      const handleModalChange = (e) => {
        const { name, value } = e.target;
    
        setModalData({
          ...modalData,
          [name]: value,
        });
      };
      
    
      const handleFileChange = (e) => {
        setModalData({
          ...modalData,
          file: e.target.files[0], // Store the selected file
        });
      };



  const handleModalSubmit = async () => {
  
    setLoading(true)
    const formData = new FormData();
   
    formData.append("course_id", Number(selectedCourse?.id )|| "");
    formData.append("instructor_id", Number(targetId) || "");
    modalData.assigned_students.forEach((studentId) => {
      formData.append("assigned_students[]", Number(studentId));
    });
    formData.append("assessment_name", modalData.assessment_name);
    formData.append("assessment_type", modalData.assessment_type);
    formData.append("mark", modalData.mark);
    
    formData.append("due_date", modalData.due_date);
  
    // formData.append("file_path", modalData.file); // Add file to formData
    if (modalData.file) {
      formData.append("file_path", modalData.file); // Add file to formData if present
    }


    try {
      
    
      const response = await axios.post(
        `${API_BASE_URL}/assessments/${assessment.id}/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        }
      );
      
      if(response.status === 200){
        onEdit();
        onClose();
        swal({
          title: "Assessment Updated Successfully!",
          icon: "success",
        });
        
      }
    
    } catch (error) {
      console.error("Error submitting assessment:", error);
    } finally{
      setLoading(false)
    }
  };



  return (
    <DashboardLayout>
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}>
        Edit Assessment
      </DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <Autocomplete
            fullWidth
            sx={{ my:2}}
            options={courses}
            getOptionLabel={(option) => option.name || ''}
            onChange={(event, newValue) => setSelectedCourse(newValue)}
            value={selectedCourse}
            renderInput={(params) => <TextField {...params} label="Course" />}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Assessment Name
          </MDTypography>
          <MDInput
            type="text"
            name="assessment_name"
            fullWidth
            required
            value={modalData.assessment_name}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
  <MDTypography variant="caption" color="text" fontWeight="medium">
    Mark
  </MDTypography>
  <MDInput
    type="number" // Changed to number type
    name="mark"
    fullWidth
    required
    value={modalData.mark}
    onChange={(e) => handleModalChange({ target: { name: 'mark', value: Number(e.target.value) } })} // Ensure the value is treated as a number
  />
</MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Remark
          </MDTypography>
          <MDInput
            type="text"
            name="remark"
            fullWidth
            required
            value={modalData.remark}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Due Date
          </MDTypography>
          <MDInput
            type="date"
            name="due_date"
            fullWidth
            required
            value={modalData.due_date}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
                  <FormControl fullWidth>
                      <InputLabel id="assessment_type">Assessment Type</InputLabel>
                      <Select
                      labelId="assessment_type"
                      id="assessment_type"
                      name="assessment_type"
                      value={modalData.assessment_type}
                      label="Correct Answer"
                      sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#f5f5f5",
                          padding: "10px",
                          borderRadius: 1,
                          "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "32px",
                          },
                      }}
                      IconComponent={() => (
                          <ArrowDropDownIcon
                          sx={{
                              position: "absolute",
                              right: 8,
                              pointerEvents: "none",
                          }}
                          />
                      )}
                      onChange={handleModalChange}
                      >
                      <MenuItem value="quiz">Thesis</MenuItem>
                      <MenuItem value="individual">Individual</MenuItem>
                      <MenuItem value="group">Group</MenuItem>
                      <MenuItem value="work_sheet">Work Sheet</MenuItem>
                      </Select>
                  </FormControl>
                  </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Upload File
          </MDTypography>
          <MDInput
            type="file"
            name="file"
            fullWidth
            onChange={handleFileChange}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  </DashboardLayout>
  )
}

export default EditAssessment