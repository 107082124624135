import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState,useCallback } from "react";
import { Card,CardContent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {Box} from "@mui/material";
import ViewEmployeesPayroll from './viewEmployeePayroll';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import LoadingDialog from 'components/Loading/LoadingDialog';


const FinancePayroll = () => {
    const [search] = useState('');
    const token = localStorage.getItem("authToken");
    const [openView, setOpenView] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [payrolls, setPayrolls] = useState([]);
    const [employeesPayroll,setEmployeesPayroll] = useState([]);
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        let timer;
        if (showAlert) {
            timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
    }, [showAlert]);

    const fetchPayrolls = useCallback(async () => {
    setLoading(true);
      try {
            const response = await axios.get(`${API_BASE_URL}/payrolls`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
           
            setPayrolls(response.data);
        } catch (err) {
            console.error(err);
        }finally{
          setLoading(false);
        }
    },[token]);
    useEffect(() => {
        fetchPayrolls();
    }, [fetchPayrolls]);


    const filteredData = payrolls.filter(payroll => {
        return (
            payroll.remark.toLowerCase().includes(search.toLowerCase())
        );
    });

    const handleViewDetails = (data) => {
   
      setEmployeesPayroll(data.employee_payrolls)
  
      setOpenView(true);
      
    }
   

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const handleCloseDialog = () => {

      setOpenView(false);

  };

    
    const columns = [
      {
        accessor: 'action',
        Header: '',
        width: 40,
        padding: 0,
        Cell: ({ row }) => (
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                            <MoreVertIcon />
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            
                            <MenuItem onClick={() => {
          handleViewDetails(row.original);
          popupState.close();
        }} style={{ color: '#1976d2' }}>
          <VisibilityIcon style={{ marginRight: 8 }} /> View Details
        </MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
        ),
        showSortIcons: false,
    },
        { Header: 'Year', accessor: 'year' },
        { Header: 'Month', accessor: 'month', Cell: ({ value }) => monthNames[value - 1] || 'Invalid month',showSortIcons:false },
        { Header: 'Payroll Status', accessor: 'payroll_status',showSortIcons:false },
        { Header: 'Generated By', accessor: 'generated_by_full_name',showSortIcons:false },
        {
          Header: 'Approved By',
          accessor: 'approved_by_full_name',
         
        },
        {
          Header: 'Approved Date',
          accessor: 'approved_date',
          showSortIcons:false,
          Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : 'Not Approved',
        },
        { Header: 'Remark', accessor: 'remark',showSortIcons:false },
        {
          Header: 'Actions',
          accessor: 'id',
          Cell: ({ row }) => {
            const [anchorEl, setAnchorEl] = useState(null);
            const [approvalStatus, setApprovalStatus] = useState(null);
      
            const handleClick = (event) => {
              setAnchorEl(event.currentTarget);
            };
      
            const handleClose = () => {
              setAnchorEl(null);
            };
      
            const handleApproval = async (id, status) => {
              const confirm = await swal({
                title: `Are you sure you want to ${status === 'Approved' ? 'approve' : 'reject'} this payroll?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              });
      
              if (confirm) {
                try {
                  const response = await axios.post(`${API_BASE_URL}/payrolls/${id}/approve`, {
                    payroll_status: status === 'Approved' ? 'approved' : 'pending',
                  }, {
                    headers: { "Authorization": `Bearer ${token}` },
                  });
      
                  if (response.status === 200) {
                    setApprovalStatus(status); // Update the local UI status
                    fetchPayrolls(); // Refresh the payroll list to reflect the changes
                    swal({
                      title: `Payroll ${status} successfully!`,
                      icon: "success",
                    });
                  }
                } catch (error) {
                  swal({
                    title: `${error.response?.data?.message || 'An error occurred'}`,
                    icon: "error",
                  });
                }
              }
            };
      
            return (
              <Box display="flex" justifyContent="space-between" gap={2}>
                {approvalStatus === null ? (
                  <>
                    <IconButton onClick={handleClick}>
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <MenuItem
                        onClick={() => handleApproval(row.original.id, 'Approved')}
                        sx={{ color: '#4caf50' }} // Green text for approval
                      >
                        <CheckIcon /> Approve
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleApproval(row.original.id, 'pending')}
                        sx={{ color: '#f44336' }} // Red text for rejection
                      >
                        <CloseIcon /> Pending
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Box
                    component="button"
                    style={{
                      backgroundColor: approvalStatus === 'Approved' ? '#4caf50' : '#f44336',
                      color: '#fff',
                      border: 'none',
                      padding: '8px 16px',
                      cursor: 'pointer',
                    }}
                  >
                    {approvalStatus}
                  </Box>
                )}
              </Box>
            );
          },
          showSortIcons: false,
        },
      ];
      
      

  return (
    <DashboardLayout>
            <Card>
                <CardContent>
                    <MDBox>
                        <MDTypography variant="h5" fontWeight="medium">
                            Payrolls
                        </MDTypography>
                        <MDBox>
                            <DataTable
                                table={{
                                    columns,
                                    rows: filteredData,
                                }}
                                canSearch={true}
                                sortable={true}
                                rowsPerPage={5}
                                showPagination
                                widgets={
                                    <MDBox ms={2}>
                                        <MDButton
                                            variant="gradient"
                                            ml="auto"
                                            color="info"
                                            size="small"
                                          
                                            sx={{background:"#00274D", my:4,mx:3}}
                                        >
                                            <span>Add </span>
                                        </MDButton>
                                    </MDBox>
                                }
                            />
                        </MDBox>
                        {
                employeesPayroll &&(
                  <ViewEmployeesPayroll employees={employeesPayroll} openView={openView} onClose={handleCloseDialog}/>
                )
              }
                       
                    </MDBox>
                </CardContent>
            </Card>
            <LoadingDialog open={loading} />
        </DashboardLayout>
  )
}

export default FinancePayroll