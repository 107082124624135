import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar,  Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState,useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AddExamResult from './addResult';
import ApprovalModal from './approvalModal';
import LoadingDialog from 'components/Loading/LoadingDialog';
import FileViewer from "components/FileViewer";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit'; // Icon for Enter Result
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; 


const ApplicantManagement = () => {
  const [search] = useState('');
  const[applicants,setApplicants] = useState([])
  const [open, setOpen] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const token =  localStorage.getItem("authToken");
  const [openAdd, setOpenAdd] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [applicant, setApplicant] = useState(false);
  const [cashopen, setCashopen] = useState(false);
  const role= localStorage.getItem("role");



  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };

  const handleCashClose = () => {
    setCashopen(false);
    setTransactionData(null);

  };

  const handleClose = () => {
    setOpen(false);
    setTransactionData(null); // Clear the data when closing
};
const handleCloseDialog = () => {
  setOpenAdd(false);
  setOpenApprove(false);
  setOpenDialog(false);
  setSelectedFile(null);
  setFileType(""); 
  fetchApplicants();
};
const fetchApplicants = useCallback(async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${API_BASE_URL}/applicant-student`, 
      
      {
      headers: { "Authorization": `Bearer ${token}` }
    });
    setApplicants(response.data);
  } catch (err) {
  } finally {
    setLoading(false);
  }
},[token]);
  useEffect(() => {

    fetchApplicants();
  },[fetchApplicants]);
  
  const filteredData = applicants.filter(registrar => {
    return (
      registrar.first_name.toLowerCase().includes(search.toLowerCase()) ||
      registrar.email_1.toLowerCase().includes(search.toLowerCase()) 
     
    );

  });
  const handleViewDetail = (person) => {
    navigate(`/viewApplicantDetail/${person.id}`);

  }

  const handleTransaction = async (payment) => {    

    if (payment != null && payment.transaction_id !== null ){
      setLoading(true);
      try {
         if (payment.payment_method === "Chapa") {

        const response = await axios.get(`${API_BASE_URL}/callback/${payment.transaction_id}`);    

        setTransactionData(response.data.data); 
        setOpen(true);
        setLoading(false);
   
         }
         else{
          setTransactionData(payment); 
          setCashopen(true);
          setLoading(false);


         }

      } catch (err) {
        console.error(err);
      }
    }
  };

  const lables = (label) => (  
    <span style={{ color: '#00274D',fontSize:"1.2rem" }} variant="h6" fontWeight="semibold">{label}</span>
); 

const valueStyle = { 
color: '#6B7280', // Tailwind Gray-500 for subtle contrast
fontWeight: "bold", 
fontSize: "1rem",
marginLeft: '0.5rem' // Adds space between label and value
};

  const columns = [ 

          {
            accessor: 'action',
            Header: '', 
            width: 40,
            Cell: ({ row }) => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button color="secondary" {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                      <MoreVertIcon />
                    </Button>
                    <Menu {...bindMenu(popupState)}>
            {role !== "academic dean" && (
              <React.Fragment>
                <MenuItem
                  onClick={() => {
                    setApplicant(row.original);
                    setOpenAdd(true);
                    popupState.close();
                  }} 
                  style={{ color: '#1976d2' }}
                >
                  <EditIcon style={{ marginRight: 8 }} />
                  Enter Result
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setApplicant(row.original);
                    setOpenApprove(true);
                    popupState.close();
                  }}
                  style={{ color: '#4caf50' }}
                >
                  <CheckCircleIcon style={{ marginRight: 8 }} />
                  Approve
                </MenuItem>
              </React.Fragment>
            )}
            <MenuItem
              onClick={() => {
                handleViewDetail(row.original);
                popupState.close();
              }} 
              style={{ color: '#1976d2' }}
            >
              <VisibilityIcon style={{ marginRight: 8 }} />
              View Detail
            </MenuItem>
          </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            ),
            showSortIcons: false,
          },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
      showSortIcons: false,
    },
    {
      Header: 'Name',
      accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
      id: 'full_name', // Optional: Use an id for the column
      sorted: "none",
    },
    // entrance_exam
    { Header: 'Entrance Exam', accessor: 'entrance_exam', showSortIcons: false },
    { Header: 'Interview', accessor: 'interview', showSortIcons: false },
    { Header: 'Telephone', accessor: 'phone', showSortIcons: false,   },
    { Header: 'Gender', accessor: 'gender',  showSortIcons: false, },
    { Header: 'Email', accessor: 'email_1',  showSortIcons: false, },
    { Header: 'Application Date', accessor: 'application_date',  showSortIcons: false, },

    {
      Header: 'Application Program',
      id:'application_program',
      accessor: row => (
        <Link to={`/programs/${row.program.id}`}>
          {row.program ? row.program.program_name : null}
        </Link>
      ),
      sortable: true,
    },  
    
    { Header: 'Postal Address', accessor: 'postal_address',  showSortIcons: false, },
    {
      Header: 'Application Fee',
      id: 'application_fee',
      accessor: row => (
        <MDButton onClick={() => handleTransaction(row.payments.length ? row.payments[0] : null)}>
          {row.payments.length ? row.payments[0].transaction_id : 'No Transaction'}
        </MDButton>
      ),
      showSortIcons: false,
    }

  ];
  
  return (
    
    <DashboardLayout>
            <MDBox>
            <Card >
      <>
   
       <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Application Fee Details</DialogTitle>
                {/* <Typography variant="body1"><strong> {transactionMessage} </strong></Typography> */}

                <DialogContent>
                    {transactionData && (
                        <>
                           <DialogContent>
    {transactionData && (
        <>
            <MDTypography variant="body2"> {lables("Full Name:")}  <span style={valueStyle}>{transactionData.first_name + ' ' + transactionData.last_name}</span></MDTypography>
            <Typography variant="body1"> {lables("Amount:")} {transactionData.amount}</Typography>
            <Typography variant="body1"><strong>Charge:</strong> {transactionData.charge}</Typography>
            <Typography variant="body1"><strong>Created At:</strong> {new Date(transactionData.created_at).toLocaleString()}</Typography>
            <Typography variant="body1"><strong>Currency:</strong> {transactionData.currency}</Typography>
            <Typography variant="body1"><strong>Customization Title:</strong> {transactionData.customization?.title}</Typography>
            <Typography variant="body1"><strong>Customization Description:</strong> {transactionData.customization?.description}</Typography>
            <Typography variant="body1"><strong>Email:</strong> {transactionData.email}</Typography>
            <Typography variant="body1"><strong>Method:</strong> {transactionData.method}</Typography>
            <Typography variant="body1"><strong>Mode:</strong> {transactionData.mode}</Typography>
            <Typography variant="body1"><strong>Reference:</strong> {transactionData.reference}</Typography>
            <Typography variant="body1"><strong>Status:</strong> {transactionData.status}</Typography>
            <Typography variant="body1"><strong>Transaction Reference:</strong> {transactionData.tx_ref}</Typography>
        </>
    )}
</DialogContent>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={cashopen} onClose={handleCashClose}>
                <DialogTitle>Cash Payment Details</DialogTitle>
                <DialogContent>
                    {transactionData && (
                        <>
                           <Typography variant="body1">
                            <strong>{lables("Full Name:")}</strong>
                            <span style={valueStyle}>{transactionData.full_name}</span>
                          </Typography>
                          <Typography variant="body1">
                            <strong>{lables("Phone Number:")}</strong>
                            <span style={valueStyle}>{transactionData.phone_number}</span>
                          </Typography>
                          <Typography variant="body1">
                            <strong>{lables("Payment Amount:")}</strong>
                            <span style={valueStyle}>{transactionData.payment_amount}</span>
                          </Typography>
                          <Typography variant="body1">
                            <strong>{lables("Payment Date:")}</strong>
                            <span style={valueStyle}>{new Date(transactionData.payment_date).toLocaleString()}</span>
                          </Typography>
                          <Typography variant="body1">
                            <strong>{lables("Payment Method:")}</strong>
                            <span style={valueStyle}>{transactionData.payment_method}</span>
                          </Typography>
                          <Typography variant="body1">
                            <strong>{lables("Receipt Path:")}</strong>
                            {transactionData.receipt_path ? (
                          <MDButton
                            variant="body2"
                            color="primary"
                            component="a"
                            onClick={() => handleViewFile(transactionData.receipt_path)}
                            style={{ cursor: 'pointer' }}
                            sx={{ 
                              cursor: 'pointer',
                              ml: 1,
                              textDecoration: 'underline',
                              boxShadow: 2, // Adds a default shadow
                              transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
                              '&:hover': {
                                color: 'secondary.main',
                                boxShadow: 4, // Increases shadow intensity on hover
                              }
                            }}
                          >
                            View File
                          </MDButton>
                        ) : (
                          <span style={valueStyle}>
                            No receipt file available
                          </span>
                        )}

                          </Typography>
                          <Typography variant="body1">
                            <strong>{lables("Transaction ID:")}</strong>
                            <span style={valueStyle}>{transactionData.transaction_id}</span>
                          </Typography>

                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCashClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
            </>

          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Applicants
            </MDTypography>
              <MDBox mt={1}>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                />
              </MDBox>
          </CardContent>
          </Card>
        </MDBox>

        <AddExamResult open={openAdd} applicant={applicant}  onClose={handleCloseDialog} />
        <ApprovalModal open={openApprove} applicant={applicant}  onClose={handleCloseDialog} />
        <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />

        <LoadingDialog open={loading} />

    </DashboardLayout>
  )
}

export default ApplicantManagement;