import API_BASE_URL from "api";
import axios from "axios";

export function checkPermission(permission) {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    
    return permissions.includes(permission);
}
export const fetchAndStorePermissions = async () => {
    const token = localStorage.getItem("authToken");
    try {
        const response = await axios.get(`${API_BASE_URL}/user-permissions`,{
            headers : {
                Authorization : `Bearer ${token}`
            }
        }); 
        const permissionNames = response.data.map(permission => permission.name);
        localStorage.setItem('permissions', JSON.stringify(permissionNames));
    } catch (error) {
        if (error.response) {

        } else if (error.request) {
        } else {
        }
    }
};