import { createSlice } from '@reduxjs/toolkit';

const initialState = [{
    employer_name: "",
    employer_address: "",
    job_title: "",
    employment_start_date: "",
    employment_end_date: "",
    job_responsibilities: "",
}];

const studentEmployeementRecordsSlice = createSlice({
  name: "studentEmployeementRecords",
  initialState,
  reducers: {
    addStudentEmployeementRecord: (state, action) => {
      state[0] = action.payload;
    },
    updateStudentEmployeementRecord: (state, action) => {
      state[0] = { ...state[0], ...action.payload };
    },
  },
});

export const { addStudentEmployeementRecord, updateStudentEmployeementRecord } = studentEmployeementRecordsSlice.actions;
export default studentEmployeementRecordsSlice.reducer;