import React, { useState, useEffect,useCallback } from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Autocomplete,  Card, CardContent, TextField } from '@mui/material';
import LoadingDialog from "components/Loading/LoadingDialog";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`, 
    'Content-Type': 'application/json',
  },
});

const ViewCourse = () => {
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [semester, setSemester] = useState(null);
  const [year, setYear] = useState(null);
  const targetId = localStorage.getItem("targetId");
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [semestersData, yearsData] = await Promise.all([
          axiosInstance.get('/semesters'),
          axiosInstance.get('/years'),
        ]);
        setSemesters(semestersData.data);
        setYears(yearsData.data);
      } catch (error) {
        console.error("Error fetching semesters and years:", error);
      }
    };

    fetchData();
  }, [token]);

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/instructor/${targetId}/courses`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      const courses = response.data[0].course_history;
      setAssignedCourses(courses);
      setFilteredCourses(courses);  // Initially, filteredCourses will be the same as assignedCourses
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[token,targetId]);

  useEffect(() => {
    fetchCourses();
},[fetchCourses]);

  // Filter/sort data based on selected semester and year
  useEffect(() => {
    let updatedCourses = [...assignedCourses];

    if (semester) {
      updatedCourses = updatedCourses.filter(course => course.semester === semester.semester_code);
    }

    if (year) {
      updatedCourses = updatedCourses.filter(course => course.year === parseInt(year.year_name));
    }

    setFilteredCourses(updatedCourses);
  }, [semester, year, assignedCourses]);

  const columns = [
    { Header: 'Course Number', accessor: 'course.course_number', showSortIcons: false },
    { Header: 'Course Name', accessor: 'course.course_name' },
    { Header: 'Credit Hours', accessor: 'course.credit_hour', showSortIcons: false },
    { Header: 'Semester', accessor: 'semester' },
    { Header: 'Year', accessor: 'year' },
  ];

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Courses
            </MDTypography>

            

            <MDBox>
             <DataTable
                  table={{
                    columns,
                    rows: filteredCourses,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                  widgets={
                    <MDBox display="flex" >
              {/* Semester Autocomplete */}
              <Autocomplete
                fullWidth
                sx={{ mx: 2, my: 2, width: '200px' }}
                options={semesters}
                getOptionLabel={(option) => option.semester_code || ''}
                onChange={(event, newValue) => setSemester(newValue)}
                value={semester}
                renderInput={(params) => <TextField {...params} label="Semester" />}
              />

              {/* Year Autocomplete */}
              <Autocomplete
                fullWidth
                sx={{ mx: 2, my: 2, width: '200px' }}
                options={years}
                getOptionLabel={(option) => option.year_name || ''}
                onChange={(event, newValue) => setYear(newValue)}
                value={year}
                renderInput={(params) => <TextField {...params} label="Year" />}
              />
            </MDBox>

                  }
                />
                 {!loading && filteredCourses.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No courses available
          </MDTypography>
        )}
            
            </MDBox>
          </MDBox>
        </CardContent>
      </Card>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default ViewCourse;
