import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingDialog from 'components/Loading/LoadingDialog';
import swal from 'sweetalert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { setEmployee,setEditStatus } from '../../../redux/features/employeeSlice';
import { useDispatch} from "react-redux";
import { axiosInstance } from 'api2';

const Employees = () => {
  const[employees,setEmployees] = useState([])
  const[filteredEmployees,setFilteredEmployees] = useState([])
  const navigate = useNavigate();
  const token =  localStorage.getItem("authToken");
  const [loading,setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [positions,setPositions] = useState([]);
  const [staff,setStaff] = useState([]);
  const gender = [
    {id:1,name:'Male'},{id:2,name:'Female'}
  ]
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [positionData, staffData] = await Promise.all([
         
          axiosInstance.get('/positions'),
          axiosInstance.get('/staff'),
        ]);

        setPositions(positionData.data);
        setStaff(staffData.data);
        
      } catch (error) {
        console.error("Error fetching courses and years:", error);
      }
    };

    fetchData();
  },[]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/employees`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });

        setEmployees(response.data);
        setFilteredEmployees(response.data);
      } catch (err) {
      } finally {
        setLoading(false)
      }
    };
    fetchEmployees();
  }, [token]);
  

  const handleAdd = () => {    
    navigate('/employees/create/1');

  }


  const handleOpenEdit = (employee) => {
    dispatch(setEmployee(employee));
    dispatch(setEditStatus('editing'));
    navigate('/employees/create/1');
  };


  const handleDeleteEmployee = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this employee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setDeleting(true);
    
        try {
          const response = await axios.delete(`${API_BASE_URL}/employees/${id}`, {
            headers: { "Authorization": `Bearer ${token}` },
          });
  
          if (response.status === 204) {
            setEmployees((prevUsers) => prevUsers.filter((user) => user.id !== id));
            swal({
              title: "Employee Deleted Successfully!",
              icon: "success",
            });
          }
        } catch (error) {
          swal({
            title: `${error.response?.data?.message || "Error occurred"}`,
            icon: "error",
          });
        } finally {
          setDeleting(false); // Stop the deleting process
        }
    }
  };
 
  const columns = [ 
    {
      accessor: 'action',
      Header: '', 
      width:40,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
              <MenuItem onClick={() => {
                      handleDeleteEmployee(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }} >   <DeleteIcon style={{ marginRight: 8 }} /> Delete
                      </MenuItem>

             <MenuItem onClick={() => {
    navigate(`/employees/${row.original.id}`);
}} style={{ color: '#1976d2' }}><VisibilityIcon style={{ marginRight: 8 }} /> View Details</MenuItem>

              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
    },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
      showSortIcons: false,
    },
    {
      Header: 'Name',
      accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
      id: 'full_name', // Optional: Use an id for the column
      sorted: "none",
    },
    { Header: 'Telephone', accessor: 'phone', showSortIcons: false, },
    { Header: 'Gender', accessor: 'gender',showSortIcons: false, },
    { Header: 'Email', accessor: 'email',showSortIcons: false, },
    { Header: 'Employement Date', accessor: 'employment_date',showSortIcons: false, },

    { Header: 'Position', accessor: 'position.position_title',showSortIcons: false, },
    { Header: 'Staff', accessor: 'staff.name',showSortIcons: false, },
    { Header: 'Employement Type', accessor: 'employment_type',showSortIcons: false, },

  ];


  useEffect(() => {

    if (selectedPosition || selectedStaff || selectedGender) {
      const filtered = employees.filter(employee => {
        const positionMatch = selectedPosition ? employee.position_id === selectedPosition?.id : true;
        const staffMatch = selectedStaff ? employee.staff_id === selectedStaff?.id : true;
        const genderMatch = selectedGender ? employee.gender === selectedGender.name : true;
        return positionMatch && staffMatch && genderMatch;
      });
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees(employees); // Reset to original list if no filters are selected
    }
  }, [selectedPosition, selectedStaff,selectedGender, employees]);

  
  return (
    
    <DashboardLayout>
            <MDBox>
            <Card >
          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Employees
            </MDTypography>
              <MDBox mt={1}>
                <DataTable
                  table={{
                    columns,
                    rows: filteredEmployees,
                  }}
                  canSearch={true}
                  sortable={true}
                  // pagination
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox ms={2} display="flex">
                    <MDButton 
                    variant="gradient"
                    ml="auto"
                        color="info"
                        size="small"
                        onClick={handleAdd}
                        sx={{background:"#00274D", my:4,mx:1}}
                  >
                    <span>Add</span>
                  </MDButton>
                  <Autocomplete
                      fullWidth
                      sx={{ mx: 2, my: 2, width: '135px' }}
                      options={positions}
                      getOptionLabel={(option) => option.position_title || ''}
                      onChange={(event, newValue) => setSelectedPosition(newValue)}
                      value={selectedPosition}
                      renderInput={(params) => <TextField {...params} label="Position" />}
                    />
                       <Autocomplete
                      fullWidth
                      sx={{ mx: 2, my: 2, width: '135px' }}
                      options={staff}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, newValue) => setSelectedStaff(newValue)}
                      value={selectedStaff}
                      renderInput={(params) => <TextField {...params} label="Staff" />}
                    />
                      
                     <Autocomplete
                      fullWidth
                      sx={{ mx: 2, my: 2, width: '100px' }}
                      options={gender}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, newValue) => setSelectedGender(newValue)}
                      value={selectedGender}
                      renderInput={(params) => <TextField {...params} label="Gender" />}
                    />
                 
                  </MDBox>
                  }
                />
              </MDBox>
          </CardContent>
          </Card>
          <LoadingDialog open={deleting || loading}/>
        </MDBox>
    </DashboardLayout>
  )
}

export default Employees;