import React, { useEffect, useState,useCallback } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Card, CardContent } from "@mui/material";
import MDButton from "components/MDButton";
import AddGradeRequests from "./addGradeRequest";
import ViewInstructorResponse from "./viewResponse";
import VisibilityIcon from '@mui/icons-material/Visibility';

const GradeRequest = () => {
  const [search] = useState('');
  const [loading, setLoading] = useState(true);
  const [gradeRequests, setGradeRequests] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [openAdd,setOpenAdd] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const targetId = localStorage.getItem("targetId");
  const [newResponse, setNewResponse] = useState([]);


  const fetchGradeRequests = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`student/${targetId}/grade-change-requests`);
      setGradeRequests(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[targetId]);

  useEffect(() => {
  

    fetchGradeRequests();
  }, [fetchGradeRequests]);


  const handleViewDetails = (value) => {
    setOpenDetail(true);
    setNewResponse(value);
  };

  const handleCloseDialog = () => {

    setOpenDetail(false)
    setOpenAdd(false)
    setOpenView(false)
  };

  const filteredData = gradeRequests.filter(request => request.reason.toLowerCase().includes(search.toLowerCase()));

  const handleViewDetail = (value) => {
    setOpenView(!openView);
  };

  const handleAddGradeRequest = () => {
        fetchGradeRequests()
    
  };




  const columns = [
    {
      accessor: 'action',
      Header: '',
      width: 40,
      padding: 0,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => {
              handleViewDetails(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
                </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    {
      Header: 'Student Name',
      accessor: 'student',
      Cell: ({ value }) => `${value.first_name} ${value.last_name}`,
      showSortIcons: false,
    },
    { Header: 'Instructor Name', accessor: 'instructor.full_name', showSortIcons: false },
    { Header: 'Course Name', accessor: 'course.course_name', showSortIcons: false },
    { Header: 'Request Date', accessor: 'request_date', showSortIcons: false },
    { Header: 'Status', accessor: 'status', showSortIcons: false },
    {
      Header: 'Action',
      accessor: 'responses',
      Cell: ({ value }) => (
        <Button color='secondary' onClick={() => handleViewDetail(value)}>View Details</Button>
      ),
      showSortIcons: false,
    }
  ];

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Grade Requests
            </MDTypography>
            <MDBox display="flex" gap={3}>
              <MDBox flex="1">
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox mx={2}>
                   <MDButton
                   variant="gradient"
                   ml="auto"
                       color="info"
                       size="small"
                       onClick={() => setOpenAdd(true)}
                       sx={{background:"#00274D", my:2,  mx: { xs: 0, md: 2 }}}
                 >
                   <span>Add</span>
                 </MDButton>
                 </MDBox>
                  }
                />
                {!loading && filteredData.length === 0 && (
                  <MDTypography variant="h5" display="block" align="center" my={2} sx={{
                    fontStyle: "italic",
                    fontWeight: "bold",
                    color: "#9e9e9e",
                    opacity: 0.7,
                  }}>
                    No data available
                  </MDTypography>
                )}
              </MDBox>

            
            </MDBox>
            <LoadingDialog open={loading} />
          </MDBox>
        </CardContent>
      </Card>
       
              <AddGradeRequests  open={openAdd}
                  onAdd={handleAddGradeRequest}
                  onClose={handleCloseDialog}/>
                     {
                openDetail && (
                  <ViewInstructorResponse responses={newResponse.responses} id={newResponse.id} openView={openDetail} onClose={handleCloseDialog}/>
                )
              }
    </DashboardLayout>
  );
};

export default GradeRequest;
