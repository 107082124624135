import React, { useState ,useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import API_BASE_URL from 'api';
import axios from 'axios';
import MDButton from 'components/MDButton';
import DataTable from 'examples/Tables/DataTable';
import swal from 'sweetalert';
import MDTypography from 'components/MDTypography';
import LoadingDialog from 'components/Loading/LoadingDialog';


const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json',
  },
});

const AddIssuingVoucher = ({ open, onClose, onAdd }) => {
    const [error, setError] = useState(null);
    const [supplier, setSupplier] = useState("");
    const [date, setDate] = useState("");
    const [remark, setRemark] = useState("");
    const [issued_to, setIssuedTo] = useState("");
    const [quantity, setQuantity] = useState("");
    const [unitPrice, setUnitPrice] = useState("");
    const [unit_of_measure, setUnitOfMeasurement] = useState("");
    const [assets,setAssets] = useState([])
    const [stores, setStores] = useState([]);

    const [selectedStore, setSelectedStore] = useState(null);
    
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [displayGoods, setDisplayGoods] = useState([]);
    const [loading, setLoading] = useState(false); 
    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            const [storesResponse, assetsResponse] = await Promise.all([
              axiosInstance.get("/stores"),
              axiosInstance.get("/assets"),
            ]);
            setStores(storesResponse.data);
            setAssets(assetsResponse.data);
          } catch (err) {
            console.error("Failed to fetch data:", err);
            setError("Failed to fetch data");
          } finally{
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);

      const handleAddRequest = () => {
        if (!supplier || !selectedAsset || quantity <= 0 || unitPrice <= 0 || !remark || !issued_to || !date || !unit_of_measure ) {
          setError("Please fill in all fields correctly.");
          return;
        }
      
      
        const newRequest = {
            store_id: selectedStore.id,        
            store_name: selectedStore.name, 
          asset_id: selectedAsset.id,        
          asset_name: selectedAsset.name,    
          asset_code: selectedAsset.code,    
          supplier,                         
          quantity,                          
          unit_price: unitPrice, 
          unit_of_measure: unit_of_measure,            
          remark,                            
          issued_to,                            
          request_date: date,                
        };

      
        setDisplayGoods((prev) => [...prev, newRequest]);
      
   
        setSupplier("");
        setSelectedStore(null);
        setSelectedAsset(null);
        setUnitOfMeasurement("");
        setQuantity("");
        setUnitPrice("");
        setRemark("");
        setDate("");
        setIssuedTo("");
        setError(null); 
      };
      
      
    
      const handleDeleteGoods = (index) => {
        setDisplayGoods((prev) => prev.filter((_, i) => i !== index));
      };

      const columns = [

        { Header: 'Store', accessor: 'store_name' },    
        { Header: 'Asset Name', accessor: 'asset_name' },     
       
        { Header: 'Quantity', accessor: 'quantity',showSortIcons: false, },    
        { Header: 'IssuedTo', accessor: 'issued_to',showSortIcons: false, },        
        {
            Header: 'Unit Price',
            accessor: 'unit_price',
            Cell: ({ row }) => {
              const formattedUnitPrice = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(row.original.unit_price);
              
              return <span>{formattedUnitPrice}</span>;
            },showSortIcons: false,
          },
          

        // { Header: 'Unit Measure', accessor: 'unit_of_measure' },    
        { 
          Header: 'Amount', 
          accessor: 'amount',                          
          Cell: ({ row }) => {
            const amount = row.original.quantity * row.original.unit_price;
            const formattedAmount = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }).format(amount);
            
            return <span>{formattedAmount}</span>;
          },showSortIcons: false,
        },
        { Header: 'Remark', accessor: 'remark',showSortIcons: false, },   
        {
            Header: 'Actions',
            Cell: ({ row }) => (  // Use Cell to define a custom render for the action button
              <IconButton onClick={() => handleDeleteGoods(row.index)}>
                <Delete />
              </IconButton>
            ),showSortIcons: false,
          },          
      ];
      
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          // Check if there are any requests to submit
          if (displayGoods.length === 0) {
            setError("Please add at least one request.");
            return;
          }

        setLoading(true);      
         
          const requestPayload = {
            store_id: displayGoods[0]?.store_id ,  
            supplier_name: displayGoods[0]?.supplier,
            date: displayGoods[0]?.request_date,
            total_amount: displayGoods.reduce((total, request) => total + request.quantity * request.unit_price, 0),
            // reason: displayGoods[0]?.reason, 
            issued_to: displayGoods[0]?.issued_to,   
            lines: displayGoods.map((request) => ({
              asset_id: request.asset_id,        
              unit_of_measure: request.unit_of_measure, 
              quantity: request.quantity,
              unit_price: request.unit_price,      
             amount: request.quantity * request.unit_price, 
              remark: request.remark,
            })),
          };

      
         await axiosInstance.post("/goods-issuing-vouchers", requestPayload);
          onAdd();  

          setDisplayGoods([]);

        
      
          onClose();  
      
        
          swal({
            title: "Good Issued added successfully!",
            icon: "success",
          });
        } catch (err) {
          console.error("Error submitting store request:", err);
          setError(
            err.response?.data?.message || "Failed to submit store requests"
          );
        } finally{
          setLoading(false);
        }
      };
  return (
    <DashboardLayout>
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle textAlign="center">
        <Add /> Add Issued Good
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={1}>
        <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Supplier"
              value={supplier}
              onChange={(e) => {
                setSupplier(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>
         
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Issued To"
              value={issued_to}
              onChange={(e) => {
                setIssuedTo(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
             
              type='date'
              value={date}
              onChange={(e) => {
                setDate(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={stores}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedStore(newValue);
                setError(null)
              }}
              value={selectedStore}
              renderInput={(params) => (
                <TextField {...params} label="Store" />
              )}
            />
          </Grid>
          
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={assets}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedAsset(newValue);
                setError(null)
              }}
              value={selectedAsset}
              renderInput={(params) => (
                <TextField {...params} label="Asset" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
                setError(null);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Unit Price"
              type="number"
              name="unit_price"
              value={unitPrice}
              onChange={(e) => {
                setUnitPrice(e.target.value);
                setError(null);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Unit of Measure"
              value={unit_of_measure}
              onChange={(e) => {
                setUnitOfMeasurement(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Remark"
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>
          {error && (
          <MDTypography color="error" variant="body2" mt={5} mx={2}>
            {error}
          </MDTypography>
        )}
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <MDButton
              variant="gradient"
              size="small"
              color="info"
              type="button"
              sx={{ background: "#00274D" }}
              onClick={handleAddRequest}
            >
              Add Request
            </MDButton>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              table={{
                columns,
                rows: displayGoods,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
            />
          </Grid>
        </Grid>
         {/* Display Total Amount */}
         {
  displayGoods.length > 0 && (
    <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
      <MDTypography variant="h6">
        Total Amount: {
          new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(displayGoods.reduce((sum, item) => sum + (item.quantity * item.unit_price), 0))
        }
      </MDTypography>
    </Grid>
  )
}

       
      
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  </DashboardLayout>
  )
}

export default AddIssuingVoucher