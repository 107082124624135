import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import API_BASE_URL from "api";
import swal from "sweetalert";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Switch, TextField, Autocomplete, Grid } from "@mui/material";
import DataTable from 'examples/Tables/DataTable';
import LoadingDialog from "components/Loading/LoadingDialog";


const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const RequestWithdraw = ({ open, onClose, onAdd }) => {
  const targetId = localStorage.getItem("targetId");
  const [modalData, setModalData] = useState({
    student_id: targetId,
    semester: "",
    year: "",
    is_full_semester: false,
    withdraw_lines: [], // Store selected course data
  });
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [loading,setLoading] = useState(false);


  useEffect(() => {
    // Fetch the list of years and semesters when the component loads
    const fetchData = async () => {
      try {
        const [semestersData, yearsData,courseData] = await Promise.all([
          axiosInstance.get('/semesters'),
          axiosInstance.get('/years'),
          axiosInstance.get(`/student/${targetId}/registration`),
        ]);

        setSemesters(semestersData.data);
        setYears(yearsData.data);
        const coursesData = courseData.data[0]?.registrations;
        setCourses(coursesData);
        // setWithdrawalRequests(CourseData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [targetId]);



  const handleSwitchChange = (e) => {
    setModalData({
      ...modalData,
      is_full_semester: e.target.checked,
    });
  };

  const handleModalSubmit = async () => {
    
    if (!modalData.student_id || !modalData.semester || !modalData.year || modalData.withdraw_lines.length === 0) {
      alert("Please fill in all required fields.");
    } else {
    
        
     
      try {
        const response = await axiosInstance.post(`/withdrawal-request`, modalData);

        if (response.status === 201) {
          onAdd(response.data);
          onClose();
          swal({
            title: "Withdrawal Request Submitted Successfully!",
            icon: "success",
          });

          setModalData({
            student_id: targetId,
            semester: "",
            year: "",
            is_full_semester: false,
            withdraw_lines: [],
          });
        }
      } catch (err) {
        console.error("Error submitting form:", err);
      } 
      finally{
        setLoading(false);
      }
    }
  };

  const columns = [
    { Header: 'Course Number', accessor: 'course_number', sortable: true },
    { Header: 'Course Name', accessor: 'course_name', sortable: true },
    { Header: 'Description', accessor: 'course_description', sortable: true },
    { Header: 'Credit Hour', accessor: 'credit_hour', sortable: true },
    { Header: 'Approved', accessor: 'is_approved', sortable: true, Cell: ({ value }) => (value === 1 ? 'Yes' : 'No') },
    { Header: 'Active', accessor: 'is_active', sortable: true, Cell: ({ value }) => (value === 1 ? 'Yes' : 'No') },
  ];


  useEffect(() => {
    if (modalData.year && modalData.semester) {
      
   

    const filteredCourse = courses.filter(course => {
      const matchesYear = modalData.year ? Number(course.course_history.year) === Number(modalData.year) : true; // Ensure both are compared as numbers
      const matchesSemester = modalData.semester ? course.course_history.semester === modalData.semester : true;

      return matchesYear && matchesSemester;
        
    })
  
    if (filteredCourse) {
      setModalData(prevData => ({
        ...prevData,
        withdraw_lines: filteredCourse.map(course => ({
          course_history_id: course.course_history.id
        }))
      }));


      // Extract courses from withdraw_lines
      const filteredCourses =filteredCourse?.map(line => ({
        course_number: line.course_history.course.course_number,
        course_name: line.course_history.course.course_name,
        course_description: line.course_history.course.course_description,
        credit_hour: line.course_history.course.credit_hour,
        is_approved: line.course_history.is_approved,
        is_active: line.course_history.is_active,
        deadline:line.course_history.mark_deadline
      }));

      const today = new Date();
      const filteredData = filteredCourses?.filter(course => {
        const deadlineDate = new Date(course.deadline);

        return deadlineDate > today;
      })

     
  
      setFilteredCourses(filteredData || []);
    }
    else {
      setFilteredCourses([]);
    }
  }
  }, [ modalData.semester, modalData.year,courses]);
  

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle textAlign={"center"}>
        <AddIcon /> Request Withdraw
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={semesters}
              getOptionLabel={(option) => option.semester_name}
              onChange={(event, newValue) => {
                setModalData({
                  ...modalData,
                  semester: newValue ? newValue.semester_code : null,
                });
              }}
              renderInput={(params) => <TextField {...params} label="Select Semester" fullWidth required />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={years}
              getOptionLabel={(option) => option.year_name}
              onChange={(event, newValue) => {
                setModalData({
                  ...modalData,
                  year: newValue ? newValue.year_name : null,
                });
              }}
              renderInput={(params) => <TextField {...params} label="Select Year" fullWidth required />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mb={2}>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Full Semester Withdraw
              </MDTypography>
              <Switch
                checked={modalData.is_full_semester}
                onChange={handleSwitchChange}
                name="is_full_semester"
                color="primary"
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={12} lg={12}>
            <DataTable
              table={{
                columns,
                rows: filteredCourses,
              }}
              canSearch={true}
              sortable={true}
              rowsPerPage={5}
              showPagination
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" size="small" color="info" sx={{ background: "#00274D" }} onClick={handleModalSubmit}>
          Submit
        </MDButton>
        <MDButton variant="gradient" size="small" color="black" sx={{ background: "grey" }} onClick={onClose}>
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default RequestWithdraw;
