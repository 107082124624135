import React, { useEffect,useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography,Box } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import { checkPermission } from 'utils/checkPermission';
import { axiosInstance } from 'api2';
import swal from "sweetalert";
import MDBox from 'components/MDBox';
const ViewWithdrawalRequests = ({ openView, onClose, data,onView }) => {
  const targetId = localStorage.getItem("targetId");
  const role = localStorage.getItem("role");
  const [id,setId] = useState(null);
  const [withdrawData,setWithdrawData] = useState([])

    const [requests,setRequests] = useState([]);


    useEffect(() => {
        if(data){
            setWithdrawData(data)
            setId(data.id)

            if(role === "instructor"){
                setRequests(
                    data.withdraw_lines?.filter(request => Number(request.course_history.instructor_id) === Number(targetId))
                )
            }
            else{
                setRequests(
                    data.withdraw_lines
                )
            }

          
        }
    },[data,role,targetId])


    const handleApprove = async (id) =>  {
  
            try {
                const response = await axiosInstance.post(
                    `/withdraw-request/${targetId}/approve-instructor`,{line_id:id},
                );

                if (response.status === 200) {
                    onView();
                    onClose();
                    swal({
                        title: "Withdrawal Request Approved Successfully!",
                        // text: "Course Created Successfully!",
                        icon: "success"
                      });
                }
            } catch (error) {
                onClose();
                swal({ title: error.response.data.message, icon: "error" });
            }
        
    }

    const handleGsoApprove = async (id) =>  {

        try {
            const response = await axiosInstance.post(
                `/withdraw-request/${targetId}/approve-gso`,{line_id:id},
            );

            if (response.status === 200) {
                onView();
                onClose();
                swal({
                    title: "Withdrawal Request Approved Successfully!",
                    // text: "Course Created Successfully!",
                    icon: "success"
                  });
            }
        } catch (error) {
            onClose();
            swal({ title: error.response.data.message, icon: "error" });
        }
    }

    const handleApproveRegistrar = async () => {
        

        try {
            const response = await axiosInstance.post(
                `/withdraw-request/${id}/approve-registrar`,
            );

            if (response.status === 200) {
                onView();
                onClose();
                swal({
                    title: "Withdrawal Request Approved Successfully!",
                    // text: "Course Created Successfully!",
                    icon: "success"
                  });
            }
        } catch (error) {
            onClose();
            swal({ title: error.response.data.message, icon: "error" });
        }
    }

    const handleApproveAdvisor = async () => {
        

        try {
            const response = await axiosInstance.post(
                `/withdraw-request/${id}/approve-adviser`,
            );

            if (response.status === 200) {
                onView();
                onClose();
                swal({
                    title: "Withdrawal Request Approved Successfully!",
                    // text: "Course Created Successfully!",
                    icon: "success"
                  });
            }
        } catch (error) {
            onClose();
            swal({ title: error.response.data.message, icon: "error" });
        }
    }
    const columns = [
        { Header: 'Course Number', accessor: 'course_history.course.course_number', showSortIcons: false },
        { Header: 'Course Name', accessor: 'course_history.course.course_name', showSortIcons: false },
        { Header: 'Instructor Name', accessor: 'course_history.instructor.full_name', showSortIcons: false },
        { Header: 'Instructor Approval', accessor: row => row.instructor_approved_date ? 'Approved' : 'Pending', id: 'instructorApproval', showSortIcons: false },
        { Header: 'GSO Approval', accessor: row => row.gso_approved_date ? 'Approved' : 'Pending', id: 'gsoApproval', showSortIcons: false },
        ...(checkPermission('withdrawl-request-approval-instructor') || checkPermission('withdrawl-request-approval-gso') ? [
            {
                Header: 'Action',
                accessor: 'id',
                Cell: ({ row }) => {
                    const isInstructorApproved = row.original.instructor_approved_date;
                    const isGsoApproved = row.original.gso_approved_date;
    
                    return (
                        <MDBox display="flex" gap={1}>
                            {checkPermission('withdrawl-request-approval-instructor') && (
                                isInstructorApproved ? (
                                    <Typography sx={{ color: "green", fontWeight: "semibold",fontSize:"1rem" }}>Approved</Typography>
                                ) : (
                                    <MDButton
                                        variant="gradient"
                                        size="small"
                                        color="info"
                                        sx={{ background: "#00274D" }}
                                        onClick={() => handleApprove(row.original.id, 'instructor')}
                                    >
                                        Approve
                                    </MDButton>
                                )
                            )}
                            {checkPermission('withdrawl-request-approval-gso') && (
                                isGsoApproved ? (
                                    <Typography sx={{ color: "green",fontWeight: "semibold",fontSize:"1rem"  }}>Approved</Typography>
                                ) : (
                                    <MDButton
                                        variant="gradient"
                                        size="small"
                                         color="info"
                                        sx={{ background: "#00274D" }}
                                        onClick={() => handleGsoApprove(row.original.id, 'GSO')}
                                    >
                                        Approve
                                    </MDButton>
                                )
                            )}
                        </MDBox>
                    );
                },
                showSortIcons: false
            }
        ] : [])
    ];
    

    const rows = requests?.flatMap(request => {
        const courseHistory = request?.course_history;
        return [
            request,
            ...(request.withdraw_lines || [])
        ]
        .filter(line => line) // Ensure line is defined
        .map(line => ({
            ...line,
            course_history: courseHistory
        }));
    }) || []; // Fallback to an empty array if rows is undefined
    
    // Pass rows to DataTable
    <DataTable
        table={{
            columns,
            rows
        }}
        canSearch={true}
        sortable={true}
        pagination
        rowsPerPage={5}
        showPagination
    />
    
    return (
        <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle textAlign="center">
                Withdrawal Requests
            </DialogTitle>
            <DialogContent>
                <DataTable
                    table={{
                        columns,
                        rows
                    }}
                    canSearch={true}
                    sortable={true}
                    pagination
                    rowsPerPage={5}
                    showPagination
                />

<Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
  {/* Registrar Approval */}
  {checkPermission("withdrawl-request-approval-registrar") && (
    withdrawData?.registrar_approved_date ? (
    //   <Typography sx={{ color: "green", fontWeight: "semibold", fontSize: "1rem" }}>
    //     Approved
    //   </Typography>
    ""
    ) : (
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleApproveRegistrar}
      >
        Approve
      </MDButton>
    )
  )}

  {/* Advisor Approval */}
  {checkPermission("withdrawl-request-approval-advisor") && (
    withdrawData?.advisor_approved_date ? (
    //   <Typography sx={{ color: "green", fontWeight: "semibold", fontSize: "1rem" }}>
    //     Approved
    //   </Typography>
    ""
    ) : (
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleApproveAdvisor}
      >
        Approve
      </MDButton>
    )
  )}
</Box>

               
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{ background: "grey" }}
                    onClick={onClose}
                >
                    Close
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

export default ViewWithdrawalRequests;