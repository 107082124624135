import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState, useCallback } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';
import { Card, CardContent } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddRemuneration from './addRemuneration';  // Change to relevant component
import EditRemuneration from './editRemuneration'; 
import LoadingDialog from "components/Loading/LoadingDialog";

const Remunerations = () => {
  const token = localStorage.getItem("authToken");

  const [showAlert, setShowAlert] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [remunerations, setRemunerations] = useState([]);
  const [selectedRemuneration, setSelectedRemuneration] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deletingStatus, setDeletingStatus] = useState(false); 


  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  const fetchRemunerations = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/remunerations`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      setRemunerations(response.data);
    } catch (err) {
      console.error(err);
    }  finally{
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchRemunerations();
  }, [fetchRemunerations]);


  const handleAddRemunerations = () => {
    fetchRemunerations();
  };

  const handleEditRemunerations = () => {
    fetchRemunerations();
  };

  const handleDeleteRemuneration = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this remuneration!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setDeletingStatus(true);
      try {
        const response = await axios.delete(`${API_BASE_URL}/remunerations/${id}`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        if (response.status === 200) {
          setRemunerations(remunerations.filter((rem) => rem.id !== id));
          swal({
            title: "Remuneration Deleted Successfully!",
            icon: "success"
          });
        }
      } catch (error) {
        swal({
          title: `${error.response.data.message}`,
          icon: "warning"
        });
      }finally {
        setDeletingStatus(false); // End loading for deletion
      } 
    }
  };

  const handleOpenEdit = (remuneration) => {
    setSelectedRemuneration(remuneration);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedRemuneration(null);
  };

  const updatedRemunerationsData = remunerations.map((rem) => {
    // You might want to compute any additional fields relevant to remuneration
    return {
      ...rem,
      // Add additional calculated fields if needed
    };
  });

  const columns = [
    {
      accessor: 'action',
      Header: '',
      width: 40,
      padding: 0,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteRemuneration(row.original.id);
                  popupState.close();
                }} style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
               
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    {
      Header: 'Employee Name',
      accessor: 'employee.name',
      showSortIcons: false,
      
    },
    { Header: 'Amount', accessor: 'amount', showSortIcons: false, Cell: ({ value }) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
      }},
    { Header: 'Title', accessor: 'title', showSortIcons: false },
    { Header: 'Is Percentage?', accessor: 'is_percentage', showSortIcons: false, Cell: ({ value }) => value ? 'Yes' : 'No' },
    { Header: 'Is Addition?', accessor: 'is_addition', showSortIcons: false, Cell: ({ value }) => value ? 'Yes' : 'No' },
    { Header: 'Is Archived?', accessor: 'is_archived', showSortIcons: false, Cell: ({ value }) => value ? 'Yes' : 'No' },
  
  ];

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Remunerations
            </MDTypography>
            <MDBox>
              <DataTable
                table={{
                  columns,
                  rows: updatedRemunerationsData,
                }}
                canSearch={true}
                sortable={true}
                rowsPerPage={5}
                showPagination
                widgets={
                  <MDBox ms={2}>
                    <MDButton
                      variant="gradient"
                      ml="auto"
                      color="info"
                      size="small"
                      onClick={() => setOpenAdd(true)}
                      sx={{ background: "#00274D", my: 4, mx: 3 }}
                    >
                      <span>Add</span>
                    </MDButton>
                  </MDBox>
                }
              />
            </MDBox>
            {/* Add Remuneration Dialog */}
            <AddRemuneration open={openAdd} onClose={handleCloseDialog} onAdd={handleAddRemunerations} />
            {/* Edit Remuneration Dialog */}
            <EditRemuneration open={openEdit} onClose={handleCloseDialog} remuneration={selectedRemuneration} onEdit={handleEditRemunerations} />
          
          </MDBox>
          <LoadingDialog open={loading || deletingStatus} />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default Remunerations;
