import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState } from "react";


const Stores = () => {
  const [search] = useState('');
  const token =  localStorage.getItem("authToken");
 
  const [showAlert, setShowAlert] = useState(false);


  const[store,setStore] = useState([])


  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/stores`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
      

        setStore(response.data);
      } catch (err) {
        console.error(err);
      } 
      
    };
    fetchStore();
  }, [token]);


  const filteredData = search
  ? store && store.filter(l => 
      l.name &&
      l.name.toLowerCase().includes(search.toLowerCase())
    )
  : store;

  const columns = [
  
    { Header: 'Code', accessor: 'code',showSortIcons: false, },
    { Header: 'name', accessor: 'name', },
    { Header: 'Description', accessor: 'description',showSortIcons: false, },
    { Header: 'Address', accessor: 'address',showSortIcons: false, },
    { Header: 'Remark', accessor: 'remark',showSortIcons: false, },
    
   
   
  ];

  return (
    <>
        <MDBox>
        <MDTypography variant="h5" fontWeight="medium">
           Store
         </MDTypography>
         <MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  pagination
                  rowsPerPage={5}
                  showPagination
                 
                />
         </MDBox>
        
        
        </MDBox>

</>
  )
}

export default Stores;