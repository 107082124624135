import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MDButton from 'components/MDButton';
import { axiosInstance } from 'api2';
import swal from "sweetalert";
import { useParams } from 'react-router-dom';

const EditEducationalBackgroundDialog = ({ open, onClose, background }) => {
  const [formData, setFormData] = useState({
    level: '',
    educational_institution: '',
    education_type: '',
    attended_year: '',
    document_path: '',
  });
  const {id} = useParams();


  useEffect(() => {
    if (background) {
      setFormData({
        level: background.level,
        educational_institution: background.educational_institution,
        education_type: background.education_type,
        attended_year: background.attended_year,
      });
    }
  }, [background]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      document_path: e.target.files[0], // For file uploads, store the file directly
    }));
  };

  const handleSubmit = async () => {
    try {
      // employees/{employee}/educational-backgrounds/{id}
      const response = await axiosInstance.post(`/employees/${id}/educational-backgrounds/${background.id}`, formData,{
        headers: {
            'Content-Type': 'multipart/form-data',
          },
      });
      // Handle success (e.g., update the UI or show a message)
      console.log("response:",response.status)
      if (response.status === 200) {
        onClose();
        swal({
          title: "Education Background Updated Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        }).then(() => window.location.reload());
        // window.location.reload();

      }
      onClose(); // Close the dialog after success
    } catch (error) {
      // Handle errors (e.g., show validation errors)
      console.error(error.response.data);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Educational Background</DialogTitle>
      <DialogContent>
        <TextField
          name="level"
          label="Education Level"
          value={formData.level}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="educational_institution"
          label="Educational Institution"
          value={formData.educational_institution}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="education_type"
          label="Education Type"
          value={formData.education_type}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
        type='date'
          name="attended_year"
          label="Attended Year"
          value={formData.attended_year}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
           <input
          type="file"
          name="document_path"
          onChange={handleFileChange}
        />
      </DialogContent>
      <DialogActions>
      <MDButton   variant="gradient"
                       size="small"
                       color="info"
                       sx={{background:"#00274D"}} onClick={handleSubmit}>Save</MDButton>
        <MDButton   variant="gradient"
                       size="small"
                       color="black"
                       sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditEducationalBackgroundDialog;
