import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from '@mui/material/Switch';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const AddBranchDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    name: "",
    address: "",
    branch_category: "",
    is_extension: 0,
  });
  const [loading, setLoading] = useState(false); 

  const [error, setError] = useState({});

  const token = localStorage.getItem("authToken");

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", // Clear the error for this field when it's being modified
    });
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/branches`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Branch Created Successfully!",
          icon: "success",
        });

        setModalData({
          name: "",
          address: "",
          branch_category: "",
          is_extension: 0,
        });
        setError({});
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.error;
        setError(errors); // Set the error object
      }
    }finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}>
        <AddIcon /> Add Branch
      </DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Branch Name
          </MDTypography>
          <MDInput
            type="text"
            name="name"
            fullWidth
            required
            value={modalData.name}
            onChange={handleModalChange}
            error={!!error.name} // MUI error handling
          />
          {error.name && (
            <MDTypography variant="caption" color="error">
              {error.name[0]} {/* Show the error message for the 'name' field */}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Address
          </MDTypography>
          <MDInput
            type="text"
            name="address"
            fullWidth
            required
            value={modalData.address}
            onChange={handleModalChange}
            error={!!error.address}
          />
          {error.address && (
            <MDTypography variant="caption" color="error">
              {error.address[0]} {/* Show the error message for the 'address' field */}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Branch Category
          </MDTypography>
          <MDInput
            type="text"
            name="branch_category"
            fullWidth
            required
            value={modalData.branch_category}
            onChange={handleModalChange}
            error={!!error.branch_category}
          />
          {error.branch_category && (
            <MDTypography variant="caption" color="error">
              {error.branch_category[0]} {/* Show the error message for the 'branch_category' field */}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Is Extension
          </MDTypography>
          <Switch
            checked={modalData.is_extension === 1}
            onChange={(e) =>
              setModalData({
                ...modalData,
                is_extension: e.target.checked ? 1 : 0,
              })
            }
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddBranchDialog;
