import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import { STORAGE_BASE_URL } from "api";



const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontSize: 10,
      borderBottom: "5px solid black",
    },
    header: {
      borderBottom: "3px solid black",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    boldText: {
      fontWeight: "bold",
    },
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      borderRight: "none",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      borderRight: "none",
      paddingBottom: "8px",
    },
    tableHeader: {
      backgroundColor: "#f4f4f4",
      fontWeight: "bold",
      borderRight: "none",
    },
    tableCell: {
      flex: 1, // Ensures that cells are distributed equally
      borderRight: "none",
    },
    lastCell: {
      borderRight: "none",
    },
  });

  export default function ClearancePDF({rows, student, info}) {
    return (
      <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          {/* Logo and college name */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: "50px",
                height: "50px",
              }}
              src={{
                uri: "/mks-logo.png",
              }}
            ></Image>
            <View>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Meserete Kristos College
              </Text>
              <Text>Student Clearance Form</Text>
            </View>
          </View>

          {/* Student info */}
          {student && <View style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}>
            <Image
              style={{
                width: "50px",
                height: "50px",
              }}
              src={{
                uri: `${STORAGE_BASE_URL}/${student.profile_picture}`, method: "GET"
              }}
            ></Image>

            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                  <Text>Name:</Text>
                </View>
                <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                  <Text>{`${student.first_name} ${student.last_name}`}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                  <Text>Program of Study:</Text>
                </View>
                <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                  <Text>{student.program.program_name}</Text>
                </View>
              </View>
            </View>
          </View>}
        </View>

        <View style={{ paddingTop: "10px", paddingBottom: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={styles.title}>{`Year: ${info.year}`}</Text>
          <Text style={styles.title}>{`Date of Request: ${info.date_requested}`}</Text>
          <Text style={styles.title}>{`Reason: ${info.reason}`}</Text>
        </View>


        <View style={styles.section}>
          {/* Table Header */}
          <View style={[styles.tableRow]}>
            <Text style={styles.tableCell}>Department</Text>
            <Text style={styles.tableCell}>Person In Charge</Text>
            <Text style={styles.tableCell}>Date Approved</Text>
          </View>

          {/* Table Rows */}
          {rows && rows.map((row, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCell}>{row.department}</Text>
              <Text style={styles.tableCell}>{row.person}</Text>
              <Text style={styles.tableCell}>{row.date_approved}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
    )
  }
