 
import PropTypes from "prop-types";

 

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
 

function Footer({ light }) {
 
  return (
    <MDBox position="absolute" width="100%" bottom={0} py={4}>
      
    </MDBox>
  );
}

 Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
