import { Box, Card, CardContent, Divider, Tab, Tabs } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useEffect, useState } from 'react';
import Address from './Address';
import EducationalBackground from './EducationalBackground';
import FamilyBackground from './familyBackground';
import PersonalBackground from './personalBackground';
// import OthersDetail from './o';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const EmployeementListDetail = () => {
  const [value, setValue] = useState(0);
  const [employees,setEmployees] = useState({});
  const {id} = useParams();
  console.log(id);
  
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/employees/${id}`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });
      
        setEmployees(response.data);
      } catch (err) {
      } finally {
      }
    };
    fetchEmployees();
  }, [id, token]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTab = (label, index) => (
    <Tab
      label={
        value === index ? (
          <span style={{ color: 'white' }}>{label}</span>
        ) : (
          label
        )
      }
      {...a11yProps(index)}
    />
  );

  return (
    <DashboardLayout>
      {/* Employee Info Card */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
        <MDBox display="flex" alignItems="center" gap={4} flexDirection={{ xs: 'column', sm: 'row' }}>
  {/* Profile Picture */}
  <img 
    src={`${STORAGE_BASE_URL}/${employees.profile_picture}`}
    alt="Profile"
    style={{ 
      width: '170px', 
      height: '170px', 
      borderRadius: '50%', 
      marginRight: { xs: '0', sm: '16px' }, // Remove margin on small screens
      marginBottom: { xs: '16px', sm: '0' }, // Add margin below on small screens
      objectFit: 'cover' 
    }}
  />

  {/* Employee Info */}
  <MDBox>
    <MDTypography variant="body2">
      <strong>Name:</strong> {employees.first_name} {employees.last_name}
    </MDTypography>
    <MDTypography variant="body2">
      <strong>Employee Id:</strong> {employees.user_id}
    </MDTypography>
    <MDTypography variant="body2">
      <strong>Position:</strong> {employees.Position}
    </MDTypography>
    <MDTypography variant="body2">
      <strong>Telephone:</strong> {employees.phone}
    </MDTypography>
  </MDBox>
</MDBox>

        </CardContent>
      </Card>

      {/* Divider */}
      <Divider sx={{ mb: 2 }} />

      <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }} >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
         TabIndicatorProps={{
          style: {
            backgroundColor: "#00274D",
          }
        }}
        sx={{
          "& .MuiTab-root": {
            marginRight: 2, // Uniform spacing between tabs
          },
          "& .MuiTab-root:last-child": {
            marginRight: 0, // Remove margin on the last tab
          }
        }}
        >
            {renderTab("PersonalBackground", 0)}
            {renderTab("FamilyBackground", 1)}
            {renderTab("Address", 2)}
            {renderTab("EducationalBackground", 3)}
            {renderTab("Payroll", 4)}
            {renderTab("Addition/Deduction", 5)}
            

        </Tabs>
      </MDBox>
      <CustomTabPanel value={value} index={0}>
        <PersonalBackground  
            firstName={employees.first_name}
            lastName={employees.last_name}
            motherName={employees.mother_name}
            nationality={employees.nationality} // Pass nationality
            birthDate={employees.birth_date}
            grandFatherName={employees.grand_father_name}
            birthPlace={employees.birth_place}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <FamilyBackground 
            marriageStatus={employees.is_married}
            motherName={employees.mother_name}
            spouseFullName={employees.spouse_full_name}
            isMarried={employees.is_married}
            emergencyFullName={employees.emergency_full_name}
            emergencyAddress={employees.emergency_adress}
            emergencyPhone={employees.emergency_phone}
            phone={employees.phone}
            kids={employees.kids}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Address 
          residentCity={employees.resident_city}
          woreda={employees.woreda}
          kebele={employees.kebele}
          houseNumber={employees.house_number}
          housePhone={employees.house_phone}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <EducationalBackground
          educationalBackground={employees.educational_backgrounds} 
        />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={4}>
        <Payroll payroll={employees.payroll}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Addition_deduction />
      </CustomTabPanel> */}





    </DashboardLayout>
  );
};

export default EmployeementListDetail;
