import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import LoadingDialog from "components/Loading/LoadingDialog";

const Bincard = () => {
    const token =  localStorage.getItem("authToken");
    const [loading, setLoading] = useState(false); 
    const[bincard,setBincard] = useState([])

    
      const fetchAsset = useCallback(async () => {
     setLoading(true);
        try {
          const response = await axios.get(`${API_BASE_URL}/bincard`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
                 
          setBincard(response.data);
        } catch (err) {
          console.error(err);
        } finally{
          setLoading(false);
        }
       
      },[token]);
      useEffect(() => {

        fetchAsset();
      },[fetchAsset]);

      const columns = [
        { Header: 'Asset Code', accessor: 'asset.code',showSortIcons: true, },
        { Header: 'Asset Name', accessor: 'asset.name',showSortIcons: true, },
        { Header: 'In Quantity', accessor: 'in_quantity',showSortIcons: true, },
        { Header: 'OUt Quantity', accessor: 'out_quantity',showSortIcons: true, },
        { Header: 'Balance', accessor: 'balance',showSortIcons: true, },
        { Header: 'Date', accessor: 'date',showSortIcons: false, },
        { Header: 'Location', accessor: 'store_location.name',showSortIcons: false, },
      ];
  return (

    <DashboardLayout>
        <Card>
            <CardContent>

        <MDBox >
                <MDTypography variant="h5" fontWeight="medium">
                   Bincard Balance
                 </MDTypography>
                 <MDBox>
                
                <MDBox >
                    <DataTable
                      table={{
                        columns,
                        rows: bincard
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
                    />
                </MDBox>
                
                </MDBox>
                
        </MDBox>
                        
        </CardContent>
        </Card>
        <LoadingDialog open={loading} />
        </DashboardLayout>
  )
}

export default Bincard