import React, { useState, useEffect, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import { axiosInstance } from 'api2';
import FileViewer from "components/FileViewer";
import MDButton from "components/MDButton";
import LoadingDialog from 'components/Loading/LoadingDialog';


const StudentMaterial = () => {
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const targetId = localStorage.getItem("targetId");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const fetchMaterials = useCallback(async () => {
    try {
      setLoading(true);
      
      // Fetch materials from the API using the targetId
      const response = await axiosInstance.get(`student/${targetId}/materials`);
      
      const formattedMaterials = response.data && response.data.map(material => ({
        id: material.id,
        materialName: material.material_name,
        materialType: material.material_type,
        dueDate: material.due_date,
        remark: material.remark,
        filePath: material.file_path,
        course: material.course,
        instructor: material.instructor,
        sharedBy: material.instructor ? material.instructor.full_name : 'N/A',
      }));

      setMaterials(formattedMaterials);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [targetId]);

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };

  useEffect(() => {
    fetchMaterials();
  }, [fetchMaterials]);

  const columns = [
    { Header: 'Material Name', accessor: 'materialName' },
    { Header: 'Course Number', accessor: 'course.course_number', showSortIcons: false },
    { Header: 'Course Name', accessor: 'course.course_name' },
    { Header: 'Material Type', accessor: 'materialType', showSortIcons: false },
    { Header: 'Remark', accessor: 'remark', showSortIcons: false },
    {
      Header: 'File Path',
      accessor: 'filePath',
      Cell: ({ value }) => (
        <MDButton
          variant="body2"
          color="primary"
          onClick={() => handleViewFile(value)}
          sx={{ cursor: 'pointer', boxShadow: 1, transition: 'box-shadow 0.3s ease-in-out', '&:hover': { color: 'secondary.main', boxShadow: 4 } }}
        >
          View File
        </MDButton>
      ),
    },
  ];



  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Card>
          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Material
            </MDTypography>
            
            <MDTypography variant="caption" fontWeight="small">
              Materials for each course and instructor
            </MDTypography>

            <MDBox mt={2}>
  

    <DataTable
      table={{
        columns,
        rows: materials,
      }}
      canSearch
      sortable
      pagination
      rowsPerPage={10}
      showPagination
      noEndBorder
    />

{!loading && materials.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No materials available
          </MDTypography>
        )}
 
     
            </MDBox>
            <FileViewer open={openDialog} onClose={() => setOpenDialog(false)} filePath={selectedFile} fileType={fileType} />
      <LoadingDialog open={loading} />
          </CardContent>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default StudentMaterial;
