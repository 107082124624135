import React, { useState ,useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Autocomplete,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import API_BASE_URL from 'api';
import axios from 'axios';
import MDButton from 'components/MDButton';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import LoadingDialog from 'components/Loading/LoadingDialog';






const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json',
  },
});
const ConvertToPurchase = ({ open, onClose, onAdd,detaildata }) => {
   

    const [supplier, setSupplier] = useState(detaildata.supplier_name || '');
    useEffect(() => {
      if (detaildata && detaildata.supplier_name) {
          setSupplier(detaildata.supplier_name);
      }
  }, [detaildata]);
    const [remark, setRemark] = useState("");
    const [stores, setStores] = useState([]);
    const navigate = useNavigate();
    const [selectedStore, setSelectedStore] = useState(null);
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            const [storesResponse] = await Promise.all([
              axiosInstance.get("/stores"),
            ]);
            setStores(storesResponse.data);
          } catch (err) {
            console.error("Failed to fetch data:", err);
          }finally{
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    


      
      
      
      const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
      
        try {
      
         
          const requestPayload = {
            store_id: selectedStore.id , 
            supplier_name: supplier,
            remark: remark,    
          };

          console.log("requestPayload:",requestPayload)
          const response = await axiosInstance.post(`/purchase-requests/${detaildata.id}/convert`, requestPayload);
           setSelectedStore(null);
           setSupplier("");
           setRemark("");
          onAdd();  

          console.log("response:",response)
          onClose();  
          swal({
            title: "Purchase Request converted successfully!",
            icon: "success",
          });
          navigate('/finance/purchase-list');
        } catch (err) {
          swal({
            title: err.response.data.error,
            icon: "warning",
          });
        }finally{
          setLoading(false);
        }
      };
      
  
  return (
    <DashboardLayout>
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle textAlign="center">
        <Add /> Convert Purchase Request
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={1}>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
              label="Supplier"
              value={supplier}
              onChange={(e) => {
                setSupplier(e.target.value)
              }}
              fullWidth
            />
          </Grid>
         
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              label="Remark"
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value)
              }}
              fullWidth
            />
          </Grid>
           
          <Grid item xs={12} md={12} lg={12}>
            <Autocomplete
              options={stores}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedStore(newValue);
              }}
              value={selectedStore}
              renderInput={(params) => (
                <TextField {...params} label="Store" />
              )}
            />
          </Grid>
        </Grid>       
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
    <LoadingDialog open={loading} />

  </DashboardLayout>

  )
}

export default ConvertToPurchase