import React, { useState } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import swal from 'sweetalert';
import API_BASE_URL from 'api';
import MDButton from 'components/MDButton';
import { CheckCircle } from '@mui/icons-material';
import LoadingDialog from 'components/Loading/LoadingDialog';

const GoodsRecievingApprovalModal = ({ open, onClose, detailData }) => {
    const [declineReason, setDeclineReason] = useState('');
    const [action, setAction] = useState('approve'); 
    const token = localStorage.getItem("authToken");
    const [loading, setLoading] = useState(false); 
    const handleApprove = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${API_BASE_URL}/goods-receiving-vouchers/${detailData.id}/approve`,
                { action: 'approve' }, 
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (response.status === 200) {
                onClose();
                swal({ title: response.data.message, icon: "success" });
            }
        } catch (error) {
            onClose();
            swal({ title: error.response.data.message, icon: "error" });
        } finally{
            setLoading(false);
        }
    };

    const handleDecline = async () => {
        if (!declineReason) {
            swal({ title: "Please provide a reason for decline.", icon: "warning" });
            return;
        }

        
        try {
            setLoading(true);
            const response = await axios.post(
                `${API_BASE_URL}/goods-receiving-vouchers/${detailData.id}/approve`,
                { action: 'decline', reason: declineReason }, 
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (response.status === 200) {
                onClose();
                swal({ title: response.data.message, icon: "success" });
            }
        } catch (error) {
            onClose();

            swal({ title: error.response.data.message, icon: "error" });
        } finally{
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        if (action === 'approve') {
            handleApprove();
        } else {
            handleDecline();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogTitle textAlign={"center"}>
            <CheckCircle style={{ color: 'green', marginRight: '8px' }} /> Approve or Decline Applicant
            </DialogTitle>
            <DialogContent>
                <FormLabel component="legend">Select Action</FormLabel>
                <RadioGroup value={action} onChange={(e) => setAction(e.target.value)}>
                    <FormControlLabel value="approve" control={<Radio />} label="Approve" />
                    <FormControlLabel value="decline" control={<Radio />} label="Decline" />
                </RadioGroup>
                
                {action === 'decline' && (
                    <TextField
                        autoFocus
                        margin="dense"
                        id="declineReason"
                        label="Reason for Decline"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4} 
                        value={declineReason}
                        onChange={(e) => setDeclineReason(e.target.value)}
                    />
                )}
            </DialogContent>
            <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleSubmit}
        >
          {action.charAt(0).toUpperCase() + action.slice(1)}
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
        </Dialog>
    );
};

export default GoodsRecievingApprovalModal;