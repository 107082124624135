import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import { Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateStudentInfo,updateFile } from "../../../redux/features/studentInfoSlice";
import {  useRef } from "react";
function Applications() {
  const navigate = useNavigate();
  const student = useSelector((state) => state.studentInfo);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateStudentInfo({ [name]: value }));
  };

  const handleNext = async () => {
    const form = formRef.current; 
    const fileInput = form.querySelector('input[name="profile_picture"]');

    if (student.profile_picture) {
      fileInput.removeAttribute('required');
    }
  
    // Perform the validation check
    if (form.checkValidity()) {
      navigate("/applicationPage2"); // Proceed if form is valid
    } else {
      form.reportValidity(); // Show validation errors if invalid
      alert('Please fill out all required fields.');
    }
  
    // Restore the "required" attribute if necessary
    if (!student.profile_picture) {
      fileInput.setAttribute('required', true);
    }
  };
  



  


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      dispatch(updateFile({ field: 'profile_picture', file: selectedFile })); // Update Redux state with the file
    }
  };
  return (
    <DashboardLayout>
      <Card>
        <MDBox
          borderRadius="lg"
          p={2}
          textAlign="center"
          position="relative"
        >
          <MDTypography variant="h4" fontWeight="medium">
            Application Form
          </MDTypography>
       
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form"  ref={formRef} id="studentForm" role="form">
            <MDTypography variant="h5" fontWeight="bold" gutterBottom>
              Personal Data
            </MDTypography>
            <Grid container spacing={3}>
              {/* First Row of Inputs */}
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    First Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="first name"
                    name="first_name"
                    fullWidth
                    value={student.first_name}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="last name"
                    name="last_name"
                    fullWidth
                    value={student.last_name}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Private Residential Address <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="private address"
                    name="private_address"
                    fullWidth
                    value={student.private_address}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>

              {/* Second Row of Inputs */}
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Telephone <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="number"
                    label="phone"
                    name="phone"
                    fullWidth
                    value={student.phone}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Postal Address <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="postal address"
                    name="postal_address"
                    fullWidth
                    value={student.postal_address}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Email <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="email"
                    label="email 1"
                    name="email_1"
                    fullWidth
                    value={student.email_1}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>

              {/* Third Row of Inputs */}
              <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Email 2
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="email 2"
                    name="email_2"
                    fullWidth
                    value={student.email_2}
                    onChange={handleInputChange}
                  />
                </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>

              <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Place of Birth <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Place Of Birth"
                    name="place_of_birth"
                    fullWidth
                    value={student.place_of_birth}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Nationality at Birth <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Nationality at Birth"
                    name="nationality_at_birth"
                    fullWidth
                    value={student.nationality_at_birth}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
       
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                      Gender <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="gender-label"   sx={{ 
        backgroundColor: '#f5f5f5', 
        px: 1,
        my: 0.5,
        transform: 'translate(14px, -9px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        }
      }}>Select Gender</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender-select"
                      label="Gender"
                      name="gender"
                      required
                      value={student.gender}
                      onChange={handleInputChange}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: 1,
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "32px",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          sx={{
                            position: "absolute",
                            right: 8,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                   
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Date of Birth <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="date"
                    name="date_of_birth"
                    value={student.date_of_birth}
                    onChange={handleInputChange}
                    required
                    fullWidth
                  />
                </MDBox>
              </Grid>

              {/* Fourth Row of Inputs */}
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Nationality <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="nationality"
                    name="nationality"
                    fullWidth
                    required
                    value={student.nationality}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Profile Picture <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <input
                    type="file"
                    style={{ display: "block", marginTop: 8 }}
                    name="profile_picture"
                    required
                    onChange={handleFileChange}
                  />
                 
                     {
                    student.profile_picture && (
                      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                       {   student.profile_picture 
                       && <p> {  student.profile_picture.name}</p>
                       }
                    </MDTypography>
                    )
                  }
                  
                </MDBox>
              </Grid>
            </Grid>

            <MDTypography variant="h6" fontWeight="semibold" gutterBottom>
              Person to be notified in case of emergency
            </MDTypography>
            {/* Fifth Row */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="emergency name"
                    name="emergency_name"
                    fullWidth
                    required
                    value={student.emergency_name}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Telephone<span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="number"
                    label="emergency phone"
                    name="emergency_phone"
                    fullWidth
                    required
                    value={student.emergency_phone}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Relation Ship <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="relation ship"
                    name="emergency_relationship"
                    fullWidth
                    required
                    value={student.emergency_relationship}
                    onChange={handleInputChange}
                  />
                </MDBox>

              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                  address <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="emergency address"
                    name="emergency_address"
                    fullWidth
                    required
                    value={student.emergency_address}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDTypography variant="h6" fontWeight="bold" gutterBottom>
              Religious Background
            </MDTypography>
            <Grid container spacing={3}>
              {/* First Row of Inputs */}
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Denomination <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="denomination"
                    name="denomination"
                    fullWidth
                    value={student.denomination}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Local Congregation <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Local Congregation"
                    name="local_congregation"
                    fullWidth
                    value={student.local_congregation}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Telephone <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="telephone"
                    name="church_phone"
                    fullWidth
                    value={student.church_phone}
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                  address <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Church adress"
                    name="denomination_address"
                    fullWidth
                    required
                    value={student.denomination_address}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              {/* Second Row of Inputs */}
            
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    When did you receive jesus christ as your saviour? <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="date"
                    // label="jesus_receive_date"
                    name="jesus_receive_date"
                    fullWidth
                    value={student.jesus_receive_date}
                    required
                    
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    For how long you have been in minstry <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="number"
                    label="ministry duration"
                    name="ministry_duration"
                    fullWidth
                    value={student.ministry_duration}
                    
                    required
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Please mention your experience in minstry  <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="ministry experience"
                    name="ministry_experience"
                    fullWidth
                    value={student.ministry_experience}
                    required
                    multiline
                    rows={5}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>

              {/* Third Row of Inputs */}
              
            </Grid>
            {/* Buttons */}
            <MDBox
              mt={4}
              mb={1}
              display="flex"
              justifyContent="center"
              position="absolute"
              bottom={{ xs: -6, md: -4 }}
              right={76}
            >
              <MDButton type="submit" variant="contained" color="info" onClick={handleNext} size="small" sx={{paddingY: { xs: 1, sm: -5} }}>
                Next
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default Applications;
