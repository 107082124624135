import React, { useEffect, useState, } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {
    Box,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
} from '@mui/material';
import MDButton from 'components/MDButton';
import { axiosInstance } from 'api2';
import { useParams,useNavigate } from 'react-router-dom';
import LoadingDialog from 'components/Loading/LoadingDialog';



const ViewEvaluation = () => {
    // Initialize as an empty object
    const [evaluationsData, setEvaluations] = useState({});
    const {id} = useParams();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
  
  
   

    useEffect(() => {
        const fetchEvaluations = async () => {
            try {
                const response = await axiosInstance.get(`/teacher/${id}/evaluations`);
                setEvaluations(response.data); // Expecting an object now
            } catch (err) {
                console.error("Error fetching evaluations:", err.response);
            }
            finally{
                setLoading(false);
            }
        };
        fetchEvaluations();
    }, [id]);


    const handleClose = () => {

        navigate('/evaluation')
    };


    return (
        <Dialog open={true} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Evaluation Details</DialogTitle>
        <DialogContent>
          {/* Handle object data instead of an array */}
          {evaluationsData && Object.keys(evaluationsData).length > 0 ? (
            <Box sx={{ mt: 2, border: '1px solid #ccc', borderRadius: 1, overflowX: 'auto' }}>
              <Box sx={{ minWidth: '600px' }}>
                <Box sx={{ display: 'flex', backgroundColor: '#f0f0f0', padding: 1, fontSize: '1.1rem' }}>
                  <Box sx={{ flex: 2, padding: 1 }}>
                    <strong>Evaluation Item</strong>
                  </Box>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <Box key={value} sx={{ flex: 1, textAlign: 'center', padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <strong>{value}</strong>
                    </Box>
                  ))}
                </Box>
              </Box>
      
              {/* Iterate over the object values */}
              {Object.values(evaluationsData).map((evalItem) => (
                <Box key={evalItem.evaluation_id} sx={{ display: 'flex', borderBottom: '1px solid #ccc', padding: 1, minWidth: '600px' }}>
                  <Box sx={{ flex: 2.3, fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
                    {evalItem.evaluations[0].evaluation.description}
                  </Box>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <Box key={value} sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 1 }}>
                      <RadioGroup
                        row
                        value={evalItem.average_point}
                        onChange={() => {}}  // Disabled since average_point is read-only
                      >
                        <FormControlLabel
                          sx={{
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            borderRadius: '50%',
                            padding: '2px',
                            margin: '0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          value={String(value)}  // Compare value to average_point
                          control={<Radio />}
                          label={null}
                          checked={evalItem.average_point === value}  // Show checked only if it matches
                          disabled  // Disable the radio button since it's for display only
                        />
                      </RadioGroup>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px" mt={2}>
              <Typography variant="h6" color="textSecondary">
                No Evaluation Items Available
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton variant="gradient" size="small" color="info" sx={{ background: "#00274D" }}  onClick={handleClose}>
            Close
          </MDButton>
        </DialogActions>
        <LoadingDialog open={loading} />
      </Dialog>
      
      
      
    );
};

export default ViewEvaluation;
