import { Button, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  PDFDownloadLink
} from "@react-pdf/renderer";
import API_BASE_URL from "api";
import axios from "axios";
import ClearancePDF from "components/ClearancePDF";
import MDBox from "components/MDBox";
import { formatDate } from "date-fns";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { checkPermission } from "utils/checkPermission";
import Confirm from "../confirm";
import DeclineRequest from "../declineRequest";

// Departments to iterate over
const departments = [
  "Dormitory",
  "Library",
  "Finance",
  "General Service",
  "Store",
  "Public Relations",
  "Administration",
  "Student Dean",
  "Academic Dean",
  "Registrar",
];

export default function ViewClearanceRequestDetail({ data, open, onClose }) {
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");

  const [rows, setRows] = useState();
  const [student, setStudent] = useState();
  const [openDecline, setOpenDecline] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [allApproved, setAllApproved] = useState(false);


  const columns = [
    {
      Header: "",
      accessor: "department",
      showSortIcons: false,
    },
    { Header: "Person In Charge", accessor: "person", showSortIcons: false },
    { Header: "Status", accessor: "status", showSortIcons: false },
    {
      Header: "Date of Approved",
      accessor: "date_approved",
      showSortIcons: false,
    },
  ];

  const handleApprove = async () => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/student-clearance/${data.id}`,
        {
          approved_by_id: userId,
          approval_type:
            role === "admin" ? "administration" : role.replace(" ", "_"),
          status: "approved",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        onClose();
        swal({
          title: "Request Approved Successfully!",
          icon: "success",
        });
        setRows(null);
      }
    } catch (error) {
      console.error("Failed to approve request", error);
    }
  };

  const handleDeclineClose = () => {
    setOpenDecline(false);
    onClose();
  };

  useEffect(() => {
    const getApprovalDetails = (department) => {
      const approvalKey = `approval_${department
        .toLowerCase()
        .replace(" ", "_")}`;
      const approval = data && data[approvalKey];

      return {
        person:
          approval && approval.user ? approval.user.full_name || "N/A" : "N/A",
        status: approval ? approval.status || "Pending" : "Pending",
        date_approved: approval
          ? formatDate(approval.created_at, "yyyy-M-dd") || "N/A"
          : "N/A",
      };
    };

    const currentRows = departments.map((department) => ({
      department,
      ...getApprovalDetails(department),
    }));

    setRows(currentRows);
    setStudent(data.student);

    // Check if all departments are approved
    const allApproved = currentRows.every((row) => row.status === "approved");
    setAllApproved(allApproved); // Set the allApproved state
  }, [data]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>Clearance Detail</DialogTitle>
      <DialogContent>
        <MDBox>
          <MDBox mb={2}>
            Student Name:{" "}
            {`${data.student.first_name} ${data.student.last_name}`}
          </MDBox>
          <MDBox mb={2}>
            Date of Requested: {formatDate(data.date_requested, "yyyy-M-dd")}
          </MDBox>
          <MDBox mb={2}>Year: {data.year.year_name}</MDBox>
          <MDBox mb={2}>Reason: {data.reason}</MDBox>
        </MDBox>
        {rows && (
          <DataTable
            table={{
              columns,
              rows: rows,
            }}
            canSearch={false}
            sortable
            pagination={false}
            entriesPerPage={{ defaultValue: 10 }}
            showTotalEntries={false}
            showPagination={false}
          />
        )}
      </DialogContent>
      <DialogActions>
        {checkPermission("approve-student-clearance") &&
          (() => {
            const approvalKey = `approval_${
              role === "admin" ? "administration" : role.replace(" ", "_")
            }`;
            const approval = data[approvalKey];
            const isApproved =
              approval === null || approval.status === "approved";
            const isDeclined =
              approval === null || approval.status === "declined";

            return (
              <>
                {isApproved && (
                  <Button
                    style={{
                      margin: "20px",
                      padding: "5px 20px",
                      border: "1px solid #00274D",
                      color: "#00274D",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "13px",
                      transition: "background-color 0.3s",
                    }}
                    onClick={() => setOpenDecline(true)}
                  >
                    Decline
                  </Button>
                )}

                {isDeclined && (
                  <Button
                    style={{
                      margin: "20px",
                      padding: "5px 20px",
                      backgroundColor: "#00274D",
                      color: "#FFFFFF",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "13px",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "#0056b3")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "#00274D")
                    }
                    onClick={() => setOpenConfirm(true)}
                  >
                    Approve
                  </Button>
                )}
                 {allApproved && checkPermission('print-clearance-pdf') && (
                <PDFDownloadLink
                  document={<ClearancePDF rows={rows} student={student} info={{year: data.year.year_name, reason: data.reason, date_requested: data.date_requested}} />}
                  fileName={`grade-report.pdf`}
                >
                  {({ blob, url, loading, error }) => (
                    <Button
                    style={{
                      margin: "20px",
                      padding: "5px 20px",
                      backgroundColor: "#00274D",
                      color: "#FFFFFF",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "13px",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "#0056b3")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "#00274D")
                    }
                  >
                    Download PDF
                  </Button>
                  )}
                </PDFDownloadLink>
              )}
              </>
            );
          })()}
      </DialogActions>

      <DeclineRequest
        open={openDecline}
        onClose={handleDeclineClose}
        id={data.id}
      />
      <Confirm
        open={openConfirm}
        onConfirm={handleApprove}
        onCancel={() => setOpenConfirm(false)}
        title="Are you sure you want to approve this request?"
        description="Make sure the student doesn't possess any remaining resources or responsibilities."
      />
    </Dialog>
  );
}
