import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState,useCallback } from 'react';
import swal from 'sweetalert';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingDialog from 'components/Loading/LoadingDialog';
import AddStoreDialog from 'layouts/adminDashboard/storeRequest/AddStoreRequest';
import ViewRequest from 'layouts/adminDashboard/storeRequest/viewRequest';


const EmployeeStoreRequest = () => {
  const [search] = useState('');
  const token = localStorage.getItem("authToken");
  const targetId = localStorage.getItem("userId");


  const [requests, setRequests] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [detailData,setdetailData] = useState([])

  const [loading, setLoading] = useState(false); 
  // Fetch store requests
  const fetchStoreRequest = useCallback(async () => {
  setLoading(true);
    try {
          const response = await axios.get(`${API_BASE_URL}/employee/${targetId}/store-requests`, {
              headers: { "Authorization": `Bearer ${token}` }
          });
          setRequests(response.data);
      } catch (err) {
          console.error('Error fetching store requests:', err);
      } finally {
        setLoading(false);
      }
  },[token,targetId]);

  useEffect(() => {
      fetchStoreRequest();
  },[fetchStoreRequest]);

  const handleAddRequest = () => {
    fetchStoreRequest();
          };
  
  const handleDeleteStoreRequest = async (id) => {
    const request = requests.find(req => req.id === id);
    if (request.approved_by !== null) {
      await swal({
            title: "You Can't Delete this request?",
            text: "approval process is started request admins to decline it ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });
        return;
    }
    const confirm = await swal({
        title: "Are you sure?",
        text: "You will not be able to recover this request!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    });

    if (confirm) {
      setLoading(true);
        try {
            const response = await axios.delete(`${API_BASE_URL}/store-request/${id}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (response.status === 204) {
                setRequests(requests.filter((request) => request.id !== id));
                swal({
                    title: "Store Request Deleted Successfully!",
                    icon: "success"
                });
            }
        } catch (error) {
            swal({
                title: `${error.response.data.message}`,
                icon: "warning"
            });
        }finally{
          setLoading(false);
        }
    }
};

const handleViewDetails = (data) => {
  setdetailData(data);
  setOpenView(true);
  
}


const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenView(false);
    fetchStoreRequest();
};

const filteredData = search
      ? requests && requests.filter(request =>
          request.store.store_name.toLowerCase().includes(search.toLowerCase())
      ) : requests;

      const columns = [
        {
          accessor: 'action',
          Header: '',
          showSortIcons: false,
          width: 40,
          padding: 0,
          Cell: ({ row }) => (
            <PopupState popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      // handleOpenEdit(row.original);
                      popupState.close();
                    }} style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteStoreRequest(row.original.id);
                      popupState.close();
                    }} style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                    <MenuItem onClick={() => {
                    handleViewDetails(row.original);
                    popupState.close();
                  }} style={{ color: '#1976d2' }}>
                    <VisibilityIcon style={{ marginRight: 8 }} /> View Details
                  </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
        },
        { Header: 'Requested Date', accessor: 'requested_date',showSortIcons: false },
        { Header: 'Requested By', accessor: 'requested_by.full_name' }, // Updated to access `full_name` from `requested_by`
        { Header: 'Approved By', accessor: 'approved_by.full_name' },  // Updated to access `full_name` from `approved_by`
        { Header: 'Approved Date', accessor: 'approved_date',showSortIcons: false },
        { Header: 'Store Name', accessor: 'store_location.name' },
        { Header: 'Reason', accessor: 'reason' ,showSortIcons: false},
      ];
      

  return (
    <DashboardLayout>
          <MDBox>
              <Card>
                  <CardContent>
                      <MDTypography variant="h5" fontWeight="medium">
                          Store Request
                      </MDTypography>
                      <MDBox>
                          <DataTable
                              table={{
                                  columns,
                                  rows: filteredData,
                              }}
                              canSearch={true}
                              sortable={true}
                              pagination
                              rowsPerPage={5}
                              showPagination
                              widgets={
                                  <MDBox mx={2}>
                                      <MDButton
                                          variant="gradient"
                                          ml="auto"
                                          color="info"
                                          size="small"
                                          onClick={() => setOpenAdd(true)}
                                          sx={{ background: "#00274D", my: 4, mx: 3 }}
                                      >
                                          <span>Add</span>
                                      </MDButton>
                                  </MDBox>
                              }
                          />
                      </MDBox>
                      <AddStoreDialog
                          open={openAdd}
                          onClose={handleCloseDialog}
                          onAdd={handleAddRequest}
                      />
                  </CardContent>
                  {
                    detailData && (
                      <ViewRequest detailData={detailData} openView={openView}  onClose={handleCloseDialog}/>
                    )
                  }

              </Card>
          </MDBox>
          <LoadingDialog open={loading} />
      </DashboardLayout>
  )
}

export default EmployeeStoreRequest