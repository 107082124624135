import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import API_BASE_URL from 'api';
import LoadingDialog from 'components/Loading/LoadingDialog';
import swal from 'sweetalert';

const ResetPassword = () => {
    const token = useParams().token;
    const query = new URLSearchParams(useLocation().search);
    const email = query.get('email');
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setError('');  // Clear error on field change
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');
        
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords don't match");
            return;
        }
        setLoading(true);

        try {
            const response = await axios.post(`${API_BASE_URL}/reset-password`, {
                email,
                password: formData.password,
                password_confirmation: formData.confirmPassword,
                token,
            });
            if (response.status === 200) {
                swal({
                    title: "Password Reset Successfully!",
                    icon: "success",
                    timer: 3000,
                });
                navigate('/authentication/sign-in');
            }
            setMessage(response.data.message);
        } catch (err) {
            console.log("error", err.response);
            setError(err.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Reset Password
                    </MDTypography>
                </MDBox>
                {message && (
                    <MDBox mx={2} mt={2} mb={2}>
                        <Alert severity="success" variant="filled">
                            {message}
                        </Alert>
                    </MDBox>
                )}
                {error && (
                    <MDBox mx={2} mt={2} mb={2}>
                        <Alert severity="error" variant="filled">
                            {error}
                        </Alert>
                    </MDBox>
                )}
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form" onSubmit={handleSubmit}>
                        <MDBox mb={2}>
                            <MDInput
                                type="email"
                                label="Email"
                                value={email}
                                fullWidth
                                readOnly
                                required
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                type="password"
                                label="New Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                type="password"
                                label="Confirm Password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="info" fullWidth type="submit">
                                Reset Password
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <LoadingDialog open={loading} />
            </Card>
        </BasicLayout>
    );
};

export default ResetPassword;
