import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Alert } from '@mui/material';
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import Grid from "@mui/material/Grid";
import { Select, MenuItem, FormControl} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LoadingDialog from "components/Loading/LoadingDialog";

const AddFeeMangement = ({ open, onClose, onAdd }) => {
    const [modalData, setModalData] = useState({
        fee_type: "",
        fee_amount:null,
        frequency:"",
        applicable_for:"",
        description:""
      });
      const [error, setError] = useState(null);
      const [loading, setLoading] = useState(false); 

      const token = localStorage.getItem("authToken");
      const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalData({
          ...modalData,
          [name]: value,
        });
        setError(null);
      };

      const handleModalSubmit = async () => {
        setLoading(true);
        try {
            console.log("modalData",modalData)
          const response = await axios.post(`${API_BASE_URL}/fees`, modalData, {
            headers: { "Authorization": `Bearer ${token}` }
          });
         
          if (response.status === 201) {
            onAdd(response.data);
            onClose();
            swal({
              title: "Fees Created Successfully!",
              // text: "Course Created Successfully!",
              icon: "success"
            });
            setModalData({
              code: "",
              name:"",
              description:"",
              remark:""
            })
          }
        } catch (error) {
          setError(error.response?.data?.errors?.code[0]);
          
        }finally {
          setLoading(false); // Stop loading
        }
      };
    
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}><AddIcon /> Add</DialogTitle>
    <DialogContent>
      {error && (
        <MDBox mx={2} mt={2} mb={2}>
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        </MDBox>
      )}
       <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Fee Type
        </MDTypography>
        <MDInput
          type="text"
          name="fee_type"
          fullWidth
          required
          value={modalData.fee_type}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Fee Amount
        </MDTypography>
        <MDInput
          type="number"
          name="fee_amount"
          fullWidth
          required
          value={modalData.fee_amount}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Applicable For
        </MDTypography>
        <MDInput
          type="text"
          name="applicable_for"
          fullWidth
          required
          value={modalData.applicable_for}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Description
        </MDTypography>
        <MDInput
          type="text"
          name="description"
          fullWidth
          required
          value={modalData.description}
          multiline
          rows={3}
          onChange={handleModalChange}
        />
      </MDBox>
       {/* Gender */}
       <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                     Frequency
                  </MDTypography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    {/* <InputLabel id="Gender-label">Select Gender</InputLabel> */}
                    <Select
                      labelId="Frequency-label"
                      id="Frequency-select"
                      fullWidth
                      label="Frequency"
                      name="frequency"
                      required
                      value={modalData.frequency}
                      onChange={handleModalChange}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: 1,
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "32px",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          sx={{
                            position: "absolute",
                            right: 8,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                    >
                         <MenuItem value="">select frequency</MenuItem>
                      <MenuItem value="once">Once</MenuItem>
                      <MenuItem value="semester">Semester</MenuItem>
                      <MenuItem value="annual">Annual</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                </Grid>
      
     
      
    </DialogContent>
    <DialogActions>
      <MDButton   variant="gradient"
                     size="small"
                     color="info"
                     sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit</MDButton>
      <MDButton   variant="gradient"
                     size="small"
                     color="black"
                     sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />

  </Dialog>
  )
}

export default AddFeeMangement
