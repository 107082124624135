import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import AddIcon from '@mui/icons-material/Add';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import API_BASE_URL from "api";
import swal from "sweetalert";


const SubmitAssignment = ({ open, onClose,onAdd,id }) => {

  const targetId = localStorage.getItem("targetId");
  const token = localStorage.getItem("authToken");

  const [modalData, setModalData] = useState({
   
    assigned_students: [],
    assessment_name: "",
    due_date: "",
    assessment_type: "",
    
    // submitted_date: "",
    // score: 0,
    file: null, // Add file field here
    mark:"",
    remark:""
  });



  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/instructor/${targetId}/students`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      

      const studentIds = response.data[0].course_history.flatMap(student => 
        student.registrations.map(registration => registration.student.id) // Extract student IDs
      );
  

      
     
      setModalData({ ...modalData, assigned_students: studentIds});
      // setFilteredStudents(allStudents);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    fetchStudents();
  });


  const handleModalChange = (e) => {
    const { name, value } = e.target;

    setModalData({
      ...modalData,
      [name]: value,
    });
  };
  

  const handleFileChange = (e) => {
    setModalData({
      ...modalData,
      file: e.target.files[0], // Store the selected file
    });
  };

  const handleModalSubmit = async () => {
  
   
    const formData = new FormData();
    
    
    formData.append("remark", modalData.remark);
    formData.append("student_id",targetId)
  
   
    if (modalData.file) {
      formData.append("file_path", modalData.file); // Add file to formData if present
    } else {
      // Show alert if the file is not selected
      swal({
        title: "Warning!",
        text: "Please upload a file before submitting.",
        icon: "warning",
        button: "OK",
      });
      return; // Exit the function early
    }
  
  

    try {
      const response = await axios.post(
        `${API_BASE_URL}/assessment/${id}/accept-submission`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        }
      );

      if(response.status === 200){

        onAdd();
        console.log("response:",response.status)

        onClose();
        swal({
          title: "Assignment Submitted Successfully!",
          icon: "success",
        });
        
      }
    
    } catch (error) {
      console.error("Error submitting assessment:", error);
    }
  };

  return (
    <DashboardLayout>
      <Dialog open={open} onClose={onClose} maxWidth="sm">
        <DialogTitle textAlign={"center"}>
          <AddIcon /> Add Assignment
        </DialogTitle>
        <DialogContent>
          
          
        
          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Remark
            </MDTypography>
            <MDInput
              type="text"
              name="remark"
              fullWidth
              required
              value={modalData.remark}
              onChange={handleModalChange}
            />
          </MDBox>
        
          
          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Upload File
            </MDTypography>
            <MDInput
              type="file"
              name="file"
              fullWidth
              onChange={handleFileChange}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleModalSubmit}
          >
            Submit
          </MDButton>
          <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default SubmitAssignment;
