import React, { useState, useEffect, useRef  } from "react";
import MDInput from "components/MDInput";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Autocomplete, TextField, Grid, IconButton} from "@mui/material";
import axios from "axios";
import API_BASE_URL from "api";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import swal from "sweetalert";
import {  RadioGroup, FormControlLabel, Radio } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import LoadingDialog from "components/Loading/LoadingDialog";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";



// Axios instance for API requests
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const AddExam = () => {
  const token = localStorage.getItem("authToken");
  const targetId = localStorage.getItem("targetId");
  const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);
  const questionFormRef = useRef(null);
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);


  const [modalData, setModalData] = useState({
    instructor_id: targetId,
    name: "",
    date: "",
    exam_type: "",
    duration: null,
    questions: [],
    students: [],
  });

  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [newQuestion, setNewQuestion] = useState({
    question: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
    correct_answer: "",
  });

const handleCloseEditDialog = () => {
  setOpenEditDialog(false);
  resetQuestionForm();
};


  // Fetch available courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesData = await axiosInstance.get(`/instructor/${targetId}/courses`);
        const coursesArray = coursesData.data[0].course_history.map(course => ({
          id: course.course_id,
          name: course.course?.course_name || 'Unknown Course',
          deadline:course.mark_deadline
        }));

        const today = new Date();
        const filteredCourses = coursesArray.filter(course => {
          const deadlineDate = new Date(course.deadline);
          return deadlineDate > today;
        })
        // console.log("coursesArray:",coursesArray)
        // console.log("filteredCourses:",filteredCourses)

        setCourses(filteredCourses || []);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [targetId]);

  useEffect(() => {
    if (selectedCourse) {
      fetchStudents(selectedCourse.id);
    }
  }, [selectedCourse]);

  const fetchStudents = async (courseId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/course-history`, {
        headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` }
      });

      // Filter the courses by courseId
      const filteredCourses = response.data.filter(course => course.course_id === courseId);

      if (filteredCourses.length > 0) {
        const studentIds = filteredCourses[0].registrations.map(registration => registration.student_id);
        setModalData((prevData) => ({
          ...prevData,
          students: studentIds,
        }));
      } else {
        console.log("No courses found for the provided courseId.");
      }
    } catch (error) {
      console.error("Error fetching course history:", error);
    }
  };

  // Handle form data change
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle question form data change
  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion((prevQuestion) => ({
      ...prevQuestion,
      [name]: value,
    }));
  };

  const resetQuestionForm = () => {
    setEditingQuestionIndex(null);
    setNewQuestion({
      question: "",
      option_a: "",
      option_b: "",
      option_c: "",
      option_d: "",
      correct_answer: "",
    });
  };

  

  // Add new question to the list of questions
  const handleAddQuestion = () => {
    // Validate that all fields are filled
    const { question, option_a, option_b, option_c, option_d, correct_answer } = newQuestion;
    if (!question || !option_a || !option_b || !option_c || !option_d || !correct_answer) {
      swal("Error", "Please fill in all fields for the question.", "error");
      return;
    }

    setModalData((prevData) => ({
      ...prevData,
      questions: [...prevData.questions, newQuestion],
    }));
    setNewQuestion({
      question: "",
      option_a: "",
      option_b: "",
      option_c: "",
      option_d: "",
      correct_answer: "",
    });
  };

  const handleEditQuestion = (index) => {
    setEditingQuestionIndex(index);
    setNewQuestion(modalData.questions[index]);
    setOpenEditDialog(true);

    setTimeout(() => {
      questionFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };



  const handleUpdateQuestion = () => {
    const updatedQuestions = [...modalData.questions];
    updatedQuestions[editingQuestionIndex] = newQuestion;
    setModalData((prevData) => ({
      ...prevData,
      questions: updatedQuestions,
    }));
    setEditingQuestionIndex(null);
    setOpenEditDialog(false);
    resetQuestionForm();
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true)
    try {
      const dataToSubmit = {
        ...modalData,
        course_id: selectedCourse?.id, // Ensure the course ID is included
      };
    
      const response = await axios.post(`${API_BASE_URL}/exams`, dataToSubmit, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 201) {
        swal({
          title: "Exam Created Successfully!",
          icon: "success"
        });
        // Clear all form data
        setModalData({
          instructor_id: targetId, // Retain instructor_id
          name: "",
          date: "",
          exam_type: "",
          duration: null,
          questions: [],
          students: [],
        });
        setNewQuestion({
          question: "",
          option_a: "",
          option_b: "",
          option_c: "",
          option_d: "",
          correct_answer: "",
        });
        setSelectedCourse(null);
        navigate('/exam')
      }
    } catch (error) {
      console.error("Error submitting exam:", error);
      swal("Error", "There was an issue submitting the exam. Please try again.", "error");
    } finally {
      setLoading(false)
    }
  };

  return (
    <DashboardLayout>
      <Card>
      <CardContent>
        <MDButton
            variant="gradient"
            ml="auto"
                color="info"
                size="small"
                onClick={() => navigate("/exam")}
                sx={{background:"#00274D", my:2,mx:3}}
            >
            <span>Back</span>
            </MDButton>
            <MDBox p={3}>
            <MDTypography variant="h5" my={2}>Create Exam</MDTypography>

            <Grid container spacing={2}>
                {/* Course Selection */}
                <Grid item xs={12} sm={6}>
                <Autocomplete
                    options={courses}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(e, newValue) => setSelectedCourse(newValue)}
                    renderInput={(params) => <TextField {...params} label="Course" />}
                />
                </Grid>

                {/* Exam Name */}
                <Grid item xs={12} sm={6}>
                <MDInput
                    label="Exam Name"
                    name="name"
                    fullWidth
                    value={modalData.name}
                    onChange={handleModalChange}
                />
                </Grid>

                {/* Exam Date */}
                <Grid item xs={12} sm={6}>
                <MDInput
                    label="Exam Date"
                    name="date"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{
                    shrink: true,
                    }}
                    value={modalData.date}
                    onChange={handleModalChange}
                    sx={{cursor:"pointer"}}
                    
                />
                </Grid>

                {/* Exam Type */}
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id="exam-type-label">Exam Type</InputLabel>
                    <Select
                    labelId="exam-type-label"
                    id="exam_type"
                    name="exam_type"
                    value={modalData.exam_type}
                    label="Exam Type"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: 1,
                        "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "32px",
                        },
                    }}
                    IconComponent={() => (
                        <ArrowDropDownIcon
                        sx={{
                            position: "absolute",
                            right: 8,
                            pointerEvents: "none",
                        }}
                        />
                    )}
                    onChange={handleModalChange}
                    >
                    <MenuItem value="quiz">Quiz</MenuItem>
                    <MenuItem value="mid">Midterm</MenuItem>
                    <MenuItem value="final">Final</MenuItem>
                    </Select>
                </FormControl>
                </Grid>

                {/* Duration */}
                <Grid item xs={12} sm={6}>
                <MDInput
                    label="Duration (minutes)"
                    name="duration"
                    type="number"
                    fullWidth
                    value={modalData.duration}
                    onChange={handleModalChange}
                />
                </Grid>

                {/* Questions Form */}
                <Grid item xs={12} ref={questionFormRef}>
                <MDTypography variant="h6">Add</MDTypography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <MDInput
                        label="Question"
                        name="question"
                        fullWidth
                        value={newQuestion.question}
                        onChange={handleQuestionChange}
                    />
                    </Grid>

                    <Grid item xs={6}>
                    <MDInput
                        label="Option A"
                        name="option_a"
                        fullWidth
                        value={newQuestion.option_a}
                        onChange={handleQuestionChange}
                    />
                    </Grid>
                    <Grid item xs={6}>
                    <MDInput
                        label="Option B"
                        name="option_b"
                        fullWidth
                        value={newQuestion.option_b}
                        onChange={handleQuestionChange}
                    />
                    </Grid>
                    <Grid item xs={6}>
                    <MDInput
                        label="Option C"
                        name="option_c"
                        fullWidth
                        value={newQuestion.option_c}
                        onChange={handleQuestionChange}
                    />
                    </Grid>
                    <Grid item xs={6}>
                    <MDInput
                        label="Option D"
                        name="option_d"
                        fullWidth
                        value={newQuestion.option_d}
                        onChange={handleQuestionChange}
                    />
                    </Grid>

                    {/* Correct Answer */}
                    <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="correct_answer-label">Correct Answer</InputLabel>
                        <Select
                        labelId="correct_answer-label"
                        id="correct_answer"
                        name="correct_answer"
                        value={newQuestion.correct_answer}
                        label="Correct Answer"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "10px",
                            borderRadius: 1,
                            "& .MuiSelect-select": {
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "32px",
                            },
                        }}
                        IconComponent={() => (
                            <ArrowDropDownIcon
                            sx={{
                                position: "absolute",
                                right: 8,
                                pointerEvents: "none",
                            }}
                            />
                        )}
                        onChange={handleQuestionChange}
                        >
                        <MenuItem value="a">A</MenuItem>
                        <MenuItem value="b">B</MenuItem>
                        <MenuItem value="c">C</MenuItem>
                        <MenuItem value="d">D</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                    <MDTypography variant="h6">
                        {/* {editingQuestionIndex !== null ? "Edit Question" : "Add Question"} */}
                    </MDTypography>
                    <Grid container spacing={2}>
                        {/* ... (question form fields remain the same) */}

                        <Grid item xs={12}>
                        <MDButton onClick={handleAddQuestion} color="info">
                            Add
                        </MDButton>
                       
                    </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>

                {/* Questions List */}
                <Grid item xs={12}>
                {modalData.questions.length > 0 && (
                    <MDTypography variant="h6">Questions</MDTypography>
                )}

    {modalData.questions.map((q, index) => (
                <MDBox key={index} style={{ marginBottom: '10px', padding: '10px', position: 'relative' }}>
                    <IconButton
                    onClick={() => handleEditQuestion(index)}
                    style={{ position: 'absolute', top: 0, right: 0 }}
                    >
                    <EditIcon />
                    </IconButton>
                    {/* Numbered Question */}
                    <MDTypography variant="h6">
                    Question {index + 1}: {q.question}
                    </MDTypography>

                    {/* Options with Radio Buttons */}
                    <FormControl component="fieldset">
                    <RadioGroup 
                        aria-label={`question-${index}`} 
                        name={`question-${index}`}
                        value={q.correct_answer.toUpperCase()}
                    >
                        <FormControlLabel 
                        value="A" 
                        control={<Radio />} 
                        label={<MDTypography variant="body2">{q.option_a}</MDTypography>}
                        />
                        <FormControlLabel 
                        value="B" 
                        control={<Radio />} 
                        label={<MDTypography variant="body2">{q.option_b}</MDTypography>}
                        />
                        <FormControlLabel 
                        value="C" 
                        control={<Radio />} 
                        label={<MDTypography variant="body2">{q.option_c}</MDTypography>}
                        />
                        <FormControlLabel 
                        value="D" 
                        control={<Radio />} 
                        label={<MDTypography variant="body2">{q.option_d}</MDTypography>}
                        />
                    </RadioGroup>
                    </FormControl>
                </MDBox>
                ))}

                </Grid>

                {/* Submit Button */}
                {modalData.questions.length > 0 && (
                <Grid item xs={12}>
                    <MDButton onClick={handleSubmit} color="info">
                    Create Exam
                    </MDButton>
                </Grid>
                )}
            </Grid>
            </MDBox>
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth="sm">
  <DialogTitle>Edit Question</DialogTitle>
  <DialogContent>
    {/* Questions Form Inside the Dialog */}
    <Grid container spacing={2}>
      <Grid item xs={12} mt={2}>
        <MDInput
          label="Question"
          name="question"
          fullWidth
          value={newQuestion.question}
          onChange={handleQuestionChange}
        />
      </Grid>
      <Grid item xs={6}>
        <MDInput
          label="Option A"
          name="option_a"
          fullWidth
          value={newQuestion.option_a}
          onChange={handleQuestionChange}
        />
      </Grid>
      <Grid item xs={6}>
        <MDInput
          label="Option B"
          name="option_b"
          fullWidth
          value={newQuestion.option_b}
          onChange={handleQuestionChange}
        />
      </Grid>
      <Grid item xs={6}>
        <MDInput
          label="Option C"
          name="option_c"
          fullWidth
          value={newQuestion.option_c}
          onChange={handleQuestionChange}
        />
      </Grid>
      <Grid item xs={6}>
        <MDInput
          label="Option D"
          name="option_d"
          fullWidth
          value={newQuestion.option_d}
          onChange={handleQuestionChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="correct_answer-label" sx={{ 
        backgroundColor: '#f5f5f5', 
        px: 1,
        my: 0.5,
        transform: 'translate(14px, -9px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        }
      }}>Correct Answer</InputLabel>
          <Select
            labelId="correct_answer-label"
            id="correct_answer"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              padding: "10px",
              borderRadius: 1,
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                paddingRight: "32px",
              },
            }}
            name="correct_answer"
            value={newQuestion.correct_answer}
            onChange={handleQuestionChange}
          >
            <MenuItem value="a">A</MenuItem>
            <MenuItem value="b">B</MenuItem>
            <MenuItem value="c">C</MenuItem>
            <MenuItem value="d">D</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions>
    <MDButton onClick={handleCloseEditDialog} variant="gradient"
                       size="small"
                       color="black"
                       sx={{background:"grey"}}>Cancel</MDButton>
  
      <MDButton onClick={handleUpdateQuestion}  variant="gradient"
      size="small"
      color="info"
      sx={{background:"#00274D"}}>Update</MDButton>
    
  </DialogActions>
</Dialog>

            <LoadingDialog open={loading} />
      </CardContent>
    </Card>
    </DashboardLayout>
  );
};

export default AddExam;