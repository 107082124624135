import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState } from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddStatusDialog from "./addStatusDialog";
import EditStatusDialog from "./editStatusDialog";
import LoadingDialog from 'components/Loading/LoadingDialog';


const Status = () => {
  const [search] = useState('');
  const token = localStorage.getItem("authToken");
  const [showAlert, setShowAlert] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [deletingStatus, setDeletingStatus] = useState(false); 
  const role = localStorage.getItem("role");


  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);


  useEffect(() => {
    const fetchStatus = async () => {
      setLoading(true); // Start loading
    
      try {
        const response = await axios.get(`${API_BASE_URL}/statuses`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        setStatus(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // End loading after fetching data
      } 
    }
  
  
    fetchStatus();
  }, [token]);

  const handleAddStatus = (newStatus) => {
    setStatus((prevStatus) => [...prevStatus, newStatus]);
  };

  const handleEditStatus = (updatedStatus) => {
    setStatus(status.map((status) =>
      status.id === updatedStatus.id ? updatedStatus : status
    ));
  };

  const handleDeleteStatus = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this status!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setDeletingStatus(true);

      try {
        const response = await axios.delete(`${API_BASE_URL}/statuses/${id}`,
          {
            headers: { "Authorization": `Bearer ${token}` }
          });
        if (response.status === 204) {
          setStatus((prevStatus) => prevStatus.filter((status) => status.id !== id)); // Optimistic update
        swal({
          title: "Status Deleted Successfully!",
          icon: "success",
        });
        }
      } catch (error) {
        swal({
          title: `${error.response.data.message}`,
          icon: "warning"
        });
      } finally {
        setDeletingStatus(false); // End loading for deletion
      } 
    }
  };

  const handleOpenEdit = (status) => {
    setSelectedStatus(status);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedStatus(null);
  };

  const filteredData = status.filter(course => {
    return (
      course.status_name.toLowerCase().includes(search.toLowerCase())
    );
  });

  const columns = [
    ...(role !== 'academic dean'
      ? [
    {
      accessor: 'action',
      Header: '',
      width: 40,
      padding: 0,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteStatus(row.original.id);
                  popupState.close();
                }} style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    }
  ]
  : []),
    { Header: 'Status Name', accessor: 'status_name' },
    { Header: 'Status Code', accessor: 'status_code', showSortIcons: false },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        value === status.is_active ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
      ),
      showSortIcons: false,
    },
  ];

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="medium">
        Statuses
      </MDTypography>

      <MDBox>
     

        <DataTable
          table={{
            columns,
            rows: filteredData,
          }}
          canSearch={true}
          sortable={true}
          rowsPerPage={5}
          showPagination
          widgets={role !== "academic dean" && (
            <MDBox ms={2}>
            <MDButton 
            variant="gradient"
            ml="auto"
                color="info"
                size="small"
                onClick={() => setOpenAdd(true)}
                sx={{background:"#00274D", my:4,mx:3}}
          >
            <span>Add</span>
          </MDButton>
          </MDBox>
          )                                                                                                  
          }
        />

        {!loading && filteredData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No data available
          </MDTypography>
        )}
      </MDBox>

      

      <AddStatusDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddStatus} />

      {selectedStatus && (
        <EditStatusDialog
          open={openEdit}
          onClose={handleCloseDialog}
          status={selectedStatus}
          onEdit={handleEditStatus}
        />
      )}
      <LoadingDialog open={loading || deletingStatus} />
    </MDBox>
  );
};

export default Status;
