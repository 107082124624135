import React, { useState ,useEffect,useCallback} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import ViewRequest from './viewRequest';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddReceivedGood from './addReceivedGood';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewRecievedGoods from './viewRecievedGoods';
import LoadingDialog from 'components/Loading/LoadingDialog';


const GoodReceiveVoucher = () => {
  const [search] = useState('');
  const token = localStorage.getItem("authToken");


  const [goods, setGoods] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [detailData,setDetailData] = useState([]);
  const [loading, setLoading] = useState(false); 
const fetchGoods = useCallback(async () => {
    setLoading(true);
    try {
        const response = await axios.get(`${API_BASE_URL}/goods-receiving-vouchers`, {
            headers: { "Authorization": `Bearer ${token}` }
        });
        
        setGoods(response.data);
    } catch (err) {
        console.error('Error fetching store goods:', err);
    } finally {
        setLoading(false);
    }
},[token]);

useEffect(() => {
    fetchGoods();
},[fetchGoods]);

const handleAddGood= () => {
  fetchGoods();
      
};

const handleDeleteGoods = async (id) => {
    const confirm = await swal({
        title: "Are you sure?",
        text: "You will not be able to recover this Good!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    });

    if (confirm) {
        setLoading(true);
        try {
            const response = await axios.delete(`${API_BASE_URL}/goods-receiving-vouchers/${id}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (response.status === 204) {
                setGoods(goods.filter((good) => good.id !== id));
                swal({
                    title: "Good Request Deleted Successfully!",
                    icon: "success"
                });
            }
        } catch (error) {
            swal({
                title: `${error.response.data.message}`,
                icon: "warning"
            });
        } finally{
            setLoading(false);
        }
    }
};


const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenView(false);
    
};

const handleViewDetails = (data) => {
    setDetailData(data);
    setOpenView(true);
    
  }
const filteredData = search
      ? goods && goods.filter(request =>
          request.store.store_name.toLowerCase().includes(search.toLowerCase())
      ) : goods;

    const columns = [
    {
        accessor: 'action',
        Header: '',
        width: 40,
        showSortIcons: false,
        padding: 0,
        Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
            {(popupState) => (
            <React.Fragment>
                <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
                </Button>
                <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                    // handleOpenEdit(row.original);
                    popupState.close();
                }} style={{ color: '#1976d2' }}>
                    <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    handleDeleteGoods(row.original.id);
                    popupState.close();
                }} style={{ color: '#d32f2f' }}>
                    <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
                <MenuItem onClick={() => {
                handleViewDetails(row.original);
                popupState.close();
                }} style={{ color: '#1976d2' }}>
                <VisibilityIcon style={{ marginRight: 8 }} /> View Details
                </MenuItem>
                </Menu>
            </React.Fragment>
            )}
        </PopupState>
        ),
    },
    { Header: 'Supplier Name', accessor: 'supplier_name' },
    { Header: 'Checked By', accessor: 'checked_by.full_name',showSortIcons: false, },
    { Header: 'Approved By', accessor: 'approved_by.full_name',showSortIcons: false, },  
    { Header: 'Approved Date', accessor: 'approved_date' ,showSortIcons: false,},
    { Header: 'Checked Date', accessor: 'checked_date',showSortIcons: false, },
    { Header: 'Store Name', accessor: 'store_location.name' ,showSortIcons: false,},
    { Header: 'Total Amount', accessor: 'total_amount',
        Cell: ({ row }) => {
            const formattedAmount = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(row.original.total_amount);

              return <span>{formattedAmount}</span>;
              

        },showSortIcons: false,
     },
    ];
  return (
    <DashboardLayout>
          <MDBox>
              <Card>
                  <CardContent>
                      <MDTypography variant="h5" fontWeight="medium">
                          Goods Received
                      </MDTypography>
                      <MDBox>
                          <DataTable
                              table={{
                                  columns,
                                  rows: filteredData,
                              }}
                              canSearch={true}
                              sortable={true}
                              pagination
                              rowsPerPage={5}
                              showPagination
                              widgets={
                                  <MDBox mx={2}>
                                      <MDButton
                                          variant="gradient"
                                          ml="auto"
                                          color="info"
                                          size="small"
                                          onClick={() => setOpenAdd(true)}
                                          sx={{ background: "#00274D", my: 4, mx: 3 }}
                                      >
                                          <span>Add</span>
                                      </MDButton>
                                  </MDBox>
                              }
                          />
                      </MDBox>
                      <AddReceivedGood
                          open={openAdd}
                          onClose={handleCloseDialog}
                          onAdd={handleAddGood}
                      />
                  </CardContent>
                  {
                    detailData && (
                      <ViewRecievedGoods detailData={detailData} openView={openView} onClose={handleCloseDialog}/>
                    )
                  }
              </Card>
          </MDBox>
          <LoadingDialog open={loading} />
      </DashboardLayout>
  )
}

export default GoodReceiveVoucher