import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useEffect, useState,useCallback } from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { axiosInstance } from 'api2';


const Payroll = ({id}) => {
  const [payrolls, setPayrolls] = useState([]);


  const fetchPayrolls = useCallback(async () => {
    try {
        const response = await axiosInstance.get(`employees/${id}/payrolls`);

        setPayrolls(response.data);
    } catch (err) {
        console.error(err);
    }
},[id]);
useEffect(() => {
    fetchPayrolls();
}, [fetchPayrolls]);

const columns = [
  { Header: 'Year', accessor: 'year' },
  { Header: 'Month', accessor: 'month', showSortIcons: false },
  { Header: 'Gross Pay', accessor: 'employee_payrolls[0].gross_pay', showSortIcons: false },
  { Header: 'Total Deduction', accessor: 'employee_payrolls[0].total_deduction', showSortIcons: false },
  { Header: 'Net Pay', accessor: 'employee_payrolls[0].net_pay', showSortIcons: false },
  
  // Employee details
  { Header: 'Employee Name', accessor: 'employee_payrolls[0].employee.first_name', showSortIcons: false },
  { Header: 'Employee Last Name', accessor: 'employee_payrolls[0].employee.last_name', showSortIcons: false },
  { Header: 'Phone', accessor: 'employee_payrolls[0].employee.phone', showSortIcons: false },
  { Header: 'Email', accessor: 'employee_payrolls[0].employee.email', showSortIcons: false },
  { Header: 'Resident City', accessor: 'employee_payrolls[0].employee.resident_city', showSortIcons: false },
  { Header: 'Position ID', accessor: 'employee_payrolls[0].employee.position_id', showSortIcons: false },
  { Header: 'Employment Type', accessor: 'employee_payrolls[0].employee.employment_type', showSortIcons: false },
]

  return (
    <MDBox>
    <Card>
      <CardContent>
      <MDTypography variant="h6">
             Payroll
            </MDTypography>
            <MDBox my={2}>
            <DataTable
                      table={{
                        columns,
                        rows: Array.isArray(payrolls) ? payrolls : []
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
          
                    />
                    {payrolls.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No payroll available
          </MDTypography>
        )}
            </MDBox>
      </CardContent>
    </Card>
  </MDBox>
  )
}

export default Payroll