import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid } from "@mui/material";
import Button from '@mui/material/Button';
import { axiosInstance } from "api2";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState } from "react";
import ExpandableDescription from "./description";
import API_BASE_URL from "api";
import axios from "axios";
import AddCourseDialog from "./addCourse";
import EditCoursesDialog from "./editCourses";


import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDInput from "components/MDInput";
import LoadingDialog from 'components/Loading/LoadingDialog';

 

const Courses = () => {
  const [search] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [importLoading,setImportLoading] = useState(false)
  // const [exportLoading,setExportLoading] = useState(false)
  const [deleteLoading,setDeleteLoading] = useState(false)
  const [openEdit, setOpenEdit] = useState(false);
  const[courses,setCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const token =  localStorage.getItem("authToken");
  const [courseData] = useState([])
  const role = localStorage.getItem("role");


  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);
  const fetchCourses = async () => {
    try {
      const response = await axiosInstance.get(`/courses`, );
      
      setCourses(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
  
    fetchCourses();
  }, [token]);

  const handleAddCourse = (course) => {
    setCourses([...courses, course]);
  };

  const handleEditCourse = (updatedCourse) => {
    setCourses(courses.map((course) =>
      course.id === updatedCourse.id ? updatedCourse : course
    ));
  };

  const handleDeleteCourse = async (id) => {
   
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this course!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    
    if (confirm) {
      setDeleteLoading(true);
  
      try {
      
        const response = await axiosInstance.delete(`courses/${id}`,
          
      );
        if(response.status === 204){
          
          swal({
            title: "Course Deleted Successfully!",
            icon: "success"
          });
          fetchCourses()
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
          // text: "Course Created Successfully!",
          icon: "warning"
        });
      }  finally{

        setDeleteLoading(false);
      } 
    }
  };

  const handleOpenEdit = (course) => {
    setSelectedCourse(course);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedCourse(null);
  };

  const filteredData = courses.filter(course => {
    return (
      course.course_name.toLowerCase().includes(search.toLowerCase()) ||
      course.course_description.toLowerCase().includes(search.toLowerCase())   
    );
  });

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
      setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    setImportLoading(true)

    try {
        const response = await axios.post(`${API_BASE_URL}/import-courses`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization" : `Bearer ${token}`
            },
        });
        fetchCourses();
        alert(response.data.message);
    } catch (error) {
        console.error(error);
        alert('Error importing courses');
    }finally {
      setImportLoading(false);
    }
};
//     const exportCourses = async () => {
//       setExportLoading(true)

//       try {
//         const response = await axiosInstance.get(`/export-courses`, {
//             headers: {
//                 'Authorization': `Bearer ${token}`,
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
//                 },
//                 responseType: 'blob'});        

//           const url = window.URL.createObjectURL(new Blob([response.data]));         

//             const a = document.createElement('a');
//             a.href = url;
//             const timestamp = new Date().toISOString().replace(/[-:T.]/g, '_').slice(0, 19); 
//             a.download = `courses_${timestamp}.xlsx`;    
//             document.body.appendChild(a);
//             a.click(); 
//             a.remove(); 
//             window.URL.revokeObjectURL(url); 
//     } catch (error) {
//         console.error('Export Error:', error.message);
//         // Optionally show a user-friendly error message
//     }finally {
//       setExportLoading(false);
//     }
// };
  
  

  const columns = [
    ...(role !== 'academic dean'
      ? [
    {
      accessor: 'action',
      Header: '',
      width: 40,  
      padding:0,
      Cell: ({ row }) => (
        <PopupState variant="popover"  popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteCourse(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    }
  ]
  : []),
    { Header: 'Course Number', accessor: 'course_number',showSortIcons: false, },

    { Header: 'Course Name', accessor: 'course_name' },
    { 
      Header: 'Course Description', 
      accessor: 'course_description',
      Cell: ({ value }) => <ExpandableDescription description={value} />
    },
    { Header: 'Credit Hours', accessor: 'credit_hour',showSortIcons: false, },
    {
      Header: 'Is Active',
      accessor: 'status',
     
      Cell: ({ value }) => (
        value === courseData.is_active ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
      ),
      showSortIcons: false,
    },
   
  ];



  

  return (
      <MDBox>     

          <MDTypography variant="h5" fontWeight="medium" my={1.5}>
              Courses
            </MDTypography>
            <div>
          
        </div>
              <MDBox>
           
              <DataTable
                table={{
                  columns,
                  rows: filteredData,
                }}
                canSearch={true}
                sortable={true}
                rowsPerPage={5}
                showPagination={true}
                widgets={role !== "academic dean" && (
                  <MDBox sx={{ my: 4 }}>
                    <Grid container spacing={2} justifyContent="flex-start">
                      <Grid item xs={12} sm={6} md={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={() => setOpenAdd(true)}
                          sx={{ background: "#00274D", width: "100%", mx: { xs: 0, md: 1.2 } }}
                        >
                          <span>Add</span>
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <MDInput
                          type="file"
                          size="small"
                          onChange={handleFileChange}
                          sx={{ my: 0, width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={handleUpload}
                          sx={{ background: "#00274D", width: "100%" }}
                        >
                          <span>Import</span>
                        </MDButton>
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={exportCourses}
                          sx={{ background: "#00274D", width: "100%" }}
                        >
                          <span>Export</span>
                        </MDButton>
                      </Grid> */}
                    </Grid>
                  </MDBox>
                )}
              />


     {!loading && filteredData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No data available
          </MDTypography>
        )}
  </MDBox>
              <AddCourseDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddCourse} />
              
            {selectedCourse && (
              <EditCoursesDialog
                open={openEdit}
                onClose={handleCloseDialog}
                course={selectedCourse}
                onEdit={handleEditCourse}
              />
            )}
       
       <LoadingDialog open={loading || importLoading || deleteLoading}/>
      </MDBox>
  )
}

export default Courses