import React, { useState, useEffect } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import MDBox from "components/MDBox";
import DataTable from 'examples/Tables/DataTable';
import MDButton from "components/MDButton";
import swal from 'sweetalert'; // Assuming you use sweetalert for notifications
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { axiosInstance } from 'api2';

const EmployeeLoans = ({openView,onClose,loans,onView}) => {
    const [repayments,setRepayments] = useState([])


    useEffect(() => {
        if (loans) {
          console.log("loans:",loans)
          setRepayments(loans);
        }
      }, [loans]);


      const columns = [

        { Header: 'Repayment Amount', accessor: 'repayment_amount',showSortIcons: false,Cell: ({ value }) => {
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
          } },
    
        { Header: 'Repayment Date', accessor: 'repayment_date',showSortIcons: false, },
        { Header: 'Repayment Status', accessor: 'repayment_status',showSortIcons: false, },
        {
          Header: 'Actions',
          accessor: 'id',
          Cell: ({ row }) => {
            const [anchorEl, setAnchorEl] = useState(null);
            const [approvalStatus, setApprovalStatus] = useState(null);
        
            const handleClick = (event) => {
              setAnchorEl(event.currentTarget);
            };
        
            const handleClose = () => {
              setAnchorEl(null);
            };
        
            const handleApproval = async (repaymentId, status) => {
              const confirm = await swal({
                title: `Are you sure you want to ${status === 'Approved' ? 'approve' : 'reject'} this repayment?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              });
        
              if (confirm) {
                try {
                  // Determine the endpoint based on the action (approve or reject)
                  const endpoint = status === 'Approved'
                    ? `/loan-repayments/${repaymentId}/approve`
                    : `/loan-repayments/${repaymentId}/reject`;
        
                  // Call the appropriate API for approval or rejection
                  const response = await axiosInstance.post(endpoint, {
                    loan_id: repayments[0].loan_id // Assuming loans object contains the loan ID
                  });
        
                  if (response.status === 200) {
                    setApprovalStatus(status); // Update the local UI status
                    onView();
                    onClose();
                    swal({
                      title: `Repayment ${status} successfully!`,
                      icon: "success",
                    });
                    
                  }
                } catch (error) {
                  swal({
                    title: `${error.response?.data?.message || 'An error occurred'}`,
                    icon: "error",
                  });
                }
              }
            };
        
            return (
              <Box display="flex" justifyContent="space-between" gap={2}>
                {approvalStatus === null ? (
                  <>
                    <IconButton onClick={handleClick}>
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <MenuItem
                        onClick={() => handleApproval(row.original.id, 'Approved')}
                        sx={{ color: '#4caf50' }} // Green text for approval
                      >
                        <CheckIcon /> Approve
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleApproval(row.original.id, 'Rejected')}
                        sx={{ color: '#f44336' }} // Red text for rejection
                      >
                        <CloseIcon /> Reject
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Box
                    component="button"
                    style={{
                      backgroundColor: approvalStatus === 'Approved' ? '#4caf50' : '#f44336',
                      color: '#fff',
                      border: 'none',
                      padding: '8px 16px',
                      cursor: 'pointer',
                    }}
                  >
                    {approvalStatus}
                  </Box>
                )}
              </Box>
            );
          },
          showSortIcons: false,
        }
        
       
       
      ];


  return (
    <Dialog open={openView} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>Loan Repayments</DialogTitle>
    <DialogContent>
          
        
              <MDBox>
              <DataTable
      table={{
        columns,
        rows: repayments,
      }}
      canSearch={true}
      sortable={true}
      rowsPerPage={5}
      showPagination
      
    />
              </MDBox>
             
</DialogContent>
<DialogActions>
        
          <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      
  )
}

export default EmployeeLoans