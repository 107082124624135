import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Checkbox, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState,useCallback } from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddRoleDialog from "./addRole";
import EditRoleDialog from "./editRole";
import LoadingDialog from 'components/Loading/LoadingDialog';


const Role = () => {
    const [search] = useState('');
  const token =  localStorage.getItem("authToken");
  const [isModalOpen, setModalOpen] = useState(false);
  const [permissionDetail , setpermissionDetail] = useState(null);

  const [openEdit, setOpenEdit] = useState(false);
  const[roles,setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleting,setDeleting] = useState(false);

 
  const fetchRoles = useCallback(async () => {
    try {
      setLoading(true);
    
      const response = await axios.get(`${API_BASE_URL}/roles`, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      setRoles(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
    
  },[token]);
  useEffect(() => {

    fetchRoles();
  },[fetchRoles]);


  const handleAddRoles = (newRoles) => {
    // console.log("Adding status...");
    fetchRoles();
  };
  

  const handleEditRole = (updatedRole) => {
    // setRoles(roles.map((role) =>
    //   role.id === updatedRole.id ? updatedRole : role
    // ));
    fetchRoles();
  };

  const handleDeleteRole = async (id) => {
    setDeleting(true);
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this roles!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
   
    if (confirm) {
      try {
        const response = await axios.delete(`${API_BASE_URL}/roles/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 200){
          setRoles(roles.filter((role) => role.id !== id));
          swal({
            title: "Roles Deleted Successfully!",
        
            icon: "success"
          });
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
      
          icon: "warning"
        });
      } finally {
        setDeleting(false);
      }
    } 
  };


  const handleOpenEdit = (role) => {
    setSelectedRole(role);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedRole(null);
    setModalOpen(false);
  };


  const filteredData = search
  ? roles && roles.filter(role => 
      role.name &&
      role.name.toLowerCase().includes(search.toLowerCase())
    )
  : roles;

  const columns = [
    {
      accessor: 'action',
      Header: '',  
      width: 40,  
      padding:0,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteRole(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'name', accessor: 'name' },
    {
      Header: 'Permissions',
      accessor: row => {
        const permissionNames = row.permissions?.map(p => p.name).slice(0, 2) || [];

        return permissionNames.length > 0
          ? permissionNames.map((name, index) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index}
                href="#"
                onClick={(e) => {
                  setpermissionDetail(row?.permissions);
                  e.preventDefault(); // Prevent default anchor behavior
                  setModalOpen(true); // Open modal
                }}
                style={{ marginRight: '5px' }}
              >
                {name.length > 10 ? `${name.slice(0, 10)}...` : name}
              </a>
            ))
          : 'No Permission';
      },
      showSortIcons: false,
    }, 
  ];
  const isPermissionChecked = (permissionId,id) => {
    return permissionDetail?.some(p => 
        (typeof p === 'object' && p.id === permissionId) || 
        (typeof p === 'number' && p === permissionId)
    );
};



  return (
    <DashboardLayout>
      <Card>
        <CardContent>
    <MDBox>
    <MDTypography variant="h5" fontWeight="medium">
           Role
         </MDTypography>
    
           <MDBox>

             <DataTable
               table={{
                 columns,
                 rows: filteredData,
               }}
               canSearch={true}
               sortable={true}
               pagination
               rowsPerPage={5}
               showPagination
               widgets = {
                 <MDBox ms={2}>
                <MDButton
                variant="gradient"
                ml="auto"
                    color="info"
                    size="small"
                    onClick={() => setOpenAdd(true)}
                    sx={{background:"#00274D", my:4,mx:3}}
              >
                <span>Add</span>
              </MDButton>
              </MDBox>
               }
             />
            
           </MDBox>
           <AddRoleDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddRoles} />
    
           {selectedRole && (
             <EditRoleDialog
               open={openEdit}
               onClose={handleCloseDialog}
               role={selectedRole}
               onEdit={handleEditRole}
             />
           )}
    
     </MDBox>
     </CardContent>
      </Card>
     <Dialog open={isModalOpen} onClose={handleCloseDialog} maxWidth="lg">
            <DialogTitle textAlign={"center"}>{roles.name}</DialogTitle>
            <DialogContent>
            <MDTypography variant="h5" textAlign="center" color="text" fontWeight="medium">
                        detail Permissions
                    </MDTypography>
                <MDBox my={1}>
                  
                    <Grid container spacing={1}>
                        {permissionDetail?.map((permission) => (
                            <Grid item xs={12} sm={6} md={3} key={permission.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        readOnly
                                            checked={isPermissionChecked(permission.id)}
                                        />
                                    }
                                    label={permission.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{background:"grey"}}
                    onClick={handleCloseDialog}
                >
                    Cancel
                </MDButton>
            </DialogActions>
        </Dialog>
    <LoadingDialog open={deleting || loading}/>
</DashboardLayout>
  )
}

export default Role