import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Switch } from "@mui/material";
import swal from "sweetalert";
import { axiosInstance } from "api2";

const EditRoom = ({ open, onClose, rooms, onEdit }) => {
  
  const [modalData, setModalData] = useState({
    room_number: rooms?.room_number || "",
    building_name: rooms?.building_name || "",
    floor_number: rooms?.floor_number || 0,
    capacity: rooms?.capacity || 0,
    current_quantity: rooms?.current_quantity || 0,
    available: rooms?.is_available === 1 || false,
  });

  const [error, setError] = useState(null);

  
  useEffect(() => {
    if (rooms) {
      setModalData({
        room_number: rooms.room_number || "",
        building_name: rooms.building_name || "",
        floor_number: rooms.floor_number || 0,
        capacity: rooms.capacity || 0,
        current_quantity: rooms.current_quantity || 0,
        available: rooms.is_available === 1 || false,
        id:rooms.id
      });
    }
  }, [rooms]);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    try {
      const response = await axiosInstance.put(`/dorms/${modalData.id}`, modalData);
     
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Dorm Updated Successfully!",
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error.response);
      setError("An error occurred");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign="center">Edit Room</DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Room Number
          </MDTypography>
          <MDInput
            type="text"
            name="room_number"
            fullWidth
            required
            value={modalData.room_number}
            onChange={handleModalChange}
          />
          {error && error.room_number && (
            <MDTypography variant="caption" color="error">
              {error.room_number[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Building Name
          </MDTypography>
          <MDInput
            type="text"
            name="building_name"
            fullWidth
            required
            value={modalData.building_name}
            onChange={handleModalChange}
          />
          {error && error.building_name && (
            <MDTypography variant="caption" color="error">
              {error.building_name[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Floor Number
          </MDTypography>
          <MDInput
            type="number"
            name="floor_number"
            fullWidth
            required
            value={modalData.floor_number}
            onChange={handleModalChange}
          />
          {error && error.floor_number && (
            <MDTypography variant="caption" color="error">
              {error.floor_number[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Capacity
          </MDTypography>
          <MDInput
            type="number"
            name="capacity"
            fullWidth
            required
            value={modalData.capacity}
            onChange={handleModalChange}
          />
          {error && error.capacity && (
            <MDTypography variant="caption" color="error">
              {error.capacity[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Current Quantity
          </MDTypography>
          <MDInput
            type="number"
            name="current_quantity"
            fullWidth
            required
            value={modalData.current_quantity}
            onChange={handleModalChange}
          />
          {error && error.current_quantity && (
            <MDTypography variant="caption" color="error">
              {error.current_quantity[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Available
          </MDTypography>
          <Switch
            checked={modalData.available}
            onChange={(e) =>
              setModalData({
                ...modalData,
                available: e.target.checked,
              })
            }
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditRoom;
