import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import swal from "sweetalert";
import { axiosInstance } from "api2";

const EditLeaveRequest = ({ open, onClose, leaveRequests, onEdit }) => {
  const [modalData, setModalData] = useState({
    employee_id: 0,
    leave_type_id: 0,    // Default to 0 until the data is loaded
    start_date: '',
    end_date: '',
    reason: '',
    attachment: '',
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    if (leaveRequests) {
      setModalData({
        employee_id: leaveRequests?.employee_id || 0,
        leave_type_id: leaveRequests?.leave_type?.id || 0,  // Safely access leave_type_id
        start_date: leaveRequests?.start_date || '',
        end_date: leaveRequests?.end_date || '',
        reason: leaveRequests?.reason || '',
        attachment: leaveRequests?.attachment || '',
        id:leaveRequests?.id
      });
    }
  }, [leaveRequests]);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError(null); // Reset error state on change
  };

  const handleModalSubmit = async () => {
    try {
        console.log("modalData:",modalData)
      const response = await axiosInstance.put(`/leaves/${modalData.id}`, modalData);
      if (response.status === 200) {
        onEdit(); // Call onEdit to update the list
        onClose(); // Close the modal
        swal({
          title: "Leave Request Updated Successfully!",
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error.response);
      setError("An error occurred");
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setModalData({
      ...modalData,
      [name]: files[0], // Store the selected file
    });
    setError((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign="center">Edit Leave Request</DialogTitle>
      <DialogContent>
        {/* Leave Type ID */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Leave Type ID
          </MDTypography>
          <MDInput
            type="number"
            name="leave_type_id"
            fullWidth
            required
            value={modalData.leave_type_id || 0}  // Fallback to 0 if undefined
            onChange={handleModalChange}
          />
          {error?.leave_type_id && (
            <MDTypography variant="caption" color="error">
              {error.leave_type_id[0]}
            </MDTypography>
          )}
        </MDBox>

        {/* Start Date */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Start Date
          </MDTypography>
          <MDInput
            type="date"
            name="start_date"
            fullWidth
            required
            value={modalData.start_date}
            onChange={handleModalChange}
            error={!!error?.start_date}
          />
          {error?.start_date && (
            <MDTypography variant="caption" color="error">
              {error.start_date[0]}
            </MDTypography>
          )}
        </MDBox>

        {/* End Date */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            End Date
          </MDTypography>
          <MDInput
            type="date"
            name="end_date"
            fullWidth
            required
            value={modalData.end_date}
            onChange={handleModalChange}
            error={!!error?.end_date}
          />
          {error?.end_date && (
            <MDTypography variant="caption" color="error">
              {error.end_date[0]}
            </MDTypography>
          )}
        </MDBox>

        {/* Reason */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Reason
          </MDTypography>
          <MDInput
            type="text"
            name="reason"
            fullWidth
            required
            value={modalData.reason}
            onChange={handleModalChange}
            error={!!error?.reason}
          />
          {error?.reason && (
            <MDTypography variant="caption" color="error">
              {error.reason[0]}
            </MDTypography>
          )}
        </MDBox>

        {/* Attachment (File Upload) */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Attachment
          </MDTypography>
          <MDInput
            type="file"
            name="attachment"
            fullWidth
            required
            onChange={handleFileChange}
            error={!!error?.attachment}
          />
          {error?.attachment && (
            <MDTypography variant="caption" color="error">
              {error.attachment[0]}
            </MDTypography>
          )}
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditLeaveRequest;
