import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import MDButton from "components/MDButton";

const ViewEmployeesPayroll = ({ openView, onClose, employees }) => {

  const columns = [
    {
      Header: 'Employee Name',
      accessor: (row) => `${row.employee.first_name} ${row.employee.last_name || ''}`,
      id: 'employeeName',
    },
    { Header: 'Basic Salary', accessor: (row) => parseFloat(row.basic_salary).toLocaleString(), showSortIcons: false, },
    { Header: 'Gross Pay', accessor: (row) => parseFloat(row.gross_pay).toLocaleString(), showSortIcons: false, },
    { Header: 'Net Pay', accessor: (row) => parseFloat(row.net_pay).toLocaleString(), showSortIcons: false, },
    { Header: 'Income Tax', accessor: (row) => parseFloat(row.income_tax).toLocaleString(), showSortIcons: false, },
    { Header: 'Total Deduction', accessor: (row) => parseFloat(row.total_deduction).toLocaleString(), showSortIcons: false, },
    
    
  
  ];

  // Format rows from the employees data
  const rows = employees.map(employee => ({
    ...employee,
    employee: employee.employee // This keeps the employee object accessible
  }));

  return (
    <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle textAlign="center">
        Employee Payroll Details
      </DialogTitle>
      <DialogContent>
        <DataTable
          table={{
            columns,
            rows,
          }}
          canSearch={true}
          sortable={true}
          pagination
          rowsPerPage={5}
          showPagination
        />
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default ViewEmployeesPayroll;
