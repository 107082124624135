import { Card, CardContent } from '@mui/material';
import API_BASE_URL from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import OthersDetail from './others';
import ViewRegisterations from './registration';


const ViewStudentDetail = () => {
  const [activeTab, setActiveTab] = useState('education');
  const [search] = useState('');
  const location = useLocation();
  const { person } = location.state || {};

  const[applicantDetail,setApplicantsDetail] = useState([])

  const token =  localStorage.getItem("authToken");

  

  useEffect(() => {
    const fetchApplicantDetail = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/applicant-student/${person.id}`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });
        
        setApplicantsDetail(response.data);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    fetchApplicantDetail();
  },[token,person]);



  if (!person) {
    return <p>No person data available</p>;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const columns = [
    { Header: 'Country', accessor: 'country' },
    { Header: 'Institution', accessor: 'educational_institution' },
    { Header: 'Program', accessor: 'study_program' },
    { Header: 'Major', accessor: 'major' },
    { Header: 'Degree', accessor: 'class_of_degree' },
    { Header: 'Attended Year', accessor: 'attended_year' },
  ];
  

  const filteredData = applicantDetail.educationalBackgrounds ? 
  applicantDetail.educationalBackgrounds.filter(education => {
    return (
      education.country.toLowerCase().includes(search.toLowerCase()) ||
      education.educational_institution.toLowerCase().includes(search.toLowerCase()) ||
      education.study_program.toLowerCase().includes(search.toLowerCase()) ||
      education.major.toLowerCase().includes(search.toLowerCase())
    );
  }) : [];


  return (
    <DashboardLayout>
      <MDBox>
        <MDBox display="flex" justifyContent="flex-start" alignItems="center" mb={2} p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
          <MDTypography
            variant="body2"
            onClick={() => handleTabChange('education')}
            style={{
              textDecoration: 'none',
              color: activeTab === 'education' ? 'blue' : 'black',
              borderBottom: activeTab === 'education' ? '2px solid blue' : 'none',
              cursor: 'pointer'
            }}
          >
            Education Background
          </MDTypography>
          <MDTypography
            variant="body2"
            onClick={() => handleTabChange('others')}
            style={{
              textDecoration: 'none',
              color: activeTab === 'others' ? 'blue' : 'black',
              borderBottom: activeTab === 'others' ? '2px solid blue' : 'none',
              marginLeft: '10px',
              cursor: 'pointer'
            }}
          >
            Others
          </MDTypography>
          <MDTypography
            variant="body2"
            onClick={() => handleTabChange('register')}
            style={{
              textDecoration: 'none',
              color: activeTab === 'register' ? 'blue' : 'black',
              borderBottom: activeTab === 'register' ? '2px solid blue' : 'none',
              marginLeft: '10px',
              cursor: 'pointer'
            }}
          >
            Registration Detail
          </MDTypography>
        </MDBox>

        {activeTab === 'education' && (
          <Card>
            <CardContent>
              <MDTypography variant="h5" fontWeight="medium">
                Educational Background
              </MDTypography>
              <MDBox mt={2}>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={false}
                  pagination
                  rowsPerPage={5}
                  showPagination
                />
              </MDBox>
            </CardContent>
          </Card>
        )}

      </MDBox>
        {activeTab === 'others' && (

          <OthersDetail applicantDetail={applicantDetail}/>

        )}
          {activeTab === 'register' && (
          <ViewRegisterations applicantDetail={applicantDetail}/>

        )}
    </DashboardLayout>
  );
};

export default ViewStudentDetail;
