import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/MDButton";
import FileViewer from "components/FileViewer";
import LoadingDialog from "components/Loading/LoadingDialog";

const StudentPayment = () => {
    const token =  localStorage.getItem("authToken");
    const[payments,setPayments] = useState([])

    const [open, setOpen] = useState(false);
    const [transactionData, setTransactionData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

  

    const handleViewDetail = (person) => {        
        navigate('/viewApplicantDetail', { state: { person } });
    
      }

      const handleViewFile = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();
        setSelectedFile(filePath);
        setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
        setOpenDialog(true);
      };
    
    const handleClose = () => {
      setOpen(false);
      setTransactionData(null); // Clear the data when closing
      setOpenDialog(false);
      setSelectedFile(null);
      setFileType("");
  };
      const fetchPayments = useCallback(async () => {
       setLoading(true);
        try {
          const response = await axios.get(`${API_BASE_URL}/student/payments`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
                 
          setPayments(response.data);
        } catch (err) {
          console.error(err);
        } finally{
          setLoading(false);
        }
       
      },[token]);
      useEffect(() => {

        fetchPayments();
      },[fetchPayments]);

      const handleTransaction = async (payment) => {    

        if (payment != null && payment.transaction_id !== null ){
          setLoading(true);
          try {
             if (payment.payment_method === "Chapa") {
    
            const response = await axios.get(`${API_BASE_URL}/callback/${payment.transaction_id}`);    
    
            setTransactionData(response.data.data); 
            setOpen(true);
            setLoading(false);
       
             }
             else{
              setTransactionData(payment); 
              setLoading(false);
    
    
             }
    
          } catch (err) {
            console.error(err);
          }
        }
      };

      const columns = [
        { Header: 'Full Name',showSortIcons: true,   accessor: row => (
            <MDButton onClick={() => handleViewDetail(row.student)}>
              {row.student ? row.full_name : null}
            </MDButton>
          ),},
        { Header: 'Phone number', accessor: 'phone_number',showSortIcons: false, },
        { Header: 'payment amount', accessor: 'payment_amount',showSortIcons: false, },
        { Header: 'payment date', accessor: 'payment_date',showSortIcons: false, },
        { Header: 'Fee Type', accessor: 'fee.fee_type',showSortIcons: false, },
        { Header: 'payment method', accessor: 'payment_method',showSortIcons: false, },
        { Header: 'transaction id', 
            accessor: row => (
                <MDButton onClick={() => handleTransaction(row)}>
                  {row.transaction_id ? row.transaction_id : 'No Transaction'}
                </MDButton>
              )
            ,showSortIcons: false, },

        { Header: 'receipt_path', 
            accessor: row => (
                <MDTypography variant="caption">
                    {row.payment_method === 'Chapa' ? (
                        'No Receipt Available'
                    ) : (
                      <MDButton
            variant="body2"
            color="primary"
            component="a"
            onClick={() => handleViewFile(row.receipt_path)}
            style={{ cursor: 'pointer' }}
            sx={{ 
              cursor: 'pointer',
              textDecoration: 'underline',
              boxShadow: 1, // Adds a default shadow
              transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
              '&:hover': {
                color: 'secondary.main',
                boxShadow: 4, // Increases shadow intensity on hover
              }
            }}
          >
            View Receipt
          </MDButton>
                       
                    )}
                </MDTypography>
            ),

            showSortIcons: false, },
      ];


  return (

    <DashboardLayout>
          <>
    
       <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Application Fee Details</DialogTitle>
                {/* <Typography variant="body1"><strong> {transactionMessage} </strong></Typography> */}

                <DialogContent>
                    {transactionData && (
                        <>
                           <DialogContent>
    {transactionData && (
        <>
            <MDTypography variant="body1"><strong>Full Name:</strong> {transactionData.first_name + ' ' + transactionData.last_name}</MDTypography>
            <MDTypography variant="body1"><strong>Amount:</strong> {transactionData.amount}</MDTypography>
            <MDTypography variant="body1"><strong>Charge:</strong> {transactionData.charge}</MDTypography>
            <MDTypography variant="body1"><strong>Created At:</strong> {new Date(transactionData.created_at).toLocaleString()}</MDTypography>
            <MDTypography variant="body1"><strong>Currency:</strong> {transactionData.currency}</MDTypography>
            <MDTypography variant="body1"><strong>Customization Title:</strong> {transactionData.customization?.title}</MDTypography>
            <MDTypography variant="body1"><strong>Customization Description:</strong> {transactionData.customization?.description}</MDTypography>
            <MDTypography variant="body1"><strong>Email:</strong> {transactionData.email}</MDTypography>
            <MDTypography variant="body1"><strong>Method:</strong> {transactionData.method}</MDTypography>
            <MDTypography variant="body1"><strong>Mode:</strong> {transactionData.mode}</MDTypography>
            <MDTypography variant="body1"><strong>Reference:</strong> {transactionData.reference}</MDTypography>
            <MDTypography variant="body1"><strong>Status:</strong> {transactionData.status}</MDTypography>
            <MDTypography variant="body1"><strong>Transaction Reference:</strong> {transactionData.tx_ref}</MDTypography>
        </>
    )}
</DialogContent>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
</>

        <Card>
            <CardContent>

        <MDBox >
                <MDTypography variant="h5" fontWeight="medium">
                   Student Payment
                 </MDTypography>
                 <MDBox>
                
                <MDBox >
                    <DataTable
                      table={{
                        columns,
                        rows: payments
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
                    />
                </MDBox>
                
                </MDBox>
                
        </MDBox>
                        
        </CardContent>
        </Card>
        <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
         <LoadingDialog open={loading} />
        </DashboardLayout>
    )
}

export default StudentPayment
       
