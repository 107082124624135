import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import swal from "sweetalert";

const AddEvent = ({ open, onClose, onAdd, selectedDate }) => {
  const [modalData, setModalData] = useState({
    academic_year: "",
    event: "",
    event_type: "",
    date: selectedDate || "",
  });

  const [error, setError] = useState({});

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    setModalData(prevData => ({
      ...prevData,
      date: selectedDate || "", // Update the date field
    }));
  }, [selectedDate]);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", // Clear the error for this field when it's being modified
    });
  };

  const handleModalSubmit = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/academic-calendar`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Event Created Successfully!",
          icon: "success",
        });

        setModalData({
          academic_year: "",
          event: "",
          event_type: "",
          date: "",
        });
        setError({});
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errors = error.response.data.error;
        setError(errors); // Set the error object
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle textAlign={"center"}>
        <AddIcon /> Add Event
      </DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Academic Year
          </MDTypography>
          <MDInput
            type="text"
            name="academic_year"
            fullWidth
            required
            value={modalData.academic_year}
            onChange={handleModalChange}
            error={!!error.academic_year} // MUI error handling
          />
          {error.academic_year && (
            <MDTypography variant="caption" color="error">
              {error.academic_year[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Event
          </MDTypography>
          <MDInput
            type="text"
            name="event"
            fullWidth
            required
            value={modalData.event}
            onChange={handleModalChange}
            error={!!error.event}
          />
          {error.event && (
            <MDTypography variant="caption" color="error">
              {error.event[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Event Type
          </MDTypography>
          <MDInput
            type="text"
            name="event_type"
            fullWidth
            required
            value={modalData.event_type}
            onChange={handleModalChange}
            error={!!error.event_type}
          />
          {error.event_type && (
            <MDTypography variant="caption" color="error">
              {error.event_type[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Date
          </MDTypography>
          <MDInput
            type="date"
            name="date"
            fullWidth
            required
            value={modalData.date}
            onChange={handleModalChange}
            error={!!error.date}
          />
          {error.date && (
            <MDTypography variant="caption" color="error">
              {error.date[0]}
            </MDTypography>
          )}
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddEvent;
