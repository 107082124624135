import { Alert } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React,{useState} from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';
import Switch from '@mui/material/Switch';

const EditYearDialog = ({ open, onClose, years, onEdit }) => {
    const [modalData, setModalData] = React.useState(years);
  const [error, setError] = React.useState(null);

  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${API_BASE_URL}/years/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Years Updated Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      console.log(error.reponse)
      // setError("An error occurred");
    }finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}> Edit Years</DialogTitle>
      <DialogContent>
        {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Year Name
          </MDTypography>
          <MDInput
            type="text"
            name="year_name"
            fullWidth
            required
            value={modalData.year_name}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Is Active
          </MDTypography>
          <Switch
            checked={modalData.is_active === 1}
            onChange={(e) =>
              setModalData({
                ...modalData,
                is_active: e.target.checked ? 1 : 0,
              })
            }
          />
        </MDBox>
        
      </DialogContent>
      <DialogActions>
        <MDButton   
            variant="gradient"
            size="small"
            color="info"
            sx={{background:"#00274D"}} 
            onClick={handleModalSubmit}>Submit
        </MDButton>
        <MDButton   
            variant="gradient"
            size="small"
            color="black"
            sx={{background:"grey"}} onClick={onClose}>Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  )
}

export default EditYearDialog