import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState } from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddStoreDialog from "./addStore";
import EditStoreDialog from "./editStore";
import LoadingDialog from 'components/Loading/LoadingDialog';


const Store = () => {
  const [search] = useState('');
  const token =  localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false); 
  const [showAlert, setShowAlert] = useState(false);


  const [openEdit, setOpenEdit] = useState(false);
  const[store,setStore] = useState([])
  const [selectedStore, setSelectedStore] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  useEffect(() => {
    const fetchStore = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/stores`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
      

        setStore(response.data);
      } catch (err) {
        console.error(err);
      } finally{
        setLoading(false);
      }
      
    };
    fetchStore();
  }, [token]);

  const handleAddStore = (newStore) => {
    setStore((prevStore) => [...prevStore, newStore]);
  };
  

  const handleEditStore = (updatedStore) => {
    setStore(store.map((l) =>
      l.id === updatedStore.id ? updatedStore : l
    ));
  };

  const handleDeleteStore = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this Store!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setLoading(true);
      try {
        const response = await axios.delete(`${API_BASE_URL}/stores/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 204){
          setStore(store.filter((c) => c.id !== id));
          swal({
            title: "Store Deleted Successfully!",
            icon: "success"
          });
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
          icon: "warning"
        });
      }finally{
        setLoading(false);
      }
    }
  };

  const handleOpenEdit = (store) => {
    setSelectedStore(store);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedStore(null);
  };

  const filteredData = search
  ? store && store.filter(l => 
      l.name &&
      l.name.toLowerCase().includes(search.toLowerCase())
    )
  : store;

  const columns = [
    {
      accessor: 'action',
      Header: '',  // You can leave this blank if you don't want a header
      width: 40,  // Explicit width in pixels
      padding:0,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteStore(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'Code', accessor: 'code',showSortIcons: false, },
    { Header: 'name', accessor: 'name', },
    { Header: 'Description', accessor: 'description',showSortIcons: false, },
    { Header: 'Address', accessor: 'address',showSortIcons: false, },
    { Header: 'Remark', accessor: 'remark',showSortIcons: false, },
    
   
   
  ];

  return (
    <DashboardLayout>
    <Card>
      <CardContent>
        <MDBox>
        <MDTypography variant="h5" fontWeight="medium">
           Store
         </MDTypography>
         <MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  pagination
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox mx={2}>
                   <MDButton
                   variant="gradient"
                   ml="auto"
                       color="info"
                       size="small"
                       onClick={() => setOpenAdd(true)}
                       sx={{background:"#00274D", my:4,mx:3}}
                 >
                   <span>Add </span>
                 </MDButton>
                 </MDBox>
                  }
                />
         </MDBox>
         <AddStoreDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddStore} />
        
        {selectedStore && (
        <EditStoreDialog
            open={openEdit}
            onClose={handleCloseDialog}
            store={selectedStore}
            onEdit={handleEditStore}
        />
        )}
        
        
        </MDBox>
      </CardContent>
    </Card>
    <LoadingDialog open={loading} />
</DashboardLayout>
  )
}

export default Store