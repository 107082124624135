import React from 'react';
import { Dialog, CircularProgress } from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const LoadingDialog = ({ open }) => {
  return (
    <Dialog 
      open={open} 
      maxWidth="sm" 
     
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <MDBox
          bgcolor="background.paper"
          // borderRadius="100%"
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          boxShadow={3}
        >
          <CircularProgress size={40} sx={{ mb: 2 }} />
          <MDTypography variant="h6" color="white">
            Loading...
          </MDTypography>
        </MDBox>
      </MDBox>
    </Dialog>
  );
};

export default LoadingDialog;