import React, { useState,useEffect } from 'react';
import { Box, Typography, Grid ,IconButton } from '@mui/material';
import mks from 'assets/images/mks1.jpg';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Switch from "@mui/material/Switch";
import { axiosInstance } from 'api2';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import FacebookIcon from '@mui/icons-material/Facebook';



const Login = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState(null);
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState(null);
  const [loading,setLoading] = useState(false);

  
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    if (storedEmail && storedPassword) {
      setFormData({
        email: storedEmail,
        password: storedPassword,
      });
      setRememberMe(true);
    }
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(`/login`, formData);
      if (response.status === 200) {
        const token = response.data.token.split("|")[1];
        localStorage.setItem("targetId", response.data.target_id);
        localStorage.setItem("userId", response.data.id);
        localStorage.setItem("authToken", token);
        localStorage.setItem("fullName", response.data.full_name);
        localStorage.setItem("profile_picture", response.data.profile_picture);
        if (response.data.roles.length > 0) {
          localStorage.setItem("role", response.data.roles[0].name);
          const permissions = response.data.roles[0].permissions;
          const permissionNames = permissions.map(
            (permission) => permission.name
          );
          localStorage.setItem("permissions", JSON.stringify(permissionNames));
        }

        // Store email and password in localStorage if "Remember Me" is checked
        if (rememberMe) {
          localStorage.setItem("email", formData.email);
          localStorage.setItem("password", formData.password);
        } else {
          // Clear stored email and password if "Remember Me" is unchecked
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }
        

        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.log("error:",error.response.data)
      setError(error.response.data.message);
    }
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordDialogOpen(true);
    setForgotPasswordError(null); // Reset any previous errors
   // Reset any previous success messages
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordDialogOpen(false);
    setForgotPasswordEmail(""); // Reset email field
  };

  const handleForgotPasswordChange = (e) => {
    setForgotPasswordEmail(e.target.value);
    setForgotPasswordError(null);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      const response = await axiosInstance.post(`/forgot-password`, { email: forgotPasswordEmail });
    

      if(response.status === 200) {
        swal({
          title: "Password Sent Successfully!",
          text: "Check your email for further instructions.",
          icon: "success",
          buttons: false,
          timer: 3000, // Optional: auto-close after 3 seconds
        });
         // Close the dialog and reset the email field
      setForgotPasswordDialogOpen(false);
      setForgotPasswordEmail(""); // Reset email field
      setForgotPasswordError(null); // Reset error state
      
      }
      
    } catch (error) {
      console.log(error.message);
      console.log(error.response.data);
      setForgotPasswordError(error.response.data.message); // Set error message
      // Reset success state
    } finally {
      setLoading(false)
    }
  };

  return (
    <PageLayout>
         <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        >
    
    <Box 
    px={1} width="100%"   sx={{
      height: {
        xs: 'calc(100vh - 125px)', // Smaller height for extra-small screens
        sm: 'calc(100vh - 114px)' // Default height for small screens and up
      }
    }}  mx="auto"
 
>
<Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
  
  <Grid container maxWidth="md" sx={{ borderRadius: '16px',}}>
    {/* Image Section */}
    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
    <Box 
  component="img" 
  src={mks} 
  alt="Login" 
  sx={{ 
    borderRadius: {xs:0,sm:'16px'}, 
    width: { xs: '100%', sm: '70%', md: '100%' }, 
    height: { xs: '170px', md: '100%' },
    objectFit: 'cover',
    margin: 'auto', 
    boxShadow: '0 6px 20px rgba(255, 255, 255, 0.5)', // Brighter and wider shadow
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition
    '&:hover': {
      transform: 'scale(1.02)', // Slightly scale up on hover
    
    },
  }} 
/>


    </Grid>
 

    {/* Form Section */}
    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ padding: 4, width: '100%', maxWidth: '400px', margin: 'auto' }}>
      {error && (
          <MDBox mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}
        <Typography variant="h4" color="#fff" >
        Sign in
        </Typography>
        <Typography variant="body2" sx={{ mt: 2,color:"#fff" }}>
          Welcome back! Please log in to your account
        </Typography>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                // label="Email"
                value={formData.email}
                fullWidth
                onChange={handleChange}
                required
                sx={{ backgroundColor: '#fff',borderRadius:2 }}
                
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                // label="Password"
                value={formData.password}
                fullWidth
                required
                onChange={handleChange}
                sx={{ backgroundColor: '#fff',borderRadius:2  }}
              />
            </MDBox>
           
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Sign in
              </MDButton>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1,fontSize: "0.88rem",color:"#fff" }}
              >
                &nbsp;Remember me
              </MDTypography>
              <MDBox pl={6}>

              <MDTypography
                variant="h6"
                fontWeight="regular"
                color="info"
                onClick={handleForgotPasswordOpen}
                sx={{ cursor: "pointer", userSelect: "none",fontSize: "0.88rem",color:"#fff" }}
              >
                Forgot password ?
              </MDTypography>
            </MDBox>
              
            </MDBox>
          </MDBox>
        
        
      </Box>
    </Grid>
    
  </Grid>
  
</Grid>
</Box>
<Box sx={{mt: !error ? {xs:2,md:4} : { xs: 8, md: 4 },}}>
          {/* Footer Section */}
<Box textAlign="center"   sx={{
    p: {
      xs: 2,    // Padding of 2 for extra-small screens
      sm: 1.6   // Padding of 1.6 for small screens and larger
    }
  }} bgcolor="#00274D" color="#fff">
  <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
    Powered by Orbit Technology Solution
  </Typography>
  <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
  Contact us: Phone: 0963626061 | Email: ceo@orbit.et | 
  Website: <a href="http://www.orbit.et" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>www.orbit.et</a>   |
  <IconButton href="https://www.facebook.com/profile.php?id=100086330515097" target="_blank" color="inherit">
      <FacebookIcon />
    </IconButton>
</Typography>

  {/* <Box mt={1}>

    <IconButton href="https://www.facebook.com/profile.php?id=100086330515097" target="_blank" color="inherit">
      <FacebookIcon />
    </IconButton>

  </Box> */}
</Box>

        </Box>
</MDBox>
   {/* Forgot Password Dialog */}
   <Dialog fullWidth open={forgotPasswordDialogOpen} onClose={handleForgotPasswordClose}>
        <DialogTitle sx={{fontSize: "1rem"}}>Forgot Password</DialogTitle>
        <DialogContent>
          <MDBox component="form" role="form" onSubmit={handleForgotPasswordSubmit}>
            <MDBox >
              <MDInput
              my={1}
                type="email"
                name="forgotPasswordEmail"
                label="Enter your email"
                value={forgotPasswordEmail}
                fullWidth
                onChange={handleForgotPasswordChange}
                required
              />
            </MDBox>
            {forgotPasswordError && (
              <MDBox mb={2}>
                <Alert severity="error" variant="filled">
                  {forgotPasswordError}
                </Alert>
              </MDBox>
            )}
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleForgotPasswordClose} variant="gradient"
              size="small"
              color="black"
              sx={{ background: "grey" }}
           
              >

            Cancel
          </MDButton>
          <MDButton type="submit" variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleForgotPasswordSubmit}
            >
              
            Send
          </MDButton>
        </DialogActions>
        <LoadingDialog open={loading} />
      </Dialog>

   </PageLayout>
   
  );
};

export default Login;
 