/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from "@mui/material/Alert";
import MDTypography from 'components/MDTypography';
import { STORAGE_BASE_URL } from "api";

const FileViewer = ({ open, onClose, filePath, fileType }) => {
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
    setLoading(false);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        {fileType === "pdf" && (
          <div>
            <iframe
              src={`${STORAGE_BASE_URL}/${filePath}`}
              width={"100%"}
              height={"700px"}
            />
          </div>
        )}

        {fileType === "image" && (
          <>
            {loading && <CircularProgress />}
            {imageError ? (
              <Alert severity="error">
                Failed to load image. The file may be missing or inaccessible.
              </Alert>
            ) : (
              <img
                src={`${STORAGE_BASE_URL}/${filePath}`}
                alt="Selected File"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  display: loading ? "none" : "block",
                }}
                onError={handleImageError}
                onLoad={handleImageLoad}
              />
            )}
          </>
        )}

        {fileType === "other" && (
          <MDTypography variant="body2">
            <a href={filePath} download>
              Click here to download the file
            </a>
          </MDTypography>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" size="small" onClick={onClose} color="info">
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default FileViewer;
