import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState, useCallback, useMemo } from "react";


import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import {Card,CardContent} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
// import ViewEvaluation from './viewEvaluation';
import { useNavigate } from 'react-router-dom';

const Evaluation = () => {
    const [evaluation, setEvaluation] = useState([]);
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate()

    const fetchEvaluation = useCallback(async () => {
        
      try {
        const response = await axiosInstance.get(`/teacher-evaluations`
        );
        setEvaluation(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // End loading after fetching data
      } 
    },[])

    useEffect(() => {
     

    fetchEvaluation();
}, [fetchEvaluation]);




const handleViewDetail = (evaluation) => {
  navigate(`/view-evaluation/${evaluation.id}`);
}

const groupEvaluations = (evaluations) => {
  const grouped = {};
  evaluations.forEach((val) => {
    const key = `${val.course.course_name}-${val.instructor.full_name}-${val.year}-${val.semester}`;
    if (!grouped[key]) {
      grouped[key] = {
        course: val.course.course_name,
        course_number: val.course.course_number,
        instructor: val.instructor.full_name,
        email: val.instructor.email,
        year: val.year,
        semester: val.semester,
        id: val.instructor.id,
        evaluations: [],  // Collect evaluations here
      };
    }
    grouped[key].evaluations.push(val);
  });
  return Object.values(grouped);  // Convert object to array for rendering
};

// Example usage
const groupedData = useMemo(() => groupEvaluations(evaluation), [evaluation]);



const columnConfig = [
  { Header: 'Course No', accessor: 'course_number',showSortIcons: false },  
  { Header: 'Course', accessor: 'course' },  // Access a specific field
  { Header: 'Instructor', accessor: 'instructor',showSortIcons: false },
  { Header: 'Email', accessor: 'email',showSortIcons: false },
  { Header: 'Year', accessor: 'year',showSortIcons: false },
  { Header: 'Semester', accessor: 'semester',showSortIcons: false },
 
];

// Add columns for actions and other required fields
const columns = [
  {
    Header: '',
    accessor: 'action',
    width: 40,
    Cell: ({ row }) => (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <Button color="secondary" {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
              <MoreVertIcon />
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  
                  handleViewDetail(row.original);
                  popupState.close();
                }}
                style={{ color: '#1976d2' }}
              >
                <VisibilityIcon style={{ marginRight: 2.5 }} /> View Detail
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    ),
    showSortIcons: false,
  },
  ...columnConfig,  // Spread the configurations
];
      
  return (
    <DashboardLayout>
    <Card>
      <CardContent>
        <MDBox>
        <MDTypography variant="h5" fontWeight="medium" my={2}>
          Evaluation
        </MDTypography>
        <MDBox>
          <DataTable
            table={{
              columns,
              rows: groupedData,
            }}
            canSearch={true}
            sortable={true}
            rowsPerPage={5}
            showPagination
           
        
          />
          {!loading && evaluation.length === 0 && (
            <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
              fontStyle: "italic",
              fontWeight: "bold",
              color: "#9e9e9e", // Light grey color
              opacity: 0.7,
            }}>
              No Evaluation available
            </MDTypography>
          )}
        </MDBox>
        
        <LoadingDialog open={loading } />
          </MDBox>
      </CardContent>
    </Card>
  </DashboardLayout>
  )
}

export default Evaluation