import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import API_BASE_URL from "api";
import swal from "sweetalert";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import {
  TextField,
  Autocomplete,
} from "@mui/material";
import LoadingDialog from "components/Loading/LoadingDialog";

// Axios instance for API requests
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const AddAssetDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    "code": "",
    "name": "",
    "current_price": "",
    "status": "",
    "category_id": null,
    "location_id": null
  });
  const [loading, setLoading] = useState(false); 
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);

  const [error, setError] = useState({});

  const token = localStorage.getItem("authToken");

  // Fetch positions for Autocomplete dropdown
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const categoryResponse = await axiosInstance.get("/categories");
        const locationResponse = await axiosInstance.get("/locations");

        setCategories(categoryResponse.data); 
        setLocations(locationResponse.data);
      } catch (err) {
        console.error("Failed to fetch employees:", err);
      }finally{
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);


  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", // Clear the error for this field when it's being modified
    });
  };

  const handleModalSubmit = async () => {
    if (modalData.code=== "" ||
      modalData.category_id=== "" ||
      modalData.name=== 0 ||
      modalData.current_price=== 0) {
       alert("Please add all required fields to continue");
     }
     else{
    try {
      
      const response = await axios.post(`${API_BASE_URL}/assets`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Asset Created Successfully!",
          icon: "success",
        });

        // Reset form data
        setModalData({
        "code": "",
    "name": "",
    "current_price": "",
    "status": "",
    "category_id": null,
    "location_id": null
        });
        setError({});
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = err.response.data.error;
        setError(errors); // Set the error object
      } else {
        console.error("Error submitting form:", err);
      }
    }
  }
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
    <DialogTitle textAlign={"center"}>
      <AddIcon /> Add Asset
    </DialogTitle>
    <DialogContent>
      {/* Position Title */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Code
        </MDTypography>
        <MDInput
          type="text"
          name="code"
          fullWidth
          required
          value={modalData.code}
          onChange={handleModalChange}
        />
      </MDBox>
      {/* Job Description */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
        name
        </MDTypography>
        <MDInput
          type="text"
          name="name"
          fullWidth
          required
          value={modalData.name}
          onChange={handleModalChange}
        />
      </MDBox>

      {/* Minimum Salary */}
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
        current price
        </MDTypography>
        <MDInput
          type="number"
          name="current_price"
          fullWidth
          required
          value={modalData.current_price}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            status
          </MDTypography>
          <MDInput
            type="text"
            name="status"
            fullWidth
            required
            value={modalData.status}
            onChange={handleModalChange}
          />
        </MDBox>


      {/* Maximum Salary */}
      

      {/* Reports To (Autocomplete for selecting a manager) */}
      <MDBox mb={2}>
      
        <Autocomplete
          options={categories}
          getOptionLabel={(option) => option.code + ' ' + option.name } // Display the position title in the dropdown
          onChange={(event, newValue) => {
            setModalData({
              ...modalData,
              category_id: newValue ? newValue.id : null, // Set reports_to to the selected position's id
            });
          }}
          renderInput={(params) => <TextField {...params} label="Select Category" />}
        />
</MDBox>

<MDBox mb={2}>
      
      <Autocomplete
        options={locations}
        getOptionLabel={(option) => option.code + ' ' + option.name } // Display the position title in the dropdown
        onChange={(event, newValue) => {
          setModalData({
            ...modalData,
            location_id: newValue ? newValue.id : null, // Set reports_to to the selected position's id
          });
        }}
        renderInput={(params) => <TextField {...params} label="Select Location" />}
      />
</MDBox>

    </DialogContent>

    {/* Actions */}
    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleModalSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Cancel
      </MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />
  </Dialog>
  )
}

export default AddAssetDialog