import React, { useState ,useEffect, useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";

import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddCategoryDialog from "./addCategory";
import EditCategoryDialog from "./editCategory";
import LoadingDialog from "components/Loading/LoadingDialog";
import MDInput from "components/MDInput";

const Category = () => {
  const [search] = useState('');
  const token =  localStorage.getItem("authToken");
 
  const [showAlert, setShowAlert] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const[category,setCategory] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(false); 
 
  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  
  const fetchCategory = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/categories`, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      setCategory(response.data);
    } catch (err) {
      console.error(err);
    } finally{
      setLoading(false);
    }
    
     },[token]);
     useEffect(() => {

       fetchCategory();
     },[fetchCategory]);


  const handleAddCategory = (newCategory) => {
    setCategory((prevCategory) => [...prevCategory, newCategory]);
  };
  

  const handleEditCategory = (updatedCategory) => {
    
    setCategory(category.map((c) =>
      c.id === updatedCategory.id ? updatedCategory : c
    ));
  };

  const handleDeleteCategory = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this Category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setLoading(true);
      try {
        const response = await axios.delete(`${API_BASE_URL}/categories/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 204){
          setCategory(category.filter((c) => c.id !== id));
          swal({
            title: "Categorys Deleted Successfully!",
            // text: "Course Created Successfully!",
            icon: "success"
          });
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
          // text: "Course Created Successfully!",
          icon: "warning"
        });
      }finally{
        setLoading(false);
      }
    }
  };

  const handleOpenEdit = (category) => {
    setSelectedCategory(category);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedCategory(null);
  };

  const filteredData = search
  ? category && category.filter(c => 
      c.name &&
      c.name.toLowerCase().includes(search.toLowerCase())
    )
  : category;

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
      setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
 
    try {
        const response = await axios.post(`${API_BASE_URL}/import-categories`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization" : `Bearer ${token}`
            },
        });
        fetchCategory();
        alert(response.data.message);
    } catch (error) {
        console.error(error);
        alert('Error importing categories');
    }finally {
     }
};

  const columns = [
    {
      accessor: 'action',
      Header: '',  // You can leave this blank if you don't want a header
      width: 40,  // Explicit width in pixels
      padding:0,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteCategory(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'Category Code', accessor: 'code',showSortIcons: false, },
    { Header: 'name', accessor: 'name', },
    { Header: 'Description', accessor: 'description',showSortIcons: false, },
    { Header: 'Remark', accessor: 'remark',showSortIcons: false, },
    
   
   
  ];

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>

          <MDTypography variant="h5" fontWeight="medium">
            Category
          </MDTypography>
          <MDBox>
                  <DataTable
                    table={{
                      columns,
                      rows: filteredData,
                    }}
                    canSearch={true}
                    sortable={true}
                    pagination
                    rowsPerPage={5}
                    showPagination
                    widgets = {
                      <MDBox mx={2}>
                    <MDButton
                    variant="gradient"
                    ml="auto"
                        color="info"
                        size="small"
                        onClick={() => setOpenAdd(true)}
                        sx={{background:"#00274D", my:4,mx:3}}
                  >
                    <span>Add</span>
                  </MDButton>
                  <MDBox mx={2}>

                         <MDInput
                          type="file"
                          size="small"
                          onChange={handleFileChange}
                          sx={{ my: 0, width: "100%" }}
                        />
                        </MDBox>
                        <MDBox mx={2}>

                         <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={handleUpload}
                          sx={{ background: "#00274D", width: "100%" }}
                        >
                          <span>Import</span>
                        </MDButton>
                        </MDBox>
                  </MDBox>
                    }
                  />
          </MDBox>
          <AddCategoryDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddCategory} />
          
          {selectedCategory && (
          <EditCategoryDialog
              open={openEdit}
              onClose={handleCloseDialog}
              category={selectedCategory}
              onEdit={handleEditCategory}
          />
          )}
          
          
          </MDBox>
        </CardContent>
      </Card>
  <LoadingDialog open={loading} />
    </DashboardLayout>
  )
}

export default Category