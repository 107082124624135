import React,{useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";

const EditPermissionDialog = ({ open, onClose, permission, onEdit }) => {
    const [modalData, setModalData] = React.useState(permission);
    const [loading, setLoading] = useState(false);
  
    const token = localStorage.getItem("authToken");
    const handleModalChange = (e) => {
      const { name, value } = e.target;
      setModalData({
        ...modalData,
        [name]: value,
      });
    };

    const handleModalSubmit = async () => {
      setLoading(true);
        try {
          const response = await axios.put(`${API_BASE_URL}/permissions/${modalData.id}`, modalData, {
            headers: { "Authorization": `Bearer ${token}` }
          });
          
          if (response.status === 200) {
            onEdit(response.data);
            onClose();
            swal({
              title: "Permissions Updated Successfully!",
              // text: "Course Created Successfully!",
              icon: "success"
            });
          }
        } catch (error) {
          console.log(error.reponse)
        }finally {
          setLoading(false); // Stop loading
        }
      };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>Edit Permission</DialogTitle>
    <DialogContent>

         
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Permission Name
        </MDTypography>
        <MDInput
          type="text"
          name="name"
          fullWidth
          required
          value={modalData.name}
          onChange={handleModalChange}
        />
      </MDBox>
      
    </DialogContent>
    <DialogActions>
      <MDButton   variant="gradient"
                     size="small"
                     color="info"
                     sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit</MDButton>
      <MDButton   variant="gradient"
                     size="small"
                     color="black"
                     sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
    </DialogActions>
    <LoadingDialog open={loading}/>
  </Dialog>
  )
}

export default EditPermissionDialog
