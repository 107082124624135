import React, { useState ,useEffect} from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";




const Locations = () => {
  const [search] = useState('');
  const token =  localStorage.getItem("authToken");
 
  const [showAlert, setShowAlert] = useState(false);
  const[location,setLocation] = useState([])

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/locations`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        

        setLocation(response.data);
      } catch (err) {
        console.error(err);
      } 
      
    };
    fetchLocation();
  }, [token]);



  const filteredData = search
  ? location && location.filter(l => 
      l.name &&
      l.name.toLowerCase().includes(search.toLowerCase())
    )
  : location;

  const columns = [
   
    { Header: 'Code', accessor: 'code',showSortIcons: false, },
    { Header: 'name', accessor: 'name', },
    { Header: 'Description', accessor: 'description',showSortIcons: false, },
    { Header: 'Remark', accessor: 'remark',showSortIcons: false, },
    
   
   
  ];
  return (
    <>
    <MDBox>

    <MDTypography variant="h5" fontWeight="medium">
       Location
     </MDTypography>
     <MDBox>
            <DataTable
              table={{
                columns,
                rows: filteredData,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
             
            />
     </MDBox>
    
    

    </MDBox>
</>
  )
}

export default Locations