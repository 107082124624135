import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';

const EducationalBackground = ({educationalBackground}) => {
  const columns = [
     
    { Header: 'Education Level', accessor: 'level' },
    { Header: 'Educational Institution', accessor: 'educational_institution',showSortIcons: false, },
    { Header: 'Education Type', accessor: 'education_type',showSortIcons: false, },
    { Header: 'Attended Year', accessor: 'Attended_year',showSortIcons: false, },
   
  ];
  return (
    <MDBox>
      <Card>
        <CardContent>
        <MDTypography variant="h6">
                Education
              </MDTypography>
              <MDBox my={2}>
                    <DataTable
                      table={{
                        columns,
                        rows: educationalBackground,
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
          
                    />
          </MDBox>
          </CardContent>
      </Card>
    </MDBox>
  )
}

export default EducationalBackground