import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from "react";
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddPositionDialog from "./addPosition";
import EditPositionDialog from "./editPosition";
import LoadingDialog from "components/Loading/LoadingDialog";

const Position = () => {
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);

  
    const [openEdit, setOpenEdit] = useState(false);
    const[position,setPosition] = useState([])
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [openAdd, setOpenAdd] = useState(false)
    const [loading,setLoading] = useState(true);
    const [deletingStatus, setDeletingStatus] = useState(false); 

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);
    
      useEffect(() => {
        const fetchPosition = async () => {
          try {
            const response = await axios.get(`${API_BASE_URL}/positions`, {
              headers: { "Authorization": `Bearer ${token}` }
            });
            
           
    
            setPosition(response.data);
          } catch (err) {
            console.error(err);
          }   finally{
            setLoading(false);
          }
         
        };
        fetchPosition();
      }, [token]);

      const handleAddPosition = (newPosition) => {
        
        setPosition((prevPosition) => [...prevPosition, newPosition]);
      };
      
    
      const handleEditPosition = (updatedPosition) => {
        // Map through the existing positions and replace the one with the updated position
        setPosition((prevPositions) =>
          prevPositions.map((p) =>
            p.id === updatedPosition.id ? updatedPosition : p
          )
        );
      };
      

      const handleDeletePosition = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this position!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          setDeletingStatus(true);
          try {
            const response = await axios.delete(`${API_BASE_URL}/positions/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
            if(response.status === 204){
              setPosition(position.filter((position) => position.id !== id));
              swal({
                title: "Position Deleted Successfully!",
                // text: "Course Created Successfully!",
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              // text: "Course Created Successfully!",
              icon: "warning"
            });
          } finally {
            setDeletingStatus(false);
          }
        }
      };

      const handleOpenEdit = (position) => {
        setSelectedPosition(position);
        setOpenEdit(true);
      };

      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedPosition(null);
      };



      const columns = [
        {
          accessor: 'action',
          Header: '',  
          width: 40, 
          padding:0,
          Cell: ({ row }) => (
            <PopupState variant="popover"  popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }}  style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeletePosition(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        },
        { Header: 'Position Name', accessor: 'position_title' },
        { Header: 'Job description', accessor: 'job_description',showSortIcons: false, },
        { Header: 'Minimum Salary', accessor: 'salary_range_min',showSortIcons: false, },
        { Header: 'Maximum Salary', accessor: 'salary_range_max',showSortIcons: false, },
     
       
      ];
    
  return (
    <DashboardLayout>
        <MDBox >
        <Card>
            <CardContent>
                <MDTypography variant="h5" fontWeight="medium">
                   Position
                 </MDTypography>
                 <MDBox>
                
                <MDBox >
                    <DataTable
                      table={{
                        columns,
                        rows: position
                      }}
                      canSearch={true}
                      sortable={true}
                      rowsPerPage={5}
                      showPagination
                      widgets = {
                        <MDBox mx={2}>
                       <MDButton
                        variant="gradient"
                        color="info"
                        ml="auto"
                        onClick={() => setOpenAdd(true)}
                        sx={{background:"#00274D", my:2,  mx: { xs: 0, md: 2 }}}
                        >Add 
                        </MDButton>
                        </MDBox>
                      }
                    />
                </MDBox>
                
                </MDBox>
                <AddPositionDialog
                  open={openAdd}
                  onAdd={handleAddPosition}
                  onClose={handleCloseDialog}
                
                />
                {selectedPosition && (
                <EditPositionDialog
                  open={openEdit}
                  onClose={handleCloseDialog}
                  position={selectedPosition}
                  setPosition={setPosition}
                  onEdit={handleEditPosition}
                />
                )}
                <LoadingDialog open={loading || deletingStatus} />
            </CardContent>
                        </Card>
        </MDBox>
    </DashboardLayout>
  )
}

export default Position