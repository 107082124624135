import { Card } from "@mui/material";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useCallback, useEffect, useState } from "react";
import GradeReportDetails from "./viewDetail";
import LoadingDialog from 'components/Loading/LoadingDialog';


export default function GradeReportForNonStudent() {

  const token = localStorage.getItem("authToken");

  const [data, setData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [loading, setLoading] = useState(true); // Loading state

  const handleDialogClose = () => {
    setOpenDetails(false);
    setCurrentRow(null);
  }

  const handleOpenDetail = (row) => {
    setOpenDetails(true);
    setCurrentRow(row);
  };

  const columns = [
    {
      Header: "Student Name",
      Cell: ({ row }) => (
        <div>
          {row.original.student.first_name} {row.original.student.last_name}
        </div>
      )
    },
    { Header: "ID", accessor: "student.id",showSortIcons: false },
    { Header: "Gender", accessor: "student.gender",showSortIcons: false },
    { Header: "Program", accessor: "student.program.program_name" },
    { Header: "Degree Award", accessor: "student.program.degree_award",showSortIcons: false },
    {
      Header: "",
      accessor: "action",
      width: "40px",
      showSortIcons: false,
      Cell: ({ row }) => (
        <MDButton
          variant="body2"
          component="a"
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            boxShadow: 1,
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              color: 'secondary.main',
              boxShadow: 4,
            },
          }}
          onClick={() => handleOpenDetail(row.original)}
        >
          View Details
        </MDButton>
      ),
    
    },
  ];

  const fetchData = useCallback(async () => {
   
    try {
      const response = await axios.get(`${API_BASE_URL}/grade-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status === 200) {
        setData(response.data);
        console.log('mydata', response.data);

      }
    } catch (error) {
      console.error("Failed to fetch requests:", error);
    }finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DashboardLayout>
      <Card>
        <MDBox p={3}>
          <MDTypography>Grade Reports</MDTypography>
           <DataTable
            table={{
              columns,
              rows: data,
            }}
            sortable
            pagination
            entriesPerPage={{ defaultValue: 5 }}
            showPagination
          />
          {/* {!loading && data.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            Grade Reports not available
          </MDTypography>
        )} */}
        </MDBox>
      </Card>
      {currentRow && <GradeReportDetails student={currentRow.student} courses={currentRow.courses} open={openDetails} onClose={handleDialogClose} />
      }    
        <LoadingDialog open={loading} />
      </DashboardLayout>
  )
}
