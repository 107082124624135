import React, { useState, useEffect } from 'react';
import { Alert } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const EditPayrollDialog = ({ open, onClose, selectedPayroll, onEdit }) => {
  const [modalData, setModalData] = useState({
    year: '',
    amount: '',
    payroll_status: '',
    remark: '',
  });
  const [error, setError] = useState(null); 
  const [loading,setLoading]= useState(false)

  // Use useEffect to set modalData from selectedPayroll
  useEffect(() => {
    if (selectedPayroll) {
      setModalData(selectedPayroll);
    }
  }, [selectedPayroll]);

  const token = localStorage.getItem("authToken");

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${API_BASE_URL}/payrolls/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Payroll Updated Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      console.log(error.response);
      setError("An error occurred while updating the payroll.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}>
         Edit Payroll
      </DialogTitle>
      <DialogContent>
        {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Year
          </MDTypography>
          <MDInput
            type="text"
            name="year"
            fullWidth
            required
            value={modalData.year}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Month
          </MDTypography>
          <MDInput
            type="number"
            name="month"
            fullWidth
            required
            value={modalData.month}
            onChange={handleModalChange}
          />
        </MDBox>
      
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Remark
          </MDTypography>
          <MDInput
            type="text"
            name="remark"
            fullWidth
            value={modalData.remark}
            onChange={handleModalChange}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default EditPayrollDialog;
