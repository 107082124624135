import { Card, CardContent } from '@mui/material';
import API_BASE_URL from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OthersDetail from './others';
import ViewRegisterations from './registration';
import FileViewer from "components/FileViewer";
import MDButton from 'components/MDButton';
import LoadingDialog from 'components/Loading/LoadingDialog';


const ViewRegistrarDetail = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [activeTab, setActiveTab] = useState('education');
  const [search] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const { id } = useParams();
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const[applicantDetail,setApplicantsDetail] = useState([])

  const token =  localStorage.getItem("authToken");

  

  useEffect(() => {
    const fetchApplicantDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/applicant-student/${id}`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });

        setApplicantsDetail(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false)
      }
    };
    fetchApplicantDetail();
  },[id,token,updateTrigger]);




  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };

  const columns = [
    { Header: 'Country', accessor: 'country' },
    { Header: 'Institution', accessor: 'educational_institution', },
    { Header: 'Program', accessor: 'study_program' },
    { Header: 'Major', accessor: 'major' },
    { Header: 'Degree', accessor: 'class_of_degree',showSortIcons: false },
    { Header: 'Attended Year', accessor: 'attended_year',showSortIcons: false },
    {Header:'File Path',accessor:'document', Cell: ({ value }) => (
      <MDButton
        variant="body2"
        color="primary"
        component="a"
        onClick={() => handleViewFile(value)}
        style={{ cursor: 'pointer' }}
        sx={{ 
          cursor: 'pointer',
          textDecoration: 'underline',
          boxShadow: 1, // Adds a default shadow
          transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
          '&:hover': {
            color: 'secondary.main',
            boxShadow: 4, // Increases shadow intensity on hover
          }
        }}
      >
        View File
      </MDButton>
    ),}
  ];
  

  const filteredData = applicantDetail.educationalBackgrounds ? 
  applicantDetail.educationalBackgrounds.filter(education => {
    return (
      education.country.toLowerCase().includes(search.toLowerCase()) ||
      education.educational_institution.toLowerCase().includes(search.toLowerCase()) ||
      education.study_program.toLowerCase().includes(search.toLowerCase()) ||
      education.major.toLowerCase().includes(search.toLowerCase())
    );
  }) : [];


  return (
    <DashboardLayout>
      <MDBox>
        <MDBox display="flex" justifyContent="flex-start" alignItems="center" mb={2} p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
          <MDTypography
            variant="body2"
            onClick={() => handleTabChange('education')}
            style={{
              textDecoration: 'none',
              color: activeTab === 'education' ? 'blue' : 'black',
              borderBottom: activeTab === 'education' ? '2px solid blue' : 'none',
              cursor: 'pointer'
            }}
          >
            Education Background
          </MDTypography>
          <MDTypography
            variant="body2"
            onClick={() => handleTabChange('others')}
            style={{
              textDecoration: 'none',
              color: activeTab === 'others' ? 'blue' : 'black',
              borderBottom: activeTab === 'others' ? '2px solid blue' : 'none',
              marginLeft: '10px',
              cursor: 'pointer'
            }}
          >
            Others
          </MDTypography>
          <MDTypography
            variant="body2"
            onClick={() => handleTabChange('register')}
            style={{
              textDecoration: 'none',
              color: activeTab === 'register' ? 'blue' : 'black',
              borderBottom: activeTab === 'register' ? '2px solid blue' : 'none',
              marginLeft: '10px',
              cursor: 'pointer'
            }}
          >
            Registration Detail
          </MDTypography>
        </MDBox>

        {activeTab === 'education' && (
          <Card>
            <CardContent>
              <MDTypography variant="h5" fontWeight="medium">
                Educational Background
              </MDTypography>
              <MDBox mt={2}>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={false}
                  pagination
                  rowsPerPage={5}
                  showPagination
                />
              </MDBox>
            </CardContent>
          </Card>
        )}

      </MDBox>
        {activeTab === 'others' && (
      
          <OthersDetail applicantDetail={applicantDetail}/>

        )}
          {activeTab === 'register' && (
          <ViewRegisterations applicantDetail={applicantDetail} onTriggerUpdate={() => setUpdateTrigger((prev) => prev + 1)}/>

        )}
         <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
       <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default ViewRegistrarDetail;
