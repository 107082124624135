import React, { useState } from 'react';
import API_BASE_URL from 'api';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { axiosInstance } from 'api2';
import MDButton from 'components/MDButton';
import LoadingDialog from 'components/Loading/LoadingDialog';
import swal from 'sweetalert';

const BackupButtons = () => {
    const [loading, setLoading] = useState(false); 

    const handleBackup = async (type) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(`${API_BASE_URL}/backup`, { type });
            if(response.status === 200){
             swal({
                title: "Back Up Sucess!",
                text:"The system will send email and store the backup on your google drive",
                icon: "success",
              });
            }

        } catch (error) {
            swal({
                title: `Backup failed:', ${error}`,
                icon: "danger",
              });
            
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
        <div>
           
            <MDButton
                        variant="gradient"
                        color="info"
                        ml="auto"
                        onClick={() => handleBackup('db')}
                        sx={{background:"#00274D", my:4,mx:3}}
                        >Back Up Only Database
                        </MDButton>
             <MDButton
                        variant="gradient"
                        color="info"
                        ml="auto"
                        onClick={() => handleBackup('all')}
                        sx={{background:"#00274D", my:4,mx:3}}
                        >Back Up All files
                        </MDButton>
                        <LoadingDialog open={loading} />
                        </div>
        </DashboardLayout>
    );
};

export default BackupButtons;