import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddLeaveTypes from "./addLeaveType";
import EditLeaveType from "./editLeaveType";
import LoadingDialog from "components/Loading/LoadingDialog";

const LeaveType = () => {
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);
    
  
    const [openEdit, setOpenEdit] = useState(false);
    const[leaveTypes,setLeaveTypes] = useState([])
    const [selectedLeaveType, setSelectedLeaveType] = useState(null);
    const [loading,setLoading] = useState(true)
    const [deletingStatus, setDeletingStatus] = useState(false); 

    const [openAdd, setOpenAdd] = useState(false);

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); 
        }
        return () => clearTimeout(timer); 
      }, [showAlert]);

      const fetchLeaveTypes = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/leave-types`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
          setLeaveTypes(response.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }, [token]);
      
      useEffect(() => {
        fetchLeaveTypes();
      }, [fetchLeaveTypes]);
      

      const handleAddLeaveType = () => {
        
        fetchLeaveTypes();
      };

      const handleEditLeaveType = ( ) => {
        fetchLeaveTypes();
      };

    
      const handleDeleteLeaveType = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this LeaveTypes!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          setDeletingStatus(true);
          try {
            const response = await axios.delete(`${API_BASE_URL}/leave-types/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
          console.log("response",response.status)
            if(response.status === 200){
              setLeaveTypes(leaveTypes.filter((leave) => leave.id !== id));
             
              swal({
                title: "Leave Type Deleted Successfully!",
                
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              
              icon: "warning"
            });
          } finally {
            setDeletingStatus(false);
          }
        }
      };

      const handleOpenEdit = (leave) => {
        console.log("leave:",leave)
       
        setSelectedLeaveType(leave);
        setOpenEdit(true);
      };

      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedLeaveType(null);
      };
    
      const columns = [
        {
          accessor: 'action',
          Header: '', 
          width: 40,  
          padding: 0,
          Cell: ({ row }) => (
            <PopupState 
            variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button  color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }} style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteLeaveType(row.original.id);
                      popupState.close();
                    }} style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
        },
        { Header: 'Leave Type', accessor: 'leave_type_name', showSortIcons: true },  
        { Header: 'Description', accessor: 'description',  showSortIcons: false}, 
        { Header: 'Accrual Rate', accessor: 'accrual_rate',  showSortIcons: false},   
        { Header: 'Maximum Carryover', accessor: 'maximum_carryover',  showSortIcons: false},          
        
      ];
  return (
    <DashboardLayout>
    <Card>
        <CardContent>
            <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
               Leave Types
             </MDTypography>
             <MDBox>
                    <DataTable
                      table={{
                        columns,
                        rows: leaveTypes,
                      }}
                      canSearch={true}
                      sortable={true}
                      rowsPerPage={5}
                      showPagination
                      widgets = {
                        <MDBox ms={2}>
                       <MDButton
                       variant="gradient"
                       ml="auto"
                           color="info"
                           size="small"
                           onClick={() => setOpenAdd(true)}
                           sx={{background:"#00274D", my:4,mx:3}}
                     >
                       <span>Add</span>
                     </MDButton>
                     </MDBox>
                      }
                    />
             </MDBox>
             <AddLeaveTypes open={openAdd} onClose={handleCloseDialog} onAdd={handleAddLeaveType} />
            
            {selectedLeaveType && (
            <EditLeaveType
                open={openEdit}
                onClose={handleCloseDialog}
                leaveTypes={selectedLeaveType}
                onEdit={handleEditLeaveType}
            />
            )}
            
            <LoadingDialog open={loading || deletingStatus} />
        </MDBox>
        </CardContent>
    </Card>
</DashboardLayout>
  )
}

export default LeaveType