import {
    Autocomplete,
    TextField
  } from "@mui/material";
  import Dialog from "@mui/material/Dialog";
  import DialogActions from "@mui/material/DialogActions";
  import DialogContent from "@mui/material/DialogContent";
  import DialogTitle from "@mui/material/DialogTitle";
  import AddIcon from "@mui/icons-material/Add";
  import { axiosInstance } from "api2";
  import MDBox from "components/MDBox";
  import MDButton from "components/MDButton";
  import MDInput from "components/MDInput";
  import MDTypography from "components/MDTypography";
  import React, { useEffect, useState } from "react";
  import swal from "sweetalert";
  import LoadingDialog from "components/Loading/LoadingDialog";
  
  const EditLoan = ({ open, onClose, loan, onEdit }) => {
  
    const [loanData, setLoanData] = useState({
        loan_amount: "",
        loan_interest_rate: "",
        loan_start_date: "",
        loan_end_date: "",
      });
      const [loading,setLoading]=useState(false)

      useEffect(() => {
        if (loan) {
          setLoanData(loan);
        }
      }, [loan]);
   
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
  
    useEffect(() => {
      // Fetch employees data
      const fetchEmployees = async () => {
        try {
          const response = await axiosInstance.get('/employees');
          
          setEmployees(response.data);
        } catch (error) {
          console.error("Error fetching employees:", error);
        }
      };
      fetchEmployees();
    }, []);
  
    useEffect(() => {
      if (loan) {
        setLoanData(loan);
        setSelectedEmployee(
          employees.find((emp) => emp.id === loan.employee_id) || null
        );
      }
    }, [loan, employees]);
  
    const handleLoanDataChange = (e) => {
      const { name, value } = e.target;
      setLoanData({
        ...loanData,
        [name]: value,
      });
    };
  
    const handleLoanSubmit = async () => {
      if (!selectedEmployee) {
        swal("Error", "Please select an employee", "error");
        return;
      }
  
      const updatedLoanData = {
        ...loanData,
        employee_id: selectedEmployee.id,
      };

      setLoading(true);
  
      try {
        await axiosInstance.put(`/loans/${loan.id}`, updatedLoanData);
        swal("Success", "Loan updated successfully", "success");
        onEdit();
        onClose();
      } catch (error) {
        swal("Error", "Failed to update the loan", "error");
        console.error("Error updating loan:", error);
      } finally{
        setLoading(false);
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm">
        <DialogTitle textAlign="center">
          <AddIcon /> Edit Loan
        </DialogTitle>
        <DialogContent>
          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Employee
            </MDTypography>
            <Autocomplete
              fullWidth
              options={employees}
              getOptionLabel={(option) => {
                return `${option.first_name} ${option.last_name}` || '';
              }}
              onChange={(event, newValue) => setSelectedEmployee(newValue)}
              value={selectedEmployee}
              renderInput={(params) => <TextField {...params} label="Employee" />}
            />
          </MDBox>
  
          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Loan Amount
            </MDTypography>
            <MDInput
              type="number"
              name="loan_amount"
              fullWidth
              required
              value={loanData.loan_amount || ''}
              onChange={handleLoanDataChange}
            />
          </MDBox>
  
          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Interest Rate
            </MDTypography>
            <MDInput
              type="number"
              name="loan_interest_rate"
              fullWidth
              required
              value={loanData.loan_interest_rate || ''}
              onChange={handleLoanDataChange}
            />
          </MDBox>
  
          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Loan Start Date
            </MDTypography>
            <MDInput
              type="date"
              name="loan_start_date"
              fullWidth
              required
              value={loanData.loan_start_date || ''}
              onChange={handleLoanDataChange}
            />
          </MDBox>
  
          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Loan End Date
            </MDTypography>
            <MDInput
              type="date"
              name="loan_end_date"
              fullWidth
              required
              value={loanData.loan_end_date || ''}
              onChange={handleLoanDataChange}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleLoanSubmit}
          >
            Submit
          </MDButton>
          <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
          >
            Close
          </MDButton>
        </DialogActions>
        <LoadingDialog open={loading} />
      </Dialog>
    );
  };
  
  export default EditLoan;
  