// AddCourseDialog.js
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Alert } from '@mui/material';
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import LoadingDialog from 'components/Loading/LoadingDialog';


const AddGradeDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    grade:"",
    point:null,
    credit:1,
    min_score:0,
    max_score:100
  });
  const [error, setError] = useState(null);

  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false); 

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/grades-info`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
   
      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Grade Info Created Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
        setModalData({
          grade:"",
          point:null,
          credit:null
        })
      }
    } catch (error) {
      setError(error.response?.data?.errors?.grade);
      
    }finally {
      setLoading(false); // Stop loading
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}><AddIcon /> Add Grade Info</DialogTitle>
      <DialogContent>
        {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Grade
          </MDTypography>
          <MDInput
            type="text"
            name="grade"
            fullWidth
            required
            value={modalData.grade}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Point
          </MDTypography>
          <MDInput
            type="text"
            name="point"
            fullWidth
            required
            value={modalData.point}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Min Score
          </MDTypography>
          <MDInput
            type="text"
            name="min_score"
            fullWidth
            required
            value={modalData.min_score}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Max Score
          </MDTypography>
          <MDInput
            type="text"
            name="max_score"
            fullWidth
            required
            value={modalData.max_score}
            onChange={handleModalChange}
          />
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Credit
          </MDTypography>
          <MDInput
            type="text"
            name="credit"
            fullWidth
            required
            value={modalData.credit}
            onChange={handleModalChange}
          />
        </MDBox>
        
        
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient"
          size="small"
          color="info"
          sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit
        </MDButton>
        <MDButton variant="gradient"
          size="small"
          color="black"
          sx={{background:"grey"}} onClick={onClose}>Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  )
}

export default AddGradeDialog