import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { axiosInstance } from "api2";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import LoadingDialog from "components/Loading/LoadingDialog";
import { Select, MenuItem, FormControl} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const AddLeaveType = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    leave_type_name: '',
    description: '',
    accrual_rate: 0,
    maximum_carryover: 0,
    applicable_for: 'Both' // Default value
  });
  const [loading,setLoading] = useState(false);

  const [error, setError] = useState({});

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", 
    });
  };

  const handleModalSubmit = async () => {
    console.log("modalData:",modalData)
    if (modalData.leave_type_name=== "" ||
      modalData.description=== "" || modalData.applicable_for=== "" || modalData.accrual_rate=== 0 || modalData.maximum_carryover=== 0
     
    ) {
       alert("Please add all required fields to continue");
     }
     else{
    setLoading(true);
    try {
      
      const response = await axiosInstance.post(`/leave-types`, modalData);
      console.log("response:",response.data)

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Leave Type Created Successfully!",
          icon: "success",
        });

        setModalData({
          leave_type_name: '',
          description: '',
          accrual_rate: 0,
          maximum_carryover: 0,
          applicable_for: ''
        });
        setError({});
      }
    } catch (error) {
      if (error.response?.status === 400) {
        const errors = error.response.data.error;
        setError(errors); 
      }
    }finally{
      setLoading(false);
    }}
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign="center">
        <AddIcon /> Add Leave Type
      </DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Leave Type Name
          </MDTypography>
          <MDInput
            type="text"
            name="leave_type_name"
            fullWidth
            required
            value={modalData.leave_type_name}
            onChange={handleModalChange}
            error={!!error.leave_type_name}
          />
          {error.leave_type_name && (
            <MDTypography variant="caption" color="error">
              {error.leave_type_name[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Description
          </MDTypography>
          <MDInput
            type="text"
            name="description"
            fullWidth
            value={modalData.description}
            onChange={handleModalChange}
            error={!!error.description}
          />
          {error.description && (
            <MDTypography variant="caption" color="error">
              {error.description[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Accrual Rate
          </MDTypography>
          <MDInput
            type="number"
            name="accrual_rate"
            fullWidth
            required
            value={modalData.accrual_rate}
            onChange={handleModalChange}
            error={!!error.accrual_rate}
          />
          {error.accrual_rate && (
            <MDTypography variant="caption" color="error">
              {error.accrual_rate[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Maximum Carryover
          </MDTypography>
          <MDInput
            type="number"
            name="maximum_carryover"
            fullWidth
            required
            value={modalData.maximum_carryover}
            onChange={handleModalChange}
            error={!!error.maximum_carryover}
          />
          {error.maximum_carryover && (
            <MDTypography variant="caption" color="error">
              {error.maximum_carryover[0]}
            </MDTypography>
          )}
        </MDBox>

        {/* Applicable For Select Box */}
        <MDBox mb={2}>
  <MDTypography variant="caption" color="text" fontWeight="medium">
    Applicable For
  </MDTypography>
  <FormControl fullWidth sx={{ mb: 2 }}>
    <Select
      labelId="applicable-for-label"
      id="applicable-for-select"
      name="applicable_for"
      value={modalData.applicable_for}
      onChange={handleModalChange}
      required
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        padding: "10px",
        borderRadius: 1,
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          paddingRight: "32px",
        },
      }}
      IconComponent={() => (
        <ArrowDropDownIcon
          sx={{
            position: "absolute",
            right: 8,
            pointerEvents: "none",
          }}
        />
      )}
    >
      <MenuItem value="Both">Both</MenuItem>
      <MenuItem value="Male">Male</MenuItem>
      <MenuItem value="Female">Female</MenuItem>
    </Select>
  </FormControl>
  {error.applicable_for && (
    <MDTypography variant="caption" color="error">
      {error.applicable_for[0]}
    </MDTypography>
  )}
</MDBox>


      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddLeaveType;
