import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState,useCallback } from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from "api2";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Students from './students';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Card,CardContent } from '@mui/material';
import ZoomCreate from './create';
import EditZoom from './edit';


const Zoom = () => {
    const [loading, setLoading] = useState(true);
    const [zoom,setZoom] = useState([]);
    const [openView, setOpenView] = useState(false);
    const [students,setStudents] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [selectedZoomMeeting, setSelectedZoomMeeting] = useState(null);
    // const [deletingStatus, setDeletingStatus] = useState(false); 
    const [openEdit, setOpenEdit] = useState(false);
    const [meetingId,setMeetingId] = useState(null);

    const fetchZoom = useCallback(async () => {
      setLoading(true); // Start loading
    
      try {
        const response = await axiosInstance.get(`/zoom`,);
        setZoom(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // End loading after fetching data
      } 
    },[]);

    useEffect(() => {

        
      
      
        fetchZoom();
      }, [fetchZoom]);

      const handleViewDetails = (data) => {
        setStudents(data.students)
        setMeetingId(data.students[0].pivot.meeting_id)
    
        setOpenView(true);
        
      }

      const handleAddZoom = (newStatus) => {
       fetchZoom()
      };

      const handleAddParticipant = () => {
        fetchZoom()
       };

      const handleEditZoom = (updatedStatus) => {
        fetchZoom()
      };

      const handleCloseDialog = () => {
        setOpenView(false);
        setOpenAdd(false);
        setOpenEdit(false)
      };

      const handleOpenEdit = (zoom) => {
        setSelectedZoomMeeting(zoom);
        setOpenEdit(true);
      };

    //   const handleDeleteMeeting = async (id) => {
    //     const confirm = await swal({
    //       title: "Are you sure?",
    //       text: "You will not be able to recover this meeting!",
    //       icon: "warning",
    //       buttons: true,
    //       dangerMode: true,
    //     });
    
    //     if (confirm) {
    //       setDeletingStatus(true);
    
    //       try {
    //         const response = await axios.delete(`${API_BASE_URL}/zoom/${id}`,
    //           {
    //             headers: { "Authorization": `Bearer ${token}` }
    //           });
    //         if (response.status === 204) {
    //           setZoom((prevStatus) => prevStatus.filter((status) => status.id !== id)); // Optimistic update
    //         swal({
    //           title: "Meeting Deleted Successfully!",
    //           icon: "success",
    //         });
    //         }
    //       } catch (error) {
    //         swal({
    //           title: `${error.response.data.message}`,
    //           icon: "warning"
    //         });
    //       } finally {
    //         setDeletingStatus(false); // End loading for deletion
    //       } 
    //     }
    //   };

      

      const columns = [
        {
          accessor: 'action',
          Header: '',
          width: 40,
          padding: 0,
          Cell: ({ row }) => (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }} style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                    //   handleDeleteStatus(row.original.id);
                      popupState.close();
                    }} style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                    <MenuItem onClick={() => {
              handleViewDetails(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
        },
        { Header: 'Topic', accessor: 'topic' }, // Added Topic column
        
        { Header: 'Start Time', accessor: 'start_time' }, // Added Start Time column
        { Header: 'Duration (min)', accessor: 'duration', showSortIcons: false }, 
        { Header: 'Status', accessor: 'status', 
            Cell: ({ value }) => (
              value === 'waiting' ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
            ),
            showSortIcons: false,
          },
           {
        Header: 'Action',
        accessor: 'join_url',
        showSortIcons: false,
        Cell: ({ row }) => {
            const currentTime = new Date();
            const startTime = new Date(row.original.start_time); // Convert start_time to Date object
            
            // Check if the current time is past the start time
            if (currentTime > startTime) {
              return <MDTypography style={{ color: 'red',fontSize:'1rem',opacity:0.7 }}>Date Passed</MDTypography>; 
            }

            return (
                <Button
                    variant="contained"
                    style={{ backgroundColor: '#D4AF37', color: '#fff' }}
                    onClick={() => window.open(row.original.join_url, '_blank')} // Open in a new tab
                >
                    Start
                </Button>
            );
        },
    },
        
      
      ];
      

  return (
    <DashboardLayout>
        <Card>
            <CardContent>
                <MDBox>
                <MDTypography variant="h5" fontWeight="medium">
                  Zoom
                </MDTypography>
                <MDBox>
                  <DataTable
                    table={{
                      columns,
                      rows: zoom,
                    }}
                    canSearch={true}
                    sortable={true}
                    rowsPerPage={5}
                    showPagination
                    widgets={
                      <MDBox ms={2}>
                      <MDButton
                      variant="gradient"
                      ml="auto"
                          color="info"
                          size="small"
                          onClick={() => setOpenAdd(true)}
                          sx={{background:"#00274D", my:4,mx:3}}
                    >
                      <span>Add</span>
                    </MDButton>
                    </MDBox>
                
                    }
                  />
                  {!loading && zoom.length === 0 && (
                    <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      color: "#9e9e9e", // Light grey color
                      opacity: 0.7,
                    }}>
                      No Zoom available
                    </MDTypography>
                  )}
                </MDBox>
                
                <ZoomCreate open={openAdd} onClose={handleCloseDialog} onAdd={handleAddZoom} />
                 
                {selectedZoomMeeting && (
                  <EditZoom
                    open={openEdit}
                    onClose={handleCloseDialog}
                    zoom={selectedZoomMeeting}
                    onEdit={handleEditZoom}
                  />
                )}
                {
                        students &&(
                          <Students students={students} open={openView} id={meetingId} onClose={handleCloseDialog} onView={handleAddParticipant}/>
                        )
                      }
                <LoadingDialog open={loading } />
                  </MDBox>
            </CardContent>
        </Card>
    </DashboardLayout>
  )
}

export default Zoom;