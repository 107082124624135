import { Add, Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import API_BASE_URL from 'api';
import axios from 'axios';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { addStoreRequest } from '../../../../redux/features/storeRequestSlice';
import LoadingDialog from 'components/Loading/LoadingDialog';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json',
  },
});


const AddStoreDialog = ({onClose,onAdd,open}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [stores, setStores] = useState([]);
  const [assets, setAssets] = useState([]);
  const [remark, setRemark] = useState("");
  const [reason, setReason] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [displayRequests, setDisplayRequests] = useState([]);
  const [loading, setLoading] = useState(false); 


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [storesResponse, assetsResponse] = await Promise.all([
          axiosInstance.get("/stores"),
          axiosInstance.get("/assets"),
        ]);
        setStores(storesResponse.data);
        setAssets(assetsResponse.data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
        setError("Failed to fetch data");
      }finally{
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const handleAddRequest = () => {
    if (!selectedStore || !selectedAsset || quantity <= 0 || !remark) {
      setError("Please fill in all fields correctly.");
      return;
    }
  
    const newRequest = {
      store_id: selectedStore.id,        // Store ID
      store_name: selectedStore.name,    // Store Name
      asset_id: selectedAsset.id,        // Asset ID
      asset_name: selectedAsset.name,    // Asset Name
      asset_code:selectedAsset.code,
      quantity,
      remark,
      reason
    };

    dispatch(addStoreRequest(newRequest));
  
    // Add new request to display list
    setDisplayRequests((prev) => [...prev, newRequest]);
  
    // Clear input fields
    setSelectedStore(null);
    setSelectedAsset(null);
    setQuantity("");
    setRemark("");
    setReason("")
    setError(null); // Clear error if any
  };
  

  const handleDeleteRequest = (index) => {
    setDisplayRequests((prev) => prev.filter((_, i) => i !== index));
  };

  const columns = [
    { Header: 'Store', accessor: 'store_name' },    // Display Store Name
    { Header: 'Asset Code', accessor: 'asset_code' }, 
    { Header: 'Asset Name', accessor: 'asset_name' },    // Display Asset Name
    { Header: 'Quantity', accessor: 'quantity' },
    { Header: 'Remark', accessor: 'remark' },
    {
      Header: 'Actions',
      Cell: ({ row }) => (  // Use Cell to define a custom render for the action button
        <IconButton onClick={() => handleDeleteRequest(row.index)}>
          <Delete />
        </IconButton>
      ),
    },
  ];
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();    
   
  
    try {
      if (displayRequests.length === 0) {
        setError("Please add at least one request.");
        return;
      }
  
  
      const requestPayload = {
        store_id: displayRequests[0]?.store_id ,  // Take from displayRequests or fallback to selectedStore
        reason:displayRequests[0]?.reason,
        lines: displayRequests.map((request) => ({
          asset_id: request.asset_id,
          quantity: request.quantity,
          remark: request.remark,
        })),
      };
      setLoading(true);
  
      // Submit the store request
       await axiosInstance.post("/store-request", requestPayload);
      onAdd();
      setDisplayRequests([]);
      setSelectedStore(null);
  
      onClose();  // Close the dialog
      swal({
        title: "Store requests added successfully!",
        icon: "success",
      });
    } catch (err) {
      console.error("Error submitting store request:", err);
      setError(
        err.response?.data?.message || "Failed to submit store requests"
      );
    } finally{
      setLoading(false);
    }
  };
  


  return (
    <DashboardLayout>
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle textAlign="center">
        <Add /> Add Store Request
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={6} lg={6}>
            <Autocomplete
              options={stores}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedStore(newValue);
                setError(null)
              }}
              value={selectedStore}
              renderInput={(params) => (
                <TextField {...params} label="Store" />
              )}
            />

          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Reason"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={assets}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedAsset(newValue);
                setError(null)
              }}
              value={selectedAsset}
              renderInput={(params) => (
                <TextField {...params} label="Asset" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
                setError(null);
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Remark"
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value)
                setError(null)
              }}
              fullWidth
            />
          </Grid>
          {error && (
          <MDTypography color="error" variant="body2" mt={5} mx={2}>
            {error}
          </MDTypography>
        )}
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <MDButton
              variant="gradient"
              size="small"
              color="info"
              type="button"
              sx={{ background: "#00274D" }}
              onClick={handleAddRequest}
            >
              Add Request
            </MDButton>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              table={{
                columns,
                rows: displayRequests,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
            />
          </Grid>
        </Grid>
      
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>

  </DashboardLayout>
  )
}

export default AddStoreDialog