import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import moment from "moment/moment";
import LoadingDialog from "components/Loading/LoadingDialog";

const DetailBincardTransaction = () => {
    const token =  localStorage.getItem("authToken");
    
    const[detailbincard,setDetailBincard] = useState([])
    const [loading, setLoading] = useState(false); 
    
      const fetchAsset = useCallback(async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${API_BASE_URL}/asset-transaction`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
                 
          setDetailBincard(response.data);
        } catch (err) {
          console.error(err);
        } finally{
          setLoading(false);
        }
       
      },[token]);
      const formatDate = (dateString) => {
        return moment(dateString).format('MM/DD/YYYY'); 
      };
      useEffect(() => {

        fetchAsset();
      },[fetchAsset]);

      const columns = [
        { Header: 'Asset Code', accessor: 'asset.code',showSortIcons: true, },
        { Header: 'Asset Name', accessor: 'asset.name',showSortIcons: true, },
        { Header: 'Quantity', accessor: 'quantity',showSortIcons: true, },
        { Header: 'Transaction Type', accessor: 'transaction_type',showSortIcons: true, },
        {
            Header: 'Date',
            accessor: 'created_at',
            showSortIcons: true,
            Cell: ({ value }) => formatDate(value), 
          },        { Header: 'Location', accessor: 'store_id',showSortIcons: false, },
      ];
  return (

    <DashboardLayout>
        <Card>
            <CardContent>

        <MDBox >
                <MDTypography variant="h5" fontWeight="medium">
                   Detail Asset Transaction
                 </MDTypography>
                 <MDBox>
                
                <MDBox >
                    <DataTable
                      table={{
                        columns,
                        rows: detailbincard
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
                    />
                </MDBox>
                
                </MDBox>
                
        </MDBox>
                        
        </CardContent>
        </Card>
        <LoadingDialog open={loading} />
        </DashboardLayout>
  )
}

export default DetailBincardTransaction