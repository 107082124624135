import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    first_name: '',
    last_name: '',
    grand_father_name: '',
    gender:null,
    mother_name: '',
    birth_place: '',
    birth_date: '',
    email: '',
    phone: '',
    position_id: '',
    resident_city: '',
    woreda: '',
    kebele: '',
    house_number: '',
    house_phone: '',
    is_married: 0,
    spouse_full_name: '',
    education_level: '',
    emergency_full_name: '',
    emergency_address: '',
    emergency_phone: '',
    employment_date: '',
    employment_type: '',
    marriage_certificate: null,
    profile_picture:null,
    hours_per_week: 0,
    staff_id: '',
    salary: 0.00,
    kids: [],
    educational_backgrounds: [],
    editStatus: null,
}
const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setEmployee: (state, action) => {
            
            return { ...state, ...action.payload };            

        },
        setEditStatus: (state, action) => {
            state.editStatus = action.payload; // Set the edit status
        },
        addKid: (state, action) => {
            state.kids.push(action.payload);
        },
        deleteKid: (state, action) => {
            state.kids = state.kids.filter(kid =>
                JSON.stringify(kid) !== JSON.stringify(action.payload)
            );
        },
        addEducationalBackground: (state, action) => {
            state.educational_backgrounds.push(action.payload);
        },

        updateEducationalBackground: (state, action) => {
            state = state.map((item) =>
              item.id === action.payload.id ? action.payload : item
            );
          },
       
          deleteEducationalBackground: (state, action) => {
            state.educational_backgrounds = state.educational_backgrounds.filter(education =>
              !(education.attended_year === action.payload.attended_year &&
                education.education_type === action.payload.education_type &&
                education.educational_institution === action.payload.educational_institution)
            );
        }
          
          
    },
});

export const { setEmployee, setEditStatus, addKid, deleteKid,addEducationalBackground,updateEducationalBackground,deleteEducationalBackground } = employeeSlice.actions;
export default employeeSlice.reducer;