import React,{useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Alert } from '@mui/material';
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import LoadingDialog from "components/Loading/LoadingDialog";

const EditLocation = ({ open, onClose, location, onEdit }) => {
    const [modalData, setModalData] = useState(location);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); 
  const token = localStorage.getItem("authToken");
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null);
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${API_BASE_URL}/locations/${modalData.id}`, modalData, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        onEdit(response.data);
        onClose();
        swal({
          title: "Location Updated Successfully!",
          // text: " Created Successfully!",
          icon: "success"
        });
      }
    } catch (error) {
      setError(error.response?.data?.errors);
    }finally{
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}><AddIcon /> Edit  Location</DialogTitle>
    <DialogContent>
      {error && (
        <MDBox mx={2} mt={2} mb={2}>
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        </MDBox>
      )}
       <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Code
        </MDTypography>
        <MDInput
          type="text"
          name="code"
          fullWidth
          required
          value={modalData.code}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Location Name
        </MDTypography>
        <MDInput
          type="text"
          name="name"
          fullWidth
          required
          value={modalData.name}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Description
        </MDTypography>
        <MDInput
          type="text"
          name="description"
          fullWidth
          required
          value={modalData.description}
          multiline
          rows={5}
          onChange={handleModalChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Remark
        </MDTypography>
        <MDInput
          type="text"
          name="remark"
          fullWidth
          required
          multiline
          rows={3}
          value={modalData.remark}
          onChange={handleModalChange}
        />
      </MDBox>
     
      
    </DialogContent>
    <DialogActions>
      <MDButton   variant="gradient"
                     size="small"
                     color="info"
                     sx={{background:"#00274D"}} onClick={handleModalSubmit}>Submit</MDButton>
      <MDButton   variant="gradient"
                     size="small"
                     color="black"
                     sx={{background:"grey"}} onClick={onClose}>Cancel</MDButton>
    </DialogActions>
    <LoadingDialog open={loading} />
  </Dialog>
  )
}

export default EditLocation