import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api"; // Update with your actual API base URL
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';


const AddPayrollDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    year: "",
    month: "",
    payroll_status: "generated",
    remark: "",
  });
  const [loading,setLoading] = useState(false);

  const [error, setError] = useState({});

  const token = localStorage.getItem("authToken");

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", // Clear the error for this field when it's being modified
    });
  };

  const handleModalSubmit = async () => {
    setLoading(true)
    try {
      const response = await axios.post(`${API_BASE_URL}/payrolls`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        onAdd();
        onClose();
        swal({
          title: "Payroll Created Successfully!",
          icon: "success",
        });

        setModalData({
          year: "",
          month: "",
          payroll_status: "",
          remark: "",
        });
        setError({});
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.error;
        setError(errors); // Set the error object
      }
    } finally {
      setLoading(false)
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}>
        <AddIcon /> Add Payroll Entry
      </DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Year
          </MDTypography>
          <MDInput
            type="number"
            name="year"
            fullWidth
            required
            value={modalData.year}
            onChange={handleModalChange}
            error={!!error.year}
          />
          {error.year && (
            <MDTypography variant="caption" color="error">
              {error.year[0]} {/* Show the error message for the 'year' field */}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Month (1-12)
          </MDTypography>
          <MDInput
            type="number"
            name="month"
            fullWidth
            required
            value={modalData.month}
            onChange={handleModalChange}
            error={!!error.month}
          />
          {error.month && (
            <MDTypography variant="caption" color="error">
              {error.month[0]} {/* Show the error message for the 'month' field */}
            </MDTypography>
          )}
        </MDBox>

       
  



        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Remark
          </MDTypography>
          <MDInput
            type="text"
            name="remark"
            fullWidth
            required
            value={modalData.remark}
            onChange={handleModalChange}
            error={!!error.remark}
          />
          {error.remark && (
            <MDTypography variant="caption" color="error">
              {error.remark[0]} {/* Show the error message for the 'remark' field */}
            </MDTypography>
          )}
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddPayrollDialog;
