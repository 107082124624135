import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import AddLeaveRequest from "./addLeaveRequest";
import EditLeaveRequest from "./editLeaveRequest";
import LoadingDialog from 'components/Loading/LoadingDialog';
import MDButton from "components/MDButton";
import FileViewer from "components/FileViewer";


const LeaveRequest = () => {
    const targetId = localStorage.getItem("targetId");
    const token =  localStorage.getItem("authToken");
    const [loading,setLoading] = useState(true)
    const [openEdit, setOpenEdit] = useState(false);
    const[leaveRequests,setLeaveRequests] = useState([])
    const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
  


    const [openAdd, setOpenAdd] = useState(false);
      const fetchLeaveRequests = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/employee/${targetId}/leaves`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
         
          setLeaveRequests(response.data);
        } catch (err) {
          console.error(err);
        }finally {
          setLoading(false)
        }
      }, [token,targetId]);
      
      useEffect(() => {
        fetchLeaveRequests();
      }, [fetchLeaveRequests]);

      const handleViewFile = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();
        setSelectedFile(filePath);
        setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
        setOpenDialog(true);
      };
      
      const handleAddLeaveRequest = (data) => {
        fetchLeaveRequests();
        
       
      };

      const handleEditLeaveRequest = ( ) => {
        fetchLeaveRequests();
      };

  

  
      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedLeaveRequest(null);
      };
     
      const columns = [
        
        { Header: 'Leave Type Name', accessor: 'leave_type.leave_type_name', showSortIcons: true },
        { Header: 'Start Date', accessor: 'start_date', showSortIcons: false },
        { Header: 'End Date', accessor: 'end_date', showSortIcons: false },
        { Header: 'Reason', accessor: 'reason', showSortIcons: false },
        { Header: 'Status', accessor: 'status', showSortIcons: false },
        { Header: 'Applicable For', accessor: 'leave_type.applicable_for', showSortIcons: false },
        {
          Header: 'Attachment',
          accessor: 'attachment',
          showSortIcons: false,
          Cell: ({ value }) => (
            <>
              {value  ? (
                <MDButton
                  variant="body2"
                  color="primary"
                  component="a"
                  onClick={() => handleViewFile(value)}
                  style={{ cursor: 'pointer' }}
                  sx={{ 
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    boxShadow: 1, // Adds a default shadow
                    transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
                    '&:hover': {
                      color: 'secondary.main',
                      boxShadow: 4, // Increases shadow intensity on hover
                    }
                  }}
                >
                  View File
                </MDButton>
              ) : null}
            </>
          ),
        },
      
      ];
      
    
      
  return (
    <DashboardLayout>
    <Card>
        <CardContent>
            <MDBox>
            <MDTypography my={2} variant="h5" fontWeight="medium">
               Leave Requests
             </MDTypography>
             <MDBox>
          
                          <DataTable
                              table={{
                                  columns,
                                  rows: leaveRequests,
                              }}
                              canSearch={true}
                              sortable={true}
                              rowsPerPage={5}
                              showPagination
                              widgets = {
                                <MDBox mx={2}>
                               <MDButton
                               variant="gradient"
                               ml="auto"
                                   color="info"
                                   size="small"
                                   onClick={() => setOpenAdd(true)}
                                   sx={{background:"#00274D", my:2,  mx: { xs: 0, md: 2 }}}
                             >
                               <span>Add</span>
                             </MDButton>
                             </MDBox>
                              }
                          />  
                          {!loading && leaveRequests.length === 0 && (
                            <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
                              fontStyle: "italic",
                              fontWeight: "bold",
                              color: "#9e9e9e", // Light grey color
                              opacity: 0.7,
                            }}>
                              Leave Requests not available
                            </MDTypography>
)}
   
             </MDBox>
             <AddLeaveRequest open={openAdd} onClose={handleCloseDialog} onAdd={handleAddLeaveRequest} />
            
            {selectedLeaveRequest && (
            <EditLeaveRequest
                open={openEdit}
                onClose={handleCloseDialog}
                leaveRequests={selectedLeaveRequest}
                onEdit={handleEditLeaveRequest}
            />
            )}
            
            <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
            </MDBox>
        </CardContent>
        <LoadingDialog open={loading} />
    </Card>
</DashboardLayout>
  )
}

export default LeaveRequest