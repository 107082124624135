import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from 'examples/Tables/DataTable';
import { Typography, TextField, Autocomplete,Card,CardContent } from "@mui/material";
import API_BASE_URL from "api";
import MDBox from 'components/MDBox';
import LoadingDialog from "components/Loading/LoadingDialog";
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const AttendanceList = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [attendanceResponse] = await Promise.all([
          axiosInstance.get("/attendances"),
        
        ]);
        const uniqueCourses = Array.from(
            new Set(attendanceResponse.data.map(attendance => JSON.stringify(attendance.course)))
          ).map(course => JSON.parse(course));
  
          setCourses(uniqueCourses);
        console.log("courses:",uniqueCourses)
        // setCourses(coursesResponse.data);

        // Process attendance data
        const groupedData = attendanceResponse.data.reduce((acc, attendance) => {
          const key = `${attendance.student.id}-${attendance.course.id}`;
          if (!acc[key]) {
            acc[key] = {
              id: `${attendance.student.id}-${attendance.course.id}`,
              studentName: `${attendance.student.first_name} ${attendance.student.last_name}`,
              courseName: attendance.course.course_name,
              courseNumber: attendance.course.course_number,
              attendanceByDate: {},  // Store attendance data by actual date
              totalDays: 0,
              attendedDays: 0,
            };
          }
          
          // Store attendance status for the actual date
          acc[key].attendanceByDate[attendance.day] = attendance.status;
  
          // Update attendance stats
          acc[key].totalDays += 1;
          if (attendance.status === 'present') acc[key].attendedDays += 1;
          return acc;
        }, {});
  
        setAttendanceData(Object.values(groupedData));

  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
  
    fetchData();
  }, []);



  const generateColumns = (attendanceData) => {
    const allDates = new Set();

    attendanceData.forEach((item) => {
      Object.keys(item.attendanceByDate).forEach((date) => allDates.add(date));
    });

    const sortedDates = Array.from(allDates).sort();

    const dateColumns = sortedDates.map((date) => ({
      Header: date,
      accessor: (row) => row.attendanceByDate[date] || "-",
      Cell: ({ value }) => renderStatus(value),  // Assuming you have a renderStatus function
      showSortIcons: false,
    }));

    return [
      { Header: "Student Name", accessor: "studentName" },
      { Header: "Course Number", accessor: "courseNumber", showSortIcons: false },
      ...dateColumns,
      
    ];
  };

  const columns = generateColumns(attendanceData);
  const renderStatus = (status) => {
    switch (status) {
      case 'present':
        return <CheckCircleIcon color="success" />;
      case 'absent':
        return <CancelIcon color="error" />;
      case 'late':
        return (
          <Typography
            component="span"
            style={{ display: 'flex', alignItems: 'center', color: '#FF9800',fontSize:"1rem" }}  // Orange color for late
          >
            <AccessTimeIcon style={{ marginRight: 4, }} /> {/* Optional clock icon */}
            Late
          </Typography>
        );
      default:
        return '-';
    }
  };

  const filteredAttendanceData = selectedCourse
  ? attendanceData.filter(item => item.courseName === selectedCourse.course_name)
  : attendanceData;


  return (
    <DashboardLayout>
        <Card>
            <CardContent>
                <MDBox>
                
                    <Typography variant="h5" fontWeight="medium" mt={1}>
                      Attendance List
                    </Typography>
                      <DataTable
                        table={{
                          columns: columns,
                          rows: filteredAttendanceData,
                        }}
                        canSearch={true}
                        showTotalEntries={false}
                        isSorted={true}
                        noEndBorder
                        entriesPerPage
                        pagination={{ variant: "gradient", color: "info" }}
                        widgets={
                          <MDBox >
                            <Autocomplete
                              fullWidth
                              sx={{ mx: 2, my: 2, width: '200px' }}
                              options={courses}
                              getOptionLabel={(option) => option.course_name || ''}
                              onChange={(event, newValue) => {
                                setSelectedCourse(newValue); // Update the selected course
                              }}
                              
                              value={selectedCourse}
                              renderInput={(params) => (
                              <TextField {...params}
                              label="Course"
                              InputProps={{
                                ...params.InputProps,
                                style: { height: '40px' }, // Adjust the height as needed
                              }}
                              InputLabelProps={{
                                style: { marginTop: '0px' }, // Adjust the label margin if needed
                              }}
                              />
                            )}
                            />
                          </MDBox>
                        }
                      />
                  <LoadingDialog open={loading} />
                </MDBox>
            </CardContent>
        </Card>
    </DashboardLayout>
  );
};

export default AttendanceList;