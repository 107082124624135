// src/api.js

// const API_BASE_URL = "https://manage.mks.edu.et/api"; 
// const STORAGE_BASE_URL = "https://manage.mks.edu.et/storage";

// const API_BASE_URL = "http://127.0.0.1:8000/api";
// const STORAGE_BASE_URL = "http://127.0.0.1:8000/storage";


//const API_BASE_URL = "http://188.245.35.116:8000/api"; 
//const STORAGE_BASE_URL = "http://188.245.35.116:8000/storage";

const API_BASE_URL = "https://mksapi.mksportal.com/api"; 
const STORAGE_BASE_URL = "http://mksapi.mksportal.com/storage";

export { STORAGE_BASE_URL };
export default API_BASE_URL;



