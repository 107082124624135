import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Alert, Grid, Checkbox, FormControlLabel } from '@mui/material';
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";


const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
});

const EditRoleDialog = ({ open, onClose, role, onEdit }) => {
    const [modalData, setModalData] = useState(role);
    const [error, setError] = useState(null);
    const [fetchedPermissions, setFetchedPermissions] = useState([]);
    const [loading,setLoading] = useState(false);
    const [loading2,setLoading2] = useState(true);

    const token = localStorage.getItem("authToken");

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axiosInstance.get("/permissions");
                setFetchedPermissions(response.data);
            } catch (err) {
                console.error("Failed to fetch permissions:", err);
            }
            finally {
                setLoading2(false);
            }
        };

        fetchPermissions();
    }, []);

    useEffect(() => {
        setModalData(role);
    }, [role]);

    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalData({
            ...modalData,
            [name]: value,
        });
        setError(null);
    };

    const handlePermissionChange = (permissionId) => {
        const permission = fetchedPermissions.find(p => p.id === permissionId);
    
        setModalData(prevData => {
          const updatedPermissions = prevData.permissions.some(p => p.id === permissionId)
            ? prevData.permissions.filter(p => p.id !== permissionId)
            : [...prevData.permissions, permission]; // Add the entire permission object
    
          return { ...prevData, permissions: updatedPermissions };
        });
      };

    const handleModalSubmit = async () => {
        setLoading(true);
        try {
            
            const roleData = {
                name: modalData.name, // "test role"
                permission: modalData.permissions.map(permission => permission.name), 
              };            
            const response = await axios.put(`${API_BASE_URL}/roles/${modalData.id}`, roleData, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            
            if (response.status === 200) {
                onEdit(response.data);
                onClose();
                swal({
                    title: "Role Updated Successfully!",
                    icon: "success"
                });
            }
        } catch (error) {
            console.log(error.response);
            setError("Failed to update role. Please try again.");
        } finally{
            setLoading(false)
        } 
    };

    const isPermissionChecked = (permissionId) => {
        return modalData.permissions.some(p => 
            (typeof p === 'object' && p.id === permissionId) || 
            (typeof p === 'number' && p === permissionId)
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle textAlign={"center"}>Edit Role</DialogTitle>
            <DialogContent>
                {error && (
                    <MDBox mx={2} mt={2} mb={2}>
                        <Alert severity="error" variant="filled">
                            {error}
                        </Alert>
                    </MDBox>
                )}
                <MDBox mb={2}>
                    <MDTypography variant="body2" color="text" fontWeight="medium" >
                        Role Name
                    </MDTypography>
                    <MDInput
                        type="text"
                        name="name"
                        required
                        value={modalData.name}
                        onChange={handleModalChange}
                        sx={{width:"60%"}}
                    />
                </MDBox>

                <MDBox my={3}>
                    <MDTypography variant="h6" color="text" fontWeight="medium">
                        Add Permissions
                    </MDTypography>
                    <Grid container spacing={2}>
                        {fetchedPermissions.map((permission) => (
                            <Grid item xs={12} sm={6} md={3} key={permission.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isPermissionChecked(permission.id)}
                                            onChange={() => handlePermissionChange(permission.id)}
                                        />
                                    }
                                    label={permission.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="info"
                    sx={{background:"#00274D"}}
                    onClick={handleModalSubmit}
                >
                    Submit
                </MDButton>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{background:"grey"}}
                    onClick={onClose}
                >
                    Cancel
                </MDButton>

            </DialogActions>
            <LoadingDialog open={loading || loading2}/>
        </Dialog>
    )
}

export default EditRoleDialog;