import { Dialog, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';
import DialogActions from "@mui/material/DialogActions";
import { axiosInstance } from 'api2';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import DataTable from 'examples/Tables/DataTable';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import LoadingDialog from 'components/Loading/LoadingDialog';

const ApproveStudents = ({ openView, onClose, registeredStudents }) => {

  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const updatedStudents = registeredStudents.map(student => ({
      ...student,
      status: student.status === "submitted" ? "Approved" : student.status,
    }));
    setFilteredStudents(updatedStudents);
  }, [registeredStudents]);
  
  const handleStatusChange = (studentIndex, field, value) => {
    const updatedStudents = [...filteredStudents];
    if (updatedStudents[studentIndex]) {
      updatedStudents[studentIndex][field] = value;
      setFilteredStudents(updatedStudents);
    } else {
      console.error("Student not found at index:", studentIndex);
    }
  };

  const handleSubmit = async () => {
  
    const submitData = {
      students: filteredStudents.map(student => ({
        student_id: student.student.id, // Assuming `student` object contains the ID
        status: student.status,
        course_history_id: student.course_history_id,
      }))
    };

    setLoading(true);

    try {
      
      const response = await axiosInstance.post(`/approve-registrations`, submitData);
      swal("Success!", response.data.message, "success");
      onClose(); // Close the dialog after submission
    } catch (error) {
      console.error("Error updating registrations:", error);
      swal("Error!", "There was an error updating registrations.", "error");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const columns = [
    { Header: 'Full Name', accessor: (row) => `${row.student.first_name} ${row.student.last_name || ''}`, id: 'fullName' },
    { Header: 'Registered Date', accessor: 'registration_date', showSortIcons: false },
    { Header: 'Status', accessor: 'student.status', showSortIcons: false },
    { Header: 'Payment Status', accessor: (row) => row.is_paid ? 'Paid' : 'Not Paid', id: 'paymentStatus', showSortIcons: false },
    { Header: 'Email', accessor: 'student.email_1' },
    { Header: 'Phone', accessor: 'student.phone', showSortIcons: false },
    { Header: 'Class', accessor: 'student.class', showSortIcons: false },
    {
      Header: 'Action',
      accessor: 'status',
      showSortIcons: false,
      Cell: ({ row }) => {
        const studentIndex = row.index;

        const currentStatus = filteredStudents[studentIndex]?.status === "submitted"
          ? "Approved"
          : filteredStudents[studentIndex]?.status;

        return (
          <MDBox style={{ position: 'relative' }}>
            <Select
              value={currentStatus}
              onChange={(event) => handleStatusChange(studentIndex, 'status', event.target.value)}
              sx={{
                backgroundColor: "#f5f5f5",
                padding: "10px",
                borderRadius: 1,
              }}
            >
              <MenuItem value="Approved">Approve</MenuItem>
              <MenuItem value="Declined">Decline</MenuItem>
            </Select>
          </MDBox>
        );
      }
    }
  ];

  const rows = registeredStudents.map(registration => ({
    ...registration,
    student: registration.student,
  }));

  return (
    <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle textAlign="center">Registered Students</DialogTitle>
      <DialogContent>
        <DataTable
          table={{
            columns,
            rows
          }}
          canSearch={true}
          sortable={true}
          pagination
          rowsPerPage={5}
          showPagination
        />
      </DialogContent>
      <DialogActions>
        <MDButton
          mt={2}
          variant="gradient"
          color="info"
          size="medium"
          onClick={handleSubmit}
          sx={{ background: "#00274D", my: 4, mx: 1 }}
        >
          Submit 
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default ApproveStudents;
