import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { checkPermission } from "utils/checkPermission";
import { Link } from "react-router-dom";
import ServerDataTable from "examples/Tables/DataTable/serverDataTable";

const formatKey = (key) => {
  return key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
};

const Elibrary = () => {
  const [open, setOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
  const [file, setFile] = useState(null);

  const token = localStorage.getItem("authToken");

  const columns = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          component="button"
          onClick={() => {
            console.log(row.original);
            setCurrentRowData(row.original);
            setOpen(true);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    { Header: "Call NO1.", accessor: "call_number_line1", showSortIcons: false },
    { Header: "Call NO2", accessor: "call_number_line2", showSortIcons: false },
    { Header: "Call NO3", accessor: "call_number_line3", showSortIcons: false },

    { Header: "Bar Code", accessor: "barcode", showSortIcons: false },
    { Header: "ISBN", accessor: "isbn", showSortIcons: false },
    {
      Header: "Author Name",
      accessor: (row) => `${row.authors_first_name} ${row.authors_last_name}`,
    },

    { Header: "Publisher", accessor: "publisher", showSortIcons: false },
    {
      Header: "Subject Headings",
      accessor: "subject_headings",
      showSortIcons: false,
    },
  ];

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${API_BASE_URL}/elibrary`, formData, {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setUploadProgress(percentage); // Update the progress state
        },
      });

      const result = response.data.data; // Assuming response is JSON
      console.log(result);
    } catch (error) {
      console.error(error);
    } finally {
      setUploadProgress(0); // Reset progress after upload is complete
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium" my={2}>
              Elibrary
            </MDTypography>
            <MDBox>
              <MDBox>
                <ServerDataTable
                  fetchDataUrl={`${API_BASE_URL}/elibrary`}
                  table={{
                    columns,
                    // rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                  widgets={
                    checkPermission("upload_concourse_file") ? (
                      <MDBox mx={2}>
                        <MDInput
                          type="file"
                          required
                          size="small"
                          onChange={handleFileChange}
                          sx={{ my: 4, mx: 1 }}
                        />

                        <MDButton
                          variant="gradient"
                          ml="auto"
                          color="info"
                          size="small"
                          onClick={handleSubmit}
                          sx={{ background: "#00274D", my: 4, mx: 1 }}
                        >
                          <span>Upload concourse file</span>
                        </MDButton>

                        {/* Progress Bar */}
                        {uploadProgress > 0 && (
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                            sx={{ my: 2 }}
                          />
                        )}
                      </MDBox>
                    ) : null
                  }
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </CardContent>
      </Card>
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Book Details</DialogTitle>
          <DialogContent>
            {currentRowData
              ? Object.entries(currentRowData).map(([key, value]) => (
                  <MDTypography variant="body1" key={key}>
                    <strong>{formatKey(key)}:</strong> {value}
                  </MDTypography>
                ))
              : null}
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose} color="primary">
              Close
            </MDButton>
          </DialogActions>
        </Dialog>
      </>
    </DashboardLayout>
  );
};

export default Elibrary;
