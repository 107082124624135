import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { useMemo } from 'react';

const StudentRegisteredDetails = ({ applicantDetail }) => {

  const columns = useMemo(() => [

    {
      Header: 'Semester',
      accessor: row => row.course_history?.semester || 'N/A',
      sortable: true,
    },
    {
      Header: 'Year',
      accessor: row => row.course_history?.year || applicantDetail.class || 'N/A',
      sortable: true,
    },
    {
      Header: 'Course Number',
      accessor: row => row.course_history?.course?.course_number || 'N/A',
      sortable: true,
    },
    {
      Header: 'Course Name',
      accessor: row => row.course_history?.course?.course_name || 'N/A',
      sortable: true,
    },
    {
        Header: 'Instructor',
        accessor: row => row.course_history?.instructor?.full_name || 'N/A',
        sortable: true,
      },
    {
      Header: 'Is Paid',
      accessor: row => row.is_paid ? 'Yes' : 'No',
      showSortIcons: false,
    },
    {
      Header: 'Registration Date',
      accessor: 'registration_date',
      sortable: true,
    },
    {
      Header: 'Transfer Credits',
      accessor: 'transfer_credits',
      sortable: true,
    },
    {
      Header: 'Remark',
      accessor: row => row.course_history?.remark || 'N/A',
      showSortIcons: false,
    },
  ], [applicantDetail]);

  const data = useMemo(() => {
    if (!applicantDetail || !applicantDetail.registrations) return [];
    return applicantDetail.registrations;
  }, [applicantDetail]);

  return (
    <Card>
      <CardContent>
        <MDBox mb={3}>
          <MDTypography variant="h5" fontWeight="medium">
            Registrations for {applicantDetail.first_name} {applicantDetail.last_name}
          </MDTypography>
        </MDBox>
        <MDBox>
          <DataTable
            table={{ columns, rows: data }}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      </CardContent>
    </Card>
  );
};

export default StudentRegisteredDetails;